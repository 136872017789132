import store from "../store";
import { apiFetch } from ".";
import { v4 as uuidV4 } from "uuid";
import { words, capitalize, orderBy } from "lodash";

export const getHistory = async ({ partner, endpoint = `treasury-file-generation-requests?partner=${partner}`, iStore = store }) => {
  if (!partner) {
    const message = `Unable to get the partner's history. Partner (${partner}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ endpoint });
  if (!response) return;
  const defaultOrderedResponse = orderBy(response, [(data) => data.requestDate], ["desc"]);
  const transformedResponse = defaultOrderedResponse?.map((r) => {
    function convertName(name) {
      name = name.includes(":user/") ? name.split(":user/")[1] : name.split("okta_")[1];
      name = words(name.split("@")[0])
        ?.map((word) => capitalize(word))
        .join(" ");
      return name;
    }
    return {
      requestDate: r?.requestDate,
      requestor: convertName(r?.requestor),
      amount: r?.amount,
      partner: r?.partner,
      source: r?.source,
      destination: r?.destination,
      status: r?.status,
    };
  });
  return transformedResponse;
};

export const create = async ({ body, endpoint = `treasury-file-generation-requests` }, method = "POST") => {
  console.log("create body", body);
  const response = await apiFetch({ body, endpoint, method });
  if (response?.status === "success") {
    store.notifications.push({
      id: uuidV4(),
      type: "success",
      titleText: "Success",
      bodyText: body.successMessage,
      canCloseMessage: true,
      autoCloseTimeout: 4000,
      widthOverride: "300px",
    });
    store.handleStoreUpdates(store);
  } 
  return response;
};

const endpoints = {
  get: getHistory,
  create,
};
export default endpoints;
