import { CheckPicker, TagPicker, Radio, RadioGroup, Checkbox, DatePicker } from 'rsuite';
import { isEmpty, orderBy } from 'lodash';
import InputField from "../TextInput";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import microservices from '../../services/microservices';

const handleClean = async ({ selectedAnswerObjects, cb, question }) => {
  for (let answer of selectedAnswerObjects) {
    const data = {
      method: 'delete',
      selectedObject: answer
    };
    cb.setSelectedAnswerIds([]);
    cb.setSelectedAnswerObjects([]);
    await cb.save({ data, question });
  }
};

const handleChange = ({ selectedData, dataSource, cb, selectedAnswerObjects, question, method, useAsync, setDisableItemList }) => {
  if (isEmpty(selectedData)) {
    return handleClean({ selectedAnswerObjects, cb, question });
  }
  const newSelectedAnswerObjects = selectedData?.map(id => dataSource?.find(answer => answer?.id === id));
  const data = {};
  data.method = method ? method : newSelectedAnswerObjects?.length < selectedAnswerObjects?.length ? 'delete' : 'create';
  data.selectedObject = microservices.getUniqueEntry(selectedAnswerObjects, newSelectedAnswerObjects)[0];
  data.additionalComments = !!data?.selectedObject?.additionalComments;
  data.commentsRequired = !!data?.selectedObject?.commentsRequired;
  cb.setSelectedAnswerIds([...selectedData]);
  cb.setSelectedAnswerObjects([...newSelectedAnswerObjects]);
  if(setDisableItemList){
    setDisableItemList([data.selectedObject.id]);
  }
  if (useAsync) return (async () => await cb.save({ data, question }))();
  cb.save({ data, question, setDisableItemList });
};

const Tag = ({ save, handleChange, question, isMock }) => {
  const [selectedAnswerIds, setSelectedAnswerIds] = useState([]);
  const [selectedAnswerObjects, setSelectedAnswerObjects] = useState([]);
  const answers = orderBy(question?.answers, 'sortOrder', 'asc');
  const responses = question?.responses;
  const [disableItemList, setDisableItemList] = useState([]);

  const cb = {
    setSelectedAnswerIds,
    setSelectedAnswerObjects,
    save
  };

  const originalResponses = useRef([]);

  const setup = () => {
    if (responses) {
      if (responses?.answers) {
        originalResponses.current = structuredClone([...responses.answers]);
      }
      const answerIds = responses?.map(response => answers?.find(answer => answer?.value === response?.value && answer?.questionId === response?.questionId)?.id);
      setSelectedAnswerIds([...answerIds]);
      const answerObjects = answerIds?.map(id => answers?.find(answer => answer?.id === id));
      setSelectedAnswerObjects([...answerObjects]);
    }
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {!isMock ? !isEmpty(answers) &&
        <TagPicker
          data={answers || []}
          labelKey="value"
          valueKey="id"
          value={selectedAnswerIds}
          cleanable={false}
          classdescription="tag-picker"
          className='is-capitalized'
          menuStyle={{ minWidth: "200px" }}
          disabledItemValues={disableItemList}
          onChange={(data) => handleChange({ selectedData: data, dataSource: answers, cb, selectedAnswerObjects, question, setDisableItemList })}
        />
        :
        !isEmpty(answers) && <TagPicker
          data={answers?.map(answer => ({ label: answer?.value, value: answer.value }))}
          labelKey="value"
          valueKey="value"
          cleanable={false}
          classdescription="tag-picker"
          className='is-capitalized'
          style={{ minWidth: "200px" }}
        />}
    </div>
  )
};

const MultipleChoice = ({ save, handleChange, question, isMock }) => {
  const [selectedAnswerIds, setSelectedAnswerIds] = useState([]);
  const [selectedAnswerObjects, setSelectedAnswerObjects] = useState([]);
  const answers = orderBy(question?.answers, 'sortOrder', 'asc');
  const responses = question?.responses;
  const [disableItemList, setDisableItemList] = useState([]);

  const cb = {
    setSelectedAnswerIds,
    setSelectedAnswerObjects,
    save
  };

  const originalResponses = useRef([]);

  const setup = () => {
    if (responses) {
      if (responses?.answers) {
        originalResponses.current = structuredClone([...responses.answers]);
      }
      const answerIds = responses?.map(response => answers?.find(answer => answer?.value === response?.value && answer?.questionId === response?.questionId)?.id);
      setSelectedAnswerIds([...answerIds]);
      const answerObjects = answerIds?.map(id => answers?.find(answer => answer?.id === id));
      setSelectedAnswerObjects([...answerObjects]);
    }
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, []);

  return !isMock ? !isEmpty(answers) && <CheckPicker
    data={answers || []}
    labelKey="value"
    valueKey="id"
    value={selectedAnswerIds || []}
    cleanable={false}
    searchable={false}
    disabledItemValues={disableItemList}
    onChange={(data) => handleChange({ selectedData: data, dataSource: answers, cb, selectedAnswerObjects, question, setDisableItemList })}
    style={{ minWidth: "200px" }}
  /> :
    !isEmpty(answers) && <CheckPicker
      data={answers?.map(answer => ({ label: answer?.value, value: answer.value }))}
      labelKey='value'
      valueKey='value'
      cleanable={false}
      className='is-capitalized'
      searchable={false}
      style={{ minWidth: "200px" }}
    />
};

const BoolRadio = ({ save, handleChange, question, isMock }) => {
  const [selectedAnswerIds, setSelectedAnswerIds] = useState([]);
  const [selectedAnswerObjects, setSelectedAnswerObjects] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const answers = orderBy(question?.answers, 'sortOrder', 'asc');
  const responses = question?.responses;

  const cb = {
    setSelectedAnswerIds,
    setSelectedAnswerObjects,
    save
  };

  const originalResponses = useRef([]);

  const setup = () => {
    if (responses) {
      if (responses?.answers) {
        originalResponses.current = structuredClone([...responses.answers]);
      }
      const answerIds = responses?.map(response => answers?.find(answer => answer?.value === response?.value && answer?.questionId === response?.questionId)?.id);
      setSelectedAnswerIds([...answerIds]);
      const answerObjects = answerIds?.map(id => answers?.find(answer => answer?.id === id));
      setSelectedAnswerObjects([...answerObjects]);
    }
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, []);

  return (
    !isMock ? !isEmpty(answers) && <div className='is-capitalized is-flex is-align-items-center'>
      {(!answers?.length || (answers?.length !== 2)) && <p className='help is-danger'>Answer set must contain 2 entries and only 2 entries</p>}
      {answers?.length === 2 && <RadioGroup
        name="radioList"
        inline={true}
        value={selectedAnswerIds[0] || ''}
        onChange={async (data) => {
          setDisabled(true);
          const selectedDataToArray = [data];
          if (selectedAnswerObjects?.length) {
            await handleChange({ selectedData: [selectedAnswerObjects[0]?.id], dataSource: answers, cb, selectedAnswerObjects: [], question, method: 'delete', useAsync: true }).catch;
          }
          await handleChange({ selectedData: selectedDataToArray, dataSource: answers, cb, selectedAnswerObjects: [], question, useAsync: true });
          setDisabled(false);
        }}
      >
        <Radio value={answers[0]?.id} disabled={disabled}>{answers[0]?.value}</Radio>
        <Radio value={answers[1]?.id} disabled={disabled}>{answers[1]?.value}</Radio>
      </RadioGroup>}
      {!!selectedAnswerIds?.length && <i
        className={`fa-solid fa-close ml-5 is-clickable ${disabled ? 'has-text-grey-light ban-cursor' : ''}`}
        title="clear choice"
        onClick={() => {
          (async () => {
            setDisabled(true);
            await handleChange({ selectedData: [], dataSource: answers, cb, selectedAnswerObjects, question, useAsync: true })
            setDisabled(false);
          })()
        }}
      />}
    </div>
      :
      !isEmpty(answers) && <div className='is-capitalized'>
        {(!answers?.length || (answers?.length !== 2)) && <p className='help is-danger'>Answer set must contain 2 entries and only 2 entries</p>}
        {answers?.length === 2 && <RadioGroup name="radioList" inline={true}>
          <Radio value={answers[0]?.value}>{answers[0]?.value}</Radio>
          <Radio value={answers[1]?.value}>{answers[1]?.value}</Radio>
        </RadioGroup>}
      </div>
  )
};

const SingleSelectRadio = ({ save, handleChange, question, isMock }) => {
  const [selectedAnswerIds, setSelectedAnswerIds] = useState([]);
  const [selectedAnswerObjects, setSelectedAnswerObjects] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const answers = orderBy(question?.answers, 'sortOrder', 'asc');
  const responses = question?.responses;

  const cb = {
    setSelectedAnswerIds,
    setSelectedAnswerObjects,
    save
  };

  const originalResponses = useRef([]);

  const setup = () => {
    if (responses) {
      if (responses?.answers) {
        originalResponses.current = structuredClone([...responses.answers]);
      }
      const answerIds = responses?.map(response => answers?.find(answer => answer?.value === response?.value && answer?.questionId === response?.questionId)?.id);
      setSelectedAnswerIds([...answerIds]);
      const answerObjects = answerIds?.map(id => answers?.find(answer => answer?.id === id));
      setSelectedAnswerObjects([...answerObjects]);
    }
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, []);

  return (
    !isMock ? !isEmpty(answers) && <div className='is-capitalized is-flex is-align-items-center'>
      <RadioGroup
        name="radioList"
        inline={true}
        value={selectedAnswerIds[0] || ''}
        onChange={async (data) => {
          setDisabled(true);
          const selectedDataToArray = [data];
          if (selectedAnswerObjects?.length) {
            await handleChange({ selectedData: [selectedAnswerObjects[0]?.id], dataSource: answers, cb, selectedAnswerObjects: [], question, method: 'delete', useAsync: true });
          }
          await handleChange({ selectedData: selectedDataToArray, dataSource: answers, cb, selectedAnswerObjects: [], question, useAsync: true });
          setDisabled(false);
        }}
      >
        {answers?.map(answer => (
          <Radio key={answer?.id} value={answer?.id} disabled={disabled} >{answer?.value}</Radio>
        ))}
      </RadioGroup>
      {!!selectedAnswerIds?.length && <i
        className={`fa-solid fa-close ml-5 is-clickable ${disabled ? 'has-text-grey-light ban-cursor' : ''}`}
        title="clear choice"
        onClick={() => {
          (async () => {
            setDisabled(true);
            if (disabled) return;
            await handleChange({ selectedData: [], dataSource: answers, cb, selectedAnswerObjects, question, useAsync: true });
            setDisabled(false);
          })()
        }}
      />}
    </div>
      :
      !isEmpty(answers) && <div className='is-capitalized'>
        <RadioGroup name="radioList" inline={true}>
          {answers?.map(answer => (
            <Radio key={answer?.id} value={answer?.value}>{answer?.value}</Radio>
          ))}
        </RadioGroup>
      </div>
  )
};

const BoolCheck = ({ save, handleChange, question, isMock }) => {
  const [selectedAnswerIds, setSelectedAnswerIds] = useState([]);
  const [selectedAnswerObjects, setSelectedAnswerObjects] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const answers = orderBy(question?.answers, 'sortOrder', 'asc');
  const responses = question?.responses;

  const cb = {
    setSelectedAnswerIds,
    setSelectedAnswerObjects,
    save
  };

  const originalResponses = useRef([]);

  const setup = () => {
    if (responses) {
      if (responses?.answers) {
        originalResponses.current = structuredClone([...responses.answers]);
      }
      const answerIds = responses?.map(response => answers?.find(answer => answer?.value === response?.value && answer?.questionId === response?.questionId)?.id);
      setSelectedAnswerIds([...answerIds]);
      const answerObjects = answerIds?.map(id => answers?.find(answer => answer?.id === id));
      setSelectedAnswerObjects([...answerObjects]);
    }
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, []);

  return (
    !isMock ? !isEmpty(answers) && <div className='is-capitalized'>
      {answers?.length !== 1 && <p className='help is-danger'>Answer set must only contain one entry. Currently there are {answers?.length} assigned to this question.</p>}
      {answers?.length === 1 && <Checkbox
        value={answers[0]?.id}
        disabled={disabled}
        checked={!!selectedAnswerIds[0]}
        onChange={(id, checked) => {
          (async () => {
            setDisabled(true);
            let data = { dataSource: answers, cb, question, useAsync: true };
            data = checked ? { ...data, selectedData: [id], selectedAnswerObjects: [], method: 'create' } :
              { ...data, selectedData: [], selectedAnswerObjects: [selectedAnswerObjects[0]], method: 'delete' };
            await handleChange({ ...data });
            setDisabled(false);
          })();
        }}
      >{answers[0]?.value}</Checkbox>}
    </div>
      :
      !isEmpty(answers) && <div className='is-capitalized'>
        {answers?.length !== 1 && <p className='help is-danger'>Answer set must only contain one entry. Currently there are {answers?.length} assigned to this question.</p>}
        {answers?.length === 1 && <Checkbox value={answers[0]?.value}>{answers[0]?.value}</Checkbox>}
      </div>
  )
};

const SingleSelectDropList = ({ save, handleChange, question, isMock }) => {
  const [selectedAnswerIds, setSelectedAnswerIds] = useState([]);
  const [selectedAnswerObjects, setSelectedAnswerObjects] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const answers = orderBy(question?.answers, 'sortOrder', 'asc');
  const responses = question?.responses;

  const cb = {
    setSelectedAnswerIds,
    setSelectedAnswerObjects,
    save
  };

  const originalResponses = useRef([]);

  const setup = () => {
    if (responses) {
      if (responses?.answers) {
        originalResponses.current = structuredClone([...responses.answers]);
      }
      const answerIds = responses?.map(response => answers?.find(answer => answer?.value === response?.value && answer?.questionId === response?.questionId)?.id);
      setSelectedAnswerIds([...answerIds]);
      const answerObjects = answerIds?.map(id => answers?.find(answer => answer?.id === id));
      setSelectedAnswerObjects([...answerObjects]);
    }
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, []);

  return (
    !isMock ? <div>
      {(answers.length < 2) && <p className='help is-danger'>Select an answer set with more than one answer</p>}
      {(answers.length > 1) && <div className="field is-capitalized">
        <div className="select mr-2">
          <select
            value={selectedAnswerIds[0]}
            disabled={disabled}
            onChange={(e) => {
              (async () => {
                setDisabled(true);
                let data = { dataSource: answers, cb, question, useAsync: true };
                if (selectedAnswerObjects.length) {
                  data = { ...data, selectedData: [], selectedAnswerObjects: [selectedAnswerObjects[0]], method: 'delete' };
                  await handleChange({ ...data });
                }
                if (e?.target?.value !== 'Select') {
                  data = { ...data, selectedData: [e?.target?.value], selectedAnswerObjects: [], method: 'create' }
                  await handleChange({ ...data });
                }
                setDisabled(false);
              })();
            }} >
            <option>Select</option>
            {answers?.map(answer => <option key={answer.id} value={answer.id}>{answer.value}</option>)}
          </select>
        </div>
      </div>}
    </div>
      :
      <div>
        {(answers.length < 2) && <p className='help is-danger'>Select an answer set with more than one answer</p>}
        {(answers.length > 1) && <div className="field is-capitalized">
          <div className="select mr-2">
            <select value={selectedAnswerIds[0]} onChange={e => setSelectedAnswerIds([e.target.value])}>
              <option>Select</option>
              {answers?.map(answer => <option key={answer.id} value={answer.id}>{answer.value}</option>)}
            </select>
          </div>
        </div>}
      </div>
  )
};

const Text = ({ save, question, isMock, maxlength }) => {
  const [textValue, setTextValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const responses = question?.responses || [];
  const isUpdate = useRef(false);

  const handleSave = () => {
    (async () => {
      setDisabled(true);
      const data = {};
      if (!textValue && responses?.length) {
        data.method = 'delete';
        data.selectedObject = { ...responses[0] }
      } else if (!textValue && isEmpty(responses)) {
        setDisabled(false);
        return;
      } else {
        data.method = isUpdate?.current && responses[0]?.id  ? 'update' : 'create';
        data.comment = isUpdate?.current ? textValue : null;
        data.selectedObject = {
          id: isUpdate?.current ? responses[0]?.id : null,
          value: 'Text Answer',
          questionId: question?.id,
          comments: textValue
        };
      }
      await save({ data, question });
      if(question?.responses?.length){
        isUpdate.current = true;
      }
      setDisabled(false);
    })();
  };

  const handleInput = (e) => {
    setTextValue(e?.target?.value);
  };

  const setup = () => {
    if (responses?.length) {
      isUpdate.current = true;
      setTextValue(responses[0]?.comments);
    }
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, []);

  return (
    !isMock ? <InputField
      type="textarea"
      value={textValue}
      maxlength={maxlength ?? 1024}
      disabled={disabled}
      onChange={handleInput}
      onBlur={handleSave}
    />
    :
    <InputField
      type="textarea"
    />
  )
};

const DatePickerQuestion = ({ save, question, isMock }) => {
  const [dateValue, setDateValue] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const responses = question?.responses || [];
  const isUpdate = useRef(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const setup = () => {
    if (responses?.length) {
      isUpdate.current = true;
      setDateValue(responses[0]?.value);
    }
  };

  useEffect(() => {
    // Setup function only called once on initial render
    setup();
  
    // eslint-disable-next-line
  }, []);

  const handleSave = useCallback(async () => {
    (async () => {
      setDisabled(true);
      const data = {};
      if (!dateValue && responses?.length) {
        data.method = 'delete';
        data.selectedObject = responses[0] ;
      } else if (!dateValue && isEmpty(responses)) {
        setDisabled(false);
        return;
      } else {
        data.method = isUpdate.current && responses[0]?.id ? 'update' : 'create';
        if(data.method === 'update'){
          responses[0].value = dateValue;
        }
        data.value = isUpdate.current ? dateValue : null;
        data.selectedObject = {
          id: isUpdate.current ? responses[0]?.id : null,
          value: dateValue,
          questionId: question?.id,
          comments: null,
          type: 'date-picker'
        };
      }
      await save({ data, question });
      if (question?.responses?.length) {
        isUpdate.current = true;
      }
      setDisabled(false);
    })();
    // eslint-disable-next-line
  }, [dateValue, question, save]);

  const formatDateToString = (date) => {
    if (!(date instanceof Date)) {
      return date; // If it's not a Date object, return it as is
    }
  
    const month = date.getMonth() + 1; // Months are zero-indexed
    const day = date.getDate();
    const year = date.getFullYear();
  
    return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;
  };
  
  const handleInput = (event) => {
    if (event) {
      const selectedDate = event instanceof Date ? formatDateToString(event) : event;
      setDateValue(selectedDate);
    } else {
      console.error('Invalid date');
    }
  };

  const clearDate = () => {
    setDateValue(null);
  }

  return (
    !isMock ? (
      <div className={isDialogOpen ? 'datepicker-open' : 'datepicker-closed'}>
        <DatePicker
          value={dateValue && new Date(dateValue)}
          disabled={disabled}
          onSelect={handleInput}
          onOk={handleSave}
          format="MM-dd-yyyy"
          onClean={clearDate}
          onOpen={() => setIsDialogOpen(true)}
          onClose={() => setIsDialogOpen(false)}
        />
      </div>
    ) : (
      <DatePicker
        value={dateValue}
        disabled={disabled}
        onChange={handleInput}
      />
    )
  );
};


export default function QuestionType({ type, answers, isMock, style, className, ...props }) {

  const AnswerField = () => {
    switch (type) {
      case ('multiple choice'):
        return <MultipleChoice handleChange={handleChange} isMock={isMock} {...props} />
      case ('tag'):
        return <Tag handleChange={handleChange} isMock={isMock} {...props} />
      case ('text'):
        return <Text isMock={isMock} {...props} />
      case ('bool radio'):
        return <BoolRadio handleChange={handleChange} isMock={isMock} {...props} />
      case ('bool check'):
        return <BoolCheck handleChange={handleChange} isMock={isMock} {...props} />
      case ('single select radio'):
        return <SingleSelectRadio handleChange={handleChange} isMock={isMock} {...props} />
      case ('single select droplist'):
        return <SingleSelectDropList handleChange={handleChange} isMock={isMock} {...props} />
      case ('date picker'):
        return <DatePickerQuestion handleChange={handleChange} isMock={isMock} {...props} />
      default:
        return <p className='help is-danger'>Unknown Type Selected: {type}</p>
    }
  };

  return useMemo(() => {
    return (
      isMock ?
        <div className='example-container p-3 my-5 mr-6'>
          <label className="label mb-0" style={{ fontWeight: "normal" }}>Answer Display Example Sandbox:</label>
          <div className='is-capitalized'>{type}</div>
          <div className='is-flex is-align-items-flex-end'>
            <AnswerField />
            <span className='ml-2 help has-text-warning-dark'>values entered are for example only and won't be saved.</span>
          </div>
        </div>
        :
        <div className={type === 'text' ? 'is-width-full ' : ' ' + className}>
          <AnswerField />
        </div>
    )
  }, [className, isMock, type]);
}