import Header from "../../components/header";
import SideMenu from "../../components/sidemenu";
import React, { useState, useEffect, useCallback } from 'react';
import api from '../../api';
import store from '../../store';
import { Panel, PanelGroup, Loader } from 'rsuite';
import ReactMarkdown from 'react-markdown'
import { Link, useNavigate } from "react-router-dom";
import ConfirmDeleteFaq from "../../components/confirmdelfaq";
import Search from '../../components/Search';

export default function Faq() {

  function submitRequest() {
    window.location.href = 'mailto:FAQpillar@webbank.com?subject=New FAQ Suggestion';
  }

  const [isLoading, setIsLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [section, setSection] = useState();
  const [sections, setSections] = useState([]);

  const Content = () => {
    const navigate = useNavigate('');
    const isAdmin = store.user.groups.includes("faqAdmin");

    let confirmDeleteFaq = (id) => {
      setShowModal(true);
      setFaqToDelete(id);
    }

    const [showModal, setShowModal] = useState(false);
    const handleClose = useCallback(() => { setShowModal(false) }, []);
    const [faqToDelete, setFaqToDelete] = useState('');


    const deleteFaq = useCallback(async () => {
      const response = await api.faqs.deleteFaq(faqToDelete);
      if (response) {
        setShowModal(false);
        setTimeout(
          setShowModal(false),
          navigate(0),
          3000);
      } else {
        alert('Error trying to delete');
        setShowModal(false);
      }
    }, [faqToDelete, navigate]);

    const goToFaq = id => {
      async function goToFaqAsync() {
        let result = sections.filter(val => val.id.includes(id));
        let FAQs = result[0].FAQs;
        setFaqs(FAQs);
        setSection(id);
      }
      goToFaqAsync().catch(e => e)
    }

    return (
      <div className="px-3" style={{ height: '100%' }}>
        <div className="columns" style={{ height: '100%' }}>
          <div className="column section-column-width">
            <div className="is-flex" style={{ overflow: 'auto', height: '100%' }}>
              <div className="is-flex-grow-1 px-2" style={{ height: "0px" }}>
                <nav className="panel faq-section-list is-flex">
                  <div className="is-flex-grow-1">
                    <p className="panel-heading">
                      Sections
                    </p>
                    {sections.map((item, index) => {
                      return (
                        <span
                          key={index}
                          className={'panel-block is-clickable ' + (item.id === section ? 'is-active' : '')}
                          onClick={() => goToFaq(item.id)}
                        >
                          <span className="panel-icon">
                            <i className="fas fa-book" aria-hidden="true"></i>
                          </span>
                          {item.name}
                        </span>
                      );
                    })}
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div className="column faq-questions-list is-flex" style={{ height: '100%' }}>
            {!faqs.length && !section &&
              <p className="faq-select-a-section">
                <i className="fa-solid mr-2 fa-arrow-left" /> Select a Section
              </p>
            }
            <div className="is-flex is-flex-wrap-wrap is-flex-grow-1" style={{ overflow: "auto" }}>
              <div style={{ height: "0px" }}>
                <PanelGroup accordion defaultActiveKey={0} bordered>
                  {!faqs.length && section &&
                    <Panel header="No FAQS">
                      <div className="faq-answer-container">
                        <span className="faq-answer-label">Add some FAQS!</span>
                      </div>
                    </Panel>
                  }
                  {faqs.map((faq, index) =>
                    <Panel header={faq.question} eventKey={faq.id} id={faq.id} key={index}>
                      <div className="faq-answer-container">
                        <span className="faq-answer-label">Answer</span>
                        <ReactMarkdown linkTarget="_blank">{faq.answer}</ReactMarkdown>
                      </div>
                      {faq.additionalInformation &&
                        <div className="faq-answer-container">
                          <span className="faq-answer-label">Additional Information</span>
                          <ReactMarkdown linkTarget="_blank">{faq.additionalInformation}</ReactMarkdown>
                        </div>
                      }
                      {isAdmin &&
                        <p className="faq-admin-q-footer is-right"><Link to={`/faq-admin-question?id=${faq.id}`}>Edit</Link> | <span className="is-clickable faq-confirm-delete" onClick={() => confirmDeleteFaq(faq.id)}>Delete</span></p>
                      }
                    </Panel>
                  )}
                </PanelGroup>
                <div style={{ height: "500px" }}></div>
              </div>
            </div>

          </div>
        </div>
        <ConfirmDeleteFaq handleClose={handleClose} deleteFaq={deleteFaq} showModal={showModal} />
      </div>
    )
  };

  const search = ({ query, dataset, setQuery, searchProperty, queryKeys }) => {
    if (!query.trim()) {
      setQuery('');
      return dataset;
    }
    const matchingDataset = dataset.filter(data =>
      data[searchProperty].filter(obj => {
        for (let key of queryKeys) {
          if (obj[key].toLowerCase().includes(query)) return true;
        }
        return false;
      }).length > 0
    ).map(data => {
      return {
        ...data,
        [searchProperty]: data[searchProperty].filter(obj => {
          for (let key of queryKeys) {
            if (obj[key].toLowerCase().includes(query)) return true;
          }
          return false;
        })
      }
    });
    return matchingDataset;
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.faqs.getSections();
      response.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);
      setApiResponse(response);
      setSections(response);
      // need to get the first section id to open the default section
      let defaultId = response[0].id;
      let result = response.filter(val => val.id.includes(defaultId));
      let FAQs = result[0].FAQs;
      setFaqs(FAQs);
      setSection(defaultId);
      setIsLoading(false);
    };
    fetchData().catch(e => e);
  }, []);

  return (

    <>
      <Header></Header>
      <div className="is-flex" style={{ height: '100%' }}>
        <SideMenu
          defaultOpenMenus={["2"]}
        ></SideMenu>
        {isLoading
          ?
          <div className="columns is-flex">
            <div className="column is-full"><Loader center size="lg" /></div>
          </div>
          :
          <div className="is-width-full is-flex is-flex-direction-column">
            <h4 className="px-3 pt-2 title-color is-flex is-justify-content-space-between is-align-items-center">
              <span>
                <i className="fa-solid mr-2 fa-question" />
                FAQ
              </span>
              <Search dataset={apiResponse} setDataset={setSections} search={search} setDefaultDataId={setSection} setQuestion={setFaqs} placeholder={'FAQs'} searchProperty={'FAQs'} queryKeys={['question', 'answer', 'additionalInformation']} />

              <button id="faq-suggestion" className="title-button" onClick={submitRequest}>
                <i className="fa-solid mr-2 fa-envelope" />
                Submit a Suggestion or Request
              </button>
            </h4>
            <Content />
          </div>
        }
      </div>
    </>
  )
}


