import ImageUpload from "../components/devAdmin/imageupload";
import Header from "../components/header";
import TestNotifications from "../services/TestNotifications";

export default function DevAdmin(){
  return (
    <>
      <Header />
      <h3 className="m-3">I am invincible!</h3>
      <ImageUpload />
      <TestNotifications />
    </>
  )
}