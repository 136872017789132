import Header from "../../components/header";
import SideMenu from "../../components/sidemenu";
import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from '../../api';
import { Loader } from 'rsuite';
import InputField from "../../components/TextInput";
import ConfirmDeleteFaq from "../../components/confirmdelfaq";
import TopDrawer, { handleShowDrawer } from "../../components/TopDrawer";
import ApiSubmitButton from '../../components/buttons/ApiSubmitButton';
import { v4 as uuidV4 } from 'uuid';
import { getUserCognitoGroups } from '../../store';
import { useNavigate } from "react-router";

const FormContent = ({ question, handleQuestion, answer, handleAnswer, additional, handleAdditional, section, handleSectionSelect, sections, sort, handleSort, disableFields }) => (
  <div className="px-3">
    <div className="is-full faq-q-row">
      <label className="label"><span className="icon"><i className="fa-solid fa-question"></i></span>&nbsp;&nbsp;Question:</label>
      <InputField
        type="text"
        value={question}
        placeholder="What is a FAQ?"
        name="question"
        maxlength="250"
        disabled={disableFields}
        onChange={e => handleQuestion(e)}
      />
    </div>
    <div className="is-full faq-q-row">
      <label className="label"><span className="icon"><i className="fa-solid fa-a"></i></span>&nbsp;&nbsp;Answer:</label>
      <InputField
        type="textarea"
        value={answer}
        placeholder="A FAQ is the answer"
        name="question"
        maxlength="2500"
        disabled={disableFields}
        onChange={e => handleAnswer(e)}
      />
      <p className="faq-info-text">To include a link, enter the text to display in square brackets followed by the url in parentheses.</p>
      <p className="faq-info-text">e.g. [Visit Web Bank](https://www.webbank.com) will display as: <a href="https://www.webbank.com">Visit Web Bank</a> - Must include https:// or http://</p>
    </div>
    <div className="is-full faq-q-row">
      <label className="label"><span className="icon"><i className="fa-solid fa-plus"></i></span>&nbsp;&nbsp;Additional Information:</label>
      <InputField
        type="textarea"
        value={additional}
        placeholder="Additional information..."
        name="additional"
        maxlength="2500"
        disabled={disableFields}
        onChange={e => handleAdditional(e)}
      />
      <p className="faq-info-text">To include a link, enter the text to display in square brackets followed by the url in parentheses.</p>
      <p className="faq-info-text">e.g. [Visit Web Bank](https://www.webbank.com) will display as: <a href="https://www.webbank.com">Visit Web Bank</a> - Must include https:// or http://</p>
    </div>
    <div className="columns faq-q-row">
      <div className="column is-half">
        <label className="label">Section:</label>
        <div className="select">
          <select onChange={e => handleSectionSelect(e)} value={section} disabled={disableFields}>
            <option>Select</option>
            {sections.map(section => <option key={section.id} value={section.id}>{section.name}</option>)}
          </select>
        </div>
      </div>
      <div className="column">
        <label className="label">Sort Order:</label>
        <input onChange={e => handleSort(e)} className="input" type="number" placeholder="1" value={sort} disabled={disableFields}></input>
      </div>
    </div>
  </div>
)

export default function Faq() {
  // State
  const [saving, setSaving] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [faqAdmin, setFaqAdmin] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [id, setId] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [section, setSection] = useState(0);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [additional, setAdditional] = useState('');
  const [sort, setSort] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [faqToDelete, setFaqToDelete] = useState('');

  // refs and variables
  const navigator = useNavigate();
  const originalEditData = useRef({});

  // functions and callbacks
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const submit = async () => {
    setSaving(true);
    setDisableFields(true);
    const body = {
      question: question,
      answer: answer,
      additionalInformation: additional,
      sectionId: section,
      sortOrder: sort
    };
    !id ? await api.faqs.createFaq({ body }) : await api.faqs.updateFaq(id, { body });
    setActionDone(true);
    return new Promise((resolve) => {
      setTimeout(() => {
        setActionDone(false);
        setSaving(false);
        setDataUpdate(!dataUpdate);
        resolve();
      }, 1500);
    });
  }

  const clearForm = () => {
    setChangePending(false);
    setEditMode(null);
    setSection(0);
    setId('');
    setQuestion('');
    setAnswer('');
    setAdditional('');
    setSort('');
    setDisableFields(false);
    handleShowDrawer({ showDrawer, setShowDrawer, cancelActions: () => null });
    if(params?.id){
      navigator('/faq-admin-question');
    }
  }

  const handleSectionSelect = (e) => {
    setSection(e.target.value);
    checkIfFormIsValid();
  }

  const handleQuestion = (e) => {
    setQuestion(e.target.value);
    checkIfFormIsValid();
  }

  const handleAnswer = (e) => {
    setAnswer(e.target.value);
    checkIfFormIsValid();
  }

  const handleAdditional = (e) => {
    setAdditional(e.target.value);
    checkIfFormIsValid();
  }

  const handleSort = (e) => {
    setSort(e.target.value);
    checkIfFormIsValid();
  }

  const checkChangePending = useCallback(({ detail }) => {
    const pending = (detail.id === originalEditData.current.id) &&
      (detail.sectionId === originalEditData.current.sectionId) &&
      (detail.question === originalEditData.current.question) &&
      (detail.answer === originalEditData.current.answer) &&
      (detail.additionalInformation === originalEditData.current.additionalInformation) &&
      (Number(detail.sortOrder) === Number(originalEditData.current.sortOrder));
    setChangePending(!pending);
    return !pending;
  }, []);

  const checkIfFormIsValid = useCallback(() => {
    let pending;
    if (editMode) {
      pending = checkChangePending({
        detail: {
          id,
          sectionId: section,
          question,
          answer,
          additionalInformation: additional,
          sortOrder: sort
        }
      });
      const validCondition = !!section && !!question && !!answer && !!sort && !!pending;
      setFormValid(validCondition);
    } else {
      if (section && question && answer && sort) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }
  }, [id, answer, question, section, sort, additional, checkChangePending, editMode])

  const confirmDeleteFaq = (id) => {
    setShowModal(true);
    setFaqToDelete(id);
  }

  const handleClose = useCallback(() => { setShowModal(false) }, []);

  const deleteFaq = useCallback(async () => {
    const response = await api.faqs.deleteFaq(faqToDelete);
    if (response) {
      setShowModal(false);
      setTimeout(
        setShowModal(false),
        3000);
      } else {
        alert('Error trying to delete');
        setShowModal(false);
      }
      setDataUpdate(!dataUpdate);
  }, [faqToDelete, dataUpdate]);

  const getSectionNameById = (id) => {
    let result = sections.find(x => x.id === id)?.name;
    return result;
  }

  async function getFaqAdmin() {
    const isFaqAdmin = await getUserCognitoGroups('faqAdmin');
    setFaqAdmin(isFaqAdmin);
  }

  function handleEdit({ id, apiFaqs }) {
    const faqList = faqs.length ? faqs : apiFaqs;
    // get the question to edit
    const result = faqList?.filter(val => val.id.includes(id));
    if (result?.length) {
      setId(result[0].id);
      setSection(result[0].sectionId);
      setQuestion(result[0].question);
      setAnswer(result[0].answer);
      setAdditional(result[0].additionalInformation);
      setSort(result[0].sortOrder);
      originalEditData.current = structuredClone({ ...result[0] });
      setEditMode(true);
      setShowDrawer(true);
    }
  };

  useEffect(() => {
    checkIfFormIsValid();
  }, [answer, question, section, sort, checkIfFormIsValid]);

  useEffect(() => {
    const fetchFaqs = async ({ id }) => {
      const response = await api.faqs.getFaqs();
      setFaqs(response);
      if (id) {
        handleEdit({ id, apiFaqs: response });
      }
    };

    const fetchSections = async ({ id }) => {
      setIsLoading(true);
      const calls = [api.faqs.getSections(), fetchFaqs({ id })];
      const [response] = await Promise.all(calls);
      response.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);
      // need to get the fields for question we're presumably editing
      setSections(response);
      await getFaqAdmin();
      setIsLoading(false);
    };
    fetchSections({ id: params?.id }).catch(e => e);
    // eslint-disable-next-line
  }, [dataUpdate, params.id]);

  // Top drawer
  const [showDrawer, setShowDrawer] = useState(false);
  const [changePending, setChangePending] = useState(false);
  const [actionDone, setActionDone] = useState();
  const [disableFields, setDisableFields] = useState(false);

  const drawerActions = [
    {
      id: uuidV4(),
      text: "Cancel",
      className: "is-warning",
      icon: <i className="fa-solid fa-ban"></i>,
      disabled: disableFields,
      action: clearForm
    },
    {
      id: uuidV4(),
      isCustom: (
        <ApiSubmitButton
          defaultLabel={editMode ? `Update FAQ` : `Save FAQ`}
          operationLabel="Saving"
          action={submit}
          disabled={!formValid}
          cb={clearForm}
          // // used to force correct rendering when parent re-renders
          actionTriggered={saving}
          actionDone={actionDone}
        />
      )
    }
  ];
  // **** Top drawer

  return (
    <>
      <Header></Header>
      <div className="page-container">
        <SideMenu defaultOpenMenus={["2"]}></SideMenu>
        <div className="page-contents-container is-width-full">
          <div className="page-header-container">
            <h4 className="px-3 pt-2 title-color is-flex is-justify-content-space-between is-align-items-center">
              <span>FAQ</span>
              {
                faqAdmin && !isLoading &&
                <div className="drop-card-anchor is-flex" onClick={() => handleShowDrawer({ showDrawer, setShowDrawer, cancelActions: clearForm })}>
                  {
                    showDrawer ?
                      <button className="mb-1 has-icon-left button is-warning" style={{ height: "25px", fontWeight: "normal" }} disabled={disableFields}>
                        <i className="fa-solid fa-ban mr-2"></i>
                        <span>Cancel</span>
                      </button>
                      :
                      <button className="has-icon-left title-button">Create Question</button>
                  }
                </div>
              }
            </h4>
          </div>
          <div className="is-relative">
            <TopDrawer
              positionOverride={'absolute'}
              maxDrawerHeight={"87vh"}
              show={showDrawer}
              title={editMode ? `Edit FAQ` : `New FAQ`}
              body={
                <FormContent
                  question={question}
                  handleQuestion={handleQuestion}
                  answer={answer}
                  handleAnswer={handleAnswer}
                  additional={additional}
                  handleAdditional={handleAdditional}
                  section={section}
                  handleSectionSelect={handleSectionSelect}
                  sections={sections} 
                  sort={sort}
                  handleSort={handleSort}
                  disableFields={disableFields}
                />
              }
              actions={drawerActions}
              drawerBodyHeight={"74vh"}
              changePending={changePending}
            />
          </div>
          {isLoading ?
            <div className="is-full"><Loader center size="sm" content={`Loading FAQs...`} /></div>
            :
            <div className="page-contents">
              <h2 className="ml-2">Questions</h2>
              <div className="flex-scroll-container">
                <div className="flex-scroll">
                  {faqs.map((item, index) =>
                    <div className="columns faq-sections-list-item" key={index}>
                      <div className="column is-6"><strong>{item.question}</strong></div>
                      <div className="column is-4">{getSectionNameById(item.sectionId)}</div>
                      <div className="column is-1 has-text-centered"><span className="is-clickable faq-confirm-delete" onClick={() => handleEdit({ id: item.id })}>Edit</span></div>
                      <div className="column is-1 has-text-centered"><span className="is-clickable faq-confirm-delete" onClick={() => confirmDeleteFaq(item.id)}>Delete</span></div>
                    </div>
                  )}
                  <div style={{ height: "100px" }}></div>
                </div>
              </div>
            </div>}
          <ConfirmDeleteFaq handleClose={handleClose} deleteFaq={deleteFaq} showModal={showModal} />
        </div>
      </div>
    </>
  )
}
