import { useState, useRef } from "react";
import PageTemplate from "../../pages/PageTemplate";
import { getUserCognitoGroups } from "../../store";
import tf from "./TreasuryPages";
import { v4 as uuidV4 } from "uuid";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import api from "../../api";
import microservices from "../../services/microservices";

const Content = ({ ...props }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [view, setView] = useState("Shopify");
  const views = ["Shopify", "Intuit"];

  useState(() => {
    setIsAdmin(props?.hasPermission?.current);
  }, []);

  return (
    <div className="custom-flex-container p-3">
      <div className="is-flex">
        {views?.map((area) => (
          <div
            key={uuidV4()}
            className={`tab ${view === area ? "has-background-link has-text-white " : ""}`}
            onClick={() => {
              setView(area);
              props.setCurrentView(area);
            }}
          >
            {area}
          </div>
        ))}
      </div>
      {isAdmin ? (
        <div className="mt-3 custom-flex-container">
          <tf.FileIngestion {...props} />
        </div>
      ) : (
        <div>You do not have permission to access this page.</div>
      )}
    </div>
  );
};

const CrudControls = ({ ...props }) => {
  return (
    <div className="is-flex is-align-items-center">
      <ApiSubmitButton
        className="title-button mt-1"
        icon={<i className="fa-solid fa-upload" />}
        defaultLabel="Submit"
        operationLabel="Submitting..."
        action={props.submit}
        disabled={props.submitTriggered}
        actionTriggered={props.submitTriggered}
        actionDone={props.actionDone}
      />
    </div>
  );
};

const Treasury = () => {
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [actionDone, setActionDone] = useState(false);
  const [currentView, setCurrentView] = useState("Shopify");
  const hasPermission = useRef(false);
  const [tableLoading, setTableLoading] = useState(true);
  const details = useRef({
    products: ["US National Loans", "LOC"],
    product: "US National Loans",
    amount: 0,
    destination: "",
    source: "",
  });

  const getUserPermission = async () => {
    hasPermission.current = await getUserCognitoGroups("treasuryAdmin");
  };

  const getHistory = async () => {
    setTableLoading(true);
    const apiCalls = [api.treasury.get({ partner: "shopify" }), api.treasury.get({ partner: "intuit" })];
    const [responseShopify, responseIntuit] = await Promise.all(apiCalls);
    details.current.shopifyHistory = responseShopify || [];
    details.current.intuitHistory = responseIntuit || [];
    setTableLoading(false);
  };

  const resetFields = async () => {
    details.current.product = "US National Loans";
    details.current.amount = 0;
    details.current.destination = "";
    details.current.source = "";
  };

  const submit = async () => {
    setSubmitTriggered(true);
    const body = structuredClone({ ...details.current });
    if (currentView === "Shopify") {
      body.source = "";
      body.successMessage = "Wire receipt was processed successfully.";
      body.type = "wire receipt";
    } else {
      body.amount = null;
      body.product = null;
      body.successMessage = "ACH file acknowledgment was processed successfully.";
      body.type = "ach file acknowledgement";
    }
    // convert amount to number
    if (body.amount) {
      body.amount = microservices.stringToNumber(body.amount);
    }
    body.partner = currentView;
    const response = await api.treasury.create({ body });
    // refresh history
    await getHistory();
    if (!response) {
      setSubmitTriggered(false);
      return [];
    }
    setActionDone(true);
    setTimeout(() => {
      setSubmitTriggered(false);
      setActionDone(false);
      resetFields();
    }, 2000);
    return response;
  };

  const sharedProps = {
    hasPermission,
    details,
    submitTriggered,
    actionDone,
    submit,
    currentView,
    setCurrentView,
    tableLoading,
    setTableLoading,
  };

  return (
    <PageTemplate
      className="custom-flex-container"
      pageTitle="Treasury Functions"
      content={<Content {...sharedProps} />}
      setup={getHistory}
      getPermission={getUserPermission}
      defaultOpenMenus={["6"]}
      crudControls={<CrudControls {...sharedProps} />}
    />
  );
};

export default Treasury;
