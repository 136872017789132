import { fetchAuthSession } from 'aws-amplify/auth';
import { set, get } from 'lodash';
import partner from './partner';
import person from './persons';
import legal from './legal';
import s3Images from './s3images';
import faqs from './faq';
import reports from './reports';
import products from './products/products';
import productSections from './products/productSections';
import productSubsections from './products/productSubsections';
import productAnswers from './products/answers';
import productQuestions from './products/questions';
import referenceResponses from './products/referenceResponses';
import sp from './products/sp';
import treasury from './treasury';
import store from '../store';
import awsmobile from '../aws-exports';

// Authenticated Endpoint
// const _url = "https://rne7bx5arl.execute-api.us-east-2.amazonaws.com/dev/"; //dev
// const _url = "https://s6tyrxrrnf.execute-api.us-east-2.amazonaws.com/test/"; //test
const _url = awsmobile.aws_cloud_logic_custom[0].endpoint;

let token;
export async function apiFetch({ endpoint, method = 'GET', fallback, body }) {
  const url = `${_url}/${endpoint}`;
  const options = {
    method,
    headers: await getHeaders(),
  }
  if(body) options.body = JSON.stringify(body)
  try {
    const response = await fetch(url, options)
    if (!response.ok){ console.log(response); throw new Error(await response.text())}

    return response.json()
  } catch(e) {
    let message;
    if(typeof e === 'object'){
      try {
        message = JSON.parse(e?.message);
        if(message?.response_text){
          message = message?.response_text || message?.message;
        }
      } catch (error) {
        message = e?.message ? e.message : e;
      }
    } else {
      message = {message: e};
    }
    store.actions.handleError(message, store)
    return fallback;
  }
};

// this is so the unit tests would be able to load in cypress/docker container inside bitbucket pipelines
export const setTokenForUnitTests = (val) => {
  token = val;
};

export const getHeaders = async () => {
  if (!token?.length) {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    token = get(store,'session.accessToken.jwtToken') ?? accessToken?.toString();
    set(store, 'session.accessToken.jwtToken', token);
  }
  return {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json",
  }
};

const endpoints = {
  partner,
  person,
  faqs,
  reports,
  s3Images,
  legal,
  products,
  productSections,
  productSubsections,
  productAnswers,
  productQuestions,
  referenceResponses,
  treasury,
  sp,
};

export default endpoints