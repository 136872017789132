import { Table, Loader } from "rsuite";
import { getUserCognitoGroups } from "../../../store";
import { useState, useEffect, useRef, useCallback } from "react";
import { orderBy, words, capitalize, isEmpty } from "lodash";
import QuestionCell from "../../../components/products/QuestionCell";
import AnswerCell from "../../../components/products/AnswerCell";
import SectionCell from "../../../components/products/SectionCell";
import SubsectionCell from "../../../components/products/SubsectionCell";
import ExpandCell from "../../../components/products/ExpandCell";
import DeleteCell from "../../../components/products/DeleteCell";
import CustomCell from "../../../components/products/CustomCell";
import Modal from "../../../components/modal";
import { Cell } from "rsuite-table";

export default function SharedComponent({
  handleEdit,
  data,
  deleteAction,
  cb,
  loadingData,
  setLoadingData,
  tab,
  rowExpandedHeight,
  modal,
  areYouSure,
  isContracts,
  viewType,
  ...props
}) {
  const { Column, HeaderCell } = Table;
  const [sortColumn, setSortColumn] = useState("sortOrder");
  const [sortType, setSortType] = useState("asc");
  const [open, setOpen] = useState(false);
  const table = useRef();
  const [mainColumnWidth, setMainColumnWidth] = useState();
  const defaultRowHeight = 60;
  const expandWidth = 70;
  const deleteWidth = 250;
  const sortWidth = 100;
  window.onresize = () => columnWidths();

  const getPermissions = async () => {
    const tempDev = await getUserCognitoGroups("dev");
    setIsDev(!!tempDev);
  };

  const [isDev, setIsDev] = useState(null);
  const [isAFactSheet, setIsAFactSheet] = useState(false);

  useEffect(() => {
    // Update isAFactSheet state based on if is partner/product details
    let isFactSheet = false;
    if (viewType && (viewType === "isPartnerDetails" || viewType === "isProductDetails")) {
      isFactSheet = true;
    }
    setIsAFactSheet(isFactSheet);
  }, [viewType]);

  const columnWidths = useCallback(() => {
    const el = document.querySelectorAll(".rs-table");
    if (el.length) {
      const tableWidth = el[0]?.clientWidth - deleteWidth - (tab !== "answers" ? expandWidth : 0) - sortWidth;
      switch (tab) {
        case "questions":
          // if is contracts
          if (isContracts) {
            setMainColumnWidth(tableWidth / 2);
          } else {
            if (isAFactSheet) {
              setMainColumnWidth(tableWidth);
            } else {
              setMainColumnWidth(tableWidth / 3);
            }
          }
          break;
        case "subSections":
          setMainColumnWidth(tableWidth / 2);
          break;
        case "products":
          setMainColumnWidth(tableWidth / 3);
          break;
        default:
          setMainColumnWidth(tableWidth);
          break;
      }
    }
  }, [tab, isContracts, isAFactSheet]);

  const handleSortColumn = (sortColumn, sortType) => {
    setLoadingData(true);
    setTimeout(() => {
      setLoadingData(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const filterDataByView = useCallback((data) => {
    switch (viewType) {
      case "isContracts":
        const isSubsection = data?.filter((item) => item.sectionName === "Contract Admin");
        if (!isEmpty(isSubsection)) {
          return isSubsection;
        } else {
          const contractQuestions = [];
          for (let subsection of props.subsections) {
            for (let question of subsection.questions) {
              question.subsectionName = subsection?.name;
              contractQuestions.push(question);
            }
          }
          return contractQuestions;
        }
      case "isPartnerDetails":
        return data?.filter((item) => item.subsectionName === "partner-fact-sheet-subsection");
      case "isProductDetails":
        return data?.filter((item) => item.subsectionName === "product-fact-sheet-subsection");
      default:
        return data?.filter(
          (item) => item.subsectionName !== "product-fact-sheet-subsection" && item.subsectionName !== "partner-fact-sheet-subsection"
        );
    }
  }, [viewType, props.subsections]);

  const [displayData, setDisplayData] = useState([]);
  const getData = useCallback((data) => {
    // Filter subsections and table columns based on viewType
    let filteredArray = filterDataByView(data);
    if (sortColumn && sortType) {
      // hide fact sheet from table if not dev
      filteredArray = !isDev ? filteredArray.filter((item) => item.isFactSheet !== 1) : filteredArray;
      const ordered = orderBy(filteredArray, sortColumn, sortType);
      setDisplayData(ordered);
      return ordered;
    }
    setDisplayData(filteredArray);
    return filteredArray;
  }, [sortColumn, sortType, isDev, filterDataByView]);
  
  useEffect(() => {
    // getProductSections();
    columnWidths();
    if (isDev === null) {
      getPermissions();
    }
    if(data){
      getData(data);
    }
  }, [
    //getProductSections,
    columnWidths,
    isDev,
    isAFactSheet,
    data,
    getData,
  ]);

  /* 
  ##############################################################################
  =============================== Expanded Row =================================
  ##############################################################################
  */
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [showQuestions, setShowQuestions] = useState(null);
  const [showAnswers, setShowAnswers] = useState(null);

  const rowKey = "id";

  const handleTreeExpand = ({ id, identifier, action }) => (identifier === id ? action(null) : action(id));

  const renderRowExpanded = ({ tab, rowData }) => {
    const orderedSections = orderBy(props.sections, "sortOrder", "asc");
    const orderedAnswers = orderBy(rowData.answers, "sortOrder", "asc");
    switch (tab) {
      case "products":
        return (
          <div id={`${rowData.id}-product-row`}>
            {orderedSections?.map((section) => (
              <SectionCell
                key={section.id}
                orderedSubsections={orderBy(section.subsections, "sortOrder", "asc")}
                showAnswers={showAnswers}
                setShowAnswers={setShowAnswers}
                showQuestions={showQuestions}
                setShowQuestions={setShowQuestions}
                section={section}
                handleTreeExpand={handleTreeExpand}
              />
            ))}
          </div>
        );
      case "sections":
        return (
          <div id={`${rowData.id}-product-row`}>
            {rowData?.subsections?.length ? (
              rowData?.subsections?.map((subsection) => (
                <SubsectionCell
                  key={subsection?.id}
                  subsection={subsection}
                  showAnswers={showAnswers}
                  setShowAnswers={setShowAnswers}
                  showQuestions={showQuestions}
                  setShowQuestions={setShowQuestions}
                  orderedQuestions={orderBy(subsection.questions, "sortOrder", "asc")}
                  handleTreeExpand={handleTreeExpand}
                />
              ))
            ) : (
              <div>No expandable data.</div>
            )}
          </div>
        );
      case "subSections":
        return (
          <div id={`${rowData.id}-product-row`}>
            {rowData?.questions?.length ? (
              rowData?.questions?.map((question) => (
                <QuestionCell
                  key={question.id}
                  question={question}
                  showAnswers={showAnswers}
                  setShowAnswers={setShowAnswers}
                  orderedAnswers={orderBy(question.answers, "sortOrder", "asc")}
                  handleTreeExpand={handleTreeExpand}
                />
              ))
            ) : (
              <div>No expandable data.</div>
            )}
          </div>
        );
      case "questions":
        return (
          <div id={`${rowData.id}-product-row`}>
            <label className="ml-4">Answers:</label>
            {orderedAnswers?.map((answer) => (
              <AnswerCell key={answer.id} answer={answer} tab={tab} />
            ))}
          </div>
        );
      default:
        return <div>No expandable data.</div>;
    }
  };

  const handleExpanded = (rowData) => {
    if (props.secondaryLoading) return;
    if (!open || expandedRowKeys[0] !== rowData[rowKey]) {
      setOpen(true);
      setExpandedRowKeys([rowData[rowKey]]);
    } else {
      setOpen(false);
      setExpandedRowKeys([]);
    }
  };

  /* 
  ##############################################################################
  ============================== Page View =====================================
  ##############################################################################
  */

  const transformHeader = () => {
    const tempTab = tab === "subSections" ? "subsections" : tab;
    return capitalize(words(tempTab).join(" "));
  };

  const SummaryCell = ({ rowData, dataKey, handleEdit, ...props }) => {
    return (
      <Cell {...props} className="is-clickable" onClick={() => handleEdit(rowData)}>
        {!!rowData[dataKey] && <i className="fa fa-solid fa-check has-text-success is-size-4" />}
      </Cell>
    );
  };

  return (
    <div className="mx-3 full-table-height main-container product-table">
      <Modal {...modal} />
      <Table
        shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
        minHeight={480}
        fillHeight={true}
        data={displayData}
        ref={table}
        rowKey={rowKey}
        rowHeight={defaultRowHeight}
        expandedRowKeys={expandedRowKeys}
        renderRowExpanded={({ ...rowData }) => renderRowExpanded({ tab, rowData })}
        rowExpandedHeight={rowExpandedHeight || 400}
        loading={props.productsLoading || props.sectionsLoading || props.subsectionsLoading || props.questionsLoading || props.secondaryLoading}
        renderLoading={() => (
          <div className="is-full">
            <Loader backdrop={true} center size="sm" content="Loading Table View..." />
          </div>
        )}
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={handleSortColumn}
        wordWrap={"break-word"}
      >
        {tab !== "products" && (
          <Column width={expandWidth} align="center" verticalAlign="top">
            <HeaderCell>#</HeaderCell>
            <ExpandCell dataKey="id" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
          </Column>
        )}

        <Column width={mainColumnWidth} verticalAlign="top" sortable={true}>
          <HeaderCell>{transformHeader()}</HeaderCell>
          <CustomCell dataKey={tab === "questions" ? "text" : "name"} handleEdit={handleEdit} />
        </Column>

        {tab === "products" && (
          <Column width={mainColumnWidth} verticalAlign="top" sortable={true}>
            <HeaderCell>Partner</HeaderCell>
            <CustomCell dataKey="partnerName" handleEdit={handleEdit} />
          </Column>
        )}

        {tab === "products" && (
          <Column width={mainColumnWidth} verticalAlign="top" sortable={true}>
            <HeaderCell>Status</HeaderCell>
            <CustomCell dataKey="status" handleEdit={handleEdit} />
          </Column>
        )}

        {tab === "questions" && !isContracts && !isAFactSheet && (
          <Column width={mainColumnWidth} verticalAlign="top" sortable={true}>
            <HeaderCell>Topic</HeaderCell>
            <CustomCell dataKey="topic" handleEdit={handleEdit} />
          </Column>
        )}

        {tab === "questions" && !isAFactSheet && (
          <Column width={mainColumnWidth} verticalAlign="top" sortable={true}>
            <HeaderCell>Subsection</HeaderCell>
            <CustomCell dataKey="subsectionName" handleEdit={handleEdit} />
          </Column>
        )}

        {tab === "subSections" && !isContracts && !isAFactSheet && (
          <Column width={mainColumnWidth} verticalAlign="top" sortable={true}>
            <HeaderCell>Section</HeaderCell>
            <CustomCell dataKey="sectionName" handleEdit={handleEdit} />
          </Column>
        )}

        <Column width={sortWidth} verticalAlign="top" sortable={true}>
          <HeaderCell>Sort Order</HeaderCell>
          <CustomCell dataKey="sortOrder" handleEdit={handleEdit} />
        </Column>

        {tab === "questions" && (
          <Column width={sortWidth} verticalAlign="top" sortable={true}>
            <HeaderCell>SP Summary</HeaderCell>
            <SummaryCell dataKey="spSummary" handleEdit={handleEdit} />
          </Column>
        )}

        <Column width={deleteWidth} verticalAlign="top">
          <HeaderCell />
          <DeleteCell cb={cb} deleteAction={deleteAction} areYouSure={areYouSure} />
        </Column>
      </Table>
    </div>
  );
}
