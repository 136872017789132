import { startCase } from "lodash";
import { useState, useEffect } from "react";
import wbMessageColorHandler from "../../services/wbMessageColorHandler";

export default function SystemMessages(props){
  const [incomingData, setIncomingData] = useState([]);
  const [blockBackground, setBlockBackground] = useState(null);
  useEffect(() => {
    const notifications = props?.globalStore?.notifications || [];
    setIncomingData([...notifications])
    setBlockBackground(!!props.globalStore?.notifications?.find(data => !!data?.blockBackground));
  },[props.globalStore]);

  /*
    This component has a base z-index of 99, 100, and 101.
    Types: error, warning, success, info
    bodyText: can be rjsx or a string
      i.e:
        bodyText: (
          <>
            <p>
              {e.message}. 
              <span>Please verify that you are connected to the WebBank VPN and try again.</span>
            </p>
          </>
        )
      OR:
        bodyText: 'hello world'

    unique ID is required! use uuidv4 library.

  ////////////////////////////////////////////
  ////////////// SAMPLE DATA /////////////////
  ///////////////////////////////////////////

    const sample = [
      {
        id: uuidv4(),
        type: 'success',
        // primaryColorOverride: 'red',
        // secondaryColorOverride: 'blue',
        // titleColorOverride: 'purple',
        // bodyTextColorOverride:'green',
        titleText: 'error of things',
        bodyText: 'Lorem ipsum ',
        actions: [
          {
            id: uuidv4(),
            // primaryColorOverride: 'yellow',
            // secondaryColorOverride: 'blue',
            // borderColorOverride: 'purple',
            className:'is-info',
            text: 'test',
            operation(){
              console.log('hello', );
            }
          },
          {
            id: uuidv4(),
            // primaryColorOverride: 'yellow',
            // secondaryColorOverride: 'blue',
            // borderColorOverride: 'purple',
            // className:'is-warning',
            text: 'test 2',
            operation(a){
              console.log('hello', a);
            }
          }
        ],
        blockBackground: false,
        canCloseMessage: true,
        autoCloseTimeout: null,
        closeCallback: () => {
          console.log('close', );
        }
      },
      {}
    ]
  */

  const handleAutoClose = (data) => {
    if(data.autoCloseTimeout){
      setTimeout(() => {
        dismiss(data).catch(e => e);
      }, data.autoCloseTimeout);
    }
  };

  const dismiss = async (data) => {
    const el = document.getElementById(data.id);
    el?.classList.add("hide");
    setTimeout(() => {
      props.globalStore.errorCount = 0;
      const notificationIdx = props.globalStore.notifications.indexOf(props.globalStore.notifications.find(sn => sn.id === data.id));
      if(notificationIdx > -1){
        props.globalStore.notifications.splice(notificationIdx, 1);
        props.globalStore.handleStoreUpdates(props.globalStore);
      }
      if(data.closeCallback){
        data.closeCallback(data);
      }
    },400);
  };

  return incomingData.length ? (
    <section className="wb-messages">
      { blockBackground && <div className="wb-background-disable" />}
      {
        incomingData.map(config => {
          if(!config.id) return null
          incomingData.dismiss = dismiss;
          handleAutoClose(config);
          return (
            <div id={config.id} key={config.id} className={`wb-message-container mb-5`} style={wbMessageColorHandler.getWbMessageContainerColors(config)}>
              <div className="wb-message-header">
                <div className="wb-message-header-title-container" style={wbMessageColorHandler.wbMessageHeaderTitleContainerColors(config)}>
                  <div className="wb-message-title">{startCase(config.titleText)}</div>
                  {
                    config.canCloseMessage &&
                    <div className="wb-message-closing-x is-clickable" onClick={() => {
                      (async () => {
                        await dismiss(config);
                      })();
                    }} style={wbMessageColorHandler.wbMessageClosingXColors(config)}>
                      <div className="wb-closing-x">X</div>
                    </div>
                  }
                </div>
              </div>
              <div className="wb-message-contents" style={wbMessageColorHandler.wbMessageContentsColors(config)}>{config.bodyText}</div>
              <div className="wb-message-actions">
                {
                  config.actions &&
                  config.actions?.map(action => {
                    return (
                      <div key={action.id} className={`wb-action button mr-2 ${action.className ? action.className : ''}`} onClick={() => action.operation(config)} style={wbMessageColorHandler.getButtonColors(action)}>action.text</div>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
    </section>
  ) : (<div></div>);
}