import InputField from "../../../components/TextInput";
import { useEffect, useState, useRef } from "react";
import { isEmpty, orderBy } from "lodash";
import microservices from "../../../services/microservices";
import { getUserCognitoGroups } from "../../../store";
import { Checkbox } from "rsuite";

export default function SectionForm({ sectionDetail, setSectionDetail, disabled, setchangepending, cancelled, setcancelled, editmode, sections, showDrawer }) {

  const [originalDetail, setOriginalDetail] = useState({});
  const [editDetail, setEditDetail] = useState({});
  const [originalChildren, setOriginalChildren] = useState();
  const [isDev, setIsDev] = useState(null);

  const refSectionDetail = useRef({ ...structuredClone({ ...sectionDetail }) });

  const checkPendingChanges = async (a, b) => {
    let misMatch;
    for (let item in a) {
      //eslint-disable-next-line
      if (a[item] != b[item] && item !== 'subsections') {
        misMatch = true;
        break;
      };
    }
    const isValid = await validation();
    setchangepending(!!misMatch && isValid);
  };

  const validation = () => !!(refSectionDetail.current?.name);

  const handleSectionInput = async (e) => {
    refSectionDetail.current.name = e.target?.value;
    setSectionDetail({ ...refSectionDetail.current });
    await checkPendingChanges(refSectionDetail.current?.originalData || originalDetail, refSectionDetail.current);
  };

  const handleSortOrder = async (e) => {
    refSectionDetail.current.sortOrder = e.target?.value;
    setSectionDetail({ ...refSectionDetail.current });
    await checkPendingChanges(refSectionDetail.current?.originalData || originalDetail, refSectionDetail.current);
  };

  const resetSection = () => {
    refSectionDetail.current = { ...sectionDetail };
    setSectionDetail({ ...sectionDetail });
    setOriginalDetail({});
    setEditDetail({});
    validation();
  };

  const handleIsContract = async (_val, checked) => {
    refSectionDetail.current.isContract = checked ? 1 : 0;
    refSectionDetail.current.isFactSheet = 0;
    setSectionDetail({ ...refSectionDetail.current });
    await checkPendingChanges(refSectionDetail.current?.originalData || originalDetail, refSectionDetail.current);
  };

  const handleIsFactSheet = async (_val, checked) => {
    refSectionDetail.current.isContract = 0;
    refSectionDetail.current.isFactSheet = checked ? 1 : 0;
    setSectionDetail({ ...refSectionDetail.current });
    await checkPendingChanges(refSectionDetail.current?.originalData || originalDetail, refSectionDetail.current);
  };

  const transformContractBool = (val) => val === 1;

  const getPermissions = async () => {
    const tempDev = await getUserCognitoGroups('dev');
    setIsDev(!!tempDev);
  };

  useEffect(() => {
    if (isDev === null) {
      getPermissions();
    }
    if (showDrawer) {
      if (editmode) {
        let tempOriginalDetail;
        if (isEmpty(editDetail)) {
          refSectionDetail.current = structuredClone({ ...sectionDetail });
          tempOriginalDetail = structuredClone({ ...sectionDetail });
          setEditDetail({ ...tempOriginalDetail });
        }
        let tempOriginalChildren;
        if (isEmpty(originalChildren) && refSectionDetail.current.subsections?.length) {
          refSectionDetail.current.subsections = orderBy(refSectionDetail.current?.subsections, 'sortOrder', 'asc');
          tempOriginalChildren = structuredClone(refSectionDetail.current?.subsections);
          setOriginalChildren([...tempOriginalChildren]);
        }

        refSectionDetail.current.originalData = structuredClone(tempOriginalDetail || editDetail);
        refSectionDetail.current.originalChildData = structuredClone(tempOriginalChildren || originalChildren);
      } else {
        if (isEmpty(originalDetail)) {
          // increment sort order based on highest current sortOrder value;
          if (!refSectionDetail.current.sortOrder) {
            refSectionDetail.current.sortOrder = microservices.getNextValue({ list: sections, key: 'sortOrder' });
          }

          const tempDetail = structuredClone({ ...refSectionDetail.current });
          setOriginalDetail(tempDetail);
          refSectionDetail.current.originalData = structuredClone({ ...tempDetail });
        }
        if (isEmpty(originalChildren) && refSectionDetail.current.subsections?.length) {
          refSectionDetail.current.originalChildData = structuredClone(orderBy(refSectionDetail.current?.subsections, 'sortOrder', 'asc'));
        }
      }
    }

    if (cancelled) {
      resetSection();
      setcancelled(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelled, editmode, refSectionDetail.current, showDrawer]);

  return (
    <div>
      <InputField
        label="Section"
        value={refSectionDetail.current?.name || ''}
        onChange={handleSectionInput}
        disabled={disabled}
        controlClass="mb-3"
        maxlength={64}
      />
      <InputField
        label="Sort Order"
        type="number"
        value={refSectionDetail.current?.sortOrder}
        onChange={handleSortOrder}
        disabled={disabled}
        style={{ maxWidth: "75px" }}
        controlClass="mb-3"
      />
      {isDev && <div id="is-contract-checkbox">
        <span className="help is-danger">There should only be one contract section and one fact sheet section! Codebase is written to only take the 1st section that isContract is true.</span>
        <Checkbox
          checked={transformContractBool(refSectionDetail.current?.isContract)}
          onChange={handleIsContract}
          disabled={disabled}
          inline={true}
        >Is Contract</Checkbox>
        <Checkbox
          checked={transformContractBool(refSectionDetail.current?.isFactSheet)}
          onChange={handleIsFactSheet}
          disabled={disabled}
          inline={true}
        >Is Fact Sheet</Checkbox>
      </div>}
    </div>
  )
}