import Header from "../../components/header";
import SideMenu from "../../components/sidemenu";
import { useEffect, useState, useCallback } from "react";
import { Loader } from 'rsuite';
import { useNavigate } from "react-router-dom";
import store, { getUserCognitoGroups } from "../../store";
import config from "../../config/appflags.json";
import api from "../../api";

export default function SPD(){
  const navigate = useNavigate();
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [partnerAdmin, setPartnerAdmin] = useState(false);

  const setup = useCallback(async () => {
    const tempAdmin = await getUserCognitoGroups('partnerAdmin');
    setPartnerAdmin(tempAdmin);
    // average load time 7-11 seconds
    let partnerList;
    if(!store?.partners?.length || store.partnerUpdated){
      const orderedPartnersWithImages = await api.partner.getPartners({});
      if(tempAdmin){
        partnerList = orderedPartnersWithImages;
      } else {
        partnerList = orderedPartnersWithImages.filter(p => !p.internal);
      }
      setPartners(partnerList);
      store.partnerUpdated = false;
    } else if(store?.partners){
      if(tempAdmin){
        partnerList = store.partners;
      } else {
        partnerList = store.partners.filter(p => !p.internal);
      }
      setPartners([...store.partners]);
    }
  }, []);

  useEffect(() => {    
    async function fetchData() {
      setLoading(true);
      await setup();
      setLoading(false);
      // ALL parent elements have to have a height set or scrolling won't work
      const divElement = document.querySelector('.is-flex.is-flex-direction-column.is-flex-grow-1');
      if (divElement) {
        divElement.id = 'spd-container-height';
      }
    };
    fetchData().catch(e => e);
  }, [setup]);

  return (
    <>
    <Header></Header>
    <div className="is-flex" style={{ height: "100%"}}>
      <SideMenu defaultOpenMenus={["1"]}></SideMenu>
      <div className="is-flex is-flex-direction-column" style={{ height: "100%"}}>
        <h4 className="px-3 pt-2 title-color is-flex is-justify-content-space-between is-align-items-center">
          <span>
            <i className="fa-solid mr-2 fa-chess-pawn" />
            Strategic Partners Dashboard
          </span>
          {/* {
            partnerAdmin &&
            <div className="drop-card-anchor is-flex">
              <button className={"title-button drop-card-activate-button "} onClick={() => {
                store.partnerView = null;
                navigate('/partner/null');
                }}>Add New Partner</button>
            </div>
          } */}
        </h4>
        {
          loading
          ?
            <Loader size="lg" content="Loading..." vertical center />
          :
            <div className="is-flex is-justify-content-center is-flex-wrap-wrap spd-main">
              {
                !!partners?.length && partners?.map((pi) => {
                  // not an admin - so they should not see inactive partners
                  if ((pi.status === 'Active Partner' || pi.status === 'Active DD&I') && !partnerAdmin) {
                    return (
                      <div className="spd-card-container is-flex is-justify-content-center is-align-items-center" key={pi.id}>
                        <div className="card spd-partner-card is-clickable" onClick={() => {
                          store.partnerView = pi;
                          navigate(`/partner/${pi.id}`);
                        }}>
                          <div className="card-content is-flex is-justify-content-center is-align-items-center">
                            <div className="content" title={pi.name}>
                              <img src={`${config.cloudfrontHost}${pi.imagePath}?${new Date().getTime()}`} alt={pi.name} />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                // is an admin so see all partners inactive or active
                if (partnerAdmin) {
                  return (
                    <div className="spd-card-container is-flex is-justify-content-center is-align-items-center" key={pi.id}>
                      <div className="card spd-partner-card is-clickable" onClick={() => {
                        store.partnerView = pi;
                        navigate(`/partner/${pi.id}`);
                      }}>
                        <div className="card-content is-flex is-justify-content-center is-align-items-center">
                          <div className="content" title={pi.name}>
                            <img src={`${config.cloudfrontHost}${pi.imagePath}?${new Date().getTime()}`} alt={pi.name} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return null; // If pi.status is inactive, don't render anything for this iteration
                })
              }
            </div>
        }
      </div>
    </div>
    </>
  )
}