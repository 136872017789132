import { apiFetch } from ".";

export const getPersons = async () => {
  const response =  await apiFetch({ endpoint: 'persons' });
  return response;
};

const endpoints = {
  getPersons
};

export default endpoints;