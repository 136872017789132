import { startCase } from "lodash"

export const handleShowDrawer = ({showDrawer, setShowDrawer, cancelActions}) => {
  if(showDrawer){
    cancelActions();
  }
  const bodyTop = document.getElementsByClassName("drawer-body-top")[0];
  bodyTop?.scrollIntoView();
  setShowDrawer(!showDrawer);
};

export default function TopDrawer({show, title, body, maxDrawerHeight, actions, drawerBodyHeight, contentsId, changePending, positionOverride, scrollBody=true}){
  const setClass = ({icon, className}) => {
    return icon ? `${className} has-icon-left` : className;
  };

  return (
    <div className={`top-drawer-container ` + (show ? "active" : "")} style={{"--maxDrawerHeight": maxDrawerHeight, position: positionOverride ? positionOverride : ''}}>
      <div className="is-flex is-flex-direction-column is-justify-content-space-between p-3 is-max-height">        
        <div className="top-drawer-title">
          <h4>{startCase(title?.toLowerCase())}</h4>
        </div>
        <div id={contentsId} className={scrollBody ? "top-drawer-contents" : ""} style={{"--drawerBodyHeight":drawerBodyHeight}}>
          {body}
        </div>
        <div className="actions is-flex is-align-items-center mt-3">
          {
            actions?.map(({isCustom, id, icon, text, action, className, disabled}) => (
              isCustom 
              ?
              <div key={id}>
                {isCustom}
              </div> 
              :
              <button key={id} className={`button mr-3 ${setClass({icon, className})}`} onClick={action} disabled={disabled}>
                <span className={icon ? "mr-2" : ""}>
                  {icon}
                </span>
                <span>
                  {startCase(text?.toLowerCase())}
                </span>
              </button>
            ))
          }
          {
            changePending &&
            <div className="has-text-warning-dark ml-2">Unsaved changes</div>
          }
        </div>
      </div>
    </div>
  )
}