import React, { useState } from 'react';
import reportWebVitals from './reportWebVitals';
import './styles/main.scss';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import 'rsuite/dist/rsuite.min.css';
import 'rsuite-table/dist/css/rsuite-table.min.css';
import router, { routes } from '../src/routes/index.js'; 
import aws_exports, { env as amplifyEnv } from './redirect-mutator';
import SignBackInModal from "./components/signbackinmodal";
import SystemMessages from './components/messaging/systemmessages';
import store from "./store";
import ImagesProvider from './components/ImagesContext';

// reassignment of dispatchEvent to ignore ResizeObserver
// certain rsuite elements sometimes give a harmless error
// that is cause from the resize animation. This was the 
// suggested way to ignore it from github.
const originalDispatchEvent = window.dispatchEvent;
window.dispatchEvent = (event) => {
  if(event.message.includes('ResizeObserver')) {
    console.warn('Ignored ResizeObserver error');
    return
  };
  originalDispatchEvent(event);
};

const awsConfig = aws_exports();

Amplify.configure(awsConfig);

const EnvBanner = () => {
  if(amplifyEnv !== 'production') {
    return <div className="env-banner banner is-uppercase is-relative py-1 has-text-centered is-size-7">
      <div className='inner-banner'>{amplifyEnv}</div>
    </div>
  }
}

const Main = () => {
  const [globalStore, setStore] = useState(store);

  const handleStoreUpdates = async (update = globalStore) => {
    setStore({...update});
    return globalStore;
  };
  store.handleStoreUpdates = handleStoreUpdates;

  return (
    <div className="is-flex is-flex-direction-column" style={{height:"100vh"}}>
      { !!globalStore.notifications.length && <SystemMessages globalStore={globalStore} />}
      <EnvBanner></EnvBanner>
      <SignBackInModal 
        key={globalStore.signBackIn} 
        globalStore={globalStore} 
        handleStoreUpdates={handleStoreUpdates}
      />
      <div className="is-flex is-flex-direction-column is-flex-grow-1">
        <ImagesProvider>
          <router.RouterProvider router={routes}/>
        </ImagesProvider>
      </div>
    </div>
  )
}

router.ReactDOM.createRoot(document.getElementById("root")).render(
  <Main />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
