import AnswerCell from "./AnswerCell";
import { useCallback, useState } from "react";

export default function QuestionCell({ question, showAnswers, setShowAnswers, orderedAnswers, handleTreeExpand, partnerReferenceMode, responses, filtered, ...props }) {

  const [showAnswersLocal, setShowAnswersLocal] = useState(null);
  const showCriteria = filtered ? true : ((showAnswersLocal || showAnswers) === question.id);

  const setShowAnswersLocalCallback = useCallback((val) => setShowAnswersLocal(val), [])

  return (
    question?.id &&
    <div>
      <div className={`ml-4 is-flex is-clickable branch-styling ${showCriteria ? 'mt-1' : ''}`} onClick={() => handleTreeExpand({ id: question.id, identifier: showAnswers || showAnswersLocal, action: setShowAnswers || setShowAnswersLocalCallback })}>
        <i className={`fa-solid fa-caret-${showCriteria ? 'down' : 'right'} mr-2`}></i>
        {question.topic && <div className="mr-2 has-text-weight-semibold has-text-grey-dark">
          {question.topic}:
        </div>}
        <div>
          {question.text}
        </div>
      </div>
      {
        (showCriteria) && partnerReferenceMode === 'answers' ?
          <div className="ml-6 is-size-6 is-capitalized mb-4">
            {responses?.map(response => (
              response?.questionId === question?.id &&
              <li key={response?.id}>
                {response.value === 'Text Answer' ?
                  response?.comments && <span key={response?.id}>{response?.comments}</span>
                  :
                  <span>
                    <span>{response?.value}</span>
                    {response?.comments && <div key={response?.id} className="ml-3">- comments: {response?.comments}</div>}
                  </span>
                }
              </li>
            ))}
          </div>
          :
          (showCriteria) && orderedAnswers?.map(answer => {
            return (
              answer.id &&
              <AnswerCell key={answer.id} answer={answer} isMock={true} />
            )
          })
      }
    </div>
  )
}