import { useState, useEffect } from 'react';

export default function Toggle({ value, setValue, disabled, ...props }) {
  const [togglePos, setTogglePos] = useState(false);
  const toggleSwitch = () => {
    if(disabled){
      return;
    }
    if(setValue){
      setValue(!value);
      props.cb(!value);
    } else {
      setTogglePos(!togglePos);
      props.cb(!togglePos);
    }
  };

  useEffect(() => {
    if(value !== undefined && setValue !== undefined ){
      setTogglePos(value);
    }
  }, [value, setValue]);

  return (
    <div className={`field is-flex is-align-items-center ${props.className}`}>
      <span className="mb-0 mr-2 label">{props.label}</span>
      <div className="is-clickable is-relative" onClick={toggleSwitch}>
        <div className={`override-toggle ${togglePos ? ' override-toggle-on' : ''} ${disabled ? 'toggle-disabled' : ''}`}>
          <div className={`override-latch ${!togglePos ? '' : 'override-latch-on'}`}></div>
        </div>
      </div>
    </div>
  )
}