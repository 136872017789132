import { apiFetch } from "..";
import store from "../../store";

export const getSpSummary = async ({ endpoint = `sp-summaries`, iStore = store }) => {
  return apiFetch({ endpoint });
};

const endpoints = {
  get: getSpSummary,
};

export default endpoints;
