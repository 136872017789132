import InputField from "../../../components/TextInput";
import QuestionType from "../../../components/products/QuestionType";
import { useState, useEffect } from "react";
import { v4 as uuidV4 } from "uuid";
import { Checkbox } from "rsuite";
import { isEmpty, orderBy, isEqual } from "lodash";
import microservices from "../../../services/microservices";
import ToggleSlider from "../../../components/ToggleSlider";

export default function QuestionForm({
  questionDetail,
  setQuestionDetail,
  disabled,
  setchangepending,
  cancelled,
  setcancelled,
  editmode,
  answerDetail,
  setAnswerDetail,
  subsections,
  isContracts,
  viewType,
  showDrawer,
}) {
  const [selectedAnswerType, setSelectedAnswerType] = useState("");
  const [originalDetail, setOriginalDetail] = useState({});
  const [editDetail, setEditDetail] = useState({});
  const [selectedSubsection, setSelectedSubsection] = useState({});
  const [canIncrementSort, setCanIncrementSort] = useState(true);

  // filter subsections based on if is partner or product fact sheet admin
  if (viewType && viewType === "isPartnerDetails") {
    const filteredArray = subsections.filter((item) => item.name === "partner-fact-sheet-subsection");
    subsections = filteredArray;
  } else if (viewType && viewType === "isProductDetails") {
    const filteredArray = subsections.filter((item) => item.name === "product-fact-sheet-subsection");
    subsections = filteredArray;
  }

  const checkPendingChangesChildren = async (a, b, questionDetail) => {
    let items = a?.length > b?.length ? a : b;
    let misMatch;
    for (let item of items) {
      const aExists = a?.find((i) => i.id === item.id);
      const bExists = b?.find((i) => i.id === item.id);
      // handle image flag nulls
      aExists.allowImage = aExists?.allowImage === false ? null : !!aExists?.allowImage;
      bExists.allowImage = bExists?.allowImage === false ? null : !!bExists?.allowImage;
      if (
        !isEqual(aExists, bExists) &&
        (aExists?.value || bExists?.value || questionDetail.answerType === "text" || questionDetail.answertype === "date picker")
      ) {
        misMatch = true;
        break;
      }
    }
    return !!misMatch;
  };

  const checkPendingChanges = async (a, b) => {
    let misMatch;
    for (let item in a) {
      if (a[item] !== b[item]) {
        if (item === "answers") {
          misMatch = await checkPendingChangesChildren(a[item], b[item], b);
          break;
        }
        misMatch = true;
        break;
      }
    }
    const isValid = await validation();
    setchangepending(!!misMatch && isValid);
  };

  const resetAnswerFields = () => {
    answerDetail.value = "";
    answerDetail.sortOrder = 0;
    answerDetail.additionalComments = 0;
    answerDetail.commentsRequired = 0;
    answerDetail.allowImage = null;
    setAnswerDetail({ ...answerDetail });
  };

  const validation = async () => {
    const isValid =
      (!!questionDetail?.text &&
        !!questionDetail?.subSectionId &&
        (questionDetail?.answers[0]?.value || (!questionDetail?.answers[0]?.value && questionDetail?.answerType === "text"))) ||
      ((questionDetail?.answers[0]?.value || (!questionDetail?.answers[0]?.value && questionDetail?.answerType === "date picker")) &&
        (questionDetail?.answerType !== "text" ? !!questionDetail?.answerType : true));
    return isValid;
  };

  const handleAnswerType = (e) => {
    async function handleAnswerTypeAsync() {
      const tempAnswerType = answerTypes.find((type) => type.toLowerCase() === e.target.value.toLowerCase());
      if (tempAnswerType === "text") {
        setSelectedAnswerType(null);
        if (questionDetail.answers?.length) {
          questionDetail.answers[0].value = "Text Answer";
        }
      }
      if (tempAnswerType === "date picker") {
        setSelectedAnswerType(null);
        if (questionDetail.answers?.length) {
          questionDetail.answers[0].value = "Date";
        }
      }
      setSelectedAnswerType(tempAnswerType);
      questionDetail.answerType = tempAnswerType;
      setQuestionDetail({ ...questionDetail });
      await checkPendingChanges(editmode ? editDetail : originalDetail, questionDetail);
    }
    handleAnswerTypeAsync().catch((e) => e);
  };

  const handleSubsection = (e) => {
    async function handleSubsectionAsync() {
      const tempSubsection = subsections.find((subsection) => subsection?.id === e.target.value);
      questionDetail.subSectionId = isEmpty(tempSubsection) ? null : tempSubsection?.id;

      // increment sort order based on highest current sortOrder value;
      questionDetail.sortOrder = microservices.getNextValue({ list: tempSubsection?.questions, key: "sortOrder" });

      setSelectedSubsection({ ...tempSubsection });
      setQuestionDetail({ ...questionDetail });
      await checkPendingChanges(editmode ? editDetail : originalDetail, questionDetail);
    }
    handleSubsectionAsync().catch((e) => e);
  };

  const handleInput = async ({ event, field, detail, setDetail }) => {
    detail[field] = event.target?.value;
    setDetail({ ...detail });
    setQuestionDetail({ ...questionDetail });
    await checkPendingChanges(editmode ? editDetail : originalDetail, questionDetail);
  };

  const resetQuestion = async () => {
    const typedd = document.getElementById("question-answer-type");
    if (typedd) typedd.selectedIndex = 0;
    resetAnswerFields();
    setSelectedAnswerType("");
    setSelectedSubsection({});
    questionDetail = structuredClone({ ...originalDetail });
    setQuestionDetail({ ...questionDetail });
    setEditDetail({});
    setCanIncrementSort(true);
  };

  // isFactSheet sortOrder
  useEffect(() => {
    let tempSelectedSubsection;

    if (viewType === "isProductDetails" || viewType === "isPartnerDetails") {
      if (viewType === "isProductDetails") {
        tempSelectedSubsection = subsections.find((item) => item.name === "product-fact-sheet-subsection");
      } else if (viewType === "isPartnerDetails") {
        tempSelectedSubsection = subsections.find((item) => item.name === "partner-fact-sheet-subsection");
      }

      setSelectedSubsection({ ...tempSelectedSubsection });
      questionDetail.subSectionId = tempSelectedSubsection?.id;

      if (!editmode) {
        questionDetail.sortOrder = microservices.getNextValue({ list: tempSelectedSubsection?.questions, key: "sortOrder" });
      }

      if (canIncrementSort && !editmode) {
        questionDetail.sortOrder = microservices.getNextValue({ list: tempSelectedSubsection?.questions, key: "sortOrder" });
        setCanIncrementSort(false);
      }

      setQuestionDetail({ ...questionDetail });
    }

    //eslint-disable-next-line
  }, [viewType, editmode, cancelled, showDrawer, viewType, microservices, showDrawer]);

  const addNewAnswer = async () => {
    // increment sort order based on highest current sortOrder value;
    answerDetail.sortOrder = microservices.getNextValue({ list: questionDetail?.answers, key: "sortOrder" });

    questionDetail?.answers?.push(structuredClone({ ...answerDetail }));
    setQuestionDetail({ ...questionDetail });
    await checkPendingChanges(editmode ? editDetail : originalDetail, questionDetail);
  };

  const removeAnswer = async (answerValue) => {
    const answerIdx = questionDetail?.answers?.indexOf(questionDetail?.answers?.find((a) => a.value === answerValue));
    questionDetail?.answers?.splice(answerIdx, 1);
    setQuestionDetail({ ...questionDetail });
    await checkPendingChanges(editmode ? editDetail : originalDetail, questionDetail);
  };

  useEffect(() => {
    if (editmode) {
      if (!questionDetail?.answers?.length) {
        questionDetail.answers = [structuredClone({ ...answerDetail })];
      }

      let tempOriginalDetail;
      if (isEmpty(editDetail)) {
        tempOriginalDetail = structuredClone(questionDetail);
        setEditDetail({ ...tempOriginalDetail });
      }

      if (!selectedAnswerType) {
        setSelectedAnswerType(questionDetail.answerType);
      }

      if (isEmpty(selectedSubsection) || !selectedSubsection) {
        const tempSelectedSubsection = subsections.find((subsection) => subsection?.id === questionDetail?.subSectionId);
        setSelectedSubsection({ ...tempSelectedSubsection });
      }
    } else {
      if (isEmpty(originalDetail)) {
        // set original data to make the mismatch comparison
        setOriginalDetail(structuredClone({ ...questionDetail }));
      }
    }

    if (questionDetail?.answers) {
      questionDetail.answers = orderBy(questionDetail?.answers, "sortOrder", "asc");
    }
    if (isEmpty(questionDetail.originalDetail) || !questionDetail?.originalDetail) {
      // to make comparisons on submit
      questionDetail.originalDetail = structuredClone({ ...questionDetail });
    }

    if (cancelled) {
      resetQuestion().catch((e) => e);
      setcancelled(false);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelled, editmode, questionDetail]);

  const answerTypes = ["multiple choice", "tag", "text", "bool radio", "bool check", "single select radio", "single select droplist", "date picker"];
  // drawer-body-top causes jump on close, but will scroll to top of drawer

  const Answer = ({ answer, idx, checkPendingChanges, addNewAnswer, removeAnswer }) => {
    const [a, setA] = useState({ ...answer });

    const handleAnswerInputs = async ({ field, value, setDetail }) => {
      answer[field] = value;
      setDetail({ ...answer });
      await checkPendingChanges();
    };

    const handleChange = async ({ name, status }) => {
      if (name === "commentsRequired") {
        if (status === true) {
          a.additionalComments = 1;
          a.commentsRequired = 1;
        } else {
          a.commentsRequired = 0;
        }
      } else {
        if (status === true) {
          a.additionalComments = 1;
        } else {
          a.additionalComments = 0;
          a.commentsRequired = 0;
        }
      }
      setA({ ...a });
      answer.additionalComments = a.additionalComments;
      answer.commentsRequired = a.commentsRequired;
      await checkPendingChanges();
    };

    return (
      <div className="is-flex is-flex-direction-column">
        <div className="is-flex is-align-items-flex-start answer-row">
          <InputField
            label="Answer"
            value={a.value}
            onChange={(e) => {
              handleAnswerInputs({ field: "value", value: e.target.value, setDetail: setA }).catch((e) => e);
            }}
            disabled={disabled}
            maxlength="256"
            controlClass="mr-2 mb-0 is-width-full"
          />
          <InputField
            label="Answer Sort Order"
            type="number"
            value={a?.sortOrder || 0}
            onChange={(e) => handleAnswerInputs({ field: "sortOrder", value: e.target.value, setDetail: setA })}
            disabled={disabled}
            style={{ maxWidth: "200px" }}
            controlClass="mr-2 mb-0"
          />
          <div className="control is-flex" style={{ paddingTop: "34px" }}>
            {questionDetail?.answers?.length - 1 === idx && (
              <button className="button is-info mr-2" onClick={addNewAnswer} disabled={disabled}>
                <i className="fa-solid fa-plus"></i>
              </button>
            )}
            {questionDetail?.answers.length > 1 && (
              <button className="button is-warning mr-2" onClick={() => removeAnswer(a?.value)} disabled={disabled}>
                <i className="fa-solid fa-minus"></i>
              </button>
            )}
          </div>
        </div>
        <div className="is-flex">
          <div className="field mt-2 mr-6">
            <label className="label" style={{ fontWeight: "normal" }}>
              Comments
            </label>
            <Checkbox
              checked={!!a?.additionalComments}
              onChange={(_a, status) => handleChange({ name: "additionalComments", status })}
              value={a?.additionalComments}
              disabled={disabled}
            >
              Allow additional comments
            </Checkbox>
            <Checkbox
              checked={!!a?.commentsRequired}
              onChange={(_a, status) => handleChange({ name: "commentsRequired", status })}
              value={a?.commentsRequired}
              disabled={disabled}
            >
              Require additional comments
            </Checkbox>
          </div>
          <div className="is-flex mt-2 ml-6 is-flex-grow-1">
            {viewType && viewType !== "isProductDetails" && viewType && viewType !== "isPartnerDetails" && (
              <div className="field mb-4 mr-6">
                <label className="label mb-4" style={{ fontWeight: "normal" }}>
                  Allow Image
                </label>
                <ToggleSlider
                  disabled={disabled}
                  value={a?.allowImage}
                  setValue={() => true}
                  cb={(e) => handleAnswerInputs({ field: "allowImage", value: e, setDetail: setA })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="drawer-body-top">
      {!isContracts && viewType && viewType !== "isProductDetails" && viewType && viewType !== "isPartnerDetails" && (
        <InputField
          label="Topic"
          placeholder={`(Optional)`}
          value={questionDetail?.topic || ""}
          onChange={(e) => handleInput({ event: e, field: "topic", detail: questionDetail, setDetail: setQuestionDetail })}
          disabled={disabled}
          maxlength="128"
        />
      )}
      <InputField
        label="Question"
        type="textarea"
        value={questionDetail?.text || ""}
        onChange={(e) => handleInput({ event: e, field: "text", detail: questionDetail, setDetail: setQuestionDetail })}
        disabled={disabled}
        maxlength="512"
      />
      <InputField
        label="Description"
        type="textarea"
        value={questionDetail?.description || ""}
        onChange={(e) => handleInput({ event: e, field: "description", detail: questionDetail, setDetail: setQuestionDetail })}
        disabled={disabled}
        maxlength="512"
      />
      {viewType && viewType !== "isProductDetails" && viewType && viewType !== "isPartnerDetails" && (
        <div className="field is-capitalized is-flex is-flex-direction-column">
          <label className="label mb-0" style={{ fontWeight: "normal" }}>
            subsections
          </label>
          <div className="select mr-2">
            <select id="question-subsectionId" onChange={(e) => handleSubsection(e)} value={selectedSubsection?.id || ""} disabled={disabled}>
              <option defaultValue={true}>Choose From List</option>
              {orderBy(
                subsections?.filter(
                  (subsection) => subsection?.name !== "partner-fact-sheet-subsection" && subsection?.name !== "product-fact-sheet-subsection"
                ),
                [(data) => data.name?.toLowerCase()],
                ["asc"]
              ).map((subsection) => (
                <option key={subsection?.id} value={subsection?.id}>
                  {subsection?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <div className="is-flex">
        <InputField
          label="Question Sort Order"
          type="number"
          value={questionDetail?.sortOrder || 0}
          onChange={(e) => handleInput({ event: e, field: "sortOrder", detail: questionDetail, setDetail: setQuestionDetail })}
          disabled={disabled || !questionDetail?.subSectionId}
          style={{ maxWidth: "75px" }}
          controlClass="mb-3"
        />
        <div className="ml-6 mt-3">
          <label className="label" style={{ fontWeight: "normal" }}>
            SP Summary
          </label>
          <ToggleSlider
            disabled={disabled}
            value={questionDetail?.spSummary === 0 ? false : questionDetail?.spSummary === 1 ? true : questionDetail?.spSummary}
            setValue={() => true}
            cb={(e) => handleInput({ event: { target: { value: e } }, field: "spSummary", detail: questionDetail, setDetail: setQuestionDetail })}
          />
        </div>
      </div>
      {selectedAnswerType !== "text" && selectedAnswerType !== "date picker" && (
        <div>
          <hr />
          <div className="field">
            <label className="label mb-0 is-capitalized" style={{ fontWeight: "normal" }}>
              Answer list
            </label>
            {questionDetail?.answers?.map((answer, idx) => {
              return (
                <Answer
                  key={answer?.id || uuidV4()}
                  answer={answer}
                  idx={idx}
                  checkPendingChanges={() => checkPendingChanges(editmode ? editDetail : originalDetail, questionDetail)}
                  addNewAnswer={addNewAnswer}
                  removeAnswer={removeAnswer}
                />
              );
            })}
          </div>
        </div>
      )}
      <div className="is-flex">
        <div className="field is-capitalized mt-3 mr-6 is-flex is-flex-direction-column">
          <label className="label mb-0" style={{ fontWeight: "normal" }}>
            Answer Type
          </label>
          <div className="select mr-2">
            <select id="question-answer-type" onChange={(e) => handleAnswerType(e)} value={selectedAnswerType || ""} disabled={disabled}>
              <option defaultValue={true}>Choose From List</option>
              {answerTypes?.map((type) => (
                <option key={uuidV4()} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
        {viewType && viewType !== "isProductDetails" && viewType && viewType !== "isPartnerDetails" && selectedAnswerType === "text" && (
          <div className="field mr-6 mt-3">
            <label className="label" style={{ fontWeight: "normal" }}>
              Allow Image
            </label>
            <ToggleSlider
              disabled={disabled}
              value={questionDetail.answers[0].allowImage}
              setValue={(val) => {
                if (questionDetail?.answers.length === 1) {
                  questionDetail.answers[0].allowImage = val;
                  setQuestionDetail({ ...questionDetail });
                }
                answerDetail.allowImage = val;
                setAnswerDetail({ ...answerDetail });
              }}
              cb={async (e) => await checkPendingChanges(editmode ? editDetail : originalDetail, questionDetail)}
            />
          </div>
        )}
      </div>
      <div className="content">
        <QuestionType type={questionDetail.answerType} question={questionDetail} isMock={true} />
      </div>
    </div>
  );
}
