import { apiFetch } from ".";
import store from '../store';

export const getS3Image = async (id, iStore = store) => {
  const response = await apiFetch({ endpoint: `s3images/${id}` });
  if(!iStore.s3ImageObjects.includes(response)){
    iStore.s3ImageObjects.push(response);
  }
  return response;
};

export const uploadImage = async ({iStore = store, body}) => {
  return apiFetch({ endpoint: `s3images`, method: 'POST', body});
};

export const deleteImage = async ({fileName,body}, iStore = store) => {
  // example: {"fileName": "testImage.jpg", body: {"fileName": 'testImage.jpg'}}
  if(!fileName) {
    const message = `Unable to delete this image. ${fileName} file name can not be found.`;
    store.actions.handleError({message}, iStore);
    return Promise.reject(message)
  }
  const response = await apiFetch({ endpoint: `s3images/${fileName}`, method: 'DELETE', body});
  if(!response) return;
  return response;
};

const endpoints = {
  get: ({id}) => getS3Image(id),
  uploadImage,
  deleteImage
};

export default endpoints;