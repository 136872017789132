import Header from "../../components/header";
import SideMenu from "../../components/sidemenu";
import ReportsCard from "../../components/reportscard";
import SearchList from "../../components/searchlist";
import { useState, useEffect, useRef, useCallback } from "react";
import { Table, SelectPicker, CheckPicker } from 'rsuite';
import { useNavigate, useLocation } from 'react-router-dom';
import store, { getUserCognitoGroups } from '../../store';
import { trim, orderBy, union } from 'lodash';
import { v4 as uuidV4} from 'uuid';
import api from '../../api';
import { Loader } from 'rsuite';

// top drawer
import ApiSubmitButton from '../../components/buttons/ApiSubmitButton';
import TopDrawer, { handleShowDrawer } from "../../components/TopDrawer";
import NewReportDrawerBody from "./NewReportDrawerBody";

export default function ReportsLandingPage() {
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(store.reportsDisplay === 'table');
  const [reports, setReports] = useState([]);
  const [allReports, setAllReports] = useState([]); // Store all reports
  const [allDepartments, setAllDepartments] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const table = useRef();
  const { Column, HeaderCell, Cell } = Table;
  const [liHeaders, setLiHeaders] = useState([]);
  const [sortType, setSortType] = useState();
  const [sortColumn, setSortColumn] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [reportUrlCopied, setReportUrlCopied] = useState('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reportTitle = searchParams.get('title');
  const reportUrl = searchParams.get('url');
  const reportId = searchParams.get('reportId');

  // delete Tableau redirect cookie
  document.cookie = "tableauCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

  const tableFilter = a => {
    async function tableFilterAsync(){
      setLoading(true);
      const filteredTableData = [];
      // Do async magic
      for(let r of tableData){
        if(r.id === a){
          filteredTableData.push(r);
        }
      }
      setTableData([...filteredTableData]);
      setLoading(false);
    }
    tableFilterAsync().catch(e => e);
  };

  const goToReport = useCallback(({ title, reportUrl, tableauJwt }) => {
    navigate('/reports/report-view', { state: { title, reportUrl, tableauJwt } });
  }, [navigate]);

  const tableDataLoader = useCallback(() => {
    const uniqHeaders = [];
    const headerObjList = [];
    const tableDta = [...reports];
    for(let r of tableDta){
      for(let li of r.reportDetails){
        const property = li.detailKey
        const value = trim(li.detailValue);
        r[property] = value;
        if(uniqHeaders.indexOf(property) === -1){
          uniqHeaders.push(property);
          headerObjList.push({id:uuidV4(), detailValue:property, detailKey:value})
        }
      }
    }
    const reorderedReportDetails = tableDta.map(report => {
      report.reportDetails = orderBy(report?.reportDetails, [rd => rd.detailKey.toLowerCase()], ['asc']);
      return report;
    });
    const reordered = orderBy(reorderedReportDetails, [report => report.title.toLowerCase()], ['asc']);
    setLiHeaders(headerObjList);
    setTableData(reordered);
  }, [reports]);

  useEffect(() => {
    // Create a function to extract all unique departments from the reports data
    const uniqueDepartments = Array.from(
      new Set(allReports.flatMap(report =>
        report.reportDetails
          .filter(detail => detail.detailKey === 'Department')
          .map(detail => detail.detailValue)
      ))
    );

    // Set allDepartments state with the list of all unique departments
    setAllDepartments(uniqueDepartments);

    // handle department navigation from sidebar
    const reportSessionFilter = sessionStorage.getItem('reportFilter')
    if(reportSessionFilter){
      setSelectedDepartments([reportSessionFilter]);
      sessionStorage.removeItem('reportFilter');
    }
  }, [allReports]);

  const [isReportsAdmin, setIsReportsAdmin] = useState(false);
  useEffect(() => {
    const checkReportsAdmin = async () => {
      const response = await getUserCognitoGroups('reportsAdmin')
      setIsReportsAdmin(response);
    };
    checkReportsAdmin().catch(e => e);
  }, []);

  const [tableauJwt, setTableauJwt] = useState();
  useEffect(() => {
    async function fetchData(){
      if(!store.embeddedReports.length) {
        await api.reports.getEmbeddedReports();
      }
      setReports([...store.embeddedReports]);
      setAllReports([...store.embeddedReports]);
      const tempToken = await api.reports.getTableauToken({username: store?.user?.email});
      setTableauJwt(tempToken?.token);
      if(reportId){
        const matchingRecord = store.embeddedReports.find(record => record.id === reportId);
        if (matchingRecord) {
          goToReport({title: matchingRecord.title, reportUrl: matchingRecord.reportUrl, tableauJwt: tempToken.token});
        } 
      }
      setPageLoading(false);
    }
    fetchData().catch(e => e);
  },[reportId, goToReport]);

  useEffect(() => {
    const handleIdExistsWithToken = (reportTitle, reportUrl, tableauJwt) => {
      handleRedirectToReport(reportTitle, reportUrl, tableauJwt);
    };
  
    const handleRedirectToReport = (reportTitle, reportUrl, tableauJwt) => {
      // logic to redirect to the report
      if(reportTitle && reportUrl && tableauJwt){
        goToReport({title: reportTitle, reportUrl: reportUrl, tableauJwt});
      }
    };
  
    if (reportTitle && reportUrl && tableauJwt) {
      setLoading(true);
      handleIdExistsWithToken(reportTitle, reportUrl, tableauJwt);
    }
     //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTitle, reportUrl, tableauJwt]);
  
  useEffect(() => {
    if(!reportTitle){
      async function loadData(){
        tableDataLoader();
      }
      if(reports.length){
        loadData().catch(e => e);
      }
    }

    /*
      using the suggested lint fixes for calling tableDataLoader causes an infinite loop.
      The best solution to this that doesn't cause an infinite loop is to just disable the 
      warning since the business logic is working as expected.
      https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
    */
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, reportUrl, reportTitle]);

  useEffect(() => {
    if(liHeaders.length){
      setPageLoading(false);
    }
  }, [liHeaders]);

  const handleTableDisplay = () => {
    setLoading(true);
    if(showTable) {
      store.reportsDisplay = 'card';
      setShowTable(false);
    } else {
      store.reportsDisplay = 'table';
      tableDataLoader();

      setShowTable(true);
      setLoading(false);
    }
  };
  
  const sortData = async (sc, st) => {
    sc = sc.split(' ').join('');
    const isMappedColumn = sc.includes('LiColumn');
    if (sc && st) {
      if(isMappedColumn){
        const customSortColumn = sc.split('LiColumn')[0];
        for(const item of tableData){
          for(const li of item.reportDetails) {
            li.detailKey = trim(li.detailKey).split(' ').join('');
            li.detailValue = trim(li.detailValue);
            if(li.detailKey.includes(customSortColumn)) {
              item[`sort${sc}`] = li.detailValue.toLowerCase();
            }
          }
        }
        const reordered = orderBy(tableData, `sort${sc}`, st);
        setTableData([...reordered]);
      } else {
        for(const item of tableData) item[`sort${sc}`] = item[sc].toLowerCase();
        const reordered = orderBy(tableData, `sort${sc}`, st);
        setTableData([...reordered]);
      }
    }
    return
  };

  const handleSortColumn = async (sortColumn, sortType) => {
    setLoading(true);
    await sortData(sortColumn, sortType);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  // New report drawer
  const [showDrawer, setShowDrawer] = useState(false);
  const [reset, setReset] = useState(false);

  const cancelActions = () => {
    setChangePending(false);
    params.current = {};
    setEditData(null);
    setReset(true);
    setDisableSubmit(true);
  };

  const handleShowDrawerLocal = () => {
    if(reset) {setReset(false)};
    handleShowDrawer({showDrawer, setShowDrawer, cancelActions});
  };
  
  let params = useRef({});
  
  const sendParams = async (value) => {
    params.current = value;
    const isValid = await validation();
    setDisableSubmit(!isValid);
    setChangePending(!!params?.current?.id);
  };

  const validation = async () => (!!params?.current?.title && !!params?.current?.description && !!params?.current?.reportUrl);

  /*
    submitActionTriggered and action done force the ApiSubmitButton to render
    in the correct state if the parent rerenders as part of the submit.
    The use case for this is disabling fields that are in the parent/sibling of 
    where the ApiSubmitButton is defined.
  */
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState();
  const [disableFields, setDisableFields] = useState();
  const [changePending, setChangePending] = useState(false);
  const submit = async (data) => {
    setChangePending(false);
    setSubmitActionTriggered(true);
    setDisableFields(true);
    const isUpdate = !!data.id;
    if(data.newBullets){
      for(const bullet of data.newBullets){
        delete bullet.id;
      }
    }
    data.reportDetails = union(data.reportDetails, data.newBullets);
    delete data.newBullets;
    for(const lineItem of data.reportDetails){
      if(!lineItem.tableauReportId){
        delete lineItem.id;
      }
    }
    let response;
    if(isUpdate){
      response = await api.reports.updateEmbeddedReport({id: data.id, data, iStore:store});
    } else {
      response = await api.reports.createEmbeddedReport({data, iStore: store});
    }
    setActionDone(true);
    return response;
  };

  const [editData, setEditData] = useState();
  const edit = async (data) => {
    setReset(true);
    setEditData({...data})
    setTimeout(() => {
      setReset(false);
      setShowDrawer(true);
    });
  };

  const deleteEmbedded = async data => { 
    const response = await api.reports.deleteEmbeddedReport({id:data.id});
    return response;
  }

  const cb = async () => navigate(0);

  const [disableSubmit, setDisableSubmit] = useState(true);
  const drawerActions = [
    {
      id: uuidV4(),
      text:"Cancel",
      className:"is-warning",
      icon:<i className="fa-solid fa-ban"></i>,
      disabled:disableFields,
      action() {
        handleShowDrawerLocal();
      }
    },
    {
      id: uuidV4(),
      isCustom:(
        <ApiSubmitButton 
          defaultLabel={editData ? "Update Report" : "Embed Report" }
          operationLabel="Saving" 
          icon={<i className="fa-solid fa-file-circle-plus"></i>}
          action={() => submit(params.current)}
          disabled={disableSubmit}
          cb={cb}
          // used to force correct rendering when parent re-renders
          actionTriggered={submitActionTriggered}
          actionDone={actionDone}
        />
      )
    }
  ];

  const handleDepartmentSelect = (value) => {
    setSelectedDepartments(value);
  };

  const handleDepartmentClick = (value) => {
    let tempArr = [];
    tempArr.push(value);
    setSelectedDepartments(tempArr);
  };

  const handleShareReport = (data) => {
    const fullURL = `${window.location.origin}/reports?reportId=${data.id}`;
      navigator.clipboard.writeText(fullURL)
      .then(() => {
        setReportUrlCopied(data.id);
        setTimeout(() => {
          setReportUrlCopied('');
        }, 5000);
      })
      .catch((error) => {
        console.error('Error copying URL to clipboard:', error);
      });
  };

  useEffect(() => {
    // If no departments are selected, display all reports from allReports
    if (selectedDepartments.length === 0) {
      setReports(allReports);
    } else {
      // Filter the reports based on the selected departments
      const filteredReports = allReports.filter(report => {
        const departmentDetail = report.reportDetails.find(detail => detail.detailKey === 'Department');
        return departmentDetail && selectedDepartments.includes(departmentDetail.detailValue);
      });

      // Update reports state with the filtered data
      setReports(filteredReports);
    }
  }, [selectedDepartments, allReports]);
  
  return (
    <>
      <Header></Header>
      <div className="is-flex" style={{ height: '100%' }}>
        <SideMenu defaultOpenMenus={["3"]}></SideMenu>
        <div className="is-flex-grow-1 is-flex is-flex-direction-column">
          <h4 className="px-3 pt-2 title-color is-flex is-justify-content-space-between is-align-items-center">
            <span>
              Reports Dashboard
            </span>
            <span>
              {
                !showTable && isReportsAdmin &&
                <button className="title-button mr-3" onClick={handleShowDrawerLocal}>Embed Report</button>
              }
              <button className="title-button" onClick={handleTableDisplay}>Display as { showTable ? 'Card' : 'Table' }</button>
            </span>
          </h4>
          {
            pageLoading ?
            <Loader  size="lg" content="Loading..." vertical center />
            :
            <>
              {
                showTable &&
                <div className="is-flex is-justify-content-flex-start is-align-items-center is-width-full">
                  <SelectPicker
                    label="Report"
                    data={tableData} 
                    labelKey="title" 
                    valueKey="id" 
                    onSelect={tableFilter}
                    onClean={tableDataLoader}
                    className="m-3 select-picker"                    
                  />
                  <SearchList id="embedded-reports-search" addClass="is-width-full mr-3" data={tableData} cb={setTableData}/>
                </div>
              }
              {
                showTable ?
                <div style={{height: "100%"}} className="p-3">
                  <Table
                    minHeight={480}
                    fillHeight={true}
                    data={tableData}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    loading={loading}
                    headerHeight={50}
                    rowHeight={50}
                    ref={table}
                    wordWrap={"break-word"}
                    onRowClick={(rowData, e) => {
                      if(e.target.tagName === 'A') return e.stopPropagation();
                      goToReport({title:rowData.title, reportUrl:rowData.reportUrl, tableauJwt});
                    }}
                  >
                    <Column fixed sortable resizable verticalAlign={'middle'} width={200}>
                      <HeaderCell>Report</HeaderCell>
                      <Cell dataKey="title" className="is-clickable"/>
                    </Column>
                    <Column sortable resizable verticalAlign={'middle'} width={200}>
                      <HeaderCell>Description</HeaderCell>
                      <Cell dataKey="description" className="is-clickable"/>
                    </Column>
                    {
                      liHeaders && liHeaders.map(li => {
                        return (
                          <Column resizable width={200} key={li.id || uuidV4()} verticalAlign={'middle'} sortable>
                            <HeaderCell>{li.detailValue}</HeaderCell>
                            <Cell dataKey={`${li.detailValue}LiColumn`} className="is-clickable">{
                              rowData => {
                                if(rowData[li.detailValue]?.includes('http')){
                                  return (
                                    <a href={rowData[li.detailValue]} target="_blank" rel="noreferrer">{rowData[li.detailValue]}</a>
                                  )
                                }
                                return rowData[li.detailValue]
                              }}
                            </Cell>
                          </Column>
                        )
                      })
                    }
                  </Table>
                </div> :
                <div className="is-flex is-flex-direction-column is-width-full" style={{height: "100%"}}>
                  <TopDrawer
                    maxDrawerHeight="400px"
                    show={showDrawer}
                    title={ editData?.id ? "Edit embedded report" : "Embed new report"}
                    body={
                      !reset &&
                      <NewReportDrawerBody updateParams={sendParams} headers={liHeaders} data={structuredClone(editData)} disabled={disableFields}/>
                    }
                    actions={drawerActions}
                    drawerBodyHeight="700px"
                    contentsId="new-report-contents"
                    changePending={changePending}
                  />
                  <div className="is-flex is-width-full" style={{marginTop: '1rem'}}>
                    <CheckPicker
                      data={allDepartments.map(option => ({ label: option, value: option }))}
                      style={{ width: 224, marginLeft: 12 }}
                      className="mb-3 select-picker"
                      labelKey="label"
                      valueKey="value"
                      multiple
                      value={selectedDepartments}
                      label="Departments"
                      onChange={handleDepartmentSelect}
                      cleanable={false}
                    />
                  </div>
                  <div className="is-flex is-flex-wrap-wrap is-flex-grow-1 is-flex-shrink-1" style={{height:"0", overflow: "auto"}}>
                    { !reports?.length ?
                      <div className="is-flex is-justify-content-center is-align-items-center" style={{height: "500px", width: "100%"}}>No Reports Found</div>
                      :
                      reports.map(r => (
                        <ReportsCard 
                          key={r.id || uuidV4()} 
                          {...r} 
                          editAction={edit} 
                          deleteAction={deleteEmbedded} 
                          cb={cb} 
                          tableauJwt={tableauJwt} 
                          isReportsAdmin={isReportsAdmin} 
                          handleDepartmentClick={handleDepartmentClick}
                          handleShareReport={handleShareReport}
                          reportUrlCopied={reportUrlCopied}
                        />
                      ))
                    }
                  </div>
                </div>
              }
            </>
          }
        </div>
      </div>
    </>
  )
}