import { apiFetch } from ".";
import store from '../store';

export const createEmbeddedReport = async ({data}) => {
  const response = await apiFetch({endpoint: `tableau-reports`, method: 'POST', body: data});
  if(!response) return;
  await getEmbeddedReports();
  return response;
};

export const getEmbeddedReports = async (iStore = store) => {
  const response = await apiFetch({endpoint: `tableau-reports/?includeDetails=true`});
  if(!response) return;
  iStore.embeddedReports = [...response];
  return iStore.embeddedReports;
};

export const updateEmbeddedReport = async ({id, data, fallback, iStore = store}) => {
  if(!id) {
    const message = `Unable to update this embedded report. Embedded report ID (${id}) can not be found.`;
    store.actions.handleError({message}, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({endpoint: `tableau-reports/${id}`, method: 'PUT', body: data, fallback});
  
  if(!response) return Promise.reject('error');
  const refreshStoreReports = await getEmbeddedReports();
  return refreshStoreReports;
};

export const deleteEmbeddedReport = async ({id, iStore = store}) => {
  if(!id) {
    const message = `Unable to delete this embedded report. Embedded report ID (${id}) can not be found.`;
    store.actions.handleError({message}, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({endpoint: `tableau-reports/${id}`, method: 'DELETE', body: {}});
  if(!response) return Promise.reject('error');
  await getEmbeddedReports();
  return response;
};

export const getTableauToken = async ({username, iStore = store}) => {
  if(!username) {
    const message = `Unable to get tableau token. username is undefined.`;
    store.actions.handleError({message}, iStore);
    return Promise.reject(message);
  }                                         
  const response = await apiFetch({endpoint: `tableau-jwt/{}/?username=${username}`});
  if(!response) return Promise.reject('error');
  return response;
};

const endpoints = {
  createEmbeddedReport,
  getEmbeddedReports,
  updateEmbeddedReport,
  deleteEmbeddedReport,
  getTableauToken
};

export default endpoints;