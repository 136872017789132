import { useState, useEffect, useRef } from "react";
import { last, get } from "lodash";

export default function ImageUpload({...props}){
  const [fileIsValid, setFileIsValid] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [currentImageSize, setCurrentImageSize] = useState();

  const clear = () => {
    async function clearAsync(){
      document.querySelectorAll('#selected-file')[0].value = null;
      setDisabled(false);
      setFileIsValid(true);
      setTooLarge(false);
      setCurrentImageSize("");
      if(props?.cb){
        props.cb({});
      }
    }
    clearAsync().catch(e => e);
  };

  useEffect(() => {
    if(props.reset){
      clear();
    }
    if(props.disabled){
      setDisabled(true);
    }
    // eslint-disable-next-line
  }, [props.reset, props.disabled]);

  const getExt = (fileName) => last(fileName.split('.'));

  const checkValidation = async (file) => {
    setTooLarge(false);
    const validExtentions = ["jpg", "bmp", "png", "tif", "gif", "tiff", "jpeg"];
    const extension = getExt(file?.name);
    if(!validExtentions.includes(extension.toLowerCase())){
      setFileIsValid(false);
      return false;
    }
    return true;
  };
  
  const handleFileSelection = (val) => {
    async function fileSelectAsync(){
      const tempFile = get(val, 'target.files[0]');
      await createBlob(tempFile)
    }
    fileSelectAsync().catch(e => e);
  };
  
  const createBlob = async (file) => {
    await checkValidation(file);
    const blob = await uploadPartnerImage();
    return props.cb ? props.cb({blob, isValid: !tooLarge || fileIsValid}) : blob;
  };

  const [tooLarge, setTooLarge] = useState(false);
  const imageDimensions = useRef({
    width: 0,
    height: 0
  });
  const qualityVal = useRef(1);
  const uploadPartnerImage = async () => {
    const selectedFile = document.querySelectorAll('#selected-file')[0].files[0];
  
    const compressImage = async (imageFile, quality, name) => {
      return new Promise((resolve, reject) => {
        const $canvas = document.createElement("canvas");
        const image = new Image();
        image.onload = () => {
          console.log('resizing');
          imageDimensions.current.width = imageDimensions.current.width ? imageDimensions.current.width : image.width;
          imageDimensions.current.height = imageDimensions.current.height ? imageDimensions.current.height : image.height;
          const iw = imageDimensions.current.width;
          const ih = imageDimensions.current.height;
          $canvas.width = iw
          $canvas.height = ih
          $canvas.getContext("2d").drawImage(image, 0, 0, iw, ih);
          return resolve($canvas.toDataURL(`image/${getExt(name)}`, quality))
        };
        image.src = URL.createObjectURL(imageFile);
      });
    };
  
    let blob = {
      fileName: selectedFile?.name,
      fileObject: await compressImage(selectedFile, qualityVal.current, selectedFile?.name)
    };
  
    while (new Blob([blob.fileObject]).size >= 1024000) {
      imageDimensions.current.width /= 2;
      imageDimensions.current.height /= 2;
      blob.fileObject = await compressImage(selectedFile, qualityVal.current, selectedFile?.name);
    }
  
    if (!!blob.fileObject) {
      setTooLarge(false);
      const size = new Blob([blob.fileObject]).size;
      const newSize = (size / 1000) + 'kb';
      setCurrentImageSize(newSize);
      setFileIsValid(true);
    }
  
    return blob;
  };

  return (
    <>
      <div className="image-upload-container p-3 mt-3 is-width-full">
        <div className="field">
          <label className="label is-capitalized">{props.uploadDescription}</label>
          <div className="control has-icons-right">
            <input className={fileIsValid ? "input" : "input is-danger"} id="selected-file" type="file" title="" multiple onChange={handleFileSelection} disabled={disabled}/>
            {
              !fileIsValid &&
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
            }
          </div>
          <p className="help">
            Image size must be less than 1MB.
            {
              currentImageSize &&
              <strong className="ml-2">
                <span>Selected image size: {currentImageSize}</span>
              </strong>
            }
          </p>
          {
            !fileIsValid &&
              <p className="help is-danger">You must select a valid file</p>
          }
          {
            tooLarge &&
            <div>
              <p className="help is-danger">The selected file is too large, automagically resizing!</p>
            </div>
          }
        </div>
        <div className="field is-grouped mt-3">
          <div className="control">
            <button className="button" onClick={clear} disabled={disabled}>
              <span className="mr-2">
                <i className="fa-solid fa-xmark"></i>
              </span>
              <span>
                Clear
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}