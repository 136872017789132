import Header from "../../components/header";
import SideMenu from "../../components/sidemenu";
import React,{useState,useEffect,useRef} from 'react'; // reactive state
import api from '../../api';
import { Table, SelectPicker, Loader } from 'rsuite';
import { orderBy, words, findIndex } from "lodash";
import store from '../../store';

const _orderBy = orderBy;

const reduceTopicRoles = async legalRoles => {
  const reducePersonList = async personList => {
    const reducedPersonList = [];
    for(let person of personList){
      if(person.id !== "007"){
        reducedPersonList.push({
          id:person.id
        });
      }
    }
    return reducedPersonList;
  };
  const reducedTopicRoles = [];
  for(let topicRole of legalRoles){
    reducedTopicRoles.push({
      id: topicRole.id,
      personList: await reducePersonList(topicRole.personList)
    });
  }
  return reducedTopicRoles;
};

const unAssignedPlaceholder = {
  "id": "007",
  "name": "",
  "email": "",
  "title": "Unassigned",
  "department": null,
  "active": 1
};

const defaultColumnWidth = 150;
const defaultEditColumnWidth = 250;
const defaultRowHeight = 120;

const addPersonPlaceHolder = async prtList => {
  for(let p of prtList){
    for(let r of p.legalRoles){
      if(!r.personList.length){
        r.personList.push(unAssignedPlaceholder);
      }
    }
  }
  return prtList
};

export default function TopicsMain() {

  const isPageAdmin = store.user.groups.includes("legalResponsibilitiesAdmin");
  const [topics,setTopics]=useState([]); // reactive state
  const [roles,setRoles]=useState([]); // reactive state
  const [people,setPeople]=useState([]); // reactive state
  const table = useRef();

  useEffect(() => {// reactive state
    async function fetchData() {
      fetchPeople().catch(e => e);
      const p = await fetchTopics();
      const rolesFromPRT = p[0]?.legalRoles.map(pr => ({id:pr.id,name:pr.name}));
      setRoles(rolesFromPRT);
      setLoading(false);
    }
    fetchData().catch(e => e);
  }, []);

  const fetchTopics = async () => { // reactive state
    const response = await api.legal.getLegalResponsibilities();    
    const temp = await addPersonPlaceHolder(response);
    _orderBy(temp, 'name.toLowerCase()', 'asc');
    setTopics([...temp]); 
    return temp;
  };

  const fetchPeople = async () => {
    const response = await api.person.getPersons();
    const activeOnly = response.filter(r => !!r.active);
    const tempPeople = orderBy(activeOnly, 'name', 'asc');
    tempPeople.push(unAssignedPlaceholder);
    setPeople(tempPeople); 
  }

  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(true);

  const sortData = async (sc, st) => {
    const isMappedColumn = sc.includes('RoleColumn');
    if (sc && st) {
      if(isMappedColumn){
        const customSortColumn = sc.split('RoleColumn')[0];
        for(const topic of topics) {
          for(const role of topic.legalRoles) {
            if(role.name.includes(customSortColumn)) {
              const personToSortBy = role.personList[0]?.name;
              topic[`sort${sc}`] = personToSortBy.toLowerCase();
            }
          }
        }
        const reordered = orderBy(topics, `sort${sc}`, st);
        setTopics([...reordered]);
      } else {
        for(const topic of topics) topic[`sort${sc}`] = topic[sc].toLowerCase();
        const reordered = orderBy(topics, `sort${sc}`, st);
        setTopics([...reordered]);
      }
    }
    return
  };

  const handleSortColumn = async (sortColumn, sortType) => {
    setLoading(true);
    await sortData(sortColumn, sortType);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  // handleCellEdit logic
  const [editing, setEditCell] = useState({});
  const [originalScrollPos, setOriginalScrollPos] = useState({x:0,y:0})
  const [roleColumnWidth, setRoleColumnWidth] = useState(defaultColumnWidth);
  const [selectPickerWidth, setSelectPickerWidth] = useState(defaultEditColumnWidth);
  const [scrollPos, setScrollPos] = useState({x: 0,y: 0});

  const handleCellEdit = (e,pId,{...rowData},{...props}) => {
    if(e.target.tagName === 'A') return e.stopPropagation();
    if(!isPageAdmin) return;
    // set display widths    
    const labelWidth = (getLongestText(people, "name")?.name.length) * 6.8;  
    setRoleColumnWidth(labelWidth);  
    setSelectPickerWidth((labelWidth -20));
    const originalColWidth = roleColumnWidth; // used to scroll to proper place during edit mode
    // get drop list data
    const cellData = rowData.legalRoles?.find(topicRole => topicRole.name === props.headername);
    const clickedPerson = people.find(p => p.id === pId);
    const sendBackData = {rowData, cellData, clickedPerson};
    setEditCell(Object.assign({}, sendBackData));  
    const columnNumber = findIndex(rowData.legalRoles, (pr => pr.name === props.headername)) +1;
    setOriginalScrollPos({x:scrollPos.x, y:scrollPos.y}); 
    const editScrollTo = (scrollPos.x + ((labelWidth - originalColWidth) * columnNumber)); 
    calculateRowHeight(rowData);  
    setTimeout(()=> {
      table.current.scrollTop(scrollPos.y);
      columnNumber === 1 ? table.current.scrollLeft(0) : table.current.scrollLeft(editScrollTo);
    }, 100)
  };

  const calculateRowHeight = rowData => {
    const row = {
      defaultRowHeight,
      overrideHeight: null
    };
    if(rowData?.legalRoles){
      for(let role of rowData.legalRoles){
        if(role.personList?.length > 1) {
          row.overrideHeight = row.overrideHeight > row.defaultRowHeight * role.personList?.length ?
          row.overrideHeight :
          row.defaultRowHeight * role.personList?.length;
        }
      }
      return row.overrideHeight ? row.overrideHeight : row.defaultRowHeight;
    } else {
      return row.defaultRowHeight;
    }
  };

  const getLongestText = (list,key) => list.reduce((a,b) => b[key].length > a[key].length ? b : a);

  const pushUnassignedToPersonList = (e,{...rowData},{...props}, cb) => {
    async function pushUnassignedToPersonListAsync(){
      const role = roles.find(pr => pr.name === props.headername);
      const topicIndex = findIndex(topics, p => p.id === rowData.id);
      const legalRoleListIndex = findIndex(rowData.legalRoles, pr => pr.id === role.id);
      const tempPersonList = topics[topicIndex].legalRoles[legalRoleListIndex].personList;
      tempPersonList.push({...unAssignedPlaceholder});
      setTopics([...topics]);
      cb(e,"007",rowData,props);
    };
    pushUnassignedToPersonListAsync().catch(e => e);
  }

  const TopicRolesCell = ({ rowData, dataKey, ...props }) => {
    const Plus = ({rowData, ...props}) => {
      let hidePlus = false;
      const legalIndex = findIndex(topics, p => p.id === rowData.id);
      const legalRoleListIndex = findIndex(rowData.legalRoles, pr => pr.name === props.headername);
      if(legalRoleListIndex > -1){
        const tempPersonList = topics[legalIndex].legalRoles[legalRoleListIndex].personList;
        hidePlus = tempPersonList.find(pl => pl.title === 'Unassigned')?.id;
      }
      if(!editing.rowData && !hidePlus && isPageAdmin) {
        return (
          <div className="ml-2" onClick={(e) => pushUnassignedToPersonList(e,rowData, props, handleCellEdit)}>
            <div className="table-cell-add-button is-clickable cell-item is-flex is-justify-content-center is-align-items-center">
              <i className="is-size-7 fa-solid fa-plus " />
            </div>
          </div>
        )
      }
    };
    
    const EditCellComponent = ({...props}) => {
      const [imSure, setImSure] = useState(false);
      const [saveDisable, setSaveDisable] = useState(true);
      const [selectedPersonId, setSelectedPersonId] = useState("");
      
      const updateSelection = id => {
        if(id){
          setSaveDisable(false);
          setSelectedPersonId(id);
          setImSure(false);
        }
      };

      const cancelEdit = () => {
        if(!editing.cellData.personList?.length){
          editing.cellData.personList.push(unAssignedPlaceholder);
        }
        setImSure(false);
        setEditCell(Object.assign({}));
        table.current.scrollTop(originalScrollPos.y);
        table.current.scrollLeft(originalScrollPos.x);
      };

      const ActionButtonsComponent = () => {
        const [saving, setSaving] = useState(false);
        const [hideDelete, setHideDelete] = useState(!editing.clickedPerson?.id || editing.clickedPerson?.id === "007");
        
        const save = () => {
          async function saveAsync(){
            if(!isPageAdmin) throw Object.assign(new Error("Operation not permitted."));
            if(!selectedPersonId && !imSure) throw Object.assign(new Error("The system failed to record the selected person's ID. Verify you are selecting a different person than the original, then please try again."));
            setSaving(true);
            const originalPersonIdx = findIndex(editing.cellData.personList, p => p.id === editing.clickedPerson.id); // need to variablize this so we can account for multiple people
            const newPerson = imSure ? null : Object.assign({},people.find(person => person.id === selectedPersonId));
            const prtToUpdate = editing.rowData.legalRoles.find(topicRole => topicRole.id === editing.cellData.id);
            if(newPerson) {
              prtToUpdate.personList[originalPersonIdx] = newPerson;
            } else {
              prtToUpdate.personList.splice(originalPersonIdx, 1);
            }
        
            const body = {
              id: editing.rowData.id,
              legalRoles: await reduceTopicRoles(editing.rowData.legalRoles)
            };
            
            // You can see what we are sending by logging id: editing.rowData.id, raw updated data: editing.rowData and cleaned data: body
            try {
              await api.legal.updateLegalResponsibilities({id:editing.rowData.id, body});
            } catch (error) {
              // reverting selection back to original on error
              prtToUpdate.personList[originalPersonIdx] = editing.clickedPerson;
              throw new Error(error);
            } finally {
              if(imSure && !prtToUpdate.personList.length) {
                prtToUpdate.personList.push(unAssignedPlaceholder);
              }
              setSaving(false);
              cancelEdit();
            }
          }
          saveAsync().catch(e => e);
        }
    
        const AreYouSureComponent = () => {
          if(!hideDelete){
            if(imSure){
              return <button className="button is-danger is-size-7 ml-2" onClick={() => {
                setHideDelete(true);
                save();
              }}>Are you sure?</button>
            } else {
              return <button className="button is-danger is-size-7 ml-2" onClick={() => setImSure(true)}>Delete</button>
            }
          }
        };
    
        const SaveBtnComponent = () => {
          if(!saving) {
            return <button className="button is-info is-size-7 ml-2" onClick={save} disabled={saveDisable}>Save</button>
          } else {
            return <button className="button is-info-light is-size-7 ml-2"><Loader></Loader> Saving</button>
          }
        };
    
        return (
          <>
            <SaveBtnComponent />
            <AreYouSureComponent />
          </>
        )
      };

      const p = props.data;
      
      return (
        <div key={p.id}>
          <SelectPicker 
            label="People" 
            defaultValue={p.id}
            data={people} 
            labelKey="name" 
            valueKey="id" 
            style={{ width: selectPickerWidth }} 
            onChange={updateSelection}
          />
          <div className="is-flex is-justify-content-flex-start is-align-items-center py-2" style={{width: selectPickerWidth}}>
            <button className="button is-size-7" onClick={() => {
              if(editing.clickedPerson?.id){
                const topicIndex = findIndex(topics, topic => topic.id === rowData.id);
                const topicRoleListIndex = findIndex(rowData.legalRoles, pr => pr.name === props.headername);
                if(topicRoleListIndex > -1){
                  const tempPersonList = topics[topicIndex].legalRoles[topicRoleListIndex].personList;
                  const unassignedIndex = findIndex(tempPersonList, pl => pl.title === 'Unassigned');
                  if(unassignedIndex > -1){
                    tempPersonList.splice(unassignedIndex, 1);
                  }
                }
              }
              cancelEdit()
            }}>cancel</button>
            <ActionButtonsComponent />
          </div>              
        </div>
      )
    };

    return (
      <Cell {...props} key={rowData.id}>
        {
          rowData && rowData.legalRoles.map(topicRole => {
            const personAssignedToRole = topicRole?.name === props.headername ? topicRole.personList || [] : [];

            return (
              personAssignedToRole && personAssignedToRole.map(p => {
                if(props.headername === editing.cellData?.name && rowData.id === editing.rowData?.id && p.id === editing.clickedPerson?.id){ 
                  return (
                    <EditCellComponent {...props} data={p} key={p.id} />
                  )
                } else {
                  return (
                    <div key={`person-${p.id}`} className="p-2 mb-2 cell-item is-clickable legal-dash" title="Click to modify assignment" onClick={(e) => handleCellEdit(e,p.id,{...rowData},{...props})}>
                      <a href={`mailto:${p?.email}`}>{p?.name}</a>
                    </div>
                  )
                }
              })
            )
          })
        }
        <Plus {...props} rowData={rowData}/>
      </Cell>
    )
  };
  
  const tableFilter = a => {
    async function tableFilterAsync(){
      setLoading(true);
      const filteredTableData = [];
      // Do async magic
      for(let p of store.legalResponsibilities){
        for(let r of p.legalRoles){
          for(let person of r.personList){
            if(person.id === a){
              filteredTableData.push(p);
            }
          }
        }
      }
      setTopics([...filteredTableData]);
      setLoading(false);
    };
    tableFilterAsync().catch(e => e);
  }

  const resetTable = () => setTopics([...store.legalResponsibilities]);

  return (
    <>
    <Header></Header>
    <div className="is-flex" style={{ height: "100%"}}>
      <SideMenu defaultOpenMenus={["4"]}></SideMenu>
      
      <section className="is-flex-grow-1 is-flex is-flex-direction-column">
        <h4 className="px-3 pt-2 title-color"><i className="fa-solid mr-2 fa-gavel" />Legal Responsibilities</h4>
        <div className="m-3 full-table-height">
          <SelectPicker
            label="People"
            data={people} 
            labelKey="name" 
            valueKey="id" 
            onSelect={tableFilter}
            onClean={resetTable}
            className="mb-3 select-picker"
          />
          <div style={{height: "100%"}}>
          <Table
            minHeight={480}
            fillHeight={true}
            rowHeight={calculateRowHeight}
            data={topics}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={loading}
            ref={table}
            onScroll={(x,y) => setScrollPos({x,y})}
            
          >
            <Column width={300} align="center" fixed sortable verticalAlign={'middle'} >
              <HeaderCell>Topic</HeaderCell>
              <Cell dataKey="name" />
            </Column>

            {
                roles && roles.map(role => (
                  <Column width={300} key={role.id} verticalAlign={'middle'} sortable>
                    <HeaderCell>{ words(role.name).join(' ') }</HeaderCell>
                    <TopicRolesCell headername={role.name} dataKey={`${role.name}RoleColumn`} />
                  </Column>
                ))
              }
          </Table>
          </div>
        </div>
      </section>
    </div>
    </>
  );
}
