import { Radio, Table } from "rsuite";
import { v4 as uuidV4 } from "uuid";
import TextInput from "../../components/TextInput";
import { useEffect, useState } from "react";
import { handleSortColumn } from "../../services/microservices";
import { words } from "lodash";
import CurrencyCell from "../../components/tableComponents/CurrencyCell";
import { env as amplifyEnv } from '../../redirect-mutator';
export const env = process.env.NODE_ENV;
const environment = amplifyEnv === env ? amplifyEnv : "unknown";

export const FileIngestion = ({ ...props }) => {
  const [disabled, setDisabled] = useState(props.submitTriggered);
  const [product, setSelectedProduct] = useState(props?.details?.current?.product);
  const [dollarInputDisplay, setDollarInputDisplay] = useState(props?.details?.current?.amount);
  const [fileNameDisplay, setFileNameDisplay] = useState(props?.details?.current?.source);

  const handleDollarAmount = (e) => {
    if (!e) return;
    if (props?.details?.current) {
      props.details.current.amount = e.target.value;
      setDollarInputDisplay(props.details.current.amount);
    }
  };

  const handleFileName = (e) => {
    if (!e) return;
    if (props?.details?.current) {
      props.details.current.source = e.target.value;
      setFileNameDisplay(props.details.current.source);
    }
  };

  const handleSelectedProduct = (e) => {
    if (props?.details?.current) {
      props.details.current.product = e;
      setSelectedProduct(props?.details?.current?.product);
    }
  };

  // == Table ==
  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState("date");
  const [sortType, setSortType] = useState("asc");
  const [tableData, setTableData] = useState(
    props.currentView === "Shopify" ? props.details.current.shopifyHistory : props.details.current.intuitHistory
  );
  const tableProps = {
    data: tableData,
    autoHeight: true,
    affixHeader: environment !== 'production' ? props.currentView === "Shopify" ? 402 : 366 : props.currentView === "Shopify" ? 366 : 330,
    className: "extend-last-row",
    style: { "--lastRowHeight": "100px" },
    sortColumn,
    sortType,
    onSortColumn: (sortColumn, sortType) =>
      handleSortColumn({
        sortColumn,
        sortType,
        data: tableData,
        setters: { setSortColumn, setSortType, setTableData, setTableLoading: props.setTableLoading },
      }),
    loading: props.tableLoading,
  };
  const columnProps = {
    key: uuidV4(),
    sortable: true,
  };

  const historyColumns = [];
  for (let key in tableData[0]) {
    if (props.currentView === "Shopify" && key !== "source" && key !== "partner") {
      historyColumns.push(words(key).join(" "));
    }
    if (props.currentView === "Intuit" && key !== "amount" && key !== "partner") {
      historyColumns.push(words(key).join(" "));
    }
  }

  useEffect(() => {
    setDisabled(props.submitTriggered);
    setDollarInputDisplay(props?.details?.current?.amount);
    setFileNameDisplay(props?.details?.current?.source);
    setSelectedProduct(props?.details?.current?.product);
    setTableData(props.currentView === "Shopify" ? props.details.current.shopifyHistory : props.details.current.intuitHistory);
  }, [props.details, props.submitTriggered, props.currentView]);

  return (
    <div className="custom-flex-container">
      <h3>{props.currentView === "Shopify" ? "Wire Receipt" : "ACH File Acknowledgment"}</h3>
      {props.currentView === "Shopify" && (
        <div className="is-flex is-align-items-center">
          <label>Products:</label>
          {props?.details?.current?.products?.map((item) => (
            <div key={uuidV4()}>
              <Radio value={item} inline checked={item === product} onChange={handleSelectedProduct} disabled={disabled}>
                {item}
              </Radio>
            </div>
          ))}
        </div>
      )}
      <div className="mb-6">
        {props.currentView === "Shopify" ? (
          <TextInput
            label="Dollar Amount"
            type="money"
            id="treasure-amount-id"
            allowZero
            value={dollarInputDisplay}
            onChange={handleDollarAmount}
            placeholder="0.00"
            style={{ maxWidth: "200px" }}
            disabled={disabled}
            autoComplete="off"
          />
        ) : (
          <div className="is-flex is-flex-direction-column">
            <TextInput
              label={
                <div className="is-flex">
                  <div>Source</div>
                  <div className="is-info help ml-2">Must be a valid existing ach file.</div>
                </div>
              }
              id="file-name"
              placeholder="Ex. webbank_ack...txt"
              value={fileNameDisplay}
              onChange={handleFileName}
              style={{ maxWidth: "600px" }}
              disabled={disabled}
              autoComplete="off"
            />
          </div>
        )}
      </div>
      <div className="custom-flex-container">
        <div className="is-size-6">History</div>
        <div id="shopify-history-table" className="custom-flex-container flex-scroll">
          <div>
            <div>
              <Table {...tableProps}>
                {historyColumns?.map((column) => {
                  if (column === "source" || column.includes("estination")) {
                    columnProps.flexGrow = 1;
                    delete columnProps.width;
                  } else {
                    columnProps.width = 200;
                    delete columnProps.flexGrow;
                  }
                  return (
                    <Column {...columnProps}>
                      <HeaderCell>
                        <div className="is-capitalized">{column}</div>
                      </HeaderCell>
                      {column === "amount" ? <CurrencyCell dataKey={column} /> : <Cell dataKey={words(column).join("")} />}
                    </Column>
                  );
                })}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const tf = {
  FileIngestion,
};
export default tf;
