import React,{useState,useEffect} from 'react';
import config from "../../config/appflags.json";

export default function PartnerImageCell({rowData, calculaterowheight, partners}) {
  const [partnerImage, setPartnerImage] = useState("");

  useEffect(() => {
    async function fetchData() {
      const matchedPartner = partners.find(partner => partner.id === rowData.id);
      const imagePath = `${config.cloudfrontHost}${matchedPartner?.imagePath}?${new Date().getTime()}`;
      setPartnerImage(imagePath);
    }
    fetchData().catch(e => e);
  }, [partners, rowData]);

  return (
    <div role="gridcell" headername="Partner" className="custom-cell rs-table-cell is-flex-justify-center-align-center" aria-colindex="1" style={{width: '150px', height: calculaterowheight(rowData), zIndex: '0'}}>
      {!partnerImage
        ?
        <span>{rowData?.name}</span>
        :
        <img className="is-size-6" src={partnerImage} alt={rowData?.name} title={rowData?.name} />
      }
    </div>
  )
};