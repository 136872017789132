import QuestionCell from "./QuestionCell";
import { orderBy } from "lodash";
import { useCallback, useState } from "react";

export default function SubsectionCell({ subsection, showQuestions, setShowQuestions, orderedQuestions, handleTreeExpand, filtered, ...props }) {

  const [showQuestionsLocal, setShowQuestionsLocal] = useState(false);
  const showCriteria = filtered ? true : ((showQuestionsLocal || showQuestions) === subsection?.id) // second condition controls if first row is expanded by default

  const showQuestionsLocalCallback = useCallback((val) => setShowQuestionsLocal(val), [setShowQuestionsLocal]);

  return (
    subsection &&
    <div>
      <div className="is-clickable branch-styling" onClick={() => handleTreeExpand({ id: subsection.id, identifier: showQuestions || showQuestionsLocal, action: setShowQuestions || showQuestionsLocalCallback })}>
        <i className={`fa-solid fa-caret-${showCriteria ? 'down' : 'right'} mr-2`}></i>
        <span className={(showCriteria ? "selected-branch has-text-weight-semibold" : "")}>{subsection.name}</span>
      </div>
      {
        (showCriteria) && orderedQuestions?.map(question => {
          return (
            question &&
            <QuestionCell
              key={question.id}
              question={question}
              orderedAnswers={orderBy(question.answers, 'sortOrder', 'asc')}
              handleTreeExpand={handleTreeExpand}
              filtered={filtered}
              {...props}
            />
          )
        })
      }
    </div>
  )
}