import { useNavigate } from 'react-router-dom';
import AreYouSureDelete from '../components/buttons/AreYouSureDelete';
import { v4 as uuidV4 } from 'uuid';

export default function ReportsCard({id, title, description, reportUrl, reportDetails, editAction, deleteAction, cb, tableauJwt, isReportsAdmin, handleDepartmentClick, handleShareReport, reportUrlCopied}) {
  const navigate = useNavigate();
  const data = {id, title, description, reportUrl, reportDetails};

  return (
    <div className="card m-5" style={{width: "500px", maxWidth: "500px"}} >
      <header className="card-header">
        <p className="card-header-title is-width-full">
          <span className='is-flex is-align-items-center is-justify-content-space-between is-flex-grow-1'>
            <span>{title}</span>
              <span className="is-flex is-flex-wrap-nowrap">
                {reportUrlCopied === id
                  ?
                  <button title="Copy Link" className="button is-success animate-shake mr-2 actions">
                    <i className="fa-solid fa-thumbs-up actions"></i>
                  </button>
                  :
                    <button title="Copy Link" className="button is-link mr-2 actions" onClick={() => handleShareReport({...data})}>
                      <i className="fa-solid fa-link actions"></i>
                    </button>
                }
                {
                  isReportsAdmin &&
                  <span className="is-flex is-flex-wrap-nowrap">
                    <button className="button is-info mr-2 actions" onClick={() => editAction({...data})}>
                      <i className="fa-solid fa-pen-to-square actions"></i>
                    </button>
                    <AreYouSureDelete 
                      icon={<i className="fa-solid fa-trash actions"></i>}
                      action={deleteAction}
                      data={{...data}}
                      cb={cb}
                    />
                  </span>
                }
              </span>
          </span>
        </p>
      </header>
      <div className="card-content is-clickable" onClick={(e) => {
        if(e.target.tagName === 'A') return e.stopPropagation();
        navigate('/reports/report-view', { state:{title, reportUrl, tableauJwt}});
      }}>
        <div className="content">
          {description}
        </div>
        <div className="content">
          <ul>
            {
              reportDetails.map(li => {
                if(li.detailValue?.includes('http')){
                  return (
                    <li key={li.id || uuidV4()}>{`${li.detailKey}: `}<a href={li.detailValue} target="_blank" rel="noreferrer">{li.detailValue}</a></li>
                  )
                } else if (li.detailKey?.includes('Department')) {
                  return (
                    <li key={li.id || uuidV4()}>{`${li.detailKey}: `}
                      <button
                        className="is-text dept-button"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent click from propagating to the div
                          handleDepartmentClick(li.detailValue);
                        }}
                      >
                        {li.detailValue}
                      </button>
                    </li>
                  )
                } else {
                  return (
                    <li key={li.id || uuidV4()}>{`${li.detailKey}: ${li.detailValue}`}</li>
                  )
                }
              })
            }
          </ul>
        </div>
      </div>
    </div>
  )
}