import { signIn } from '../store';

export default function loginPage() {
  return (
    <>
      <section id="okta-login-container" onClick={() => void signIn()}>
        <div className="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center">
          <img src="./WebBank-logo.png" alt="web bank logo" aria-label="web bank logo"/>
          <div id="okta-button">
            <span id="okta-o"></span>
            <span>Sign in with Okta</span>
          </div>
        </div>
      </section>
    </>
  )
}
