import SubsectionCell from "./SubsectionCell";
import { orderBy } from "lodash";

export default function SectionCell({ orderedSubsections, section, className, ...props }) {
  return (
    <div className={className}>
      <h5 style={{ color: "#3d84a5" }}>{section.name}</h5>
      <div className="is-flex is-flex-direction-column pl-3">
        {
          orderedSubsections?.map(subsection => {
            return (
              subsection &&
              <SubsectionCell
                key={subsection?.id}
                subsection={subsection}
                orderedQuestions={orderBy(subsection.questions, 'sortOrder', 'asc')}
                {...props}
              />
            )
          })
        }
      </div>
    </div>
  )
}