import { isEqual, differenceWith, isEmpty, capitalize, words, orderBy } from "lodash";
import html2canvas from "html2canvas";

const uniqCollection = async (collection1, collection2) => {
  // does the same thing as getUniqueEntry just written out instead of lodash.
  const c1 = collection1?.length > collection2?.length ? structuredClone([...collection2]) : structuredClone([...collection1]);
  const c2 = collection1?.length < collection2?.length ? structuredClone([...collection2]) : structuredClone([...collection1]);
  for (let item of c1) {
    const matched = c2.find((entry) => isEqual(entry, item));
    const idx = c2.indexOf(matched);
    c2.splice(idx, 1);
  }
  return c2;
};

const getUniqueEntry = (collection1, collection2) => {
  // the order of collection 1 and collection 2 matters. c1 and c2 vars should do the same thing regardless of order.
  const c1 = collection1?.length < collection2?.length ? structuredClone([...collection2]) : structuredClone([...collection1]);
  const c2 = collection1?.length > collection2?.length ? structuredClone([...collection2]) : structuredClone([...collection1]);
  return differenceWith(c1, c2, isEqual);
};

const getNextValue = ({ list, key }) => {
  if (!list || isEmpty(list)) return 0;
  const currentHighestValue = list?.reduce((a, b) => (a[key] > b[key] ? a : b));
  const nextValue = typeof currentHighestValue === "number" ? Number(currentHighestValue) + 1 : Number(currentHighestValue[key]) + 1;
  return nextValue || 0;
};

const captureImage = (downloadFileName) => {
  // hide all the elements we don't want in the export
  document.querySelectorAll(".hide-on-export").forEach((el) => el.classList.add("is-hidden"));

  const elementToCapture = document.getElementById("html2canvas");

  // remove classes on container so we capture all the data
  const elementToRemoveClasses = document.getElementById("remove-classes");

  // remove height and flex from parent-flex then get dimensions
  elementToCapture.className = "parent-flex-print";
  const elementWidth = elementToCapture.offsetWidth;
  const elementHeight = elementToCapture.offsetHeight;

  elementToCapture.scrollTo(0, 0);

  html2canvas(elementToCapture, {
    width: elementWidth,
    height: elementHeight,
  }).then(function (canvas) {
    const imgData = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = imgData;
    link.download = downloadFileName ? `${downloadFileName}.png`.replaceAll(" ", "") : "image.png";
    link.click();
    elementToRemoveClasses.className = "contain-and-scroll py-3 is-relative";
    elementToCapture.className = "parent-flex";
  });

  // restore hidden elements
  document.querySelectorAll(".hide-on-export").forEach((el) => el.classList.remove("is-hidden"));
};

// move the response for Text Answer to the response column from the comment column
const filterTextAnswer = (data) => {
  // Check if data is defined and not null
  if (!data) {
    return data; // Return original data if it's not valid
  }
  const updatedJsonData = data.map((section) => {
    // Check if 'questions' array exists
    if (section.questions && Array.isArray(section.questions)) {
      // Sort the questions array by sortOrder
      section.questions.sort((a, b) => a.sortOrder - b.sortOrder);
      section.questions.forEach((question) => {
        // Check if 'responses' array exists
        if (question.responses && Array.isArray(question.responses)) {
          question.responses.forEach((response) => {
            // Check if 'value' property exists in response
            if (response.value === "Text Answer") {
              response.value = response.comments;
              response.comments = null; // Clear the comments field
            }
          });
        }
      });
    }
    return section;
  });

  return updatedJsonData; // Return the modified data
};

export const capitalizeEach = (str) => {
  return typeof str === "string"
    ? words(str)
        ?.map((word) => capitalize(word))
        .join(" ")
    : str;
};

export const handleSortColumn = async ({ sortColumn, sortType, data, setters }) => {
  setters.setTableLoading(true);
  async function sortData({ sortColumn, sortType, data, setData }) {
    const ordered = orderBy(data, [(item) => (typeof item[sortColumn] === "string" ? item[sortColumn].toLowerCase() : data[item])], [sortType]);
    setData(ordered);
  }
  await sortData({ sortColumn, sortType, data, setData: setters.setTableData });
  setTimeout(() => {
    setters.setTableLoading(false);
    setters.setSortColumn(sortColumn);
    setters.setSortType(sortType);
  }, 500);
};

export const mockApiTimeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const stringToNumber = (currencyString) => {
  if (typeof currencyString !== "string") return currencyString;
  // Remove dollar signs and commas
  const cleanedString = currencyString?.replace(/\$|,/g, "");
  // Parse the cleaned string to a number
  const number = parseFloat(cleanedString);
  const currency = parseFloat(number.toFixed(2));
  return currency;
};

export const usd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const microservices = {
  getUniqueEntry,
  uniqCollection,
  getNextValue,
  captureImage,
  filterTextAnswer,
  capitalizeEach,
  stringToNumber,
  usd,
  mockApiTimeout,
};
export default microservices;
