import { Table } from 'rsuite';
import { useEffect, useState } from 'react';

const PartnerFactSheetAnswers = ({ subsections, partnerDetails, partnerStatus }) => {
  // state
  const [loaded, setLoaded] = useState(false);

  // refs and variables
  const { Column, HeaderCell, Cell } = Table;

  // functions and callbacks
  const getData = ({ data }) => {
    // restructure data so each answer is it's own contained dataset (needed to remove arrays for table display purposes)
    const newDataSet = [];
    for (let subsection of data) {
      if (subsection?.questions?.length) {
        subsection?.questions?.forEach(question => {
          if (question?.responses?.length) {
            question?.responses?.forEach(response => {
              const tempData = { ...response };
              tempData.topic = question?.topic;
              if(question?.description){
                tempData.questionText = `<p>${question?.text}<br /><span class="answer-text">${question?.description}</span></p>`;
              } else {
                tempData.questionText = question?.text;
              }
              tempData.subsectionName = subsection?.name;
              newDataSet.push(tempData);
            });
          }
        });
      }
    }

    // need to find number of questions per section and number of responses per section
    for (let subsection of data) {
      const ndidx = newDataSet?.indexOf(newDataSet?.find(entry => entry?.subsectionName === subsection?.name));
      if(newDataSet[ndidx]){
        newDataSet[ndidx].subsectionRowSpan = newDataSet?.filter(entry => entry?.subsectionName === subsection?.name)?.length;
      }
      if (subsection?.questions?.length) {
        for (let question of subsection.questions) {
          const ndqidx = newDataSet?.filter(entry => entry?.questionText === question?.text)[0];
          const idxof = newDataSet?.indexOf(ndqidx);
          if(newDataSet[idxof]){
            newDataSet[idxof].questionRowSpan = question?.responses?.length;
          }
        }
      }
    }
    return newDataSet;
  };
  
  const addManagerAndProducts = (data, additionalData) => {
    // Check if data is defined and not null
    if (!data) {
      return data; // Return original data if it's not valid
    }
    // Create a new question for "Manager" and add "managerName" as its response
    const managerQuestion = {
      text: "Manager",
      sortOrder: 0,
      responses: [
        {
          value: additionalData[0]?.managerName || '', // Set the managerName as the value
        },
      ],
    };
  
    // Create a new question for "Products" and add "products" as its response
    const productsQuestion = {
      text: "Products",
      sortOrder: 0,
      responses: [
        {
          value: additionalData[0]?.products?.map(product => product.name).join('<br>') || '', // Create a list of product names with HTML line breaks
        },
      ],
    };

    // Create a new question for "Partner Status"
    const statusQuestion = {
      text: "Partner Status",
      sortOrder: 0,
      responses: [
        {
          value: partnerStatus || '', // Set the status as the value
        },
      ],
    };
  
    // Add the new questions to the data
    data.forEach(section => {
      // Check if 'questions' array exists
      if (section.questions && Array.isArray(section.questions)) {
        // Sort the questions based on sortOrder
        section.questions.sort((a, b) => a.sortOrder - b.sortOrder);
        // Check if "Products" question already exists
        const productsIndex = section.questions.findIndex(q => q.text === "Products");
        if (productsIndex === -1) {
          // "Products" question doesn't exist, add it
          section.questions.unshift(productsQuestion);
        }
        // Check if "Manager" question already exists
        const managerIndex = section.questions.findIndex(q => q.text === "Manager");
        if (managerIndex === -1) {
          // "Manager" question doesn't exist, add it
          section.questions.unshift(managerQuestion);
        }
        // Check if "Partner Status" question already exists
        const statusIndex = section.questions.findIndex(q => q.text === "Partner Status");
        if (statusIndex === -1) {
          // "Partner Status" question doesn't exist, add it
          section.questions.unshift(statusQuestion);
        }
      }
    });
    return data
  };

  useEffect(() => {
    if (!loaded) {
      addManagerAndProducts(subsections, partnerDetails);
      setLoaded(true);
    }
    // eslint-disable-next-line
  }, [subsections, partnerDetails, loaded]);

  const modifiedData = getData({ data: subsections }).map(row => {
    if (row.value === 'Text Answer') {
      row.value = row.comments; // Replace "Text Answer" with the value from "comments"
      row.comments = ''; // Set "comments" to empty
    }
    return row;
  });

  const CustomWrapCell = ({ rowData, field, ...props }) => {
    return (
      <Cell {...props} key={rowData.id}>
				{typeof rowData[field] !== 'object'
					?
						<div className={"cell-word-wrap p-2"} dangerouslySetInnerHTML={{ __html: rowData[field] }}></div>
					:
						<div className={"cell-word-wrap p-2"}>{rowData[field]}</div>
        }
      </Cell>
    )
  };

  return (
    loaded && <div id="contract-abstract-answer-table" className='default-table'>
      <Table id="partner-fact-sheet-answer-view" wordWrap="break-word" bordered cellBordered data={modifiedData } showHeader={false} autoHeight={true}>
        <Column
          flexGrow={1}
          rowSpan={rowData => {
            return rowData.questionRowSpan;
          }}
        >
          <HeaderCell>Questions</HeaderCell>
          <CustomWrapCell field="questionText" className="questions-cell"/>
        </Column>
        <Column
          flexGrow={1}
        >
          <HeaderCell>Responses</HeaderCell>
          <CustomWrapCell field="value" />
        </Column>
        <Column
          flexGrow={1}
          rowSpan={rowData => {
            return rowData.questionRowSpan;
          }}
        >
          <HeaderCell>Additional Comments</HeaderCell>
          <CustomWrapCell field="comments" />
        </Column>
      </Table>
    </div>
  )
};
export default PartnerFactSheetAnswers