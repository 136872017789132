import { Cell } from "rsuite-table";

const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
  <Cell {...props} style={{ padding: 5 }}>
    <div appearance="subtle" onClick={() => onChange(rowData)}>
      {expandedRowKeys.some(key => key === rowData[dataKey]) ? <i className="fa-solid fa-minus"></i> : <i className="fa-solid fa-plus"></i>}
    </div>
  </Cell>
);

export default ExpandCell;