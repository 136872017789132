import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { SelectPicker, Loader } from "rsuite";
import api from "../../api";
import { orderBy, isEmpty } from "lodash";
import QuestionType from "../../components/products/QuestionType";
import Search from "../../components/Search";
import { v4 as uuidV4 } from "uuid";
import store, { getUserCognitoGroups } from "../../store";
import { useNavigate } from "react-router-dom";
import InlineSaveIndicator from "../../components/InlineSaveIndicator";
import CommentBox from "../../components/CommentBox";
import Modal from "../../components/modal";
import DuplicateResponseModal from "./DuplicateResponseModal";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import ReferenceLibraryAnswers from "./ReferenceLibraryAnswerView";
import ImageUpload from "../../components/ImageUpload";
import ApiDeleteButton from "../../components/buttons/ApiDeleteButton";
import ReferenceLibraryAnswerHeader from "./ReferenceLibraryAnswerHeader";
import PdfButton from "../../components/Pdf";
import { ImagesContext } from "../../components/ImagesContext";

export default function PartnerReference({ partner }) {
  // state
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showSaving, setShowSaving] = useState(false);
  const [showSavingDone, setShowSavingDone] = useState(false);
  const [isPartnerReferenceAdmin, setIsPartnerReferenceAdmin] = useState(false);
  const [view, setView] = useState("answers");
  const [loading, setLoading] = useState(true);
  const [loadingProductResponses, setLoadingProductResponses] = useState(false);
  const [requireComment, setRequireComment] = useState(false);
  const [searching, setSearching] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dataChange, setDataChange] = useState(true);
  const [productCopyData, setProductCopyData] = useState({});
  const [copyingResponses, setCopyingResponses] = useState(false);
  const [doneCopying, setDoneCopying] = useState(false);
  const [partnerProducts, setPartnerProducts] = useState([]);

  // variables and refs
  const navigate = useNavigate();
  const sections = useRef({});
  const views = ["answers", "questions"];
  const responses = useRef([]);
  const originalProductSelected = useRef({});
  const { images } = useContext(ImagesContext);

  // Callbacks and functions
  const showSavingCB = useCallback((val) => setShowSaving(val), []);
  const showSavingDoneCB = useCallback((val) => setShowSavingDone(val), []);

  const handleProductSelect = (data) => {
    if (data === "newProduct") {
      return navigate("/products");
    }
    async function callReferenceResponseApi() {
      setLoadingProductResponses(!!data);
      if (!data) return productClean();
      const tempProduct = structuredClone({ ...partner?.products?.find((product) => product?.id === data) });
      const tempSections = structuredClone([...sections.content]);

      // Filter out sections where isFactSheet is 1 or isContract is 1
      const filteredSections = tempSections.filter((section) => section.isContract !== 1 && section.isFactSheet !== 1);

      tempProduct.sections = [...orderBy(filteredSections, "sortOrder", "asc")];
      const tempResponse = await api.referenceResponses.get({ productId: tempProduct?.id });
      const tempResponsesWithSort = [];
      // separate responses by questionId
      const tempResponsesByQuestionId = {};
      for (let response of tempResponse) {
        if (!tempResponsesByQuestionId[response?.questionId]) {
          tempResponsesByQuestionId[response?.questionId] = tempResponse?.filter((r) => r?.questionId === response?.questionId);
        }
      }
      // assign the responses to the question definitions
      for (let section of tempProduct.sections) {
        if (section?.subsections) {
          for (let subsection of section.subsections) {
            if (subsection?.questions) {
              for (let question of subsection.questions) {
                if (tempResponsesByQuestionId[question?.id]) {
                  question.responses = tempResponsesByQuestionId[question?.id];
                  if (question?.responses) {
                    for (let response of question.responses) {
                      response.additionalComments = question?.answers?.find((answer) => answer?.value === response?.value)?.additionalComments;
                      response.commentsRequired = question?.answers?.find((answer) => answer?.value === response?.value)?.commentsRequired;
                      response.sortOrder = question?.answers?.find((answer) => answer?.value === response?.value)?.sortOrder;
                      tempResponsesWithSort.push(response);
                    }
                    tempResponsesByQuestionId[question?.id] = question.responses;
                  }
                }
              }
            }
          }
        }
      }
      responses.current = [...orderBy(tempResponsesWithSort, "sortOrder", "asc")];
      setSelectedProduct({ ...tempProduct });
      originalProductSelected.current = structuredClone({ ...tempProduct });
      setSearching(false);
      setLoadingProductResponses(false);
    }
    callReferenceResponseApi().catch((e) => e);
  };

  const productClean = () => {
    setSelectedProduct({});
    originalProductSelected.current = {};
  };

  const save = async ({ data, question, setDisableItemList }) => {
    const responsesForThisQuestion = question?.responses?.find(
      (response) => response?.additionalComments || response?.comments || (response?.value && data?.selectedObject?.type === "date-picker")
    );
    if (responsesForThisQuestion?.comments === data?.comment && data?.method === "update") return;
    showSavingCB(data?.method !== "update" ? data?.selectedObject?.questionId : question?.id);
    const body = {
      questionId: data?.selectedObject?.questionId,
      productId: selectedProduct?.id,
      partnerId: partner?.id,
      value: data?.selectedObject?.value,
    };
    switch (data?.method) {
      case "create":
        body.comments = data?.selectedObject?.comments;
        const createResponse = await api.referenceResponses.create({ body });
        const createdResponseIdx = question.responses?.findIndex((response) => response?.id === createResponse?.id);
        if (createdResponseIdx === -1 || createdResponseIdx === undefined) {
          createResponse.additionalComments = data?.selectedObject?.additionalComments;
          createResponse.commentsRequired = data?.selectedObject?.commentsRequired;
          if (!question.responses) {
            question.responses = [];
          }
          question.responses?.push(createResponse);
        }
        break;
      case "update":
        body.id = responsesForThisQuestion?.id;
        body.value = responsesForThisQuestion?.value;
        body.questionId = question?.id;
        body.comments = data?.comment;
        const updateResponse = await api.referenceResponses.update({ id: body?.id, body });
        responsesForThisQuestion.comments = updateResponse?.comments;
        if (!!responsesForThisQuestion.comments) {
          requireCommentShow.current = false;
        }
        break;
      case "delete":
        const deleteResponse = question?.responses?.find((response) => response?.questionId === body.questionId && response?.value === body?.value);
        body.id = deleteResponse?.id;
        const deletedId = await api.referenceResponses.deleteData({ id: body?.id, productId: body.productId });
        const deletedResponseIdx = question?.responses?.findIndex((response) => response?.id === deletedId);
        if (deletedResponseIdx > -1) {
          question?.responses?.splice(deletedResponseIdx, 1);
        }
        break;
      default:
        break;
    }

    setTimeout(() => {
      if (setDisableItemList) {
        setDisableItemList([]);
      }
      showSavingDoneCB(true);
      setTimeout(() => {
        showSavingCB(false);
        showSavingDoneCB(false);
      }, 1000);
    }, 1000);
  };

  async function getUserPermissions() {
    const tempPartnerReferenceAdmin = await getUserCognitoGroups("partnerReferenceAdmin");
    setIsPartnerReferenceAdmin(tempPartnerReferenceAdmin);
    tempPartnerReferenceAdmin && (store?.partnerViewMemory?.partnerReferenceView === "questions" || !store?.partnerViewMemory?.partnerReferenceView)
      ? setView("questions")
      : setView("answers");
  }

  const partnerProductData = ({ products }) => {
    const mappedProducts = products?.map((product) => ({ label: product?.name, value: product?.id }));
    mappedProducts.unshift({ label: "Go to product setup", value: "newProduct" });
    return mappedProducts;
  };

  const getProductsByPartnerId = async ({ partnerId }) => {
    const response = await api.products.get({ endpoint: `products?partnerId=${partnerId}&includeSections=true` });
    if (!response) return;
    const updatedProductData = await partnerProductData({ products: response });
    setPartnerProducts(updatedProductData);
    return updatedProductData;
  };

  const setup = async () => {
    setLoading(true);
    await getUserPermissions();
    await getProductsByPartnerId({ partnerId: partner?.id });
    const tempSections = orderBy(await api.productSections.get({}), "sortOrder", "asc");
    sections.content = [...tempSections];
    setLoading(false);
  };

  const updateProduct = useCallback((val) => {
    setSelectedProduct(val);
  }, []);

  const search = ({ query, setQuery, queryKeys, originalDataset, setNoMatch }) => {
    const dataset = structuredClone({ ...originalDataset });
    if (!query.trim()) {
      setQuery("");
      return dataset;
    }
    function updateUniqueMatches({ condition, sourceData, filteredDataset }) {
      if (condition) {
        const exists = filteredDataset?.find((d) => d?.id === sourceData?.id);
        if (!exists || isEmpty(exists)) {
          filteredDataset.push(sourceData);
        }
      }
    }

    function loopDataset({ dataset, sourceData, queryKeys, filteredDataset }) {
      // loop dataset
      if (sourceData[dataset] && !isEmpty(sourceData[dataset])) {
        for (let data of sourceData[dataset]) {
          // run data checks
          const dataMatches = [];
          for (let key of queryKeys) {
            if (typeof data[key] === "string" && data[key]?.toLowerCase()?.includes(query)) {
              dataMatches.push(data);
            }
          }
          const condition = dataMatches && !isEmpty(dataMatches);
          updateUniqueMatches({ condition, sourceData, filteredDataset });
        }
      }
    }

    function setParentDataset({ parent, child, filteredParent, filteredDataset }) {
      if (filteredDataset && !isEmpty(filteredDataset)) {
        parent[child] = filteredDataset;
        filteredParent.push(parent);
      }
    }

    // loop sections
    if (dataset?.sections) {
      const filteredSections = [];
      for (let section of dataset.sections) {
        const filteredSubsections = [];
        // loop subsections
        if (section?.subsections) {
          for (let subsection of section.subsections) {
            const filteredDataset = [];
            // loop questions
            if (subsection?.questions && !isEmpty(subsection?.questions)) {
              for (let question of subsection.questions) {
                // run question checks
                for (let key of queryKeys) {
                  const condition = typeof question[key] === "string" && question[key]?.toLowerCase()?.includes(query);
                  updateUniqueMatches({ condition, sourceData: question, filteredDataset });
                }
                loopDataset({ dataset: "answers", sourceData: question, queryKeys, filteredDataset });
                loopDataset({ dataset: "responses", sourceData: question, queryKeys, filteredDataset });
              }
            }
            setParentDataset({ parent: subsection, child: "questions", filteredParent: filteredSubsections, filteredDataset });
          }
          setParentDataset({ parent: section, child: "subsections", filteredParent: filteredSections, filteredDataset: filteredSubsections });
        }
      }
      if (filteredSections && !isEmpty(filteredSections)) {
        dataset.sections = filteredSections;
        setNoMatch(false);
      } else {
        setNoMatch(`couldn't find a match.`);
      }
      return dataset;
    } else {
      return "bar nothing to loop";
    }
  };

  const clearOverride = () => {
    setNoMatch(false);
    handleProductSelect(selectedProduct?.id);
  };

  const handleSetView = (e) => {
    if (!store?.partnerViewMemory) {
      store.partnerViewMemory = { partnerReferenceView: e };
    } else {
      store.partnerViewMemory.partnerReferenceView = e;
    }
    setView(e);
  };

  useEffect(() => {
    setup().catch((e) => e);
    // eslint-disable-next-line
  }, []);

  // Child components

  const requireCommentShow = useRef(false);

  const cancelCopyResponses = () => {
    setDataChange(!dataChange);
    if (productCopyData?.source) {
      productCopyData.source = null;
    }
    if (productCopyData?.destination) {
      productCopyData.destination = null;
    }
    setShowModal(false);
  };

  const copyResponses = async () => {
    setCopyingResponses(true);
    const callList = productCopyData?.destination?.map((destination) =>
      api.products.clone({ body: { sourceProductId: productCopyData.source, destinationProductId: destination } })
    );
    await Promise.all(callList);
    setDoneCopying(true);
    setTimeout(() => {
      setCopyingResponses(false);
      setDoneCopying(false);
      cancelCopyResponses();
      handleProductSelect(selectedProduct?.id);
    }, 2000);
  };

  // Create a new MutationObserver instance
  const observer = new MutationObserver((mutationsList) => {
    const subElements = document.querySelectorAll("sub.has-text-danger");
    const articleElement = document.querySelector("#comments-required-banner");
    if (subElements.length > 0 && articleElement) {
      let shouldShow = false;
      subElements.forEach((subElement) => {
        if (subElement && subElement.textContent === "Comments are required") {
          const parentDiv = subElement.closest(".is-relative.p-3.inline-save-required");
          parentDiv.classList.add("is-flagged-required");
          shouldShow = true;
        } else {
          // If the subElement does not contain the text "Comments are required", remove the 'is-flagged-required' class from the parent div.
          const parentDiv = subElement.closest(".is-relative.p-3.inline-save-required");
          if (parentDiv && parentDiv.classList.contains("is-flagged-required")) {
            parentDiv.classList.remove("is-flagged-required");
          }
        }
      });
      // If the shouldShow flag is true, remove the 'is-hidden' class from the banner.
      if (shouldShow) {
        articleElement.classList.remove("is-hidden");
      } else {
        articleElement.classList.add("is-hidden");
      }
    } else if (articleElement) {
      // If the articleElement exists but there are no subElements with the class 'has-text-danger', add the 'is-hidden' class to the articleElement.
      articleElement.classList.add("is-hidden");
    }
  });

  // Start observing the document for changes
  observer.observe(document, {
    childList: true,
  });

  // Specify the target node and options for the observer
  const targetNode = document.body;
  const config = { childList: true, subtree: true };

  // Start observing the target node
  observer.observe(targetNode, config);

  // == Add image feature ==
  const [showBaModal, setShowBaModal] = useState(false);
  const [hideBaModal, setHideBaModal] = useState(false);
  const [showBaContainer, setShowBaContainer] = useState(false);
  const [imageFile, setImageFile] = useState({});
  const [imageSaveTriggered, setImageSaveTriggered] = useState(false);
  const [imageSaveDone, setImageSaveDone] = useState(false);
  const [updateResponse, setUpdateResponse] = useState(false);

  const responseImage = useRef({});
  const imageList = useRef([]);

  const showImageUploadInterface = ({ response }) => {
    if (!showBaContainer) {
      setImageFile(response);
      setShowBaContainer(true);
      setTimeout(() => {
        setShowBaModal(true);
      }, 100);
    } else {
      setHideBaModal(true);
      setTimeout(() => {
        setImageFile({});
        setShowBaContainer(false);
        setShowBaModal(false);
        setHideBaModal(false);
      }, 700);
    }
  };

  const imageSelectionCb = async ({ blob, isValid }) => {
    if (isValid) {
      const updatedResponse = { ...imageFile, image: { name: blob?.fileName, content: blob?.fileObject } };
      setImageFile(updatedResponse);
    }
  };

  const ResponseImageComponent = ({ response, ...props }) => {
    const [updateDisplay, setUpdateDisplay] = useState({});
    useEffect(() => {
      /* == Reason behind the weird refresh method ==
      In order to get the images to update correctly, I had to add them to an array REF (imageList) then,
      Do a comparison on what the response id was vs an existing entry in the array REF.
      After setting the new response data to the array REF, I then set the correct response to "updateDisplay" state.

      This is all due to the issue of updating the response doesn't have any effect on the response property as far as state goes.
      */
      if (responseImage.current?.id === response?.id) {
        const exists = imageList.current.find((resp) => resp?.id === response?.id);
        if (!exists) {
          imageList.current.push(responseImage.current);
        }
        responseImage.current = {};
      }
      if (response.image.name) {
        const exists = imageList.current.find((resp) => resp?.id === response?.id);
        if (!exists) {
          imageList.current.push(response);
        }
      }
      setUpdateDisplay(imageList.current.find((resp) => resp?.id === response?.id));
      // eslint-disable-next-line
    }, [props.updateResponse, response]);
    return (
      <div className="mb-5">
        {updateDisplay?.image?.content ? (
          <div className="is-flex is-flex-direction-column">
            <label className="label" style={{ fontWeight: "normal" }}>
              Attached Image for: {response?.value}
            </label>
            <div className="is-flex is-flex-direction-column polaroid-effect is-align-items-center" style={{ maxWidth: "200px" }}>
              <div className="p-1">
                <img src={updateDisplay?.image?.content} alt={updateDisplay?.image?.name} style={{ maxHeight: "75px" }} />
              </div>
              <ApiDeleteButton
                defaultText="Remove Image"
                processingText="Removing Image..."
                controlStyle={{ borderRadius: "0", width: "100%" }}
                action={() => {
                  const exists = imageList.current.findIndex((resp) => resp?.id === response?.id);
                  if (exists) {
                    imageList.current.splice(exists, 1);
                  }
                  updateDisplay.image = {};
                  updateDisplay.partnerId = partner?.id;
                  setUpdateDisplay({});
                  return api.referenceResponses.update({ id: updateDisplay?.id, body: updateDisplay });
                }}
                cb={() => props.setUpdateResponse(!props.updateResponse)}
              />
            </div>
          </div>
        ) : (
          <button className="button is-primary" onClick={() => props.show({ response })}>
            <i className="fa-solid fa-paperclip mr-2" />
            <span>Attach Image</span>
          </button>
        )}
      </div>
    );
  };

  const [pdfLoading, setPdfLoading] = useState(false);

  return (
    <>
      {pdfLoading && <div className="is-full loader-backdrop-override">
        <Loader center size="sm" backdrop content="Generating Pdf..." />
      </div>}
      {loading ? (
        <div className="is-full">
          <Loader center size="sm" backdrop content="Loading Reference Info..." />
        </div>
      ) : (
        !!partner?.products?.length && (
          <div className="parent-flex" id="html2canvas">
            {showBaContainer && (
              <div className="gateway-modal-container">
                <div className={`gateway-modal ${showBaModal ? "show" : ""} ${hideBaModal ? "hide" : ""}`}>
                  <div className={`p-3 gateway-container`}>
                    <Modal
                      title={`Image for: ${imageFile?.value}`}
                      hideBackground={true}
                      body={<ImageUpload uploadDescription={`${imageFile?.value} Image`} cb={imageSelectionCb} />}
                      showCloseX={true}
                      actions={[
                        {
                          buttonClass: "is-warning",
                          text: "Cancel",
                          iconClass: "fa-solid fa-ban",
                          action: showImageUploadInterface,
                        },
                        {
                          id: uuidV4(),
                          customButton: true,
                          body: (
                            <ApiSubmitButton
                              defaultLabel={`attach image`}
                              operationLabel="Upload In Progress"
                              icon={<i className="fa-solid fa-paperclip" />}
                              disabled={!imageFile?.image?.name}
                              action={async () => {
                                setImageSaveTriggered(true);
                                const apiResponse = await api.referenceResponses.update({ id: imageFile?.id, body: {...imageFile, partnerId: partner?.id}});
                                responseImage.current = apiResponse;
                                setImageSaveDone(true);
                                setTimeout(() => {
                                  setImageFile({});
                                  setImageSaveTriggered(false);
                                  setImageSaveDone(false);
                                  showImageUploadInterface({});
                                  setUpdateResponse(!updateResponse);
                                }, 1000);
                                return apiResponse;
                              }}
                              // used to force correct rendering when parent re-renders
                              actionTriggered={imageSaveTriggered}
                              actionDone={imageSaveDone}
                            />
                          ),
                        },
                      ]}
                      showModal={showBaModal && !hideBaModal}
                      handleClose={showImageUploadInterface}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="field is-capitalized is-flex is-align-items-center is-justify-content-space-between hide-on-export">
              {!isEmpty(partner) && (
                <SelectPicker
                  label="Product"
                  style={{ width: 224 }}
                  data={partnerProducts}
                  onChange={handleProductSelect}
                  renderMenuItem={(label, item) =>
                    item?.value === "newProduct" ? <div style={{ color: "#3e8ed0", textDecoration: "underline" }}>{label}</div> : <div>{label}</div>
                  }
                />
              )}
              {isPartnerReferenceAdmin && !loadingProductResponses && !isEmpty(selectedProduct) && (
                <span className="is-flex is-align-items-center">
                  <div className="is-flex is-flex-direction-column is-justify-content-flex-end mb-2" style={{ height: "45px" }}>
                    <div className="is-flex is-align-items-flex-end">
                      {views?.map((area) => (
                        <div
                          key={uuidV4()}
                          className={`tab ${view === area ? "has-background-link has-text-white " : ""}`}
                          onClick={() => handleSetView(area)}
                        >
                          {area}
                        </div>
                      ))}
                    </div>
                  </div>
                </span>
              )}
              <div className="is-flex">
                {view === "answers" && !loading && !isEmpty(selectedProduct) && !loadingProductResponses && (
                  <div className="mb-2 " style={{ marginRight: 10 }}>
                    <PdfButton reportName={selectedProduct.name} section="answers" id="html2canvas" sourceHeightId={`${selectedProduct?.id}-product-row`} paginate={true} setPdfLoading={setPdfLoading} />
                  </div>
                )}
                {isPartnerReferenceAdmin && !loadingProductResponses && !isEmpty(selectedProduct) && (
                  <button className="button is-info is-light" onClick={() => setShowModal(true)}>
                    <i className="fa-solid fa-copy mr-2" />
                    <span>Duplicate Responses</span>
                  </button>
                )}
              </div>
            </div>
            {selectedProduct?.id && !loadingProductResponses && (
              <div className="mb-2 hide-on-export">
                <Search
                  search={search}
                  dataset={selectedProduct}
                  originalDataset={originalProductSelected.current}
                  setNoMatch={setNoMatch}
                  setDataset={updateProduct}
                  placeholder="Search questions, answers, and comments"
                  queryKeys={["comments", "name", "text", "topic", "value"]}
                  clearOverride={clearOverride}
                  setLoading={setSearching}
                  setFiltered={setFiltered}
                />
                {!!noMatch && <div className="help is-danger">{noMatch}</div>}
              </div>
            )}
            {isPartnerReferenceAdmin && (
              <Modal
                title="Duplicate Responses"
                body={
                  <DuplicateResponseModal
                    products={partner?.products}
                    currentProduct={selectedProduct}
                    productCopyData={productCopyData}
                    setProductCopyData={setProductCopyData}
                    dataChange={dataChange}
                    disabled={copyingResponses}
                  />
                }
                showCloseX={true}
                closingXDisabled={copyingResponses}
                actions={[
                  {
                    buttonClass: "is-warning",
                    text: "Cancel",
                    iconClass: "fa-solid fa-ban",
                    action: cancelCopyResponses,
                    disabled: copyingResponses,
                  },
                  {
                    id: uuidV4(),
                    customButton: true,
                    body: (
                      <ApiSubmitButton
                        defaultLabel={`Copy Responses`}
                        operationLabel="Copy in Progress"
                        icon={<i className="fa-solid fa-clone" />}
                        action={copyResponses}
                        disabled={!productCopyData?.destination?.length || copyingResponses}
                        // used to force correct rendering when parent re-renders
                        actionTriggered={copyingResponses}
                        actionDone={doneCopying}
                      />
                    ),
                  },
                ]}
                showModal={showModal}
                handleClose={() => setShowModal(false)}
              />
            )}
            {view === "answers" && selectedProduct?.id && !loadingProductResponses && (
              <div id="fixed-header">
                <ReferenceLibraryAnswerHeader />
              </div>
            )}
            <article className="message is-danger is-hidden" id="comments-required-banner">
              <div className="message-header custom-center">
                <p>Please Provide Required Comments</p>
              </div>
            </article>
            <div className="contain-and-scroll py-3 is-relative" id="remove-classes">
              {loadingProductResponses || searching ? (
                <div className="is-full">
                  <Loader center size="sm" content="Loading Response Info..." />
                </div>
              ) : (
                !isEmpty(partner) && (
                  <div id={`${selectedProduct?.id}-product-row`}>
                    {selectedProduct?.sections?.map((section, idx) =>
                      // view can be 'answers' or 'questions'
                      view === "questions" ? (
                        <div key={section?.id} className="mb-4 card">
                          <h4 className="card-header pl-3" style={{ color: "#3d84a5" }}>
                            {section.name}
                          </h4>
                          {section?.subsections?.map((subsection) => (
                            <div key={subsection?.id} className="card-content">
                              <h5 className="mb-2 pb-1" style={{ color: "#7e7e8d", borderBottom: "1px solid #c9c9c9" }}>
                                {subsection?.name}
                              </h5>
                              <div className="contents">
                                <ol className="ml-4">
                                  {subsection?.questions?.map((question) => {
                                    return (
                                      <li key={question?.id}>
                                        <div className="is-flex is-align-items-center">
                                          {question.topic && <div className="mr-2">{question.topic}:</div>}
                                          <span className="has-text-weight-bold">
                                            {question.text}
                                          </span>
                                        </div>
                                        <span className="is-italic help has-text-weight-normal mb-3">{question.description}</span>
                                        <div className="is-flex is-align-items-flex-start">
                                          <QuestionType
                                            type={question?.answerType}
                                            answers={orderBy(question?.answers, "sortOrder", "asc")}
                                            save={save}
                                            question={question}
                                            className="mb-5"
                                            style={{ flex: "1" }}
                                          />
                                          <InlineSaveIndicator showId={question?.id} showSaving={showSaving} showSavingDone={showSavingDone} />
                                        </div>
                                        {question?.responses?.map((response) => {
                                          const allowImage = !isEmpty(
                                            question?.answers?.find((answer) => {
                                              const standardFields = answer.allowImage && answer.value === response.value;
                                              const textFields =
                                                question.answerType === "text" && answer.allowImage && response.value === "Text Answer";
                                              return standardFields || textFields;
                                            })
                                          );
                                          if (allowImage) {
                                            return (
                                              <ResponseImageComponent
                                                key={response?.id}
                                                response={response}
                                                show={showImageUploadInterface}
                                                updateResponse={updateResponse}
                                                setUpdateResponse={setUpdateResponse}
                                              />
                                            );
                                          }
                                          return false;
                                        })}
                                        {!!question?.responses?.find((response) => response?.additionalComments) && (
                                          <div className={"is-relative p-3 inline-save-required "}>
                                            <CommentBox
                                              question={question}
                                              requireCommentShow={requireCommentShow}
                                              setRequireComment={setRequireComment}
                                              requireComment={requireComment}
                                              save={save}
                                              showSaving={showSaving}
                                              maxlength={1024}
                                            />
                                          </div>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ol>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div id={`${selectedProduct?.id}-product-row`} key={section?.id}>
                          <ReferenceLibraryAnswers
                            section={section}
                            subsections={orderBy(section.subsections, "sortOrder", "asc")}
                            key={section.id}
                            filtered={filtered}
                          />
                        </div>
                      )
                    )}
                    <div style={{ height: "400px" }}></div>
                  </div>
                )
              )}
            </div>
          </div>
        )
      )}
      {!loading && !partner?.products?.length && (
        <div className="container">
          <p>
            {images.noProductsImage && (
              <img src={images.noProductsImage?.fileObject} alt="web bank logo" aria-label="web bank logo" style={{ height: "300px" }} />
            )}
          </p>
          <h2 className="has-text-centered">Content Creation in Progress</h2>
        </div>
      )}
    </>
  );
}
