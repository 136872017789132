import { Table } from "rsuite";
import { useEffect, useState } from "react";
import microservices from "../../services/microservices";
import { orderBy } from "lodash";

const ProductFactSheetAnswers = ({ subsections, ...props }) => {
  // state
  const [tableHeight, setTableHeight] = useState("auto");
  const [loaded, setLoaded] = useState(false);

  // refs and variables
  const { Column, HeaderCell, Cell } = Table;

  // functions and callbacks
  const getData = ({ data }) => {
    // restructure data so each answer is it's own contained dataset (needed to remove arrays for table display purposes)
    const newDataSet = [];
    for (let subsection of data) {
      if (subsection?.questions?.length) {
        subsection?.questions?.forEach((question) => {
          if (question?.responses?.length) {
            question?.responses?.forEach((response) => {
              const tempData = { ...response };
              tempData.topic = question?.topic;
              if(question?.description){
                tempData.questionText = `<p>${question?.text}<br /><span class="answer-text">${question?.description}</span></p>`;
              } else {
                tempData.questionText = question?.text;
              }
              tempData.subsectionName = subsection?.name;
              tempData.sortOrder = question?.sortOrder;
              newDataSet.push(tempData);
            });
          }
        });
      }
    }

    // need to find number of questions per section and number of responses per section
    for (let subsection of data) {
      const ndidx = newDataSet?.indexOf(newDataSet?.find((entry) => entry?.subsectionName === subsection?.name));
      if (newDataSet[ndidx]) {
        newDataSet[ndidx].subsectionRowSpan = newDataSet?.filter((entry) => entry?.subsectionName === subsection?.name)?.length;
      }
      if (subsection?.questions?.length) {
        const orderedQuestions = orderBy(subsection.questions, "sortOrder", "asc");
        subsection.questions = orderedQuestions;
        for (let question of subsection.questions) {
          const ndqidx = newDataSet?.filter((entry) => entry?.questionText === question?.text)[0];
          const idxof = newDataSet?.indexOf(ndqidx);
          if (newDataSet[idxof]) {
            newDataSet[idxof].questionRowSpan = question?.responses?.length;
          }
        }
      }
    }
    const ordered = orderBy(newDataSet, "sortOrder", "asc");
    return ordered;
  };

  useEffect(() => {
    if (document.querySelectorAll(".parent-flex")[0] && !loaded) {
      const parentHeight = document.querySelectorAll(".parent-flex")[0]?.clientHeight - 200;
      setTableHeight(parentHeight);
      setLoaded(true);
    }
  }, [subsections, loaded]);

  const CustomWrapCell = ({ rowData, field, ...props }) => {
    return (
      <Cell {...props} key={rowData.id}>
				{typeof rowData[field] !== 'object'
					?
						<div className={"cell-word-wrap p-2"} dangerouslySetInnerHTML={{ __html: rowData[field] }}></div>
					:
						<div className={"cell-word-wrap p-2"}>{rowData[field]}</div>
        }
      </Cell>
    );
  };

  return (
    loaded && (
      <div>
        {!!props?.selectedProduct?.status && <div>Product Status: {microservices.capitalizeEach(props?.selectedProduct?.status)}</div>}
        <div id="contract-abstract-answer-table" className="default-table">
          <Table
            wordWrap="break-word"
            bordered
            cellBordered
            height={tableHeight}
            data={getData({ data: microservices.filterTextAnswer(subsections) })}
          >
            <Column
              flexGrow={1}
              rowSpan={(rowData) => {
                return rowData.questionRowSpan;
              }}
            >
              <HeaderCell className="has-text-centered has-text-weight-bold">Questions</HeaderCell>
              <CustomWrapCell field="questionText" className="questions-cell"/>
            </Column>
            <Column flexGrow={1}>
              <HeaderCell className="has-text-centered has-text-weight-bold">Responses</HeaderCell>
              <CustomWrapCell field="value" />
            </Column>
            <Column
              flexGrow={1}
              rowSpan={(rowData) => {
                return rowData.questionRowSpan;
              }}
            >
              <HeaderCell className="has-text-centered has-text-weight-bold">Additional Comments</HeaderCell>
              <CustomWrapCell field="comments" />
            </Column>
          </Table>
        </div>
      </div>
    )
  );
};
export default ProductFactSheetAnswers;
