import Header from "../../components/header";
import SideMenu from "../../components/sidemenu";
import React,{useState,useEffect} from 'react'; // reactive state
import api from '../../api';
import { Table } from 'rsuite';
import { orderBy, words } from "lodash";
import { useNavigate } from "react-router";

const _orderBy = orderBy;

const unAssignedPlaceholder = {
  "id": "007",
  "name": "",
  "email": "",
  "title": "Unassigned",
  "department": null,
  "active": 1
};

const addPersonPlaceHolder = async prtList => {
  for(let p of prtList){
    for(let r of p.partnerRoles){
      if(!r.personList.length){
        r.personList.push(unAssignedPlaceholder);
      }
    }
  }
  return prtList
};

export default function PartnersMain() {
  const navigate = useNavigate();
  const [partners,setPartners]=useState([]);
  const [roles,setRoles]=useState([]);
  const [containerWidth,setContainerWidth]=useState('');
  const [containerHeight,setContainerHeight]=useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      fetchPeople().catch(e => e);
      const p = await fetchPartners();
      const rolesFromPRT = p[0]?.partnerRoles.map(pr => ({id:pr.id,name:pr.name}));
      let containerWidth = document.getElementById('table-container').clientWidth;
      let containerHeight = document.getElementById('table-container').clientHeight;
      setContainerWidth(containerWidth);
      setContainerHeight(containerHeight);
      setRoles(rolesFromPRT);
      setLoading(false);
    }
    fetchData().catch(e => e);
  }, []);

  const fetchPartners = async () => { // reactive state
    const response = await api.partner.getPartnerResponsibilities();
    const temp = await addPersonPlaceHolder(response);
    // alphabetize partnerList regardless of casing
    _orderBy(temp, 'name.toLowerCase()', 'asc');
    setPartners([...temp]); 
    return temp;
  };

  const fetchPeople = async () => {
    const response = await api.person.getPersons();
    const activeOnly = response.filter(r => !!r.active);
    const tempPeople = orderBy(activeOnly, 'name', 'asc');
    // add placeholder person to list so edit works.
    tempPeople.push(unAssignedPlaceholder);
  }

  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();

  const sortData = async (sc, st) => {
    const isMappedColumn = sc.includes('RoleColumn');
    if (sc && st) {
      if(isMappedColumn){
        const customSortColumn = sc.split('RoleColumn')[0];
        for(const partner of partners) {
          for(const role of partner.partnerRoles) {
            if(role.name.includes(customSortColumn)) {
              const personToSortBy = role.personList[0]?.name;
              partner[`sort${sc}`] = personToSortBy.toLowerCase();
            }
          }
        }
        const reordered = orderBy(partners, `sort${sc}`, st);
        setPartners([...reordered]);
      } else {
        for(const partner of partners) partner[`sort${sc}`] = partner[sc].toLowerCase();
        const reordered = orderBy(partners, `sort${sc}`, st);
        setPartners([...reordered]);
      }
    }
  };

  const handleSortColumn = async (sortColumn, sortType) => {
    setLoading(true);
    await sortData(sortColumn, sortType);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const PeopleCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props} key={rowData.id} rowData={rowData}>
        {
          rowData && rowData.partnerRoles.map(partnerRole => {
            const personAssignedToRole = partnerRole?.name === props.headername ? partnerRole.personList || [] : [];
            return (
              personAssignedToRole && personAssignedToRole.map(p => {
                return (
                  <div key={`person-${p.id}`}>
                    <span className="small-name">{p?.name}</span>
                  </div>
                )
              })
            )
          })
        }
      </Cell>
    )
  };

  const goToPRMax = () => {
    navigate('/partnerresponsibilities');
  }

  return (
    <>
    <Header></Header>
    <div className="is-flex" style={{ height: "100%"}}>
      <SideMenu defaultOpenMenus={["1"]}></SideMenu>
      <section className="is-flex-grow-1 is-flex is-flex-direction-column">
        <h4 className="px-3 pt-2 title-color"><i className="fa-solid mr-2 fa-user-tie" />Partner Responsibilities</h4>
        <div className="m-3 full-table-height">
          <div className="columns" style={{marginBottom: 5}}>
            <div className="column has-text-right">
              <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={goToPRMax}>
                <span>Maximize</span>
                <span className="icon is-small">
                  <i className="fas fa-maximize is-active" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </div>
          <div id="table-container" style={{height: "100%"}}>
          <Table
            data={partners}
            rowHeight={40}
            height={containerHeight}
            wordWrap="break-word"
            headerHeight={60}
            onSortColumn={handleSortColumn}
            loading={loading}
            sortColumn={sortColumn}
            sortType={sortType}
          >
            <Column vwidth={containerWidth / 14} verticalAlign={'middle'}  fixed sortable>
              <HeaderCell>Partner</HeaderCell>
              <Cell dataKey="name" style={{fontWeight: 'bold', fontSize: '.75em'}}/>
            </Column>
            {
              roles && roles.map(role => (
                <Column width={containerWidth / 14} key={role.id} verticalAlign={'middle'} sortable>
                  <HeaderCell>
                    { words(role.name).join(' ') }
                  </HeaderCell>
                  <PeopleCell headername={role.name} dataKey={`${role.name}RoleColumn`} />
                  
                </Column>
              ))
            }
            </Table>
          </div>
        </div>
      </section>
    </div>
    </>
  );
}
