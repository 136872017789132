import React, { useState, useEffect } from 'react';
import store from '../store';
import api from '../api';

export const ImagesContext = React.createContext({
  images: {},
});

const ImagesProvider = ({ children }) => {
  const [images, setImages] = useState({});

  useEffect(() => {
    async function getImages() {
      try {
        async function cacheImages(path) {
          let tempImage = store.s3ImageObjects.find((obj) => obj?.fileName === path);
          if (!tempImage) {
            tempImage = await api.s3Images.get({ id: path });
          }
          return tempImage;
        }
        const apiCallList = [];
        apiCallList.push(cacheImages("skynet-images/partner_portal.jpg"));
        apiCallList.push(cacheImages("skynet-images/faq_portal.jpg"));
        apiCallList.push(cacheImages("skynet-images/reports_portal.jpg"));
        apiCallList.push(cacheImages("skynet-images/legal_portal.jpg"));
        apiCallList.push(cacheImages("skynet-images/compliance_portal.jpg"));
        apiCallList.push(cacheImages("skynet-images/WebBankNoProducts.png"));
        const [partnerImageResult, faqImageResult, reportImageResult, legalImageResult, complianceImageResult, noProductsImageResult] = await Promise.all(apiCallList);
        setImages({
          partnerImage: partnerImageResult,
          faqImage: faqImageResult,
          reportImage: reportImageResult,
          legalImage: legalImageResult,
          complianceImage: complianceImageResult,
          noProductsImage: noProductsImageResult,
          mounted: true
        });
      } catch (e) {
        if(store.user){
          store.actions.handleError(e, store);
        }
        setImages({
          mounted: true
        })
      }
    }
    if(window?.location?.pathname !== "/login"){
      getImages().catch((e) => e);
    }
  }, []);

  return (
    <ImagesContext.Provider value={{ images }}>{children}</ImagesContext.Provider>
  );
};

export default ImagesProvider;
