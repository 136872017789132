import api from '../../../api';
import store from '../../../store';
import SharedComponent from './SharedProductComponent';
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { isEmpty, isEqual, capitalize, orderBy } from 'lodash';

export default function View({ handleEdit, dataUpdate, setDataUpdate, parentEndpoint, childEndpoint, parentName, childName, parents, setParents, isContracts, viewType, ...props }) {
  const [loadingData, setLoadingData] = useState(true);

  const [modalTitle, setModalTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [assignedChildren, setAssignedChilderen] = useState([]);
  const [deleteParentId, setDeleteParentId] = useState("");
  const [updateList, setUpdateList] = useState([]);
  const [updatesComplete, setUpdatesComplete] = useState(false);
  const [deleteComplete, setDeleteComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modalChangePending, setModalChangePending] = useState(false);
  const [selectedParent, setSelectedParent] = useState({});

  const reAssignCallback = async () => {
    closeFunctions();
    props.refreshSecondaryData({});
  };

  const modalSubmit = async ({ data, deleteParentId, childEndpoint, parentEndpoint }) => {
    const updateList = [];
    for (let item of data) {
      if (item?.child && item?.parent) {
        item.child[`${parentName}Id`] = item?.parent?.id;
      }
      const body = structuredClone({ ...item.child });
      updateList.push(api[childEndpoint].update({ id: body.id, body }));
    }
    await Promise.all(updateList);
    setUpdatesComplete(true);
    const deleteResponse = await api[parentEndpoint].deleteData({ id: deleteParentId })
    if (!deleteResponse) {
      closeFunctions();
      throw new Error(`no delete response from ${parentEndpoint} update`);
    };
  };

  const ModalBody = ({ assignedChildren, parentId, updateList, setUpdateList }) => {
    const assignedChildrenClone = structuredClone([...assignedChildren]);
    const availableParents = parents.filter(parent => parent.id !== parentId);

    const checkPendingChanges = async ({ originalData, newData }) => {
      setModalChangePending(originalData?.length === newData?.length);
    };

    const handleParent = ({ e, child, idx }) => {
      async function handleParentAsync() {
        const tempParent = parents.find(parent => parent?.id === e.target.value);
        if (isEmpty(tempParent)) {
          setSelectedParent({});
        };
        selectedParent[idx] = tempParent;
        setSelectedParent({ ...selectedParent });
        const existsInUpdateList = updateList?.find(detail => {
          const parentExists = isEqual(detail.parent === tempParent);
          const childExists = isEqual(detail.child === child);
          return parentExists && childExists
        });
        if (!existsInUpdateList) {
          if (updateList?.length && !tempParent) {
            updateList = [];
          } else {
            updateList.push({ child, parent: tempParent });
          }
          setUpdateList(updateList);
        }
        await checkPendingChanges({ originalData: assignedChildren, newData: updateList });
      }
      handleParentAsync().catch(e => e);
    };

    return (
      <div>
        <p>{`Please assign a different ${parentName} to each of these ${childName}s:`}</p>
        <label className="label my-3 is-capitalized" style={{ fontWeight: "normal" }}>{`${childName}s`}</label>
        <ul>
          {assignedChildrenClone?.map((child, idx) => (
            <div key={child?.id} className="field is-capitalized is-flex is-align-items-center my-3">
              <label className="mr-3">{child?.name || child?.text}</label>
              <div className="select mr-2">
                <select onChange={e => handleParent({ e, child, idx })} value={selectedParent[idx]?.id || ""} disabled={disabled}>
                  <option className='is-capitalized' defaultValue={true} value={`Choose New ${parentName}`}>{`Choose New ${parentName}`}</option>
                  {orderBy(availableParents, [data => data.name?.toLowerCase()], ['asc'])?.map(child => <option key={child?.id} value={child?.id}>{child?.name}</option>)}
                </select>
              </div>
            </div>
          ))}
        </ul>
      </div>
    )
  };

  const closeFunctions = () => {
    setModalChangePending(false);
    setSelectedParent({});
    setUpdateList([]);
    setProcessing(false);
    setUpdatesComplete(false);
    setDeleteComplete(false);
    setDisabled(false);
    setShowModal(false);
  };

  const modal = {
    title: modalTitle,
    showCloseX: true,
    showModal,
    handleClose() {
      closeFunctions();
    },
    body: (
      <ModalBody
        assignedChildren={assignedChildren}
        parentId={deleteParentId}
        updateList={updateList}
        setUpdateList={setUpdateList}
      />
    ),
    actions: [
      {
        id: uuidv4(),
        text: 'cancel',
        buttonClass: "is-capitalized is-warning",
        iconClass: "fa-solid fa-ban",
        disabled,
        action() {
          closeFunctions();
        }
      },
      {
        customButton: true,
        id: uuidv4(),
        body: (
          <button onClick={async () => {
            if (!disabled) {
              setDisabled(true);
              setProcessing(true);
              await modalSubmit({ data: updateList, deleteParentId, childEndpoint, parentEndpoint });
              setDeleteComplete(true);
              setTimeout(() => {
                reAssignCallback().catch(e => e);
              }, 1500);
            }
          }}
            className={`button is-capitalized ${!updatesComplete ? 'is-info' : 'is-danger'}`}
            disabled={!modalChangePending}
          >

            {!processing ?
              <p className="is-flex align-items-center" style={{ lineHeight: "1rem" }}>
                <i className='fa-solid fa-floppy-disk mr-2' />
                <span className='ays-deleting'>update</span>
              </p>
              :
              <div>
                {
                  !updatesComplete ?
                    <p className="is-flex align-items-center" style={{ lineHeight: "1rem" }}>
                      <span className="loader mr-2"></span>
                      <span className='ays-deleting'>Updating</span>
                    </p>
                    :

                    !deleteComplete ?
                      <p className="is-flex align-items-center" style={{ lineHeight: "1rem" }}>
                        <span className="loader mr-2"></span>
                        <span className='ays-deleting'>Deleting</span>
                      </p>
                      :
                      <p>
                        <span className="mr-2">
                          <i className="fa-solid fa-check mr-2"></i>
                        </span>
                        <span className='ays-done'>Done</span>
                      </p>
                }
              </div>}
          </button>
        )
      }
    ]
  };

  const deleteAction = async ({ id }) => {
    if (!id) throw new Error("no id from deleteAction call");
    // find all props.children that have this section id associated with it.
    const tempChildrenAssignedToParent = props.children.filter(child => child[`${parentName}Id`] === id);
    setAssignedChilderen([...tempChildrenAssignedToParent]);
    if (tempChildrenAssignedToParent?.length) {
      // trigger notification
      const selected = parents.find(parent => parent.id === id);
      setModalTitle(`${capitalize(parentName)}: "${selected?.name || selected?.text}" is assigned`);
      setDeleteParentId(id);
      setShowModal(true);
    } else {
      return api[parentEndpoint].deleteData({ id });
    }
  };

  const setGrandParentName = ({ incomingData }) => {
    if (store?.productSections) {
      for (let item of incomingData) {
        if (item?.sectionId) {
          item.sectionName = store?.productSections?.find(section => section.id === item.sectionId)?.name;
        }
      }
    }
    return incomingData;
  };

  const sharedComponentProps = {
    ...props,
    handleEdit,
    data: setGrandParentName({ incomingData: parents }),
    deleteAction,
    cb: reAssignCallback,
    loadingData,
    setLoadingData,
    tab: `${parentName}s`,
    showModal,
    modal,
    isContracts,
    viewType
  }
  return (
    <SharedComponent
      {...sharedComponentProps}
    />
  )
}