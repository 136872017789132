import { apiFetch } from ".";
import store from '../store';

// faqs

export const getFaqs = async (iStore = store) => {
  const response =  await apiFetch({ endpoint: 'frequently-asked-questions'});
  if(!response) return;
  iStore.faqs = [...response];
  return response;
};

export const createFaq = async ({body}) => {
  const response = await apiFetch({ endpoint: 'frequently-asked-questions', method: 'POST', body });
  return response;
};

export const deleteFaq = async (id) => {
  const url = `frequently-asked-questions/${id}`;
  const response = await apiFetch({ endpoint: url, method: 'DELETE', body: JSON.stringify({}) });
  return response;
};

export const updateFaq = async (id, {body}) => {
  if(!id) return Promise.reject('ID is required: Field: "id" is missing from this request.');
  const response = await apiFetch({ endpoint: `frequently-asked-questions/${id}`, method: 'PUT', body });
  return response;
};

// sections

export const getSections = async (iStore = store) => {

  const response =  await apiFetch({ endpoint: 'sections?includeFAQs=true'});
  if(!response) return;
  iStore.faqs = [...response];
  return response;
};

export const createSection = async ({body}) => {
  const response = await apiFetch({ endpoint: 'sections', method: 'POST', body });
  return response;
};

export const deleteSection = async (id) => {
  const url = `sections/${id}`;
  const response = await apiFetch({ endpoint: url, method: 'DELETE', body: JSON.stringify({}) });
  return response;
};

export const updateSection = async (id, {body}) => {
  if(!id) return Promise.reject('ID is required: Field: "id" is missing from this request.');
  const response = await apiFetch({ endpoint: `sections/${id}`, method: 'PUT', body });
  return response;
};

const endpoints = {
  getFaqs,
  createFaq,
  deleteFaq,
  updateFaq,
  getSections,
  createSection,
  deleteSection,
  updateSection
};

export default endpoints;