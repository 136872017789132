import api from "../../../api";
import { useState } from "react";
import SharedComponent from "./SharedProductComponent";

export default function QuestionsView({ handleEdit, isContracts, viewType, ...props }) {
  const [loadingData, setLoadingData] = useState(true);

  const deleteAction = async ({ id }) => {
    if (!id) throw new Error("no id from deleteAction call");
    const q = props.questions.find((question) => question.id === id);
    const answerDeleteCallList = [];
    if (q?.answers?.length) {
      for (let answer of q.answers) {
        answerDeleteCallList.push(api.productAnswers.deleteData({ id: answer.id }));
      }
    }
    // don't need to promise.all here because we aren't referencing the deleted answers once the question is deleted.
    // But we DO need to wait for the answers to finish deleting before deleting their parent question due to constraints in the api/db.
    await Promise.all(answerDeleteCallList);
    return api.productQuestions.deleteQuestion({ id });
  };

  const sharedComponentProps = {
    ...props,
    handleEdit: handleEdit,
    data: props.questions,
    deleteAction,
    cb: props.refreshSecondaryData,
    loadingData,
    setLoadingData,
    tab: "questions",
    areYouSure: true,
    isContracts,
    viewType,
  };

  return <SharedComponent {...sharedComponentProps} />;
}
