import Header from "../../components/header";
import SideMenu from "../../components/sidemenu";
import React, { useState, useEffect, useCallback } from 'react';
import api from '../../api';
import { Loader } from 'rsuite';
import { Link, useNavigate } from "react-router-dom";
import InputField from "../../components/TextInput";
import Modal from "../../components/modal";
import { v4 as uuidV4 } from 'uuid';

export default function Topic(){

  const navigate = useNavigate('');
  const [saving, setSaving] = useState(false);
  const [topics,setTopics]=useState([]); // reactive state
  const [showForm, setShowForm]=useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  }

  const handleNewTopic = () => {
    async function handleNewTopicAsync(){
      setSaving(true);
      // save new topic
      if(!params.id){
        const params = {
          name: topic
        };
        try {
          await api.legal.createNewTopic({params});
        } catch (error) {
          throw new Error(error);
        } finally {
          clearForm();
          setSaving(false);
          handleSaved().catch(e => e);
        }
      } else {  // update existing topic
        const body = {
          name: topic
        };
        try {
          await api.legal.updateTopic(params.id, {body});
        } catch (error) {
          throw new Error(error);
        } finally {
          clearForm();
          setSaving(false);
          handleSaved().catch(e => e);
        } 
      }
      navigate(0);
    }
    handleNewTopicAsync().catch(e => e);
  }  

  const clearForm = () => {
    setTopic('');
    window.history.replaceState(null, null, window.location.pathname);
    setShowForm(false);
  }

  const [isLoading, setIsLoading]=useState(false);

  const [saved, setSaved] = useState(false);
  const handleSaved = async () => {
    setSaved(true);
    setTimeout(resetSaved, 3000);
    window.history.pushState(null, null, window.location.pathname);
    setShowForm(false);

    setIsLoading(false);
  }

  const resetSaved = () => {
    setSaved(false);
  }

  const [topic, setTopic] = useState('');
  const handleTopic = (e) => {
    setTopic(e.target.value);
    checkIfFormIsValid();
  }

  const [formValid, setFormValid] = useState(false);
  const checkIfFormIsValid = useCallback(() => {
      if(topic){
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  },[topic])

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const [showModal, setShowModal] = useState(false);

  let confirmDeleteTopic = (id) => {
    setShowModal(true);
    setTopicToDelete(id);
  }

  const handleClose = useCallback(() => {setShowModal(false)}, []);

  const [topicToDelete, setTopicToDelete] = useState('');

  const deleteTopic = useCallback(async () => {
    const response = await api.legal.deleteTopic(topicToDelete);
    if(response){
      setShowModal(false);
      setTimeout(
        setShowModal(false), 
        navigate(0),
      3000);
    } else {
      alert('Error trying to delete');
      setShowModal(false);
    }
  }, [topicToDelete, navigate]);

  useEffect(() => {
    checkIfFormIsValid();
  }, [topic, checkIfFormIsValid]);

  useEffect(() => {
    const fetchTopics = async () => {
      setIsLoading(true);
      const response = await api.legal.getTopics();
      setTopics(response);
      if(params.id){
        let result = response.filter(val => val.id.includes(params.id));
        setTopic(result[0].name);
        setShowForm(true);    
      } 
      setIsLoading(false);
    };
    fetchTopics().catch(e => e);
  }, [params.id]);

  return (

    <>
    <Header></Header>
    <div className="is-flex" style={{ height: "100%"}}>
      <SideMenu 
        defaultOpenMenus={["4"]}
      ></SideMenu>
      {isLoading && 
        <div className="is-flex-grow-1 is-flex is-flex-direction-column">
          <div className="is-full"><Loader center size="lg"/></div>
        </div>
      }
      {!isLoading &&
        <div className="is-flex-grow-1 is-flex is-flex-direction-column is-width-full">
          <h4 className="px-3 pt-2 title-color"><i className="fa-solid mr-2 fa-gavel" />Legal Topic Dashboard</h4>
          <div className="scroll-container is-flex-grow-1 mx-5" style={{height: "0", overflow: "scroll", marginTop: 12}}>
            {showForm &&
              <div className="mb-6">
                <h2 className="m-3">{params.id ? 'Edit Topic' : 'New Topic'}</h2>
                <div className="container">
                  <div className="is-full topic-q-row">
                    <label className="label"><span className="icon"><i className="fa-solid fa-topic"></i></span>&nbsp;&nbsp;Topic:</label>
                    <InputField
                      type="text"
                      value={topic}
                      placeholder="Example Topic"
                      name="topic"
                      maxlength="250"
                      onChange={e => handleTopic(e)}
                    />
                  </div>
                  <hr />
                  <div className="buttons">
                    {formValid && !saving && !saved && <button className="button is-success" onClick={() => handleNewTopic()}><span className="icon"><i className="fa-solid fa-floppy-disk"></i></span>&nbsp;&nbsp;Save</button>}
                    {!formValid && !saved && <button className="button is-success is-light" disabled><span className="icon"><i className="fa-solid fa-floppy-disk"></i></span>&nbsp;&nbsp;Save</button>}
                    {formValid && saving && !saved && <button className="button is-success is-loading" disabled>Save</button>}
                    {saved && <button className="button is-success" ><i className="fa-solid fa-check" />&nbsp;Saved!</button>}
                    <button className="button is-warning" onClick={clearForm}><span className="icon"><i className="fa-solid fa-xmark"></i></span>&nbsp;&nbsp;Cancel</button>
                  </div>
                  {params.id &&
                    <p>Edit Topic ID: {params.id}</p>
                  }
                </div>
              </div>
            }
            <div className="container topic-sections-list mt-4">
              <div className="columns topic-sections-list-item-header">
                <div className="column is-10" style={{marginTop: -18}}><h2 className="m-3">Topics</h2></div>
                <div className="column is-2">
                  {!showForm &&
                    <button className="button is-light" onClick={() => handleShowForm()}><span className="icon"><i className="fa-solid fa-pencil"></i></span>&nbsp;&nbsp;&nbsp;New Topic</button>
                  }
                </div>
              </div>
              {topics.map((item, index) => 
                <div className="columns topic-sections-list-item" key={index}>
                  <div className="column is-10"><strong>{item.name}</strong></div>
                  <div className="column is-1 has-text-centered"><span className="is-clickable topic-confirm-delete"><Link title="Edit this Topic" to={`/legaldashboard?id=${item.id}`}>Edit</Link></span></div>
                  <div className="column is-1 has-text-centered"><span className="is-clickable topic-confirm-delete is-link" title="Delete this Topic" onClick={() => confirmDeleteTopic(item.id)}>Delete</span></div>
                </div>
              )}
            </div>
            <Modal
              showModal={showModal} 
              showCloseX={true} 
              title="Delete Topic" 
              body="Are you sure you want to delete this Topic? This action cannot be undone."
              actions={[
                  {   'id': uuidV4(),
                      'buttonClass': 'button is-danger',
                      'background': '',
                      'color': '',
                      'text': 'Yes. Delete It!',
                      'iconClass': 'fa-solid fa-trash',
                      'action': deleteTopic
                  },
                  {
                      'id': uuidV4(),
                      'buttonClass': 'button',
                      'background': '',
                      'color': '',
                      'text': 'Cancel',
                      'iconClass': 'fa-solid fa-times',
                      'action': handleClose
                  }
                  ]}
              handleClose={handleClose} 
            />
          </div>
        </div>
      }
    </div>
    </>
  )
}


