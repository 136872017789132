import { apiFetch } from "..";
import store from '../../store';

export const create = async ({ body, endpoint = 'reference-subsections', method = 'POST' }) => {
  console.log('create subsection', body);
  const response = await apiFetch({ body, method, endpoint });
  if (!response) return;
  return response;
};

export const getSubsection = async (id, endpoint = 'reference-subsections', iStore = store) => {
  if (!id) {
    const message = `Unable to get this subsection. subsection ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ id, endpoint: `${endpoint}/${id}` });
  if (!response) return;
  iStore.productSubsections = { ...response };
  return iStore.productSubsections;
};

export const getSubsections = async ({ endpoint = 'reference-subsections/?includeQuestions=true&includeAnswers=true', iStore = store }) => {
  let response = await apiFetch({ endpoint });
  if (!response) return;
  iStore.productSubsections = [...response];
  return iStore.productSubsections;
};

export const update = async ({ id, body, endpoint = `reference-subsections/${id}`, method = 'PUT', iStore = store }) => {
  console.log('updating subsection', { id, body });
  if (!id) {
    const message = `Unable to update this subsection. subsection ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ id, body, method, endpoint });
  if (!response) return;
  return response;
};

export const deleteSubsection = async ({ id, endpoint = `reference-subsections/${id}`, iStore = store, method = 'DELETE' }) => {
  if (!id) {
    const message = `Unable to delete this subsection. subsection ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ id, endpoint, body: {}, method });
  if (!response) return Promise.reject('error');
  return response;
};

const endpoints = {
  create,
  get: ({ id, endpoint }) => id ? getSubsection(id) : getSubsections({ endpoint }),
  update,
  deleteData: deleteSubsection
};

export default endpoints;