import React from "react";
import { v4 as uuidV4 } from 'uuid';

const Modal = ({ title, body, showCloseX, actions, showModal, handleClose, closingXDisabled, hideBackground }) => (
  <div className="control">
    <div className={'modal ' + (showModal ? 'is-active' : '')} >
      {!hideBackground && <div className="modal-background"></div>}
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title" style={{ flexShrink: "unset" }}>{title}</p>
          {showCloseX &&
            <button className="delete" aria-label="close" onClick={handleClose} disabled={closingXDisabled}></button>
          }
        </header>
        <section className="modal-card-body">
          <div>{body}</div>
        </section>
        <footer className="modal-card-foot">
          {actions?.map(item =>
            item?.customButton ?
              <span key={item?.id || uuidV4()}>{item?.body}</span>
              :
              <button key={item.id || uuidV4()} onClick={item.action} className={'button ' + item.buttonClass} style={{ 'backgroundColor': item.background, 'color': item.color }} disabled={item.disabled}>
                {item.iconClass &&
                  <span className="icon"><i className={item.iconClass}></i></span>
                }
                <span>{item.text}</span>
              </button>
          )}
        </footer>
      </div>
    </div>
  </div>
);

export default Modal;