import { v4 as uuidV4 } from "uuid";
let defaultData = [];

export default function SearchList ({...props}) {
  const id = props?.id || uuidV4();
  const style = props?.style || {height:"36px"};

  const keyPressDefault = (e) => {
    const filteredData = props?.data.filter(item => {
      //handles objects
      if(typeof item === 'object' && !Array.isArray(item)){
        for(const contents in item){
          const checkingData = item[contents];
          if(typeof checkingData === 'string'){
            const lowerData = checkingData.toLowerCase();
            if(lowerData.includes(e.target.value.toLowerCase())) {
              return item
            }
          }
        }
        return null;
      } else { // handles non-objects
        if(typeof item === 'string'){
          const lowerData = item.toLowerCase();
          if(lowerData.includes(e.target.value.toLowerCase())) {
            return item
          }
        }
        return null;
      }
    });
    props?.cb(filteredData);
  };

  const handleKeyPress = (e, cb) => {
    async function handleKeyPressAsync(){
      // Set the default table to search/revert to
      if(!defaultData.length){
        const data = props?.data || [];
        defaultData = [...data];
      }
      // allows for backspace/delete characters searching
      if(props?.data.length !== defaultData.length) {
        props.data = defaultData;
      }
      const keyPressOperation = props?.keyPress || keyPressDefault;
      keyPressOperation(e);
    }
    handleKeyPressAsync().catch(e => e);
  };
  
  const handleClear = () => {
    const el = document.querySelectorAll(`#${id}`)[0];
    el.value = "";
    props?.cb(defaultData);
  };

  return (
    <div className={`field ${props?.addClass}`}>
      <p className="control has-icons-left has-icons-right">
        <input
          type="text" 
          name={props?.name || ""} 
          id={id} 
          className={`input is-normal ${props?.addInputClass}`}
          placeholder={props?.placeholder}
          style={style}
          onKeyUp={handleKeyPress}
          autoComplete="off"
        />
        <span className="icon is-small is-left">
          <i className="fa-solid fa-magnifying-glass"></i>
        </span>
        <span className="icon is-small is-right" onClick={props?.handleClear || handleClear}>
          <span className="wb-message-closing-x is-clickable" style={{backgroundColor: "#dbdbdb", color: "#FFF"}}>X</span>
        </span>
      </p>
    </div>
  )
}