import { useState, useEffect, useRef } from "react";
import { v4 as uuidV4 } from "uuid";
import api from "../../../api";
import store, { getUserCognitoGroups } from "../../../store";
import ProductsView from "./ProductsView";
import ProductQuestions from "./QuestionsView";
import ProductForm from "./ProductForm";
import SectionForm from "./SectionForm";
import QuestionForm from "./QuestionForm";
import Header from "../../../components/header";
import SideMenu from "../../../components/sidemenu";
import TopDrawer, { handleShowDrawer } from "../../../components/TopDrawer";
import ApiSubmitButton from "../../../components/buttons/ApiSubmitButton";
import SectionSubsectionView from "./SectionsSubsectionsView";
import SubsectionForm from "./SubsectionForm";
import { trimEnd, isEqual, isEmpty, noop } from "lodash";
import { Loader } from "rsuite";
import { useParams } from "react-router";

const defaults = {
  productTemplate: {
    name: "",
    sortOrder: "",
    partnerId: "",
    sections: [],
    status: "",
  },
  sectionTemplate: {
    name: "",
    sortOrder: "",
    subsections: [],
    isContract: 0,
  },
  subsectionTemplate: {
    name: "",
    sortOrder: "",
    questions: [],
    sectionId: "",
  },
  questionTemplate: {
    topic: "",
    text: "",
    description: "",
    sortOrder: "",
    spSummary: 0,
    answerType: "",
    subSectionId: "",
    answers: [
      {
        value: "",
        additionalComments: 0,
        commentsRequired: 0,
        sortOrder: "",
        allowImage: null,
      },
    ],
  },
  answerTemplate: {
    value: "",
    additionalComments: 0,
    commentsRequired: 0,
    sortOrder: "",
    allowImage: null,
  },
};

export default function ProductsDashboard() {
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const questions = useRef([]);
  const [productAdmin, setProductAdmin] = useState(false);
  const [isDev, setIsDev] = useState(false);
  const [view, setView] = useState("Products");
  const [dataLoading, setDataLoading] = useState(true);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [partners, setPartners] = useState([]);
  const [products, setProducts] = useState([]);
  // contract
  const [isContract, setIsContracts] = useState(false);
  const [viewType, setViewType] = useState("isDefault"); // since we are no longer contract or !contract?
  const [headerTitle, setHeaderTitle] = useState("");
  // refactor
  const [productsLoading, setProductsLoading] = useState(true);
  const [sectionsLoading, setSectionsLoading] = useState(true);
  const [subsectionsLoading, setSubsectionsLoading] = useState(true);
  const [questionsLoading, setQuestionsLoading] = useState(true);
  const [secondaryLoading, setSecondaryLoading] = useState(false);
  const { urlView } = useParams();
  const [isFactSheet, setIsFactSheet] = useState(false);
  const [partnerStatuses, setPartnerStatuses] = useState([]);

  const productAreas = (() => {
    switch (viewType) {
      case "isContract":
        return ["Subsections", "Questions"];
      case "isProductDetails":
      case "isPartnerDetails":
        return ["Questions"];
      case "isDefault":
      default:
        return ["Products", "Sections", "Subsections", "Questions"];
    }
  })();

  const endpointMap = productAreas.map((area) => {
    switch (area) {
      case "Sections":
        return { area, endpoint: "productSections" };
      case "Subsections":
        return { area, endpoint: "productSubsections" };
      case "Answers":
        return { area, endpoint: "productAnswers" };
      case "Questions":
        return { area, endpoint: "productQuestions" };
      case "Products":
      default:
        return { area, endpoint: "products" };
    }
  });

  async function fetchPartners() {
    if (!store?.partners?.length || store.partnerUpdated) {
      const response = await api.partner.getPartners({});
      if (!response) throw new Error("no response from getSections call");
      setPartners([...response]);
    } else {
      setPartners([...store.partners]);
    }
  }

  async function fetchProducts() {
    const apiCalls = [api.products.getProductStatusList(), api.products.get({ endpoint: "products" })];
    const [tempProductStatusList, response] = await Promise.all(apiCalls);
    setPartnerStatuses(tempProductStatusList);
    if (!response) throw new Error("no response from getSections call");
    setProducts([...response]);
  }

  async function fetchSections({ tempIsDev }) {
    setSectionsLoading(true);
    const response = await api.productSections.get({});
    if (!response) throw new Error("no response from getSections call");
    if (tempIsDev) {
      setSections([...response]);
      setSectionsLoading(false);
    }
    return response;
  }

  async function fetchSubsections({ tempIsDev }) {
    setSubsectionsLoading(true);
    const response = await api.productSubsections.get({});
    if (!response) throw new Error("no response from getSubsections call");
    if (tempIsDev) {
      setSubsections([...response]);
      setSubsectionsLoading(false);
    } else {
      return response;
    }
    return response;
  }

  async function fetchQuestions({ tempIsDev }) {
    setQuestionsLoading(true);
    const response = await api.productQuestions.get({
      endpoint: `reference-questions/?includeAnswers=true`,
    });
    if (!response) throw new Error("no response from Questions call");
    if (tempIsDev) {
      questions.current = [...response];
      setQuestionsLoading(false);
    } else {
      return response;
    }
    return response;
  }

  async function getProductAdmin() {
    const isProductAdmin = await getUserCognitoGroups("productAdmin");
    setProductAdmin(isProductAdmin);
    return isProductAdmin;
  }

  async function getIsDev() {
    const tempIsDev = await getUserCognitoGroups("dev");
    setIsDev(tempIsDev);
    return tempIsDev;
  }

  async function getAllPartnerData() {
    setProductsLoading(true);
    const partnerCalls = [fetchPartners(), fetchProducts()];
    return Promise.all(partnerCalls);
  }

  // used for updates to sections, subsections, and questions.
  async function refreshSecondaryData({ ...props }) {
    if (isEmpty(props)) {
      props.tempIsDev = isDev;
      props.contract = isContract;
      props.factSheet = isFactSheet;
    }
    setSecondaryLoading(true);
    const callList = [fetchSections({ ...props }), fetchSubsections({ ...props }), fetchQuestions({ ...props })];
    await handleIsDevFilters({ ...props, callList });
  }

  async function handleIsDevFilters({ ...props }) {
    const [tempSections, tempSubSections, tempQuestions] = await Promise.all(props.callList);
    // remove dev only data if no dev permission (contract and fact sheet data).
    // handle sections
    const noDevOnlySections = tempSections.filter((section) => {
      return props.contract ? section.isContract : props.factSheet ? section.isFactSheet : !section.isContract && !section.isFactSheet;
    });
    setSections(noDevOnlySections);
    setSectionsLoading(false);
    // handle subsections
    const noDevOnlySubsections = tempSubSections.filter((subsection) => {
      return !isEmpty(noDevOnlySections.find((section) => section?.id === subsection?.sectionId));
    });
    setSubsections(noDevOnlySubsections);
    setSubsectionsLoading(false);
    // handle questions
    const noDevOnlyQuestions = tempQuestions.filter((question) => {
      const questionSubsection = noDevOnlySubsections.find((subsection) => subsection?.id === question?.subSectionId);
      const questionSection = noDevOnlySections.find((section) => section?.id === questionSubsection?.sectionId);
      if (!questionSubsection || !questionSection) return false;
      if (questionSection?.isContract && props.contract) return true;
      if (questionSection?.isFactSheet && props.factSheet) return true;
      return !questionSection?.isContract && !questionSection?.isFactSheet;
    });
    // setting subsection names on question object
    for (let item of noDevOnlyQuestions) {
      if (item?.subSectionId) {
        item.subsectionName = noDevOnlySubsections?.find((subsection) => subsection.id === item.subSectionId)?.name;
      }
    }

    questions.current = noDevOnlyQuestions;
    setQuestionsLoading(false);

    setSecondaryLoading(false);
  }

  async function updateAllProductData({ ...props }) {
    setDataLoading(true);
    setProductsLoading(true);
    const partnerCalls = getAllPartnerData();

    // get secondary tabs' data
    setSecondaryLoading(true);
    const callList = [fetchSections({ ...props }), fetchSubsections({ ...props }), fetchQuestions({ ...props })];
    await Promise.resolve(partnerCalls);
    setProductsLoading(false);
    setDataLoading(false);

    await handleIsDevFilters({ ...props, callList });
  }

  const setup = async () => {
    const permissionsCalls = [getProductAdmin(), getIsDev()];
    const [tempProductAdmin, tempIsDev] = await Promise.all(permissionsCalls);
    switch (urlView) {
      case "contract-abstract":
        let tempContracts = true;
        setDataLoading(true);
        setView("Subsections");
        setIsContracts(tempContracts);
        setViewType("isContract");
        setHeaderTitle("Contract Abstract Admin");
        await refreshSecondaryData({
          tempProductAdmin,
          tempIsDev,
          contract: tempContracts,
        });
        setDataLoading(false);
        break;
      case "partner-details":
        let tempPartFactSheet = true;
        setDataLoading(true);
        setView("Questions");
        setViewType("isPartnerDetails");
        setHeaderTitle("Partner Fact Sheet Admin");
        setIsFactSheet(tempPartFactSheet);
        await refreshSecondaryData({
          tempProductAdmin,
          tempIsDev,
          factSheet: tempPartFactSheet,
        });
        setDataLoading(false);
        break;
      case "product-details":
        let tempProdFactSheet = true;
        setDataLoading(true);
        setView("Questions");
        setViewType("isProductDetails");
        setHeaderTitle("Product Fact Sheet Admin");
        setIsFactSheet(tempProdFactSheet);
        await refreshSecondaryData({
          tempProductAdmin,
          tempIsDev,
          factSheet: tempProdFactSheet,
        });
        setDataLoading(false);
        break;
      default:
        setView("Products");
        setIsContracts(false);
        setIsFactSheet(false);
        setViewType("isDefault");
        setHeaderTitle("Products");
        updateAllProductData({ tempProductAdmin, tempIsDev }).catch((e) => e);
        break;
    }
  };

  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, [urlView]);

  /* 
  ##############################################################################
  =================================== DRAWER =================================== 
  ##############################################################################
  */
  const [showDrawer, setShowDrawer] = useState(false);
  const [editMode, setEditMode] = useState();
  const [changePending, setChangePending] = useState(false);
  const [productDetail, setProductDetail] = useState({
    ...defaults.productTemplate,
  });
  const [questionDetail, setQuestionDetail] = useState(structuredClone({ ...defaults.questionTemplate }));
  const [answerDetail, setAnswerDetail] = useState({
    ...defaults.answerTemplate,
  });
  const [sectionDetail, setSectionDetail] = useState({
    ...defaults.sectionTemplate,
  });
  const [subsectionDetail, setSubsectionDetail] = useState({
    ...defaults.subsectionTemplate,
  });
  const [submitActionTriggered, setSubmitActionTriggered] = useState(false);
  const [actionDone, setActionDone] = useState();
  const [disableFields, setDisableFields] = useState(false);
  const [cancelled, setCancelled] = useState();

  const cb = async () => {
    handleShowDrawer({ showDrawer, setShowDrawer, cancelActions });
    setDataUpdate(!dataUpdate);
  };

  const getDetails = ({ view }) => {
    switch (view) {
      case "Sections":
        return {
          detail: sectionDetail,
          setMethod: setSectionDetail,
          template: defaults.sectionTemplate,
        };
      case "Subsections":
        return {
          detail: subsectionDetail,
          setMethod: setSubsectionDetail,
          template: defaults.subsectionTemplate,
        };
      case "Answers":
        return {
          detail: answerDetail,
          setMethod: setAnswerDetail,
          template: defaults.answerTemplate,
        };
      case "Questions":
        return {
          detail: questionDetail,
          setMethod: setQuestionDetail,
          template: defaults.questionTemplate,
        };
      case "Products":
      default:
        return {
          detail: productDetail,
          setMethod: setProductDetail,
          template: defaults.productTemplate,
        };
    }
  };

  const drawerActions = [
    {
      id: uuidV4(),
      text: "Cancel",
      className: "is-warning",
      icon: <i className="fa-solid fa-ban"></i>,
      disabled: disableFields,
      action() {
        if (view === "Questions") {
          const viewDetails = getDetails({ view });
          const cancelledQuestionIdx = questions.current.findIndex((question) => question.id === viewDetails.detail?.id);
          questions.current[cancelledQuestionIdx] = structuredClone(viewDetails.detail.originalDetail);
          setQuestionDetail({ ...viewDetails.detail.originalDetail });
        }
        handleShowDrawer({ showDrawer, setShowDrawer, cancelActions });
      },
    },
    {
      id: uuidV4(),
      isCustom: (
        <ApiSubmitButton
          defaultLabel={editMode ? `Update ${trimEnd(view, "s")}` : `Save ${trimEnd(view, "s")}`}
          operationLabel="Saving"
          action={() => submit({ body: getDetails({ view })?.detail })}
          disabled={!changePending}
          cb={cb}
          // used to force correct rendering when parent re-renders
          actionTriggered={submitActionTriggered}
          actionDone={actionDone}
        />
      ),
    },
  ];

  const compareFields = async ({ newData, originalData, type }) => {
    const test = { update: [], create: [], deleteData: [] };
    // create and update
    newData?.forEach((d) => {
      const existsOnOriginal = originalData?.find((item) => item.id && item.id === d.id);
      if (existsOnOriginal && d.id && !isEqual(d, existsOnOriginal)) {
        test.update.push(d);
      }
      if (!existsOnOriginal && !d.id && !isEqual(d, existsOnOriginal)) {
        test.create.push(d);
      }
    });
    // delete
    originalData?.forEach((d) => {
      const existsOnNewData = newData?.find((item) => item.id && d.id && item.id === d.id);
      if (!existsOnNewData && d?.id) {
        test.deleteData.push(d);
      }
    });
    return test;
  };

  const handleChildIds = async ({ body, endpoint, parentId, childKey, type }) => {
    const callMap = [];
    body[childKey]?.map((d) => (d[parentId] = body?.id));
    const operations = await compareFields({
      newData: body[childKey],
      originalData: body?.originalDetail[childKey],
      parentId,
      type,
    });
    for (let operation in operations) {
      for (let data of operations[operation]) {
        callMap.push(api[endpoint][operation]({ id: data.id, body: data }));
      }
    }
    await Promise.all(callMap);
  };

  const sanitizeBody = async ({ data, view }) => {
    switch (view) {
      case "Questions":
        const tempBody = structuredClone({ ...defaults.questionTemplate });
        const cleanBody = {};
        for (let key in tempBody) {
          cleanBody[key] = data[key];
        }
        return cleanBody;
      case "Products":
        if (!data?.partnerId) {
          delete data?.partnerId;
          return data;
        }
        return data;
      default:
        return data;
    }
  };

  const submit = async ({ body }) => {
    const apiEndpoint = endpointMap.find((ep) => ep.area === view)?.endpoint;
    const sanitizedBody = await sanitizeBody({ data: body, view });
    setSubmitActionTriggered(true);
    setDisableFields(true);
    const method = body?.id ? "update" : "create";
    const response = await api[apiEndpoint][method]({
      id: body?.id,
      body: sanitizedBody,
    });
    if (isEmpty(response) || !response) {
      console.error({ id: body?.id, body: sanitizedBody });
      return cancelActions();
    }
    if (response?.id && !body?.id) {
      body.id = response?.id;
    }
    // the conditional and switch are to handle the update of the children's parentId.
    let dataRefresh;
    switch (apiEndpoint) {
      case "productSections":
      case "productSubsections":
        dataRefresh = refreshSecondaryData({});
        break;
      case "productQuestions":
        await handleChildIds({
          body,
          endpoint: "productAnswers",
          parentId: "questionId",
          childKey: "answers",
          type: body.answerType,
        });
        dataRefresh = refreshSecondaryData({});
        break;
      case "products":
        // refresh products data
        dataRefresh = getAllPartnerData();
        break;
      default:
        break;
    }
    delete body.originalDetail;
    questionDetail.originalDetail = { ...body };
    setActionDone(true);
    return new Promise((resolve) => {
      setTimeout(async () => {
        setShowDrawer(false);
        cancelActions();
        setActionDone(false);
        setSubmitActionTriggered(false);
        await Promise.resolve(dataRefresh);
        setProductsLoading(false);
        resolve("done");
      }, 1500);
    });
  };

  const cancelActions = async () => {
    const viewDetails = getDetails({ view });
    viewDetails.setMethod(structuredClone({ ...viewDetails.template }));
    setCancelled(true);
    setChangePending(false);
    setDisableFields(false);
    setSubmitActionTriggered(false);
    if (view === "Questions") {
      const cancelledQuestionIdx = questions.current.findIndex((question) => question.id === viewDetails.detail?.id);
      questions.current[cancelledQuestionIdx] = structuredClone(viewDetails.detail.originalDetail);
      setQuestionDetail({ ...viewDetails.detail.originalDetail });
    }
    if (editMode) {
      setEditMode(false);
    }
  };

  const handleEdit = (rowData) => {
    if (!productAdmin) return;
    const viewDetails = getDetails({ view });
    viewDetails.setMethod({ ...rowData });
    setEditMode(true);
    handleShowDrawer({ showDrawer, setShowDrawer, cancelActions });
  };

  return (
    <>
      <Header></Header>
      <div className="is-flex" style={{ height: "100%" }}>
        <SideMenu defaultOpenMenus={["1"]}></SideMenu>
        <div className="is-flex-grow-1 is-flex is-flex-direction-column">
          <h4 className="px-3 pt-2 title-color is-flex is-justify-content-space-between is-align-items-center">
            <span>{headerTitle}</span>
            {productAdmin && (
              <div className="drop-card-anchor is-flex" onClick={() => handleShowDrawer({ showDrawer, setShowDrawer, cancelActions })}>
                {showDrawer ? (
                  <button className="mr-3 mb-1 has-icon-left button is-warning" style={{ height: "25px", fontWeight: "normal" }}>
                    <i className="fa-solid fa-ban mr-2"></i>
                    <span>Cancel</span>
                  </button>
                ) : (
                  <button className="mr-3 has-icon-left title-button"> Create {trimEnd(view, "s")}</button>
                )}
              </div>
            )}
          </h4>
          <div className="is-flex is-flex-direction-column is-width-full" style={{ height: "100%" }}>
            <TopDrawer
              maxDrawerHeight={view === "Questions" ? "70vh" : "430px"}
              show={showDrawer}
              title={editMode ? `Edit ${trimEnd(view, "s")}` : `New ${trimEnd(view, "s")}`}
              body={
                <div>
                  {view === "Products" && (
                    <ProductForm
                      productdetail={productDetail}
                      setproductdetail={setProductDetail}
                      disabled={disableFields}
                      setchangepending={setChangePending}
                      cancelled={cancelled}
                      setcancelled={setCancelled}
                      editmode={editMode}
                      partners={partners}
                      products={products}
                      partnerStatuses={partnerStatuses}
                    />
                  )}
                  {view === "Sections" && (
                    <SectionForm
                      sectionDetail={sectionDetail}
                      setSectionDetail={setSectionDetail}
                      disabled={disableFields}
                      setchangepending={setChangePending}
                      cancelled={cancelled}
                      setcancelled={setCancelled}
                      editmode={editMode}
                      sections={sections}
                      showDrawer={showDrawer}
                      viewType={viewType}
                    />
                  )}
                  {view === "Subsections" && (
                    <SubsectionForm
                      subsectionDetail={subsectionDetail}
                      setSubsectionDetail={setSubsectionDetail}
                      disabled={disableFields}
                      setchangepending={setChangePending}
                      cancelled={cancelled}
                      setcancelled={setCancelled}
                      editmode={editMode}
                      sections={sections}
                      isContract={isContract}
                    />
                  )}
                  {view === "Questions" && !isEmpty(subsections) && (
                    <QuestionForm
                      answerDetail={answerDetail}
                      setAnswerDetail={setAnswerDetail}
                      questionDetail={questionDetail}
                      setQuestionDetail={setQuestionDetail}
                      disabled={disableFields}
                      setchangepending={setChangePending}
                      cancelled={cancelled}
                      setcancelled={setCancelled}
                      editmode={editMode}
                      subsections={subsections}
                      isContract={isContract}
                      viewType={viewType}
                      showDrawer={showDrawer}
                    />
                  )}
                </div>
              }
              actions={drawerActions}
              drawerBodyHeight={view === "Questions" ? "80vh" : "700px"}
              changePending={changePending}
            />
            <div className="is-flex is-flex-direction-column is-justify-content-flex-end mt-4 ml-3" style={{ height: "35px" }}>
              <div className="is-flex is-align-items-flex-end">
                {productAreas?.map((area) => {
                  // disable tabs switch
                  const disableTabsSwitch = ({ area }) => {
                    switch (area) {
                      case "Sections":
                        return sectionsLoading;
                      case "Subsections":
                        return subsectionsLoading;
                      case "Questions":
                        return questionsLoading;
                      case "Products":
                      default:
                        return productsLoading;
                    }
                  };
                  return (
                    <div
                      key={uuidV4()}
                      className={`tab ${view === area ? "has-background-link has-text-white" : ""} ${
                        disableTabsSwitch({ area }) ? "custom-disabled" : ""
                      }`}
                      onClick={() => (disableTabsSwitch({ area }) ? noop() : setView(area))}
                      style={{
                        paddingBottom: disableTabsSwitch({ area }) && area !== "Products" ? "5px" : "0.5rem",
                      }}
                    >
                      {/* {area !== 'Products' ? <Loader size="sm" content={area} /> : area} */}
                      {disableTabsSwitch({ area }) && area !== "Products" ? <Loader size="sm" content={area} /> : area}
                    </div>
                  );
                })}
              </div>
            </div>
            {dataLoading ? (
              <div className="is-full">
                <Loader center size="sm" content={`Retrieving data...`} />
              </div>
            ) : (
              <div
                style={{
                  flex: `1 1 ${showDrawer ? "" : "auto"}`,
                  height: "0px",
                  width: "100%",
                }}
              >
                {view === "Products" && (
                  <ProductsView
                    handleEdit={handleEdit}
                    getAllPartnerData={getAllPartnerData}
                    products={products}
                    setProducts={setProducts}
                    productsLoading={productsLoading}
                    setProductsLoading={setProductsLoading}
                  />
                )}
                {(view === "Sections" || view === "Subsections") && (
                  <SectionSubsectionView
                    handleEdit={handleEdit}
                    refreshSecondaryData={refreshSecondaryData}
                    setDataUpdate={setDataUpdate}
                    children={view === "Sections" ? subsections : questions.current}
                    questions={questions.current}
                    subsections={subsections}
                    parentEndpoint={view === "Sections" ? "productSections" : "productSubsections"}
                    childEndpoint={view === "Sections" ? "productSubsections" : "productQuestions"}
                    parentName={view === "Sections" ? "section" : "subSection"}
                    childName={view === "Sections" ? "subSection" : "question"}
                    parents={view === "Sections" ? sections : subsections}
                    setParents={view === "Sections" ? setSections : setSubsections}
                    isContract={isContract}
                    viewType={viewType}
                    sectionsLoading={sectionsLoading}
                    subsectionsLoading={subsectionsLoading}
                    secondaryLoading={secondaryLoading}
                    sections={sections}
                  />
                )}
                {view === "Questions" && questions.current && (
                  <ProductQuestions
                    handleEdit={handleEdit}
                    refreshSecondaryData={refreshSecondaryData}
                    questions={questions.current}
                    subsections={subsections}
                    isContract={isContract}
                    viewType={viewType}
                    questionsLoading={questionsLoading}
                    secondaryLoading={secondaryLoading}
                    sections={sections}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
