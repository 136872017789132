import { useState, useRef, useCallback, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import { startCase, orderBy } from "lodash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../../styles/tempTheme.css";
import api from "../../api";

// REUSABLE FUNCTION (part of webbank-ui)
const MultiSelectAllHeader = ({ selected, setSelected, dataset1, dataset2, setDataset1, cb }) => {
  // dataset1 represents selected
  // dataset2 represents all
  // selected and setSelected need to be defined at the parent level to handle single option selection callbacks to setSelected
  const handleCheckAll = (e) => {
    selected === false ? setDataset1(dataset2) : setDataset1([]);
    setSelected(!selected);
    e.preventDefault();
    if (cb) {
      cb();
    }
  };
  return (
    <div className="p-3" onClick={handleCheckAll} style={{ cursor: "pointer" }}>
      <Checkbox inputId="columns-all" name="category" value={selected ?? false} checked={dataset1?.length === dataset2?.length} />
      <label htmlFor="columns-all" className="ml-2" style={{ cursor: "pointer" }}>
        Select All
      </label>
    </div>
  );
};

const SpSummary = ({ partner, partners }) => {
  // state
  const [displayData, setDisplayData] = useState([]);
  const [sortField, setSortField] = useState("partnerName");
  const [sortDirection, setSortDirection] = useState(1);
  const [columns, setColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const data = useRef([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [loading, setLoading] = useState(true);

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns
      .map((mCol) => ({ field: mCol, header: mCol }))
      .filter((col) => selectedColumns.some((sCol) => sCol.field === col.field))
      .map((rCol) => rCol.field);

    setVisibleColumns(orderedSelectedColumns);
  };

  const onPartnerToggle = (event) => {
    const remapped = event.value?.map((p) => p.field);
    const selectedPartnersList = partners.filter((p) => remapped.includes(p.id));
    setSelectedPartners(selectedPartnersList);
    filterTableByPartners(selectedPartnersList);
  };

  const filterTableByPartners = useCallback((sp) => {
    if (sp.length) {
      const filteredData = data.current.filter((row) => sp.some((spItem) => spItem?.id === row.partnerId));
      setDisplayData(structuredClone(filteredData));
    } else {
      setDisplayData(structuredClone(data.current));
    }
  }, []);

  // PARTIALLY REUSABLE FUNCTION (part of webbank-ui)
  const handleSort = (event) => {
    const workingData = structuredClone([...data.current]);
    const ordered = orderBy(workingData, [data[event.sortField]?.toLowerCase()], [event.sortOrder === 1 ? "asc" : "desc"]);
    data.current = structuredClone([...ordered]);
    setSortField(event.sortField);
    setSortDirection(event.sortOrder);
  };

  // PARTIALLY REUSABLE FUNCTION (part of webbank-ui)
  const exportPdf = ({ fileName, setState = () => null }) => {
    setState(true);
    setTimeout(() => {
      let sortedData = orderBy(displayData, [item => item[sortField]?.toLowerCase()], [sortDirection === 1 ? "asc" : "desc"]);
      const doc = new jsPDF({ orientation: "p", unit: "pt", compress: true });
      const columnsNames = visibleColumns.map((col) => startCase(col));
      const titleRow = [{ content: "SP Summary Report", colSpan: visibleColumns.length }];
      const blankRow = [{ content: "", colSpan: visibleColumns.length }];
      // Convert headers to a regular row - normally would use headers but they repeat on each page, which is unwanted
      const headersAsRow = [titleRow, blankRow, columnsNames];
      const criteriaData = headersAsRow.concat(
        sortedData.map((row) => {
          return visibleColumns.map((col) => row[col] ?? "");
        })
      );
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const tableWidth = pageWidth * 0.95; // Use 95% of page width
      const columnWidth = tableWidth / (visibleColumns.length + 0.05); // Divide by number of columns

      let columnStyles = {};
      visibleColumns.forEach((item, index) => {
        columnStyles[index] = { cellWidth: columnWidth };
      });
      const options = {
        body: criteriaData,
        columnStyles,
        margin: { top: 30, right: 15, bottom: 15, left: 15 },
        horizontalPageBreak: true,
        didParseCell: (data) => {
          // Check if the cell's row index is the one you want to style
          if (data.row.index === 0) {
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fillColor = [47, 90, 198]; // Change background color to blue
          }
          if (data.row.index === 2) {
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fillColor = [111, 170, 235]; // Change background color to blue
          }
        },
        didDrawPage: (data) => {
          if(data.pageCount === 1) return;
          // Add column names at the top of each page
          doc.setFontSize(10);
          let padding = 5; // Padding in pt
          let yPos = data.settings.margin.top - 10; // Increase yPos by 10
          columnsNames.forEach((name, i) => {
            let xPos = data.settings.margin.left + i * columnWidth;
            doc.setFillColor(111, 170, 235); // Set the fill color to rgb(111, 170, 235)
            // Draw a rectangle behind the text with padding
            doc.rect(xPos - padding, yPos - 10 - padding, columnWidth + 2 * padding, 10 + 2 * padding, 'F');
            doc.setTextColor(255, 255, 255); // Set the text color to white
            // Add padding to the text position
            doc.text(name, xPos + padding, yPos - padding);
          });
          doc.setTextColor(0, 0, 0); // Reset the text color to black for the rest of the document
        },
      };

      doc.autoTable(options);
      doc.save(`${fileName}.pdf`);
      setState(false);
    }, 1000);
  };

  const [checkAllVisibleColumns, setCheckAllVisibleColumns] = useState(visibleColumns?.length === columns?.length);
  const [checkAllPartners, setCheckAllPartners] = useState(selectedPartners?.length === partners?.length);
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-end">
        <div className="flex">
          {!loading && partners?.length ? (
            <div className="flex flex-column">
              <label htmlFor="ms-partners">Columns</label>
              <MultiSelect
                panelHeaderTemplate={
                  <MultiSelectAllHeader
                    selected={checkAllVisibleColumns}
                    setSelected={setCheckAllVisibleColumns}
                    dataset1={visibleColumns}
                    dataset2={columns}
                    setDataset1={setVisibleColumns}
                  />
                }
                value={visibleColumns.map((vc) => ({ field: vc, header: startCase(vc) }))}
                options={columns.map((vc) => ({ field: vc, header: startCase(vc) }))}
                optionLabel="header"
                onChange={onColumnToggle}
                className="mr-5"
                display="chip"
                maxSelectedLabels={4}
                style={{maxWidth: "35rem"}}
              />
            </div>
          ) : (
            <Skeleton width="20rem" height="2.75rem" className="mr-5" />
          )}
          {!loading && partners?.length ? (
            <div className="flex flex-column">
              <label htmlFor="ms-partners">Partners</label>
              <MultiSelect
                panelHeaderTemplate={
                  <MultiSelectAllHeader
                    selected={checkAllPartners}
                    setSelected={setCheckAllPartners}
                    dataset1={selectedPartners}
                    dataset2={partners}
                    setDataset1={setSelectedPartners}
                    cb={() => {
                      setTimeout(() => {
                        if (checkAllPartners) {
                          filterTableByPartners(partners);
                        } else {
                          filterTableByPartners([]);
                        }
                      }, 0);
                    }}
                  />
                }
                value={selectedPartners.map((p) => ({ field: p.id, header: startCase(p.name) }))}
                options={partners.map((p) => ({ field: p.id, header: startCase(p.name) }))}
                optionLabel="header"
                onChange={onPartnerToggle}
                className="mr-3"
                display="chip"
                style={{maxWidth: "35rem"}}
                maxSelectedLabels={4}
              />
            </div>
          ) : (
            <Skeleton width="20rem" height="2.75rem" className="mr-3" />
          )}
        </div>
        <div className="flex justify-content-end mb-1">
          {!loading && partners?.length ? (
            <Button
              rounded
              raised
              icon="pi pi-file-pdf"
              severity="secondary"
              onClick={() =>
                exportPdf({
                  fileName: `SP-Summary-Report`,
                })
              }
              tooltip="Export PDF"
              tooltipOptions={{ position: "left" }}
              style={{ height: "36px", width: "36px", marginLeft: "10px" }}
            />
          ) : (
            <Skeleton shape="circle" size="2.625rem" />
          )}
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const setupTableData = useCallback(() => {
    // copy the dataset to originalDataset so we can refer to original data when updating
    const tempColumns = Object.keys(data.current[0]).filter((key) => !key?.toLowerCase()?.includes("id"));
    setColumns(tempColumns);
    setVisibleColumns(tempColumns);
  }, []);

  const setup = useCallback(async () => {
    setLoading(true);
    if (!data.current?.length) {
      data.current = (await api.sp.get({})) ?? [];
    }
    setTimeout(async () => {
      if (partner && !checkAllPartners) {
        setSelectedPartners([partner]);
        filterTableByPartners([partner]);
      } else {
        setDisplayData(structuredClone(data.current));
      }
      if (data.current?.length) {
        await setupTableData();
      }
      setLoading(false);
    }, 0);
  }, [setupTableData, partner, filterTableByPartners, checkAllPartners]);

  useEffect(() => {
    setup();
  }, [setup]);

  return (
    <div id="kill-all-styles" className="use-prime specific-prime">
      <Card className="mt-5">
        {!loading && partners?.length ? (
          <DataTable
            value={displayData}
            tableStyle={{ minWidth: "50rem" }}
            header={header}
            sortField={sortField}
            sortOrder={sortDirection}
            onSort={handleSort}
            scrollable
            scrollHeight="calc(100vh - 436px)"
          >
            {visibleColumns.map((col) => {
              console.log('col', col);
              return (
              <Column key={col} field={col} header={startCase(col)} sortable style={col === "responseComment" && {maxWidth: "15rem"}} />
            )})}
          </DataTable>
        ) : (
          // DataTable NEEDS TO BE WRAPPED IN A DIV FOR THE VALUES TO WORK
          <div>
            <DataTable
              tableStyle={{ minWidth: "50rem" }}
              header={header}
              value={[{ partner: "", product: "", type: "", topic: "", text: "", response: "", comment: "" }]}
            >
              <Column
                field="partner"
                header="Partner"
                body={
                  <div className="p-3">
                    <Skeleton />
                  </div>
                }
              />
              <Column
                field="product"
                header="Product"
                body={
                  <div className="p-3">
                    <Skeleton />
                  </div>
                }
              />
              <Column
                field="type"
                header="Type"
                body={
                  <div className="p-3">
                    <Skeleton />
                  </div>
                }
              />
              <Column
                field="topic"
                header="Topic"
                body={
                  <div className="p-3">
                    <Skeleton />
                  </div>
                }
              />
              <Column
                field="text"
                header="Text"
                body={
                  <div className="p-3">
                    <Skeleton />
                  </div>
                }
              />
              <Column
                field="response"
                header="Response"
                body={
                  <div className="p-3">
                    <Skeleton />
                  </div>
                }
              />
              <Column
                field="comment"
                header="Comment"
                body={
                  <div className="p-3">
                    <Skeleton />
                  </div>
                }
              />
            </DataTable>
          </div>
        )}
      </Card>
    </div>
  );
};
export default SpSummary;
