const getWbMessageContainerColors = (data) => {
  const colors = {};
  switch(data.type){
    case('error'):
    colors.backgroundColor = '#f14668';
    colors.borderColor='#dc405d';
    break;
    case('warning'):
    colors.backgroundColor = '#ffe08a';
    colors.borderColor='#e6cd87';
    break;
    case('success'):
    colors.backgroundColor = '#48c78e';
    colors.borderColor='#53af86';
    break;
    case('info'):
    colors.backgroundColor = '#3e8ed0';
    colors.borderColor='#5e9fd5';
    break;
    default:
      break;
  }
  if(data.primaryColorOverride){
    colors.backgroundColor = data.primaryColorOverride;
  }
  if(data.secondaryColorOverride){
    colors.borderColor = data.secondaryColorOverride;
  }
  // handle width override
  if(data?.widthOverride){
    colors.width = data?.widthOverride;
  }
  
  return colors;
};

const wbMessageClosingXColors = (data) => {
  const colors = {};
  switch(data.type){
    case('error'):
    colors.backgroundColor = '#dc405d';
    break;
    case('warning'):
    colors.backgroundColor = '#e6cd87';
    break;
    case('success'):
    colors.backgroundColor = '#53af86';
    break;
    case('info'):
    colors.backgroundColor = '#5e9fd5';
    break;
    default:
      break;
  }
  if(data.secondaryColorOverride){
    colors.backgroundColor = data.secondaryColorOverride;
  }
  return colors;
};

const wbMessageHeaderTitleContainerColors = (data) => {
  const colors = {};
  switch(data.type){
    case('error'):
    colors.borderColor = '#dc405d';
    colors.color = '#fff';
    break;
    case('warning'):
    colors.borderColor = '#e6cd87';
    colors.color = 'rgba(0,0,0,.7)';
    break;
    case('success'):
    colors.borderColor = '#53af86';
    colors.color = '#fff';
    break;
    case('info'):
    colors.borderColor = '#5e9fd5';
    colors.color = '#fff';
    break;
    default:
      break;
  }
  if(data.secondaryColorOverride){
    colors.borderColor = data.secondaryColorOverride;
  }
  if(data.titleColorOverride){
    colors.color = data.titleColorOverride;
  }
  return colors;
};

const wbMessageContentsColors = (data) => {
  const color = {};
  switch(data.type){
    case('error'):
    color.color = '#fff';
    break;
    case('warning'):
    color.color = 'rgba(0,0,0,.7)';
    break;
    case('success'):
    color.color = '#fff';
    break;
    case('info'):
    color.color = '#fff';
    break;
    default:
      break;
  }
  if(data.bodyTextColorOverride){
    color.color = data.bodyTextColorOverride;
  }
  return color;
};

const getButtonColors = (data) => {
  if(data.className && (!data.primaryColorOverride && !data.secondaryColorOverride && !data.borderColorOverride)){
    return 
  } else if(data.primaryColorOverride && data.secondaryColorOverride && data.borderColorOverride) {
    return {
      backgroundColor: data.primaryColorOverride,
      borderColor: data.borderColorOverride,
      color: data.secondaryColorOverride
    }
  }
};

const exports = {
  getWbMessageContainerColors,
  wbMessageClosingXColors,
  wbMessageHeaderTitleContainerColors,
  wbMessageContentsColors,
  getButtonColors
};
export default exports