import Header from "../components/header";
import React, { useState, useEffect } from 'react';
import { env as amplifyEnv } from '../redirect-mutator';

export default function Compliance(){

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const headerOffset = amplifyEnv === 'development' ? 100 : 55;

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight - headerOffset);
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [headerOffset]);

  return (
    <>
      <Header />
      <iframe 
        title="Compliance Sharepoint Document" 
        src="https://webbankeo.sharepoint.com/sites/WebBankCompliance" 
        width={windowWidth}
        height={windowHeight}>
      </iframe>
    </>
  )
}