import React, { useState } from 'react';
import { Input, InputGroup } from 'rsuite';

const Search = ({ searchProperty, search, placeholder, ...props }) => {
  const [query, setQuery] = useState('');

  let timeout;
  const handleSearch = ({ val, setLoading, ...props }) => {
    if (setLoading) setLoading(true);
    setQuery(val);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      handleSearchSubmit({ query: val, setLoading, ...props });
    }, 1000);
  };

  const handleSearchSubmit = ({ query, dataset, setDataset, setDefaultDataId, setQuestion, searchProperty, useCustomSearch, setLoading, setFiltered, ...props }) => {
    if (!query.trim()) {
      handleClearClick({ query, dataset, setDataset, setDefaultDataId, setQuestion, searchProperty, useCustomSearch, setLoading, setFiltered, ...props });
      return;
    }

    const filteredDataset = search({ query, setQuery, dataset, searchProperty, ...props });
    setDataset(filteredDataset);
    if (!searchProperty) {
      setFiltered(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return
    }
    setQuestion(filteredDataset.flatMap(data => data[searchProperty]));
    if (filteredDataset && filteredDataset[0]) {
      setDefaultDataId(filteredDataset[0].id); // set default data point ID
    }
  };

  const handleClearClick = ({ dataset, setDataset, setDefaultDataId, setQuestion, searchProperty, clearOverride, setFiltered }) => {
    setQuery('');
    if (!searchProperty) {
      setFiltered(false);
      return clearOverride();
    }
    setDataset(dataset);
    // need to get the first data point id to open the default data point
    let defaultId = dataset[0].id;
    let result = dataset.filter(val => val.id.includes(defaultId));
    const newDataSet = result[0][searchProperty];
    setQuestion(newDataSet);
    setDefaultDataId(defaultId);
  };

  return (
    <span className="search-input-group">
      <InputGroup size="sm">
        <Input
          placeholder={placeholder}
          value={query}
          onChange={(val) => handleSearch({ val, searchProperty, ...props })}
        />
        <InputGroup.Button onClick={() => handleClearClick({ searchProperty, ...props })}>
          <span className="icon">
            <i className={`fas fa-${query ? 'close' : 'search'}`} aria-hidden="true"></i>
          </span>
        </InputGroup.Button>
      </InputGroup>
    </span>
  )
};

export default Search;