import Toggle from "../toggle";
import { useState } from "react";
import api from "../../api";
import { last } from "lodash";

export default function DevAdmin(){
  const [fileName, setFileName] = useState("");
  const [fileSelection, setFileSelection] = useState(false);
  const [fileIsValid, setFileIsValid] = useState(true);
  const [override, setOverride] = useState(false);
  const [validOverride, setValidOverride] = useState(true);
  const [showSave, setShowSave] = useState(false);

  const checkValidation = async () => {
    if(!fileSelection){
      setFileIsValid(false);
      return false;
    }
    if(override){
      if(!fileName){
        setValidOverride(false);
        return false;
      } else {
        setValidOverride(true);
      }
    }
    return true;
  };
  
  const handleFileSelection = (val) => {
    async function handleFileSelectionAsync(){
      setFileSelection(val);
      handleToggle(false).catch(e => e);
      if(!!val) setFileIsValid(true);
    }
    handleFileSelectionAsync().catch(e => e);
  };

  const handleToggle = async (val) => {
    if(!val) {
      setFileName('');
      setValidOverride(true);
    }
    setOverride(val);
  };

  const handleFileNameInput = (val) => {
    async function handleFileNameInputAsync(){
      setFileName(val);
      await checkValidation();
    }
    handleFileNameInputAsync().catch(e => e);
  }

  const uploadNonPartnerImage = () => {
    async function uploadNonPartnerImageAsync(){
      const valid = await checkValidation();
      if(!valid) return;
      const selectedFile = document.querySelectorAll('#selected-file')[0].files[0];
      const setImagePath = async (e) => {
        const extension = last(e?.name.split('.'));
        const name = fileName ? `${fileName}.${extension}`: e?.name;
        
        let reader = new FileReader() 
        reader.readAsDataURL(e);
        
        reader.onload = async () => {      
          let img = {        
            queryImage: reader.result      
          };
          let blob = {
            fileName: name, // or /partner-images/fileName
            fileObject: JSON.stringify(img.queryImage)
          };
          try {
            await api.s3Images.uploadImage({body:blob});
            setShowSave(true);
            clear();
            setTimeout(() => {
              setShowSave(false);
            }, 3000);
          } catch (error) {
            alert(error);
          }
        }
      };
      await setImagePath(selectedFile);
    }
    uploadNonPartnerImageAsync().catch(e => e);
  };

  const clear = () => {
    async function clearAsync(){
      document.querySelectorAll('#selected-file')[0].value = null;
      setFileIsValid(true);
      setFileSelection(false);
      setOverride(false);
      setValidOverride(true);
    }
    clearAsync().catch(e => e);
  };

  return (
    <>
      <div className="image-upload-container p-3 m-3">
        <div className="field">
          <label className="label">Upload an Image to S3</label>
          <div className="control has-icons-right">
            <input className={fileIsValid ? "input" : "input is-danger"} id="selected-file" type="file" multiple onChange={handleFileSelection} />
            {
              !fileIsValid &&
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
            }
          </div>
          {
            !fileIsValid &&
              <p className="help is-danger">You must select a file</p>
          }
        </div>
        {
          fileSelection &&
            <Toggle 
              label="Override Image Name"
              default={false}
              cb={handleToggle}
            />
        }
        {
          override &&
            <div className="field">
              <label className="label">Image Name Override</label>
              <input type="text" className="input" id="image-name-override" onChange={ (e) => handleFileNameInput(e.target.value)} autoComplete="off"/>
              {
                !validOverride &&
                  <p className="help is-danger">You must either un-toggle override or enter a file name.</p>
              }
              <sub>prepend path to place in specific folder. i.e. partner-images/name. <b className="has-text-danger">do not include extension. </b>skynet exclusive non-partner images are in the skynet-images directory.</sub>
            </div>
        }
        <div className="field is-grouped mt-3">
          <div className="control">
            <button className="button" onClick={() => clear()}>Clear</button>
          </div>
          <div className="control">
            <button className="button is-info" onClick={() => uploadNonPartnerImage()}>Upload</button>
          </div>
        </div>
        {
          showSave &&
          <p className="is-success help">Saved Successful</p>
        }
      </div>
    </>
  )
}