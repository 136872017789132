import { Cell } from "rsuite-table";
import { usd, stringToNumber } from "../../services/microservices";

const CurrencyCell = ({ rowData, dataKey, ...props }) => {
  if(typeof rowData[dataKey] !== 'string'){
    rowData[dataKey] = usd.format(rowData[dataKey]);
  } else {
    rowData[dataKey] = usd.format(stringToNumber(rowData[dataKey]));
  }
  return <Cell {...props} >{rowData[dataKey]}</Cell>;
};
export default CurrencyCell;
