import PageTemplate from "../PageTemplate";
import { useLocation, useNavigate } from 'react-router-dom';
import store, { getUserCognitoGroups } from '../../store';
import { useState, useEffect, useRef, useCallback } from "react";
import InputField from "../../components/TextInput";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import api from "../../api";
import { isEmpty } from "lodash";
import { Loader, SelectPicker } from 'rsuite';
import AreYouSureDelete from "../../components/buttons/AreYouSureDelete";
import ImageUpload from "../../components/ImageUpload";
import PartnerReference from "./PartnerReference";
import ContractAbstract from "./ContractAbstract";
import SpSummary from "./SpSummary";
import { v4 as uuidV4 } from 'uuid';
import PartnerFactSheet from "./PartnerFactSheet";
import ProductFactSheet from "./ProductFactSheet";
import config from "../../config/appflags.json";
import ToggleSlider from "../../components/ToggleSlider";

let called = false;
export default function PartnerView() {
  // parent State
  const [saveClicked, setSaveClicked] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [cancelTriggered, setCancelTriggered] = useState(false);
  const [partner, setPartner] = useState({});
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState('read'); // 'read' or 'edit
  const [isPartnerAdmin, setIsPartnerAdmin] = useState(false);
  const [isDev, setIsDev] = useState(false);
  const [havePermissionResponse, setHavePermissionResponse] = useState(false);
  const [isCreate, setIsCreate] = useState(false);

  // variables and refs
  const navigate = useNavigate();
  const location = useLocation();
  const locationInfo = useRef({});
  const originalEditValue = useRef({});

  const dataDefault = useRef({
    name: "",
    partnerImage: {
      name: "",
      content: {}
    },
    products: [],
    status: 'Active DD&I',
    internal: null
  });

  // functions
  async function getUserPermissions() {
    const temp = await getUserCognitoGroups('partnerAdmin');
    const isDev = await getUserCognitoGroups('dev');
    setIsPartnerAdmin(!!temp);
    setIsDev(!!isDev);
    setHavePermissionResponse(true);
  }

  const save = (detail) => {
    setIsCreate(false);
    setSaveClicked(detail);
  };

  const edit = () => {
    setMode('edit');
    setEditClicked(true);
  };

  const cancel = () => {
    setMode('read');
    setCancelTriggered(true);
  };

  const afterDelete = async ({ response }) => {
    store.partnerView = {};
    navigate('/spd');
  };


  const setup = useCallback(async () => {
    if (store.partnerView) {
      locationInfo.current = store.partnerView;
    }
    const partnerId = window.location.pathname.split('/partner/')[1];
    if ((isEmpty(locationInfo.current) || !locationInfo.current) && (!partnerId || partnerId === 'null')) {
      setPartner({ ...dataDefault.current });
      setMode('edit');
      setIsCreate(true);
      setLoading(false);
    } else if (!isEmpty(locationInfo.current)) {
      setPartner({ ...locationInfo.current });
      setLoading(false);
    } else if (!called) {
      called = true; // used to only make this call once regardless of component updates or state changes
      const response = await api.partner.get({ id: partnerId });
      const errorOnResponse = (typeof response === 'string' && response.includes('no response'));
      if (!response || errorOnResponse) {
        setPartner({ ...dataDefault.current });
        setMode('edit');
      } else {
        setPartner({ ...response });
      }
      setLoading(false);
    }
    if (!isEmpty(partner) && havePermissionResponse) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [havePermissionResponse]);

  useEffect(() => {
    setup().catch(e => e);
  }, [setup, havePermissionResponse, location]);

  const processing = useRef(false);

  // +++++++++++++++======CONTENT COMPONENT=======++++++++++++++++++
  function PartnerViewContent({ save, loading, editClicked, cancelTriggered, partner, setPartner }) {
    // state
    const [submitting, setSubmitting] = useState(false);
    const [dataIsValid, setIsValid] = useState(false);
    const [detail, setDetail] = useState({});
    const [actionTriggered, setActionTriggered] = useState(false);
    const [actionDone, setActionDone] = useState(false);
    const [view, setView] = useState('');
    const [showPartnerDropdown, setShowPartnerDropdown] = useState(false);
    const [partners, setPartners] = useState([]);
    const [allPartners, setAllPartners] = useState([]);
    const [prev, setPrev] = useState({});
    const [next, setNext] = useState({});
    const [areas, setAreas] = useState(['Partner Fact Sheet']);

    const radioData = [
      { label: 'Active Partner', value: 'Active Partner' },
      { label: 'Active DD&I', value: 'Active DD&I' },
      { label: 'Terminated from DD&I', value: 'Terminated from DD&I' },
      { label: 'Terminated Partner', value: 'Terminated Partner' }
    ];

    // Action handlers

    const handleFieldUpdates = {
      name: (e) => {
        if (!isEmpty(detail)) {
          detail.name = e.target.value;
          setIsValid(!!detail?.name && !!detail?.status);
          setDetail({ ...detail });
        }
      },
      status: (e) => {
        if (!isEmpty(detail)) {
          detail.status = e;
          setIsValid(!!e && !!detail?.name);
          setDetail({ ...detail });
        }
      },
      internal: (e) => {
        if (!isEmpty(detail)) {
          detail.internal = e;
          setIsValid(!!detail?.status && !!detail?.name);
          setDetail({ ...detail });
        }
      }
    };

    const handleEdit = useCallback(async () => {
      originalEditValue.current = structuredClone(partner);
      setEditClicked(false);
    }, [partner]);

    const handleCancelEdit = useCallback(() => {
      setPartner({ ...originalEditValue.current });
      if (!partner?.id) navigate('/spd');
      setCancelTriggered(false);
    }, [partner, setPartner]);

    const handleSave = useCallback(async ({ partner }) => {
      processing.current = true;
      setSubmitting(true);
      setActionTriggered(true);
      // handle partner updates
      const method = partner?.id ? 'updatePartner' : 'createNewPartner';
      const response = await api.partner[method]({ id: partner?.id, body: partner });
      if (!response) handleCancelEdit();
      if (response && partner) partner = { ...response };
      if (!isEmpty(locationInfo.current)) locationInfo.current = { ...partner };
      store.partnerUpdated = true;
      setPartner({ ...partner });
      setActionDone(true);
      setTimeout(() => {
        processing.current = false;
        setActionTriggered(false);
        setActionDone(false);
        setSaveClicked(false);
        setMode('read');
        setSubmitting(false);
      }, 1000);
      // eslint-disable-next-line
    }, [handleCancelEdit]);

    const fileSelectCb = ({ blob, isValid }) => {
      if (!isEmpty(detail)) {
        detail.partnerImage = blob?.fileObject ? {
          content: blob.fileObject
        } : {};
        setDetail({ ...detail });
        if (partner?.name && isValid) {
          setIsValid(true);
        }
      }
    };

    const setupNavOptions = ({ partnerList, partner, setPrev, setNext }) => {
      // removes current partner from partner list and sets next and prev partner info
      if (partner) {
        partnerList?.reduce((a, b) => {
          if (b?.id === partner?.id) {
            setPrev(a);
          }
          if (a?.id === partner?.id) {
            setNext(b);
          }
          return b;
        });
        return partnerList?.filter(r => r?.id !== partner?.id);
      }
    };

    const handleView = ({ newView }) => {
      store.partnerViewMemory = { tab: newView };
      setView(newView);
    };

    const fetchPartners = async () => {
      let response = store?.partners;
      if (!response?.length) {
        response = await api.partner.getPartners({});
        if (!response) {
          console.error('no response from partners get');
        }
      }
      // limiting partner list to only active partners if not user isn't in the partnerAdmin group in cognito.
      const partnerList = isPartnerAdmin ? response : response.filter(partner => partner.status === "Active Partner" || partner.status === "Active DD&I");
      const tempResponse = setupNavOptions({ partnerList, partner, setPrev, setNext });
      setPartners([...tempResponse]);
      setAllPartners([...response]);
    };

    useEffect(() => {
      areas.push('Product Fact Sheet');
      areas.push('Reference Library');
      areas.push('Contract Abstract');
      areas.push('SP Summary');

      setAreas([...areas]);
      !store.partnerViewMemory?.tab ? setView('Partner Fact Sheet') : setView(store?.partnerViewMemory?.tab);
      if (editClicked) {
        (async () => await handleEdit())();
      }
      if (cancelTriggered) {
        handleCancelEdit();
      }
      if (!!saveClicked && !processing.current) {
        (async () => await handleSave(saveClicked))();
      }
      if (isEmpty(detail) || !detail) {
        const tempPartner = structuredClone({ ...partner })
        // remember state after save
        if(dataDefault.current.internal !== null && !isEmpty(tempPartner)){
          tempPartner.internal = dataDefault.current.internal;
        }
        setDetail({ ...tempPartner });
      }
      fetchPartners().catch(e => e);

      // eslint-disable-next-line
    }, [editClicked, cancelTriggered, saveClicked, location]);

    const handlePartnerNavigate = ({ direction }) => {
      const navPartner = direction === 'prev' ? prev : next;
      locationInfo.current = { ...navPartner };
      store.partnerView = { ...locationInfo.current };
      setPartner({ ...navPartner });
      navigate(`/partner/${navPartner?.id}`, { replace: true });
    };

    const handlePartnerDropdownShow = () => {
      if (!showPartnerDropdown) {
        setShowPartnerDropdown(true);
        setTimeout(() => {
          document.addEventListener('click', handlePartnerDropdownHide);
        }, 250);
      }
    };

    const handlePartnerDropdownHide = () => {
      setShowPartnerDropdown(false);
      document.removeEventListener('click', handlePartnerDropdownHide);
    };

    const handlePartnerSelect = ({ selectedPartner }) => {
      locationInfo.current = { ...selectedPartner };
      store.partnerView = { ...locationInfo.current };
      navigate(`/partner/${store.partnerView?.id}`, { replace: true });
    };

    return (
      <>
        {
          loading ?
            <div className="is-full"><Loader center size="sm" content="Loading Partner Info..." /></div>
            :
            <div className="is-relative is-page-content">
              {
                (mode !== "edit") ?
                  <div id="partner-image-container" className="is-width-full is-flex is-justify-content-space-between is-align-items-center px-3">
                    {prev && !isEmpty(prev) && <button className="button is-link" onClick={() => handlePartnerNavigate({ direction: 'prev' })} title="Previous">
                      <span><i className="fa-solid fa-arrow-left"></i></span>
                    </button>}
                    <div className="is-flex is-justify-content-center is-align-items-center is-width-full p-3 mb-2" >
                      <div className="is-relative is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                        <p className="help has-text-info">click logo to select new partner</p>
                        <div className="is-relative is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start">
                          <div>
                            <img id="pl-trigger" className={`partner-view-image ${showPartnerDropdown ? 'active' : ''}`} onClick={handlePartnerDropdownShow} src={`${config.cloudfrontHost}${partner?.imagePath}?${new Date().getTime()}`} alt={partner?.name} title={partner?.name} />
                          </div>
                          {showPartnerDropdown && !!partners?.length && <div id='pl-dropdown' className="is-absolute partner-view-partner-list">
                            <ul>
                              {partners?.map(partner => (
                                <li key={partner?.id} className="pl-item p-2" onClick={() => handlePartnerSelect({ selectedPartner: partner })}>{partner?.name}</li>
                              ))}
                            </ul>
                          </div>}
                        </div>
                      </div>
                    </div>
                    {next && !isEmpty(next) && <button className="button is-link" onClick={() => handlePartnerNavigate({ direction: 'next' })} title="Next">
                      <span><i className="fa-solid fa-arrow-right"></i></span>
                    </button>}
                  </div>
                  :
                  mode === "edit" &&
                  <div className="card-header p-5 m-3 is-flex-direction-column">
                    {
                      (!isEmpty(detail?.partnerImage?.content) || detail?.imagePath) &&
                      <div className="is-flex is-justify-content-center is-align-items-center is-width-full p-5 mb-2" style={{ border: "1px solid #dbdbdb" }}>
                        <img src={detail?.partnerImage?.content || `${config.cloudfrontHost}${detail?.imagePath}?${new Date().getTime()}`} alt={detail?.name} style={{ maxHeight: "100px" }} />
                      </div>
                    }
                    {
                      mode === "edit" &&
                      <ImageUpload
                        uploadDescription="Upload Partner Image"
                        cb={fileSelectCb}
                        disabled={submitting}
                      />
                    }
                  </div>
              }
              <div className="full-height-div is-flex">
                {
                  mode === 'edit' ?
                    <div className="is-width-full">
                      <div className="card m-3">
                        <div className="p-5 is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start">
                          <InputField
                            label="Partner name"
                            value={detail?.name || ""}
                            onChange={handleFieldUpdates.name}
                            disabled={submitting}
                            controlClass="is-width-full mb-3"
                          />
                        </div>
                        <div className="is-flex is-align-items-center">
                          <div className="p-5 is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start">
                              <label style={{ fontWeight: 400, fontSize: 16 }}>Partner Status</label>
                              <SelectPicker
                                style={{ width: 300 }}
                                data={radioData}
                                value={detail?.status}
                                disabled={submitting}
                                searchable={false}
                                size="lg"
                                name="status" 
                                onChange={handleFieldUpdates.status}
                                cleanable={false}
                              />
                              
                          </div>
                          <div className="p-5 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                            <label style={{ fontWeight: 400, fontSize: 16 }} className="mb-4">
                              Internal Use Only
                            </label>
                            <ToggleSlider
                              disabled={submitting}
                              className="is-flex is-justify-content-center"
                              value={detail?.internal}
                              setValue={() => true}
                              cb={(e) =>{
                                  dataDefault.current.internal = e;
                                  detail.internal = e;
                                  handleFieldUpdates.internal(e);
                                }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="px-3 is-flex is-flex-direction-column is-width-full" >
                      {isDev && <div className="field is-flex">
                        <label className="mr-2">Partner ID:</label>
                        <p>{partner?.id}</p>
                      </div>}
                      <div className='is-flex is-flex-direction-column is-justify-content-flex-end mb-2' style={{ height: "35px" }}>
                        <div className="is-flex is-align-items-flex-end">
                          {areas?.map(area => (
                            <div key={uuidV4()} className={`tab ${view === area ? 'has-background-link has-text-white ' : ''}`} onClick={() => handleView({ newView: area })}>{area}</div>
                          ))}
                        </div>
                      </div>
                      {view === 'Partner Fact Sheet' && <PartnerFactSheet partner={partner} setView={setView} />}
                      {view === 'Product Fact Sheet' && <ProductFactSheet partner={partner} />}
                      {view === 'Reference Library' && <PartnerReference partner={partner} />}
                      {view === 'Contract Abstract' && <ContractAbstract partner={partner} />}
                      {view === 'SP Summary' && <SpSummary partner={partner} partners={allPartners} />}
                    </div>
                }
              </div>
            </div>
        }
        <div className="is-flex is-absolute p-3" style={{ bottom: "0" }}>
          {
            (mode === 'edit' && isPartnerAdmin) ?
              <div className="is-flex">
                <button className="button is-warning mr-2" onClick={cancel} disabled={submitting}>
                  <i className="fa-solid fa-ban mr-2"></i>
                  <span>Cancel</span>
                </button>
                <ApiSubmitButton
                  icon={<i className="fa-solid fa-floppy-disk"></i>}
                  defaultLabel="save"
                  operationLabel="Saving..."
                  action={() => save({ partner: detail })}
                  actionTriggered={actionTriggered}
                  actionDone={actionDone}
                  disabled={!dataIsValid || submitting}
                />
              </div>
              :
              <button className="button mr-2 is-link" onClick={() => navigate('/spd')} style={{ zIndex: "5" }}>
                <span><i className="fa-solid fa-arrow-left mr-2"></i></span>
                <span>Back</span>
              </button>
          }
        </div>
      </>
    )
  }

  // const CrudControls = ({ handleEdit, handleCancel, afterDelete, disabled, isCreate, disableOnSave }) => {
  //   const deletePartner = async (data) => {
  //     const partnerResponse = await api.partner.deletePartner(data.id);
  //     store.partnerUpdated = true;
  //     return partnerResponse;
  //   };

  //   return (
  //     isPartnerAdmin &&
  //     <div className="is-flex is-align-items-center">
  //       {mode === 'edit' ?
  //         <button className="title-button is-warning mr-3 button" onClick={handleCancel} disabled={disabled || disableOnSave}>
  //           <i className="fa-solid fa-ban mr-2"></i>
  //           <span>Cancel</span>
  //         </button>
  //         :
  //         <button className="title-button mr-3 button" onClick={handleEdit} disabled={disabled || disableOnSave}>
  //           <i className="fa-solid fa-pen-to-square mr-2"></i>
  //           <span>Edit</span>
  //         </button>
  //       }
  //       {!isCreate && <AreYouSureDelete
  //         defaultText="Delete Partner"
  //         data={partner}
  //         cb={afterDelete}
  //         icon={<i className="fa-solid fa-trash" />}
  //         action={async () => await deletePartner(partner)}
  //         className="title-button"
  //         disabled={disabled || disableOnSave}
  //       />}
  //     </div>
  //   )
  // };

  return (
    <PageTemplate
      className="is-relative"
      pageTitle="Partner"
      content={<PartnerViewContent
        save={save}
        loading={loading}
        editClicked={editClicked}
        cancelTriggered={cancelTriggered}
        saveClicked={saveClicked}
        partner={partner}
        setPartner={setPartner}
      />}
      setup={setup}
      getPermission={getUserPermissions}
      defaultOpenMenus={["1"]}
      // crudControls={<CrudControls handleEdit={edit} handleCancel={cancel} afterDelete={afterDelete} disabled={loading} isCreate={isCreate} disableOnSave={saveClicked} />}
    />
  )

}