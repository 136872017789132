import Header from "../../components/header";
import SideMenu from "../../components/sidemenu";
import React, { useState, useEffect, useRef, useCallback } from 'react'; // reactive state
import api from '../../api';
import { Table, SelectPicker, Loader, CheckPicker, Tooltip, Whisper, TagPicker } from 'rsuite';
import { orderBy, words, findIndex } from "lodash";
import store, { getUserCognitoGroups } from '../../store';
import PartnerImageCell from '../../components/partners/PartnerImageCell';
import DropCard from "../../components/DropCard";
import InputField from "../../components/TextInput";
import { v4 as uuidV4 } from "uuid";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import { useNavigate } from "react-router-dom";
import RoleColumnEdit from "../../components/partners/RoleColumnEdit";

const _orderBy = orderBy;

const unAssignedPlaceholder = {
  "id": "007",
  "name": "",
  "email": "",
  "title": "Unassigned",
  "department": null,
  "active": 1
};

const defaultColumnWidth = 150;
const defaultRowTitleColumnWidth = 150
const defaultEditColumnWidth = 250;
const defaultRowHeight = 90;
const defaultHeaderHeight = 50;

const addPersonPlaceHolder = async prtList => {
  for (let p of prtList) {
    for (let r of p.partnerRoles) {
      if (!r.personList.length) {
        r.personList.push(unAssignedPlaceholder);
      }
    }
  }
  return prtList
};

export default function PartnersMain() {
  const navigate = useNavigate();
  // SDEV-1257 limit infosec admin privileges
  const [isOnlyInfoSecAdmin, setIsOnlyInfoSecAdmin] = useState(false);
  // check if infosec column
  const checkInfoSec = (p) => {
    if (p.headername.includes('nfo') && p.headername.includes('ec')) {
      return isOnlyInfoSecAdmin;
    }
    return false;
  };

  const isPageAdmin = store.user.groups.includes("partnerResponsibilitiesAdmin");
  const table = useRef();

  const [partners, setPartners] = useState([]); // reactive state
  const [roles, setRoles] = useState([]); // reactive state
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [people, setPeople] = useState([]); // reactive state
  const [selectedPartners, setSelectedPartners] = useState([]); // reactive state
  const [selectedColumns, setSelectedColumns] = useState([]); // reactive state
  const [partnerSelectList, setPartnerSelectList] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // reactive state
  const [peopleFiltered, setPeopleFiltered] = useState(false);
  const [partnerList, setPartnerList] = useState([]);

  const handleScreenSize = (column) => {
    const defaultColumnWidthOverride = ((window.frames.innerWidth - 100) / column.length);
    if (defaultColumnWidthOverride > 150 && column.length) {
      setRoleColumnWidth(defaultColumnWidthOverride);
    }
  };

  const filterTheColumns = useCallback(() => { // useCallback to prevent the data from being one render behind
    const filteredArray = partners.map((item) => ({
      ...item,
      partnerRoles: item.partnerRoles.filter((subitem) => !selectedColumns.some((removeItem) => removeItem === subitem.id))
    }))
    // filter the columns
    setFilteredData(filteredArray);
    // remove filtered roles from the roles array
    const filteredRoles = roles.filter((role) => !selectedColumns.includes(role.id));

    setFilteredRoles(filteredRoles);
    handleScreenSize(filteredRoles);
  }, [partners, selectedColumns, roles])

  const resetColumns = () => {
    setFilteredData([]);
    setRoleColumnWidth(defaultColumnWidth);
  }

  useEffect(() => {// reactive state
    async function fetchData() {
      const infoSecAdmin = await getUserCognitoGroups('infoSecRestrictedAdmin');
      const parallelApiCalls = [];
      parallelApiCalls.push(fetchPeople());
      parallelApiCalls.push(fetchPartners());
      const p = await Promise.all(parallelApiCalls);
      const consolidatedData = p.flat(1)?.filter(item => !!item);
      setIsOnlyInfoSecAdmin(infoSecAdmin);
      const rolesFromPRT = consolidatedData[0]?.partnerRoles.map(pr => ({ id: pr.id, name: pr.name }));
      // override default column width for large screens
      window.onresize = () => { handleScreenSize(rolesFromPRT) };
      handleScreenSize(rolesFromPRT);
      setRoles(rolesFromPRT);
      setLoading(false);
      const partnersTemp = (!store?.partners?.length || store.partnerUpdated) ? await api.partner.getPartners({}) : store?.partners;
      // remove partners from the list that are internal
      const externalPartners = partnersTemp.filter(p => !p.internal);
      setPartnerList([...externalPartners]);
    }
    fetchData().catch(e => e);
  }, []);

  useEffect(() => {
    const filtered = partners.filter(item => selectedPartners.includes(item.id))
    if (selectedColumns.length) {
      filterTheColumns();
    } else {
      setFilteredData(filtered);
    }
  }, [selectedPartners, partners, selectedColumns, filterTheColumns]);

  const handleColumnSelect = (value) => {
    resetFilteredData();
    resetPeople();
    setSelectedColumns(value);
    filterTheColumns();
  };

  const fetchPartners = async () => { // reactive state
    const response = await api.partner.getPartnerResponsibilities();
    const temp = await addPersonPlaceHolder(response);
    // alphabetize partnerList regardless of casing
    _orderBy(temp, 'name.toLowerCase()', 'asc');
    const externalPartners = temp.filter(p => !p.internal);
    setPartners([...externalPartners]);
    setPartnerList([...externalPartners]);
    setPartnerSelectList([...externalPartners]);
    setFilteredData([...externalPartners]);
    return externalPartners;
  };

  const fetchPeople = async () => {
    const response = await api.person.getPersons();
    const activeOnly = response.filter(r => !!r.active);
    const tempPeople = orderBy(activeOnly, 'name', 'asc');
    // add placeholder person to list so edit works.
    tempPeople.push(unAssignedPlaceholder);
    setPeople(tempPeople);
  }

  const { Column, HeaderCell, Cell } = Table;
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(true);

  const sortData = async (sc, st) => {
    const isMappedColumn = sc.includes('RoleColumn');
    if (sc && st) {
      if (isMappedColumn) {
        const customSortColumn = sc.split('RoleColumn')[0];
        for (const partner of partners) {
          for (const role of partner.partnerRoles) {
            if (role.name.includes(customSortColumn)) {
              const personToSortBy = role.personList[0]?.name;
              partner[`sort${sc}`] = personToSortBy.toLowerCase();
            }
          }
        }
        const reordered = orderBy(partners, `sort${sc}`, st);
        setPartners([...reordered]);
      } else {
        for (const partner of partners) partner[`sort${sc}`] = partner[sc].toLowerCase();
        const reordered = orderBy(partners, `sort${sc}`, st);
        setPartners([...reordered]);
      }
    }
    return
  };

  const handleSortColumn = async (sortColumn, sortType) => {
    if (columnEdit) return;
    setLoading(true);
    await sortData(sortColumn, sortType);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  // handleCellEdit logic
  const [editing, setEditCell] = useState({});
  const [originalScrollPos, setOriginalScrollPos] = useState({ x: 0, y: 0 })
  const [roleColumnWidth, setRoleColumnWidth] = useState(defaultColumnWidth);
  const [selectPickerWidth, setSelectPickerWidth] = useState(defaultEditColumnWidth);
  const [scrollPos, setScrollPos] = useState({ x: 0, y: 0 });

  const handleCellEdit = (e, pId, { ...rowData }, { ...props }) => {
    // Prevent the event from bubbling up to the row click handler
    e.stopPropagation();
    resetColumns();

    if (e.target.tagName === 'A') return e.stopPropagation();
    if (props.columnedit === 'true') return;
    // SDEV-1257 limit infosec admin privileges
    const columnIsInfoSec = checkInfoSec(props);

    if (!isPageAdmin && (!isOnlyInfoSecAdmin || !columnIsInfoSec)) return;
    // set display widths    
    const labelWidth = (getLongestText(people, "name")?.name.length) * 6.8;
    setRoleColumnWidth(labelWidth);
    setSelectPickerWidth((labelWidth - 20));
    const originalColWidth = roleColumnWidth; // used to scroll to proper place during edit mode
    // get drop list data
    const cellData = rowData.partnerRoles?.find(partnerRole => partnerRole.name === props.headername);
    const clickedPerson = people.find(p => p.id === pId) || {};
    if (!clickedPerson.id && pId) clickedPerson.id = pId;
    const sendBackData = { rowData, cellData, clickedPerson };
    setEditCell(Object.assign({}, sendBackData));
    // used to scroll to proper place during edit mode
    const columnNumber = findIndex(rowData.partnerRoles, (pr => pr.name === props.headername)) + 1;
    setOriginalScrollPos({ x: scrollPos.x, y: scrollPos.y });
    const editScrollTo = (scrollPos.x + ((labelWidth - originalColWidth) * columnNumber));
    calculateRowHeight(rowData);
    setTimeout(() => {
      table.current.scrollTop(scrollPos.y);
      columnNumber === 1 ? table.current.scrollLeft(0) : table.current.scrollLeft(editScrollTo);
    }, 100)
  };

  const getLongestText = (list, key) => list.reduce((a, b) => b[key].length > a[key].length ? b : a);

  const pushUnassignedToPersonList = (e, { ...rowData }, { ...props }, cb) => {
    async function pushUnassignedToPersonListAsync() {
      const role = roles.find(pr => pr.name === props.headername);
      const partnerIndex = findIndex(partners, p => p.id === rowData.id);
      const partnerRoleListIndex = findIndex(partners[partnerIndex]?.partnerRoles, pr => pr.id === role.id);
      const tempPersonList = partners[partnerIndex].partnerRoles[partnerRoleListIndex].personList;
      tempPersonList.push({ ...unAssignedPlaceholder });
      setPartners([...partners]);
      cb(e, "007", rowData, props);
    }
    pushUnassignedToPersonListAsync().catch(e => e);
  };

  const PartnerRolesCell = ({ rowData, dataKey, ...props }) => {

    const Plus = ({ rowData, ...props }) => {

      let hidePlus = false;
      const partnerIndex = findIndex(partners, p => p.id === rowData.id);
      const partnerRoleListIndex = findIndex(rowData.partnerRoles, pr => pr.name === props.headername);
      if (partnerRoleListIndex > -1) {
        const tempPersonList = partners[partnerIndex].partnerRoles[partnerRoleListIndex].personList;
        hidePlus = tempPersonList.find(pl => pl.title === 'Unassigned')?.id;
      }
      // SDEV-1257 limit infosec admin privileges
      if (!editing.rowData && !hidePlus && (isPageAdmin || !!checkInfoSec(props))) {
        return (
          <div className="ml-2" onClick={(e) => pushUnassignedToPersonList(e, rowData, props, handleCellEdit)}>
            <div className="table-cell-add-button is-clickable cell-item is-flex is-justify-content-center is-align-items-center">
              <i className="is-size-7 fa-solid fa-plus " />
            </div>
          </div>
        )
      }
    };

    const EditCellComponent = ({ ...props }) => {
      const [imSure, setImSure] = useState(false);
      const [saveDisable, setSaveDisable] = useState(true);
      const [selectedPersonId, setSelectedPersonId] = useState("");

      const updateSelection = id => {
        if (id) {
          setSaveDisable(false);
          setSelectedPersonId(id);
          setImSure(false);
        }
      };

      const cancelEdit = () => {
        if (!editing.cellData.personList?.length) {
          editing.cellData.personList.push(unAssignedPlaceholder);
        } else if(editing.cellData.personList?.length > 1) {
          const unassignedIndex = findIndex(editing.cellData.personList, pl => pl.title === 'Unassigned');
          if (unassignedIndex > -1) {
            editing.cellData.personList.splice(unassignedIndex, 1);
          }
        }
        setImSure(false);
        setRoleColumnWidth(defaultColumnWidth);
        setEditCell(Object.assign({}));
        table.current.scrollTop(originalScrollPos.y);
        table.current.scrollLeft(originalScrollPos.x);
        const filteredRoles = roles.filter((role) => !selectedColumns.includes(role.id));
        handleScreenSize(filteredRoles);
      };

      const ActionButtonsComponent = () => {
        const [saving, setSaving] = useState(false);
        const [hideDelete, setHideDelete] = useState(!editing.clickedPerson?.id || editing.clickedPerson?.id === "007");

        const save = () => {
          async function saveAsync() {
            if (!isPageAdmin && !isOnlyInfoSecAdmin) throw Object.assign(new Error("Operation not permitted."));
            if (!selectedPersonId && !imSure) throw Object.assign(new Error("The system failed to record the selected person's ID. Verify you are selecting a different person than the original, then please try again."));
            setSaving(true);
            const originalPersonIdx = findIndex(editing.cellData.personList, p => p.id === editing.clickedPerson.id); // need to variablize this so we can account for multiple people
            const newPerson = imSure ? null : Object.assign({}, people.find(person => person.id === selectedPersonId));
            const prtToUpdate = editing.rowData.partnerRoles.find(partnerRole => partnerRole.id === editing.cellData.id);
            if (newPerson) {
              prtToUpdate.personList[originalPersonIdx] = newPerson;
            } else {
              prtToUpdate.personList.splice(originalPersonIdx, 1);
            }

            // update the partnerRole in editing.rowData !! either of these work !!
            const partner = partners.find(p => p.id === editing.rowData.id);
            const body = {
              id: editing.rowData.id,
              partnerRoles: structuredClone([...partner.partnerRoles]),
            };

            // remove "unassigned" from personList to prevent 500 error (foreign key).
            const unassignedPeople = body?.partnerRoles?.filter(pr =>pr.personList?.find(pl => pl.title === 'Unassigned' || pl.id === '007'));
            for (let pr of unassignedPeople) {
              const toReplaceIdx = body?.partnerRoles?.findIndex(partnerRole => partnerRole?.id === pr?.id);
              body.partnerRoles[toReplaceIdx].personList = [];
            }

            // You can see what we are sending by logging id: editing.rowData.id, raw updated data: editing.rowData and cleaned data: body
            try {
              await api.partner.updatePartnerResponsibilities({ id: editing.rowData.id, body });
            } catch (error) {
              // reverting selection back to original on error
              prtToUpdate.personList[originalPersonIdx] = editing.clickedPerson;
              throw new Error(error);
            } finally {
              if (imSure && !prtToUpdate.personList.length) {
                prtToUpdate.personList.push(unAssignedPlaceholder);
              }
              setSaving(false);
              cancelEdit();
            }
          }
          saveAsync().catch(e => e);
        }

        const AreYouSureComponent = () => {
          if (!hideDelete) {
            if (imSure) {
              return <button className="button is-danger is-size-7 ml-2" onClick={() => {
                setHideDelete(true);
                save();
              }}>Are you sure?</button>
            } else {
              return <button className="button is-danger is-size-7 ml-2" onClick={() => setImSure(true)}>Delete</button>
            }
          }
        };

        const SaveBtnComponent = () => {
          if (!saving) {
            return <button className="button is-info is-size-7 ml-2" onClick={save} disabled={saveDisable}>Save</button>
          } else {
            return <button className="button is-info-light is-size-7 ml-2"><Loader></Loader> Saving</button>
          }
        };

        return (
          <>
            <SaveBtnComponent />
            <AreYouSureComponent />
          </>
        )
      };

      const p = props.data;

      return (
        <div key={`select-${p.id}`}>
          <SelectPicker
            label="People"
            defaultValue={p.id}
            data={people}
            labelKey="name"
            valueKey="id"
            style={{ width: selectPickerWidth }}
            onChange={updateSelection}
          />
          <div className="is-flex is-justify-content-flex-start is-align-items-center py-2" style={{ width: selectPickerWidth }}>
            <button className="button is-size-7" onClick={() => {
              if (editing.clickedPerson?.id) {
                const partnerIndex = findIndex(partners, partner => partner.id === rowData.id);
                const partnerRoleListIndex = findIndex(rowData.partnerRoles, pr => pr.name === props.headername);
                if (partnerRoleListIndex > -1) {
                  const tempPersonList = partners[partnerIndex].partnerRoles[partnerRoleListIndex].personList;
                  const unassignedIndex = findIndex(tempPersonList, pl => pl.title === 'Unassigned');
                  if (unassignedIndex > -1) {
                    tempPersonList.splice(unassignedIndex, 1);
                  }
                }
              }
              cancelEdit()
            }}>cancel</button>
            <ActionButtonsComponent />
          </div>
        </div>
      )
    };

    return (
      <Cell {...props} key={`cell-${rowData.id}`}>
        {
          rowData && rowData.partnerRoles.map((partnerRole, index1) => {
            const personAssignedToRole = partnerRole?.name === props.headername ? partnerRole.personList || [] : [];

            return (
              personAssignedToRole && personAssignedToRole.map((p, index2) => {
                const toolTip = (
                  <Tooltip>
                    <div>{p?.title}</div>
                    <div>{p?.department}</div>
                  </Tooltip>
                );
                if ((props.headername === editing.cellData?.name && rowData.id === editing.rowData?.id && p.id === editing.clickedPerson?.id) && props.columnedit === 'false') {
                  return (
                    <EditCellComponent {...props} data={p} key={`editcell-${p.id}-${index2}`} />
                  )
                } else {
                  return (
                    p.title === 'Unassigned' ?
                      <div key={`person-${p.id}-${index1}-${index2}`} className="p-2 mb-2 cell-item" onClick={(e) => handleCellEdit(e, p.id, { ...rowData }, { ...props })}>{p.title}</div>
                      :
                      <Whisper key={`person-${p.id}-${index1}-${index2}`} placement="top" controlId="control-id-hover" trigger="hover" speaker={toolTip}>
                        <div className="p-2 mb-2 cell-item" onClick={(e) => handleCellEdit(e, p.id, { ...rowData }, { ...props })}>
                          <a href={`mailto:${p?.email}`}>{p?.name}</a>
                        </div>
                      </Whisper>
                  )
                }
              })
            )
          })
        }
        {
          props.columnedit === 'false' &&
          <Plus {...props} rowData={rowData} key={`plus-${rowData.id}`} />
        }
      </Cell>
    )
  };

  const calculateRowHeight = rowData => {
    const row = {
      defaultRowHeight,
      overrideHeight: null
    };
    if (rowData?.partnerRoles) {
      for (let role of rowData.partnerRoles) {
        if (role.personList?.length > 1) {
          row.overrideHeight = row.overrideHeight > row.defaultRowHeight * role.personList?.length ?
            row.overrideHeight :
            row.defaultRowHeight * role.personList?.length;
        }
      }
      return row.overrideHeight ? row.overrideHeight : row.defaultRowHeight;
    } else {
      return row.defaultRowHeight;
    }
  };

  const tableFilter = a => {
    async function tableFilterAsync() {
      setLoading(true);
      setPeopleFiltered(true);
      setSelecPickerValue(a);
      const filteredTableData = [];
      const externalPartners = store.partnerResponsibilities.filter(p => !p.internal);
      // Do async magic
      for (let p of externalPartners) {
        for (let r of p.partnerRoles) {
          for (let person of r.personList) {
            if (person.id === a) {
              filteredTableData.push(p);
            }
          }
        }
      }
      setPartners([...filteredTableData]);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
    tableFilterAsync().catch(e => e);
  }

  const handleParnerSelect = (value) => {
    setSelectedPartners(value);
    const filtered = partners.filter(item => selectedPartners.includes(item.id));
    setFilteredData(filtered);
  }

  const resetTable = () => {
    setLoading(true);
    setSelecPickerValue([]);
    const externalPartners = store.partnerResponsibilities.filter(p => !p.internal);
    setPartners([...externalPartners]);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  //drop card stuff
  const [dropCardActive, setDropCardActive] = useState(false);
  const [newRole, setNewRole] = useState("");
  const [saving, setSaving] = useState(false);
  const [savingDone, setSavingDone] = useState();

  const handleDropCardShow = () => {
    setDropCardActive(!dropCardActive);
  };

  const submitNewRoleAction = async () => {
    setSaving(true);
    const body = {
      name: newRole
    };
    let response = await api.partner.createNewPartnerRole({ body });
    setSavingDone(true);
    return response

  };

  const afterSave = async () => {
    setSaving(false);
    handleDropCardShow();
    setNewRole("");
    navigate(0);
  }

  const cSSVars = {
    dropBackgroundColor: 'rgb(85 85 141)',
    dropBorderColor: 'rgb(179 179 220)',
    dropHeight: '173px',
    dropCardButtonHeight: "25px",
    dropCardButtonWidth: "114px"
  };

  const dropCardActions = [
    {
      id: uuidV4(),
      text: "Cancel",
      className: "is-warning",
      icon: <i className="fa-solid fa-ban"></i>,
      disabled: saving,
      action() {
        handleDropCardShow();
        setNewRole("");
      }
    },
    {
      id: uuidV4(),
      isCustom: (
        <ApiSubmitButton
          defaultLabel="Save Role"
          operationLabel="Saving"
          action={() => submitNewRoleAction()}
          disabled={!!!newRole}
          cb={afterSave}
          // used to force correct rendering when parent re-renders
          actionTriggered={saving}
          actionDone={savingDone}
        />
      )
    }
  ];

  /* EDIT COLUMN HEADERS */
  const [columnEdit, setColumnEdit] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(defaultHeaderHeight);

  const editColumnToggle = () => {
    async function editColumnToggleAsync() {
      const sortGraphicsList = document.querySelectorAll('.rs-table-cell-header-sort-wrapper');
      if (!columnEdit) {
        for (let item of sortGraphicsList) {
          item.style["display"] = "none";
        }
        setRoleColumnWidth(230);
        setHeaderHeight(120);
        setColumnEdit(true);
      } else {
        for (let item of sortGraphicsList) {
          item.style = {};
        }
        setHeaderHeight(defaultHeaderHeight);
        setRoleColumnWidth(defaultColumnWidth);
        setColumnEdit(false);
      }
    };
    editColumnToggleAsync().catch(e => e);
  }

  const goToPRMin = () => {
    navigate('/partnerresponsibilities/min');
  }

  const resetFilteredData = () => {
    setSelectedColumns([]);
    setFilteredData([]);
    setSelectedPartners([]);
  }

  const resetPeople = () => {
    setSelectedColumns([]);
    if (peopleFiltered) {
      resetTable();
      setSelecPickerValue([]);
      setPeopleFiltered(false);
    }
  }

  const [selectPickerValue, setSelecPickerValue] = useState([]);

  return (
    <>
      <Header></Header>
      <div className="is-flex" style={{ height: "100%" }}>
        <SideMenu defaultOpenMenus={["1"]}></SideMenu>
        <section className="is-flex-grow-1 is-flex is-flex-direction-column">
          <h4 className="px-3 pt-2 title-color is-flex is-justify-content-space-between is-align-items-center">
            <span>
              <i className="fa-solid mr-2 fa-user-tie" />
              Partner Responsibilities
            </span>
            {
              ((isOnlyInfoSecAdmin || isPageAdmin) && !loading) &&
              <span className="is-flex is-justify-content-space-between is-align-items-center" style={{ background: "rgb(49, 49, 100)", color: "rgb(179 179 220)" }}>
                <button className="title-button mr-2" onClick={editColumnToggle}>Edit Roles</button>
                <DropCard
                  permission={isPageAdmin}
                  handleDropCardShow={handleDropCardShow}
                  dropCardActive={dropCardActive}
                  triggerButtonText="Add new role"
                  cSSVars={cSSVars}
                  actions={dropCardActions}
                  content={
                    <div className="field">
                      <InputField
                        label="role name"
                        className="mt-2"
                        value={newRole}
                        name="role-name"
                        placeholder="Enter role"
                        type="text"
                        onChange={(e) => setNewRole(e.target.value)}
                        maxlength={50}
                        disabled={saving}
                        autoComplete="off"
                        controlClass="is-full-width mb-3"
                      />
                    </div>
                  }
                />
              </span>
            }
          </h4>
          <div className="m-3 full-table-height">
            <div className="columns" style={{ marginBottom: -10 }}>
              <div className="column">
                <SelectPicker
                  label="People"
                  data={people}
                  labelKey="name"
                  valueKey="id"
                  onSelect={tableFilter}
                  onClean={resetTable}
                  className="mb-3 select-picker"
                  onOpen={resetFilteredData}
                  value={selectPickerValue}
                />
                <CheckPicker
                  data={partnerSelectList}
                  style={{ width: 224, marginLeft: 12 }}
                  className="mb-3 select-picker"
                  labelKey="name"
                  valueKey="id"
                  onClean={resetTable}
                  onChange={handleParnerSelect}
                  multiple
                  value={selectedPartners}
                  label="Partners"
                  onOpen={resetPeople}
                />
              </div>
              <div className="column has-text-right">
                <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={goToPRMin}>
                  <span>Minimize</span>
                  <span className="icon is-small">
                    <i className="fas fa-minimize is-active" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                Hide columns&nbsp;&nbsp;
                <TagPicker
                  data={roles}
                  labelKey="name"
                  valueKey="id"
                  value={selectedColumns}
                  onChange={handleColumnSelect}
                  onClean={resetColumns}
                  cleanable={true}
                  className="tag-picker"
                />
              </div>
            </div>
            <sub className="mb-3 has-text-grey"><i className="fas fa-info-circle mr-1" aria-hidden="true"></i>Hover over names to view title and department</sub>
            <div className="default-table" style={{ height: "100%" }}>
              <Table
                minHeight={480}
                fillHeight={true}
                data={filteredData.length ? filteredData : partners}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loading}
                headerHeight={headerHeight}
                wordWrap="break-word"
                rowHeight={calculateRowHeight}
                ref={table}
                onScroll={(x, y) => setScrollPos({ x, y })}
              >
                <Column width={defaultRowTitleColumnWidth} fixed sortable resizable verticalAlign={'middle'}>
                  <HeaderCell>Partner</HeaderCell>
                  <PartnerImageCell dataKey="name" partners={partnerList} className="is-size-6" calculaterowheight={calculateRowHeight} />
                </Column>
                {
                  selectedColumns.length && filteredRoles.map(role => {
                    if (!role.editValue) {
                      role.editValue = "";
                    }
                    const isInfoSec = (role.name?.toLowerCase().includes('info') && role.name?.toLowerCase().includes('sec'));
                    return (
                      <Column resizable width={roleColumnWidth} key={role.id} verticalAlign={'middle'} sortable>
                        <HeaderCell>
                          {
                            (columnEdit && !isInfoSec && isPageAdmin) || (columnEdit && isInfoSec && isOnlyInfoSecAdmin) ?
                              <RoleColumnEdit role={role} columnToggleAction={editColumnToggle} />
                              :
                              words(role.name).join(' ')
                          }
                        </HeaderCell>
                        <PartnerRolesCell headername={role.name} dataKey={`${role.name}RoleColumn`} columnedit={columnEdit.toString()} />
                      </Column>
                    )
                  })
                }
                {
                  !selectedColumns.length && roles && roles.map(role => {
                    if (!role.editValue) {
                      role.editValue = "";
                    }
                    const isInfoSec = (role.name?.toLowerCase().includes('info') && role.name?.toLowerCase().includes('sec'));
                    return (
                      <Column resizable width={roleColumnWidth} key={role.id} verticalAlign={'middle'} sortable>
                        <HeaderCell>
                          {
                            (columnEdit && !isInfoSec && isPageAdmin) || (columnEdit && isInfoSec && isOnlyInfoSecAdmin) ?
                              <RoleColumnEdit role={role} columnToggleAction={editColumnToggle} />
                              :
                              words(role.name).join(' ')
                          }
                        </HeaderCell>
                        <PartnerRolesCell headername={role.name} dataKey={`${role.name}RoleColumn`} columnedit={columnEdit.toString()} />
                      </Column>
                    )
                  })
                }
              </Table>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
