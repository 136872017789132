import { startCase } from "lodash";
import { useState, useEffect } from "react";
import microservices, { usd } from "../services/microservices";

const InputField = ({
  value,
  name,
  placeholder,
  type,
  onChange,
  maxlength,
  className,
  errorMessage,
  disabled,
  autoComplete,
  iterateKey,
  label,
  controlClass,
  id,
  style,
  onBlur,
  onFocus,
  allowZero,
  limit,
}) => {
  const [moneyDisplayValue, setMoneyDisplayValue] = useState(value);
  useEffect(() => {
    if (type === "money") {
      setMoneyDisplayValue(value);
    }
  }, [type, value]);

  return (
    <div className={`control mt-3 ${controlClass || ""}`}>
      {type === "textarea" ? (
        <div className={label ? "is-flex is-flex-direction-column" : ""}>
          {label && <label htmlFor={name} style={{ fontWeight: 400, fontSize: 16 }}>{startCase(label.toLowerCase())}</label>}
          <textarea
            type={type}
            value={value}
            name={name}
            className={`textarea ${className || ""}`}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={maxlength}
            disabled={disabled}
            autoComplete={autoComplete}
            key={iterateKey}
            id={id}
            style={style}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </div>
      ) : type === "money" ? (
        <div className={label ? "is-flex is-flex-direction-column" : ""}>
          {label && <label htmlFor={name} style={{ fontWeight: 400, fontSize: 16 }}>{startCase(label.toLowerCase())}</label>}
          <div className="is-relative">
            <div className="is-absolute" style={{ zIndex: 1, top: "9px", left: "10px" }} disabled={disabled}>
              $
            </div>
            <div className={`input ${className || ""}`} style={style} disabled={disabled}>
              <input
                type={type}
                value={moneyDisplayValue}
                name={name}
                className="custom-money-input"
                placeholder={placeholder}
                onChange={async (e) => {
                  const validNumberOfDecimals = (val) => {
                    if (val?.includes(".")) {
                      const periodCount = val.split("")?.filter((char) => char === ".")?.length;
                      if (periodCount > 1) {
                        return false;
                      }
                      // spit it by . to only run regex on cents
                      const splitVal = val.split(".");
                      const centsRegex = /^[0-9.]+$/;
                      const tempLast = splitVal.length - 1;
                      if (splitVal[tempLast]) {
                        if (!centsRegex.test(splitVal[tempLast])) {
                          return false;
                        }
                      }
                    }
                    return true;
                  };
                  const currencyRegex = /^[0-9,.]*$/;
                  let valid = currencyRegex.test(e.target.value);
                  if (valid && limit) {
                    const exceedsLimit = ({ val, limit }) => {
                      const numVal = typeof val === "string" ? microservices.stringToNumber(val) : val;
                      const numLimit = typeof limit === "string" ? microservices.stringToNumber(limit) : limit;
                      return numVal > numLimit;
                    };
                    if (exceedsLimit({ val: e.target.value, limit })) {
                      e.target.value = "";
                      valid = false;
                      return;
                    }
                  }
                  // check if there is already a "." and prevent more
                  if (valid) {
                    valid = validNumberOfDecimals(e.target.value);
                  }
                  // check that any commas have 3 trailing digits before allowing another comma
                  if (valid) {
                    if (e.target.value?.includes(",")) {
                      if (e.target.value[0] === "," || e.target.value?.includes(",,") || e.target.value?.includes(",.")) return (valid = false);
                      const commaSeparated = e.target.value?.split(",");
                      commaSeparated.forEach((item, idx) => {
                        if (!item) return;
                        if (item?.includes(".")) {
                          valid = item.length <= 6;
                        } else {
                          valid = item.length <= 3;
                        }
                        if (valid) {
                          if (idx > 0 && idx < commaSeparated.length - 1) {
                            valid = item.length >= 3;
                            return;
                          } else {
                            valid = !!item.length;
                            return;
                          }
                        }
                        return;
                      });
                    }
                  }
                  if (valid) {
                    if (e.target.value.includes(".")) {
                      const decimalLength = e.target.value.split(".")[1].length;
                      if (decimalLength <= 2) {
                        value = e.target.value;
                        setMoneyDisplayValue(e.target.value);
                      } else {
                        console.error("invalid character");
                        e.target.value = value;
                        return;
                      }
                    } else {
                      value = e.target.value;
                      setMoneyDisplayValue(e.target.value);
                    }
                  } else {
                    console.error("invalid character");
                    e.target.value = value;
                    return;
                  }

                  if (onChange) {
                    onChange(e);
                  }
                }}
                maxLength={maxlength}
                disabled={disabled}
                autoComplete={autoComplete}
                key={iterateKey}
                id={id}
                onBlur={() => {
                  // solve for empty string in input
                  if (allowZero) {
                    const isNotANumber = isNaN(microservices.stringToNumber(value));
                    if (!value || isNotANumber) value = 0;
                    // format display value
                    value = typeof value === "string" ? microservices.stringToNumber(value).toFixed(2) : value.toFixed(2);
                    // add in commas if they are missing
                    value = usd.format(value).split("$")[1];
                    setMoneyDisplayValue(value);
                  }
                  if (onBlur) {
                    onBlur();
                  }
                }}
                onFocus={() => {
                  const input = document.getElementById(id);
                  input.focus();
                  input.select();
                  if (onFocus) {
                    onFocus();
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={label ? "is-flex is-flex-direction-column" : ""}>
          {label && <label htmlFor={name} style={{ fontWeight: 400, fontSize: 16 }}>{typeof label === "string" ? startCase(label.toLowerCase()) : label}</label>}
          <input
            type={type}
            value={value}
            name={name}
            className={`input ${className || ""}`}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={maxlength}
            disabled={disabled}
            autoComplete={autoComplete}
            key={iterateKey}
            id={id}
            style={style}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </div>
      )}
      {maxlength && (
        <span>
          <span className="char-counter">
            {value?.length || 0}/{maxlength}
          </span>
          <sub className="ml-3 has-text-danger">{errorMessage}</sub>
        </span>
      )}
    </div>
  );
};

export default InputField;
