import { apiFetch } from ".";
import store from '../store';
import orderPartners from "../services/orderPartnerList";

const getPartner = async ({ id, endpoint = `partners/${id}/?products=true`, iStore = store }) => {
  if (!id) {
    const message = "Unable to get this partner. Partner ID can not be found.";
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message)
  }
  const response = await apiFetch({ endpoint });
  if (!response) return "no response from api";
  return response;
};

// get partner and answers
const getPartnerDetails = async ({ id, endpoint = `/products?partnerId=${id}`, iStore = store }) => {
  if (!id) {
    const message = "Unable to get this partner. Partner ID can not be found.";
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message)
  }
  const response = await apiFetch({ endpoint });
  if (!response) return "no response from api";
  return response;
};

export const getPartners = async ({ iStore = store }) => {
  store.apiCalls.partner.push("getPartners");
  const response = await apiFetch({ endpoint: `partners/?products=true&includeManager=true&includeInternal=true` });
  if (!response) throw new Error("no response from api");
  const ordered = await orderPartners(response, 'asc');
  iStore.partners = [...ordered];
  store.apiCalls.partner.splice(store.apiCalls.partner.indexOf("getPartners"), 1);
  return iStore.partners;
};

export const createNewPartner = async ({ body }, iStore = store) => {
  console.log('create partner body', body);
  const response = await apiFetch({ endpoint: 'partners', method: 'POST', body });
  if (!response) return;
  await getPartner({ id: response?.id });
  console.log('created: ', response);
  return response;
};

export const updatePartner = async ({ id, body, iStore = store }) => {
  if (!id) {
    const message = "Unable to update this partner. Partner ID can not be found.";
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message)
  }
  const response = await apiFetch({ endpoint: `partners/${id}`, method: 'PUT', body });
  if (!response) throw new Error("no response from api");
  const withImages = await getPartner({ id: response?.id });
  return withImages;
};

export const deletePartner = async (id, iStore = store) => {
  if (!id) {
    const message = "Unable to delete this partner. Partner ID can not be found.";
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message)
  }
  const response = await apiFetch({ endpoint: `partners/${id}`, method: 'DELETE', body: {} });
  if (!response) return;
  iStore.partners = [];
  iStore.havePartnerImagesFromDB = null;
  console.log('deleted: ', response);
  return response;
}

export const getPartnerResponsibilities = async (iStore = store) => {
  const response = await apiFetch({ endpoint: 'partnerresponsibilities' });
  if (!response) return;
  const reorder = await orderPartners(response, 'asc');
  iStore.partnerResponsibilities = [...reorder];
  return reorder;
};

export const getPartnerRoles = async (iStore = store) => {
  const response = await apiFetch({ endpoint: 'partnerroles' });
  if (!response) return;
  iStore.partnerRoles = [...response];
  return response;
};

export const updatePartnerResponsibilities = async ({ id, body }) => {
  if (!id) return Promise.reject('ID is required: Field: "id" is missing from this request.');
  const response = await apiFetch({ endpoint: `partnerresponsibilities/${id}`, method: 'PUT', body });
  return response;
};

export const createNewPartnerRole = async ({ body, iStore = store }) => {
  const response = await apiFetch({ endpoint: `partnerroles`, method: 'POST', body });
  if (!response) throw new Error("no response from create new role");
  await getPartnerRoles();
  return response;
};

export const deletePartnerRole = async ({ id, iStore = store }) => {
  if (!id) {
    const message = "Unable to delete this partner role. Partner role ID can not be found.";
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message)
  }
  const response = await apiFetch({ endpoint: `partnerroles/${id}`, method: 'DELETE', body: {} });
  if (!response) return;
  await getPartnerRoles({});
  console.log('deleted: ', response);
  return response;
};

export const updatePartnerRole = async ({ id, body, iStore = store }) => {
  if (!id) {
    const message = `Unable to update ${body?.name}. Partner role ID can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message)
  }
  const response = await apiFetch({ endpoint: `partnerroles/${id}`, method: 'PUT', body });
  if (!response) return;
  await getPartnerRoles({});
  return response;
};

const endpoints = {
  get: getPartner,
  getPartners,
  createNewPartner,
  updatePartner,
  deletePartner,
  getPartnerResponsibilities,
  getPartnerRoles,
  updatePartnerResponsibilities,
  createNewPartnerRole,
  deletePartnerRole,
  updatePartnerRole,
  getPartnerDetails
};

export default endpoints;