import { useNavigate } from 'react-router-dom';

export default function PortalLink(props) {
  const navigate = useNavigate();
  const goToPath = (path = '/') => {
    navigate(path);
  };
  return (
    <section className={`card is-flex is-justify-content-center is-align-item-center is-flex-wrap-wrap ${props.className} mx-auto`} style={{ width: "600px" }}>
      <img className="m-5 info-line-height" src={props.image} alt={props.alt} />
      <div className='m-5 is-flex is-flex-direction-column is-justify-content-space-around info-line-height'>
        <h3>{props.title}</h3>
        <div className='is-size-6' style={{ lineHeight: "42px" }}>{props.description}</div>
        {props?.isExternalLink ? <button className="button is-info" onClick={() => window.open(props.link, '_blank')} style={{ height: "42px" }}>
          {props.buttontext}
        </button>
          :
          <button className="button is-info" onClick={() => goToPath(props.linkpath)} style={{ height: "42px" }}>{props.buttontext}</button>}
      </div>
    </section>
  )
}