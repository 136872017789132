import Header from "../../components/header";
import SideMenu from "../../components/sidemenu";
import React, { useState, useEffect, useCallback, useRef } from 'react';
import api from '../../api';
import { Loader } from 'rsuite';
import InputField from "../../components/TextInput";
import TopDrawer, { handleShowDrawer } from "../../components/TopDrawer";
import ApiSubmitButton from '../../components/buttons/ApiSubmitButton';
import { v4 as uuidV4 } from 'uuid';
import { getUserCognitoGroups } from '../../store';
import Modal from '../../components/modal';

const FormContent = ({ id, sectionName, handleSectionName, handleSort, sort, disableFields }) => (
  <div className="px-3">
    <div className="is-full faq-q-row">
      <label className="label">Section Name:</label>
      <InputField
        type="text"
        value={sectionName}
        placeholder="Section Name"
        name="section"
        maxlength="50"
        disabled={disableFields}
        onChange={e => handleSectionName(e)}
      />
    </div>
    <div className="columns faq-q-row">
      <div className="column is-one-fifth">
        <label className="label">Sort Order:</label>
        <input onChange={e => handleSort(e)} className="input" type="number" placeholder="1" value={sort} disabled={disableFields}></input>
      </div>
    </div>
    {id &&
      <p>Edit Section ID: {id}</p>
    }
  </div>
)

export default function Section() {
  const [saving, setSaving] = useState(false);
  const [faqAdmin, setFaqAdmin] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [id, setId] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sectionName, setSectionName] = useState('');
  const [sort, setSort] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [canDeleteSection, setCanDeleteSection] = useState(true);
  const [modalActions, setModalActions] = useState([]);

  // refs and variables
  const originalEditData = useRef({});

  // functions and callbacks
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const checkChangePending = useCallback(({ detail }) => {
    const pending = (detail.id === originalEditData.current.id) &&
      (detail.name === originalEditData.current.name) &&
      (Number(detail.sortOrder) === Number(originalEditData.current.sortOrder));
    setChangePending(!pending);
    return !pending;
  }, []);

  const checkIfFormIsValid = useCallback(() => {
    let pending;
    if (editMode) {
      pending = checkChangePending({
        detail: {
          id,
          name: sectionName,
          sortOrder: sort
        }
      });
      const validCondition = !!sectionName && !!sort && !!pending;
      setFormValid(validCondition);
    } else {
      if (sectionName && sort) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }
  }, [id, sectionName, sort, checkChangePending, editMode]);

  const handleModalClose = useCallback(() => { setShowModal(false) }, []);

  const handleSectionName = (e) => {
    setSectionName(e.target.value);
    checkIfFormIsValid();
  }

  const handleSort = (e) => {
    setSort(e.target.value);
    checkIfFormIsValid();
  }

  const clearForm = () => {
    setChangePending(false);
    setEditMode(null);
    setSectionName('');
    setId('');
    setSort('');
    setDisableFields(false);
    handleShowDrawer({ showDrawer, setShowDrawer, cancelActions: () => null });
  }

  function handleEdit({ id, sections }) {
    // const faqList = faqs.length ? faqs : apiFaqs;
    // get the section to edit
    const result = sections?.filter(val => val.id.includes(id));
    if (result?.length) {
      setId(result[0].id);
      setSectionName(result[0].name);
      setSort(result[0].sortOrder);
      originalEditData.current = structuredClone({ ...result[0] });
      setEditMode(true);
      setShowDrawer(true);
    }
  };

  const submit = async () => {
    setSaving(true);
    setDisableFields(true);
    const body = {
      name: sectionName,
      sortOrder: sort
    };
    !id ? await api.faqs.createSection({ body }) : await api.faqs.updateSection(id, { body });
    setActionDone(true);
    return new Promise((resolve) => {
      setTimeout(() => {
        setActionDone(false);
        setSaving(false);
        setDataUpdate(!dataUpdate);
        resolve();
      }, 1500);
    });
  }

  let confirmDeleteSection = ({id, faqs}) => {
    if (faqs.length > 0) {
      setCanDeleteSection(false);
      setModalActions([
        {
          'id': uuidV4(),
          'buttonClass': 'button',
          'background': '',
          'color': '',
          'text': 'Close',
          'iconClass': 'fa-solid fa-times',
          'action': handleModalClose
        }
      ]);
    } else {
      setCanDeleteSection(true);
      setModalActions([
        {
          'id': uuidV4(),
          'buttonClass': 'button is-danger',
          'background': '',
          'color': '',
          'text': 'Yes. Delete It!',
          'iconClass': 'fa-solid fa-trash',
          'action': () => deleteSection({id})
        },
        {
          'id': uuidV4(),
          'buttonClass': 'button is-warning',
          'background': '',
          'color': '',
          'text': 'Cancel',
          'iconClass': 'fa-solid fa-ban',
          'action': handleModalClose
        }
      ]);
    }

    setShowModal(true);
  }

  const deleteSection = ({id}) => {
    async function deleteSectionAsync() {
      handleModalClose();
      const response = await api.faqs.deleteSection(id);
      if (response) {
        setDataUpdate(!dataUpdate);
      } else {
        alert('Error trying to delete');
      }
    }
    deleteSectionAsync().catch(e => e);
  };

  //setup
  async function getFaqAdmin() {
    const isFaqAdmin = await getUserCognitoGroups('faqAdmin');
    setFaqAdmin(isFaqAdmin);
  }

  useEffect(() => {
    checkIfFormIsValid();
  }, [sectionName, sort, checkIfFormIsValid]);

  useEffect(() => {
    const fetchSections = async () => {
      setIsLoading(true);
      const response = await api.faqs.getSections();
      response.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);
      // need to get the fields for question we're presumably editing
      setSections(response);
      await getFaqAdmin();
      setIsLoading(false);
    };
    fetchSections().catch(e => e);
    // eslint-disable-next-line
  }, [dataUpdate, params.id]);

  // Top drawer
  const [showDrawer, setShowDrawer] = useState(false);
  const [changePending, setChangePending] = useState(false);
  const [actionDone, setActionDone] = useState();
  const [disableFields, setDisableFields] = useState(false);

  const drawerActions = [
    {
      id: uuidV4(),
      text: "Cancel",
      className: "is-warning",
      icon: <i className="fa-solid fa-ban"></i>,
      disabled: disableFields,
      action: clearForm
    },
    {
      id: uuidV4(),
      isCustom: (
        <ApiSubmitButton
          defaultLabel={editMode ? `Update Section` : `Save Section`}
          operationLabel="Saving"
          action={submit}
          disabled={!formValid}
          cb={clearForm}
          // // used to force correct rendering when parent re-renders
          actionTriggered={saving}
          actionDone={actionDone}
        />
      )
    }
  ];
  // **** Top drawer

  return (
    <>
      <Header></Header>
      <div className="page-container">
        <SideMenu defaultOpenMenus={["2"]}></SideMenu>
        <div className="page-contents-container is-width-full">
          <div className="page-header-container">
            <h4 className="px-3 pt-2 title-color is-flex is-justify-content-space-between is-align-items-center">
              <span>FAQ</span>
              {
                faqAdmin && !isLoading &&
                <div className="drop-card-anchor is-flex" onClick={() => handleShowDrawer({ showDrawer, setShowDrawer, cancelActions: clearForm })}>
                  {
                    showDrawer ?
                      <button className="mb-1 has-icon-left button is-warning" style={{ height: "25px", fontWeight: "normal" }} disabled={disableFields}>
                        <i className="fa-solid fa-ban mr-2"></i>
                        <span>Cancel</span>
                      </button>
                      :
                      <button className="has-icon-left title-button">Create Section</button>
                  }
                </div>
              }
            </h4>
          </div>
          <div className="is-relative">
            <TopDrawer
              positionOverride={'absolute'}
              maxDrawerHeight={"50vh"}
              show={showDrawer}
              title={editMode ? `Edit Section` : `New Section`}
              body={
                <FormContent
                  id={id}
                  sectionName={sectionName}
                  handleSectionName={handleSectionName}
                  sections={sections}
                  sort={sort}
                  handleSort={handleSort}
                  disableFields={disableFields}
                />
              }
              actions={drawerActions}
              drawerBodyHeight={"30vh"}
              changePending={changePending}
            />
          </div>
          {isLoading ?
            <div className="is-full"><Loader center size="sm" content={`Loading Sections...`} /></div>
            :
            <div className="page-contents">
              <h2 className="ml-2">Sections</h2>
              <div className="columns faq-sections-list-item-header" style={{ marginTop: "-20px" }}>
                <div className="column is-8"><span style={{ marginLeft: "-20px" }}>Section Name</span></div>
                <div className="column is-1 has-text-centered">Items</div>
                <div className="column is-1 has-text-centered">Sort</div>
                <div className="column is-1 has-text-centered"></div>
                <div className="column is-1 has-text-centered"></div>
              </div>
              <div className="flex-scroll-container">
                <div className="flex-scroll">
                  {sections.map((section, index) =>
                    <div className="columns faq-sections-list-item" key={index}>
                      <div className="column is-8"><h4>{section.name}</h4></div>
                      <div className="column is-1 has-text-centered">{section.FAQs.length}</div>
                      <div className="column is-1 has-text-centered">{section.sortOrder}</div>
                      <div className="column is-1 has-text-centered"><span className="is-clickable faq-confirm-delete" onClick={() => handleEdit({ id: section.id, sections })}>Edit</span></div>
                      <div className="column is-1 has-text-centered"><span className="is-clickable faq-confirm-delete" onClick={() => confirmDeleteSection({id: section.id, faqs: section.FAQs})}>Delete</span></div>
                    </div>
                  )}
                  <div style={{ height: "100px" }}></div>
                </div>
              </div>
            </div>}
          <Modal
            showModal={showModal}
            showCloseX={true}
            title="Delete Section"
            handleClose={handleModalClose}
            body={canDeleteSection ? "Are you sure you want to delete this Section? This action cannot be undone." : "Please move or delete the FAQ questions in this Section before deleting."}
            actions={modalActions}
          />
        </div>
      </div>
    </>
  )
}