import InputField from "../../components/TextInput";
import { startCase, findLastIndex } from 'lodash';
import { useEffect, useState, useRef } from "react";
import { v4 as uuidV4 } from 'uuid';

const NewReportDrawerBody = ({ updateParams, headers, data, disabled }) => {
  const [newBullets, setNewBullets] = useState([]);
  const [showNewBulletInterface, setShowNewBulletInterface] = useState(false);

  const setDefaultLiReportParams = () => {
    const headerClone = [...headers];
    return headerClone.map(h => ({ detailKey: h.detailValue, id: h.id, detailValue: "" }));
  };

  const defaultReportParams = useRef({
    title: "",
    description: "",
    reportUrl: "",
    reportDetails: setDefaultLiReportParams()
  });

  useEffect(() => {
    if (data) {
      defaultReportParams.current = { ...data };
    }
  }, [data]);

  const [reportParams, setReportParams] = useState({});
  useEffect(() => {
    if (defaultReportParams.current) {
      setReportParams({ ...defaultReportParams.current });
    }
  }, []);

  const [haveData, setHaveData] = useState(false);
  useEffect(() => {
    if (data && reportParams.id) {
      setHaveData(true);
    }
    setHaveData(true);
  }, [reportParams, data])

  const handleLiInput = (key, value) => {
    reportParams[key] = value;
    setReportParams({ ...reportParams });
    updateParams({ ...reportParams });
  };

  const handleBulletInput = ({ item, value }) => {
    item.detailValue = value;
    setReportParams({ ...reportParams });
    updateParams({ ...reportParams });
  };

  const handleNewBulletInput = ({ type, item, value }) => {
    const tempReport = [...newBullets];
    const exists = tempReport.find(r => r.id === item.id);
    if (!exists) {
      item[type] = value;
      tempReport.reportDetails.push(item);
    } else {
      exists[type] = value;
    }
    setNewBullets([...tempReport]);
    reportParams.newBullets = [...tempReport];
    setReportParams({ ...reportParams });
    updateParams({ ...reportParams });
  };

  const handleShowNewBullet = () => {
    setShowNewBulletInterface(true);
    const newBulletList = [...newBullets];
    newBulletList.push({ id: uuidV4(), detailKey: "", detailValue: "" });
    setNewBullets([...newBulletList]);
    setTimeout(() => {
      const searchId = newBulletList[findLastIndex(newBulletList)]?.id;
      const drawerDiv = document.getElementById(searchId);
      drawerDiv.scrollIntoView({ behavior: "smooth" });
    });
  };

  const handleRemoveExistingBullets = ({ reportParams, item }) => {
    const itemIndex = reportParams.reportDetails.indexOf(item);
    reportParams.reportDetails.splice(itemIndex, 1);
    setReportParams({ ...reportParams });
    updateParams({ ...reportParams });
  };

  const handleBulletRemove = (id) => {
    const b = newBullets.find(nb => nb.id === id);
    newBullets.splice(newBullets.indexOf(b), 1);
    setNewBullets([...newBullets]);
    reportParams.newBullets = [...newBullets];
    setReportParams({ ...reportParams });
    updateParams({ ...reportParams });
    if (!newBullets.length) {
      setShowNewBulletInterface(false);
    }
  };

  return (

    haveData &&
    <div className="report-form-inputs drawer-body-top mr-3" style={{ "--reportInputWidth": "100%" }}>
      <InputField
        label="embed url"
        value={reportParams.reportUrl || ""}
        onChange={(e) => handleLiInput("reportUrl", e.target.value)}
        maxlength={250}
        disabled={disabled}
        controlClass="is-width-full"
      />
      <InputField
        label="title"
        value={reportParams.title || ""}
        onChange={(e) => handleLiInput("title", e.target.value)}
        maxlength={75}
        disabled={disabled}
        controlClass="is-width-full"
      />
      <InputField
        label="description"
        type="textarea"
        value={reportParams.description || ""}
        onChange={(e) => handleLiInput("description", e.target.value)}
        maxlength={500}
        disabled={disabled}
        controlClass="is-width-full"
      />
      <hr />
      <h4>Bullets</h4>
      {
        reportParams.reportDetails?.map(item => {
          return (
            item.detailKey &&
            <div key={item.detailKey || uuidV4()} className="is-flex is-justify-content-space-between is-align-items-center">
              <InputField
                label={item.detailKey}
                key={item.detailKey || uuidV4()}
                value={item.detailValue || ""}
                maxlength={250}
                onChange={(e) => handleBulletInput({ item, value: e.target.value })}
                disabled={disabled}
                controlClass="is-width-full"
              />
              <button className="button ml-3 mt-3" onClick={() => handleRemoveExistingBullets({ reportParams, item })}>
                <span>
                  <i className="fa-solid fa-minus"></i>
                </span>
              </button>
            </div>
          )
        })
      }
      {
        showNewBulletInterface &&
        <div>
          <h5>New Bullet:</h5>
          {
            newBullets?.map(b => {
              return (
                <div id={b.id} key={b.id || uuidV4()} className="is-flex is-justify-content-space-between is-align-items-center">
                  <InputField
                    label="label"
                    controlClass="mr-3"
                    value={b.detailKey || ""}
                    maxlength={75}
                    onChange={(e) => handleNewBulletInput({ type: "detailKey", item: b, value: e.target.value })}
                    disabled={disabled}
                  />
                  <InputField
                    label="value"
                    value={b.detailValue || ""}
                    maxlength={250}
                    onChange={(e) => handleNewBulletInput({ type: "detailValue", item: b, value: e.target.value })}
                    disabled={disabled}
                  />
                  <button className="button ml-3 mb-3" onClick={() => handleBulletRemove(b.id)}>
                    <span>
                      <i className="fa-solid fa-minus"></i>
                    </span>
                  </button>
                </div>
              )
            })
          }
        </div>
      }
      <button className="button is-info" onClick={handleShowNewBullet}>{startCase(("Add new bullet").toLocaleLowerCase())}</button>
    </div>
  )
};

export default NewReportDrawerBody;