import Header from "../components/header";
import Footer from "../components/footer";
import PortalLink from "../components/portallinks";
import store, { getUserCognitoGroups } from '../store';
import { useState, useEffect, useContext } from "react";
import { Loader } from 'rsuite';
import { useNavigate } from "react-router-dom";
import SideMenu from "../components/sidemenu";
import { ImagesContext } from "../components/ImagesContext";

export default function Root() {
  const navigate = useNavigate();

  let cookieData = {title: '', url: ''};
  const { images } = useContext(ImagesContext);
  
  const getCookieData = () => {
    // Get the cookie value
    const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)tableauCookie\s*=\s*([^;]*).*$)|^.*$/, "$1");
    // Parse the JSON string to get the data object
    if(cookieValue){
      cookieData = JSON.parse(decodeURIComponent(cookieValue));
    }
  };
  getCookieData();

  const [isDev, setIsDev] = useState();

  useEffect(() => {
    async function fetchData() {
      const tempDev = await getUserCognitoGroups('dev');
      setIsDev(tempDev);
    }
    fetchData().catch(e => e);

    // Aaaaand if there are cookie values, let's go there. Need to nuke cookie after that. 
    if(cookieData.title !== '' && cookieData.url !== ''){
      navigate(`/reports?title=${encodeURIComponent(cookieData.title)}&url=${encodeURIComponent(cookieData.url)}`);
    }
        
  }, [cookieData.title, cookieData.url, navigate]);

  if (!store.user) {
    return
  }

  return (
    <>
      <Header></Header>
      <div className="is-flex" style={{ height: "100%" }}>
        <SideMenu defaultOpenMenus={["1"]}></SideMenu>
        <div className="is-flex is-flex-direction-column" style={{ height: "100%", width: "100%" }}>
        <div className="is-flex" style={{ height: "0", overflow: "auto", flex: "1 1 auto", justifyContent: "center" }}>
            {!images.mounted && <Loader size="lg" content="Loading..." vertical center />}
            {images.mounted && (
              <section className="m-5">
                <PortalLink
                  linkpath="spd"
                  title="Partner Portal"
                  description="The one stop for Partner Information"
                  image={images.partnerImage?.fileObject}
                  alt="partner portal"
                  className="mb-3"
                  buttontext="Let's Go!"
                ></PortalLink>
                <PortalLink
                  linkpath="faq"
                  title="FAQ"
                  description="Have questions? Get answers here."
                  buttontext="Get Started"
                  alt="faq portal"
                  image={images.faqImage?.fileObject}
                  className="mb-3"
                ></PortalLink>
                <PortalLink
                  linkpath="reports"
                  title="Reports Portal"
                  description={(() => (
                    <div style={{ lineHeight: "23px" }}>
                      <div>Into dashboards and spreadsheets?</div>
                      <div>Look no further!</div>
                    </div>
                  ))()}
                  image={images.reportImage?.fileObject}
                  alt="reports portal"
                  className="mb-3"
                  buttontext="Free the data!"
                ></PortalLink>
                <PortalLink
                  linkpath="legalresponsibilities"
                  title="Legal Portal"
                  description="Looking for Legal Assistance?"
                  image={images.legalImage?.fileObject}
                  alt="legal portal"
                  className="mb-3"
                  buttontext="Jump In"
                ></PortalLink>
                <PortalLink
                  isExternalLink={true}
                  link={"https://webbankeo.sharepoint.com/sites/WebBankCompliance"}
                  linkpath="compliance"
                  title="Compliance Portal"
                  description={(() => (
                    <div style={{ lineHeight: "23px" }}>
                      <div>Keep calm and follow the</div>
                      <div>compliance program</div>
                    </div>
                  ))()}
                  image={images.complianceImage?.fileObject}
                  alt="compliance portal"
                  className="mb-3"
                  buttontext="Get Compliant"
                ></PortalLink>
              </section>
            )}
            {isDev && (
              <>
                <Footer
                  body={
                    <button className="is-clickable custom-button-shadow" onClick={() => navigate("/devAdmin")}>
                      Dev Controls
                    </button>
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
  
}