import { useState, useRef, useCallback, useEffect } from "react";
import { Loader } from "rsuite";
import api from "../../api";
import { orderBy } from "lodash";
import QuestionType from "../../components/products/QuestionType";
import { v4 as uuidV4 } from "uuid";
import store, { getUserCognitoGroups } from "../../store";
import InlineSaveIndicator from "../../components/InlineSaveIndicator";
import CommentBox from "../../components/CommentBox";
import ContractAnswers from "./ContractAbstractAnswerView";

const ContractAbstract = ({ partner }) => {
  // state
  const [loading, setLoading] = useState(true);
  const [isContractsAdmin, setIsContractsAdmin] = useState(false);
  const [view, setView] = useState();
  const [subsections, setSubsections] = useState([]);
  const [showSaving, setShowSaving] = useState(false);
  const [showSavingDone, setShowSavingDone] = useState(false);
  const [requireComment, setRequireComment] = useState(false);

  // variables and refs
  const views = ["answers", "questions"];
  const requireCommentShow = useRef(false);
  const responses = useRef([]);

  // Callbacks and functions
  const showSavingCB = useCallback((val) => setShowSaving(val), []);
  const showSavingDoneCB = useCallback((val) => setShowSavingDone(val), []);

  const save = async ({ data, question, setDisableItemList }) => {
    const responsesForThisQuestion = question?.responses?.find(
      (response) => response?.additionalComments || response?.comments || (response?.value && data?.selectedObject?.type === "date-picker")
    );
    if (responsesForThisQuestion?.comments === data?.comment && data?.method === "update") return;
    showSavingCB(data?.method !== "update" ? data?.selectedObject?.questionId : question?.id);
    const body = {
      questionId: data?.selectedObject?.questionId,
      partnerId: partner?.id,
      value: data?.selectedObject?.value,
    };
    switch (data?.method) {
      case "create":
        body.comments = data?.selectedObject?.comments;
        const createResponse = await api.referenceResponses.create({ body });
        const createdResponseIdx = question.responses?.findIndex((response) => response?.id === createResponse?.id);
        if (createdResponseIdx === -1 || createdResponseIdx === undefined) {
          createResponse.additionalComments = data?.selectedObject?.additionalComments;
          createResponse.commentsRequired = data?.selectedObject?.commentsRequired;
          if (!question.responses) {
            question.responses = [];
          }
          question.responses?.push(createResponse);
        }
        break;
      case "update":
        body.id = responsesForThisQuestion?.id;
        body.value = responsesForThisQuestion?.value;
        body.questionId = question?.id;
        body.comments = data?.comment;
        const updateResponse = await api.referenceResponses.update({ id: body?.id, body });
        responsesForThisQuestion.comments = updateResponse?.comments;
        if (responsesForThisQuestion.comments) {
          requireCommentShow.current = false;
        }
        break;
      case "delete":
        const deleteResponse = question?.responses?.find((response) => response?.questionId === body.questionId && response?.value === body?.value);
        body.id = deleteResponse?.id;
        const deletedId = await api.referenceResponses.deleteData({ id: body?.id, partnerId: body.partnerId });
        const deletedResponseIdx = question?.responses?.findIndex((response) => response?.id === deletedId);
        if (deletedResponseIdx > -1) {
          question?.responses?.splice(deletedResponseIdx, 1);
        }
        break;
      default:
        break;
    }

    setTimeout(() => {
      if (setDisableItemList) {
        setDisableItemList([]);
      }
      showSavingDoneCB(true);
      setTimeout(() => {
        showSavingCB(false);
        showSavingDoneCB(false);
      }, 1000);
    }, 1000);
  };

  const getCommentStyle = ({ question }) => {
    const requiredComment = question?.responses?.find((response) => response?.commentsRequired);
    return !!requiredComment && !requiredComment?.comments;
  };

  const handleSetView = (e) => {
    if (!store?.partnerViewMemory) {
      store.partnerViewMemory = { contractAbstractView: e };
    } else {
      store.partnerViewMemory.contractAbstractView = e;
    }
    setView(e);
  };

  async function getUserPermissions() {
    const tempContractsAdmin = await getUserCognitoGroups("contractAbstractAdmin");
    const tempContractsView = await getUserCognitoGroups("contractAbstractView");
    setIsContractsAdmin(tempContractsAdmin);
    return { contractAbstractAdmin: tempContractsAdmin, contractAbstractView: tempContractsView };
  }

  const fetchContractResponses = async () => {
    const tempResponse = await api.referenceResponses.get({ partnerId: partner?.id, endpoint: `reference-responses/?partnerId=${partner?.id}` });
    return tempResponse;
  };

  const fetchSections = async () => {
    const contractSections = await api.productSections.get({
      endpoint: `reference-sections/?isContract=true&includeSubSections=true&includeQuestions=true&includeAnswers=true`,
    });
    const tempSubs = [...orderBy(contractSections[0]?.subsections, "sortOrder", "asc")];
    const tempResponseList = await fetchContractResponses().catch((e) => e);
    const tempResponsesWithSort = [];
    // separate responses by questionId
    const tempResponsesByQuestionId = {};
    for (let response of tempResponseList) {
      if (!tempResponsesByQuestionId[response?.questionId]) {
        tempResponsesByQuestionId[response?.questionId] = tempResponseList?.filter((r) => r?.questionId === response?.questionId);
      }
    }
    // assign the responses to the question definitions
    for (let subsection of tempSubs) {
      if (subsection?.questions) {
        // Sort the questions array by sortOrder
        subsection.questions.sort((a, b) => a.sortOrder - b.sortOrder);
        for (let question of subsection.questions) {
          if (tempResponsesByQuestionId[question?.id]) {
            question.responses = tempResponsesByQuestionId[question?.id];
            if (question?.responses) {
              for (let response of question.responses) {
                response.additionalComments = question?.answers?.find((answer) => answer?.value === response?.value)?.additionalComments;
                response.commentsRequired = question?.answers?.find((answer) => answer?.value === response?.value)?.commentsRequired;
                response.sortOrder = question?.answers?.find((answer) => answer?.value === response?.value)?.sortOrder;
                tempResponsesWithSort.push(response);
              }
              tempResponsesByQuestionId[question?.id] = question.responses;
            }
          }
        }
      }
    }
    responses.current = [...orderBy(tempResponsesWithSort, "sortOrder", "asc")];
    setSubsections([...tempSubs]);
  };

  const setup = async () => {
    setLoading(true);
    await getUserPermissions();
    await fetchSections().catch((e) => e);
    const memorizedView = !isContractsAdmin ? "answers" : "questions";
    setView(memorizedView);
    setLoading(false);
  };

  useEffect(() => {
    setup().catch((e) => e);
    // eslint-disable-next-line
  }, []);

  // reset the question view
  useEffect(() => {
    if (view === "questions") {
      setup().catch((e) => e);
    }
    // eslint-disable-next-line
  }, [view]);

  return (
    <>
      {loading ? (
        <div className="is-full">
          <Loader center size="sm" content="Loading Contract Abstract Info..." />
        </div>
      ) : (
        <div className="parent-flex">
          {isContractsAdmin && (
            <span className="is-flex is-align-items-center mb-3">
              <div className="is-flex is-flex-direction-column is-justify-content-flex-end mb-2" style={{ height: "45px" }}>
                <div className="is-flex is-align-items-flex-end">
                  {views?.map((area) => (
                    <div
                      key={uuidV4()}
                      className={`tab ${view === area ? "has-background-link has-text-white " : ""}`}
                      onClick={() => handleSetView(area)}
                    >
                      {area}
                    </div>
                  ))}
                </div>
              </div>
            </span>
          )}
          {requireComment && (
            <div className="is-absolute" style={{ zIndex: "98", height: "1000%", width: "100%", backgroundColor: "rgba(0, 0, 0, .25)" }} />
          )}
          <div className="contain-and-scroll is-relative">
            {view === "questions" ? (
              <div>
                {!!subsections?.length &&
                  subsections?.map((subsection) => (
                    <div key={subsection?.id} className="card mb-3">
                      <div key={subsection?.id} className="card-content">
                        <h5 className="mb-2 pb-1" style={{ color: "#7e7e8d", borderBottom: "1px solid #c9c9c9" }}>
                          {subsection?.name}
                        </h5>
                        <div className="contents">
                          <ol className="ml-4">
                            {subsection?.questions?.map((question) => {
                              return (
                                <li key={question?.id}>
                                  <div className="is-flex">
                                    {question.topic && <div className="mr-2">{question.topic}:</div>}
                                    <span className="has-text-weight-bold">
                                      {question.text}
                                    </span>
                                  </div>
                                  <span className="is-italic help has-text-weight-normal mb-3">{question.description}</span>
                                  <div className="is-flex is-align-items-flex-start">
                                    <QuestionType
                                      type={question?.answerType}
                                      answers={orderBy(question?.answers, "sortOrder", "asc")}
                                      save={save}
                                      question={question}
                                      className="mb-5"
                                      style={{ flex: "1" }}
                                      maxlength={5000}
                                    />
                                    <InlineSaveIndicator showId={question?.id} showSaving={showSaving} showSavingDone={showSavingDone} />
                                  </div>
                                  {!!question?.responses?.find((response) => response?.additionalComments) && (
                                    <div
                                      className="is-relative p-3 inline-save-required"
                                      style={{ zIndex: getCommentStyle({ question }) ? "99" : "auto" }}
                                    >
                                      <CommentBox
                                        question={question}
                                        requireCommentShow={requireCommentShow}
                                        setRequireComment={setRequireComment}
                                        save={save}
                                        showSaving={showSaving}
                                        maxlength={5000}
                                      />
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  ))}
                <div style={{ height: "300px" }} />
              </div>
            ) : (
              !!subsections?.length && <ContractAnswers subsections={subsections} />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ContractAbstract;
