import React  from "react";
import Modal from "./modal";
import { v4 as uuidV4 } from 'uuid';

const ConfirmDeleteFaq = ({ deleteFaq, handleClose, showModal }) => (
    <Modal
        showModal={showModal} 
        showCloseX={true} 
        title="Delete FAQ" 
        body="Are you sure you want to delete this FAQ? This action cannot be undone."
        actions={[
            {
                'id': uuidV4(),
                'buttonClass': 'button is-danger',
                'background': '',
                'color': '',
                'text': 'Yes. Delete It!',
                'iconClass': 'fa-solid fa-trash',
                'action': deleteFaq
            },
            {
                'id': uuidV4(),
                'buttonClass': 'button is-warning',
                'background': '',
                'color': '',
                'text': 'Cancel',
                'iconClass': 'fa-solid fa-ban',
                'action': handleClose
            }
            ]}
        handleClose={handleClose} 
    />
);

export default ConfirmDeleteFaq;