import { v4 as uuidv4 } from 'uuid'; // need to import this anywhere we define the incoming data object
import store from '../store';

export default function TestNotifications(){
  const testNotify = ({type, blockBackground, canCloseMessage, autoCloseTimeout, actions, closeCallback}) => {
    store.notifications.push(
      {
        id: uuidv4(),
        type,
        // primaryColorOverride: 'red',
        // secondaryColorOverride: 'blue',
        // titleColorOverride: 'purple',
        // bodyTextColorOverride:'green',
        titleText: 'error of things',
        bodyText: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi cumque delectus adipisci, nemo ipsam corporis consectetur dolore doloribus assumenda a quos praesentium minus deserunt ad facilis minima quae sit explicabo!',
        actions,
        blockBackground,
        canCloseMessage,
        autoCloseTimeout,
        closeCallback
      }
    )
    store.handleStoreUpdates(store);
  };
  
  return (
    <>
      <div className="card m-3" style={{maxWidth: "500px"}}>
        <div className="card-header">
          <div className="card-header-title">Test Notifications</div>
        </div>
        <div className="card-contents">
          <button className="button m-2" onClick={() => testNotify({type: 'error', canCloseMessage: true})}>Test Error</button>
          <button className="button m-2" onClick={() => testNotify({type: 'warning', canCloseMessage: true})}>Test Warning</button>
          <button className="button m-2" onClick={() => testNotify({type: 'info', canCloseMessage: true})}>Test Info</button>
          <button className="button m-2" onClick={() => testNotify({type: 'success', canCloseMessage: true})}>Test Success</button>
          <button className="button m-2" onClick={() => testNotify({
            type: 'success',
            canCloseMessage: true,
            actions: [
              {
                id: uuidv4(),
                // primaryColorOverride: 'yellow',
                // secondaryColorOverride: 'blue',
                // borderColorOverride: 'purple',
                className:'is-info',
                text: 'test',
                operation(){
                  alert('hello')
                }
              },
              {
                id: uuidv4(),
                // primaryColorOverride: 'yellow',
                // secondaryColorOverride: 'blue',
                // borderColorOverride: 'purple',
                // className:'is-warning',
                text: 'test 2',
                operation(a){
                  console.log('hello', a);
                }
              }
            ]
          })}>Test Action Buttons</button>
          <button className="button m-2" onClick={() => testNotify({type: 'info', blockBackground: true, canCloseMessage: true})}>Test block background</button>
          <button className="button m-2" onClick={() => testNotify({type: 'info', autoCloseTimeout: 3000, canCloseMessage: true})}>Test auto close 3 seconds</button>
          <button className="button m-2" onClick={() => testNotify({type: 'info', canCloseMessage: false, autoCloseTimeout: 10000})}>Test no close "auto in 10sec"</button>
          <button className="button m-2" onClick={() => testNotify({
            type: 'info', 
            canCloseMessage: true,
            closeCallback: () => {
              alert("I'm the close callback")
            }
          })}>Test close Callback"</button>
        </div>
      </div>
    </>
  )
}