import { v4 as uuidV4 } from "uuid";
import { startCase } from "lodash";

export default function DropCard({permission, dropCardActive, handleDropCardShow, triggerButtonText, cSSVars, content, actions}) {
  const setClass = ({icon, className}) => {
    return icon ? `${className} has-icon-left` : className;
  };

  return (
    permission &&
    <div className="drop-card-anchor is-flex">
      <button className={"title-button drop-card-activate-button " + (dropCardActive ? 'active' : '' )} onClick={handleDropCardShow}>{triggerButtonText}</button>
      <div className={'drop-card p-3 ' + (dropCardActive ? 'active' : '')} style={{'--dropCardButtonWidth':cSSVars?.dropCardButtonWidth, '--dropCardButtonHeight':cSSVars?.dropCardButtonHeight, '--dropHeight':cSSVars?.dropHeight,'--dropBorderColor':cSSVars?.dropBorderColor, '--dropBackgroundColor': cSSVars?.dropBackgroundColor}}>
        <div className="drop-card-content">
          {content}
        </div>
        <div className="drop-card-actions-container is-flex">
          {
              actions?.map(({isCustom, id, icon, text, action, className, disabled}) => (
                isCustom 
                ?
                <div key={id || uuidV4()}>
                  {isCustom}
                </div> 
                :
                <button key={id || uuidV4()} className={`button mr-3 ${setClass({icon, className})}`} onClick={action} disabled={disabled}>
                  <span className={icon ? "mr-2" : ""}>
                    {icon}
                  </span>
                  <span>
                    {startCase(text?.toLowerCase())}
                  </span>
                </button>
              ))
            }
        </div>
      </div>
    </div>
  )
}