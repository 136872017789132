import { isEmpty, remove } from "lodash";
import { useState, useEffect } from "react";
import { TagPicker } from "rsuite";

const DuplicateResponseModal = ({ products, currentProduct, productCopyData, setProductCopyData, dataChange, disabled }) => {
  const [sourceProduct, setSourceProduct] = useState({});
  const [destinationProductList, setDestinationProductList] = useState([]);
  const [destinationProductIds, setDestinationProductIds] = useState([]);

  const handleSourceSelect = (e) => {
    if (e.target?.value === "0") {
      clearSource();
      clearDestination();
    }
    const selectedProduct = products?.find(product => product?.id === e.target.value);
    setSourceProduct({ ...selectedProduct });
    productCopyData.source = selectedProduct?.id;
    setProductCopyData({ ...productCopyData });
    const copyOfProducts = structuredClone([...products]);
    remove(copyOfProducts, product => product?.id === selectedProduct?.id);
    setDestinationProductList(copyOfProducts);
  };

  const handleDestinationChange = ({ selectedData }) => {
    setDestinationProductIds([...selectedData]);
    productCopyData.destination = [...selectedData];
    setProductCopyData({ ...productCopyData });
  };

  const clearSource = () => {
    const el = document.getElementById('source-product-select');
    el.value = 0;
    setSourceProduct(null);
    productCopyData.source = null;
    setProductCopyData(productCopyData);
  };

  const clearDestination = () => {
    setDestinationProductIds([]);
    setDestinationProductList([...products]);
    productCopyData.destination = null;
    setProductCopyData(productCopyData);
  };

  useEffect(() => {
    if (!productCopyData?.source && !productCopyData?.destination) {
      clearSource();
      clearDestination();
    }
    if (currentProduct && !isEmpty(currentProduct)) {
      const a = { target: { value: currentProduct?.id } }
      handleSourceSelect(a);
    }
    // eslint-disable-next-line
  }, [dataChange, currentProduct]);

  return (
    <div className="is-flex is-flex-direction-column">
      <span className="help is-danger mb-3">
        <i className="fa-solid fa-triangle-exclamation mr-2" />
        <span>Copying product responses will overwrite all responses for any product that is selected in the destination list.</span>
      </span>
      <div className="is-flex is-justify-content-space-between">
        <div className="is-flex is-flex-direction-column is-flex-grow-1">
          <label className="">Source Product:</label>
          <div>
            <div className="select" style={{ maxHeight: '36px' }}>
              <select id="source-product-select" className="has-text-link" onChange={handleSourceSelect} value={sourceProduct?.id} disabled={disabled} style={{ maxHeight: '36px', fontSize: '14px' }}>
                <option defaultValue={true} value={0}>Choose Source</option>
                {products?.map(product => <option key={product?.id} value={product?.id}>{product?.name}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className="is-flex is-flex-direction-column is-flex-grow-2 rsuite-picker">
          <label className="">Destination Products:</label>
          <TagPicker
            data={destinationProductList}
            labelKey="name"
            valueKey="id"
            value={destinationProductIds || []}
            cleanable={true}
            searchable={false}
            disabled={(!destinationProductList?.length || isEmpty(destinationProductList)) || (!productCopyData?.source || isEmpty(productCopyData.source)) || disabled}
            onChange={(data) => handleDestinationChange({ selectedData: data, dataSource: destinationProductList, destinationProductIds })}
          />
        </div>
      </div>
    </div>
  )
};
export default DuplicateResponseModal;