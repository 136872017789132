import { useNavigate } from "react-router-dom";
import { logout } from '../store';

export default function Header(){
  const navigate = useNavigate();
  
  const goHome = (path = '/') => {
    navigate(path);
  };
  return (
    <>
      <header className="header is-flex is-justify-contents-space-between is-align-items-center">
        <nav className="navbar p-2 is-flex is-justify-content-space-between is-align-items-center is-width-full">
          <div className="navbar-brand is-flex is-justify-content-space-between is-align-items-end is-clickable" onClick={() => goHome('/')}>
            <img id="brand" src="../WebBank-logo.png" alt="brand" className="src" />
            <span className="ml-2">Skynet</span>
          </div>
          <div className="secondary-text is-clickable">
            <i className="fa-solid fa-arrow-right-from-bracket mr-1"></i>
            <span onClick={async () => {
              try {
                await logout();
              } catch (error) {
                console.error(error);
              }
            }}>Sign Out</span>
          </div>
        </nav>
      </header>
    </>
  )
}