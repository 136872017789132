import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { SelectPicker, Loader } from "rsuite";
import api from "../../api";
import { orderBy, isEmpty } from "lodash";
import QuestionType from "../../components/products/QuestionType";
import Search from "../../components/Search";
import { v4 as uuidV4 } from "uuid";
import store, { getUserCognitoGroups } from "../../store";
import { useNavigate } from "react-router-dom";
import InlineSaveIndicator from "../../components/InlineSaveIndicator";
import CommentBox from "../../components/CommentBox";
import ProductFactSheetAnswers from "./ProductFactSheetAnswerView";
import DuplicateResponseModal from "./DuplicateResponseModal";
import Modal from "../../components/modal";
import ApiSubmitButton from "../../components/buttons/ApiSubmitButton";
import { ImagesContext } from "../../components/ImagesContext";

export default function ProductFactSheet({ partner }) {
  // state
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showSaving, setShowSaving] = useState(false);
  const [showSavingDone, setShowSavingDone] = useState(false);
  const [isPartnerReferenceAdmin, setIsPartnerReferenceAdmin] = useState(false);
  const [view, setView] = useState("answers");
  const [loading, setLoading] = useState(true);
  const [loadingProductResponses, setLoadingProductResponses] = useState(false);
  const [requireComment, setRequireComment] = useState(false);
  const [searching, setSearching] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [copyingResponses, setCopyingResponses] = useState(false);
  const [doneCopying, setDoneCopying] = useState(false);
  const [pFSCopyData, setPFSCopyData] = useState({});
  const [dataChange, setDataChange] = useState(true);

  // variables and refs
  const navigate = useNavigate();
  const sections = useRef({});
  const views = ["answers", "questions"];
  const responses = useRef([]);
  const originalProductSelected = useRef({});
  const { images } = useContext(ImagesContext);

  // Callbacks and functions
  const showSavingCB = useCallback((val) => setShowSaving(val), []);
  const showSavingDoneCB = useCallback((val) => setShowSavingDone(val), []);

  const copyResponses = async () => {
    setCopyingResponses(true);
    const callList = pFSCopyData?.destination?.map((destination) =>
      api.products.clone({ body: { sourceProductId: pFSCopyData.source, destinationProductId: destination } })
    );
    await Promise.all(callList);
    setDoneCopying(true);
    setTimeout(() => {
      setCopyingResponses(false);
      setDoneCopying(false);
      cancelCopyResponses();
      handleProductSelect(selectedProduct?.id);
    }, 2000);
  };

  const cancelCopyResponses = () => {
    setDataChange(!dataChange);
    if (pFSCopyData?.source) {
      pFSCopyData.source = null;
    }
    if (pFSCopyData?.destination) {
      pFSCopyData.destination = null;
    }
    setShowModal(false);
  };

  const handleProductSelect = (data) => {
    if (data === "newProduct") {
      return navigate("/products");
    }
    async function callReferenceResponseApi() {
      setLoadingProductResponses(!!data);
      if (!data) return productClean();
      const tempProduct = structuredClone({ ...partner?.products?.find((product) => product?.id === data) });
      const tempSections = structuredClone([...sections.content]);
      tempProduct.sections = [...orderBy(tempSections, "sortOrder", "asc")];
      const tempResponse = await api.referenceResponses.get({ productId: tempProduct?.id });
      const tempResponsesWithSort = [];
      // separate responses by questionId
      const tempResponsesByQuestionId = {};
      for (let response of tempResponse) {
        if (!tempResponsesByQuestionId[response?.questionId]) {
          tempResponsesByQuestionId[response?.questionId] = tempResponse?.filter((r) => r?.questionId === response?.questionId);
        }
      }
      // assign the responses to the question definitions
      for (let section of tempProduct.sections) {
        if (section?.subsections) {
          const orderedSubs = orderBy(section.subsections, "sortOrder", "asc");
          section.subsections = orderedSubs;
          for (let subsection of section.subsections) {
            if (subsection?.questions) {
              const orderedQues = orderBy(subsection.questions, "sortOrder", "asc");
              subsection.questions = orderedQues;
              for (let question of subsection.questions) {
                if (tempResponsesByQuestionId[question?.id]) {
                  question.responses = tempResponsesByQuestionId[question?.id];
                  if (question?.responses) {
                    for (let response of question.responses) {
                      response.additionalComments = question?.answers?.find((answer) => answer?.value === response?.value)?.additionalComments;
                      response.commentsRequired = question?.answers?.find((answer) => answer?.value === response?.value)?.commentsRequired;
                      response.sortOrder = question?.answers?.find((answer) => answer?.value === response?.value)?.sortOrder;
                      tempResponsesWithSort.push(response);
                    }
                    tempResponsesByQuestionId[question?.id] = question.responses;
                  }
                }
              }
            }
          }
        }
      }
      responses.current = [...orderBy(tempResponsesWithSort, "sortOrder", "asc")];
      setSelectedProduct({ ...tempProduct });
      originalProductSelected.current = structuredClone({ ...tempProduct });
      setSearching(false);
      setLoadingProductResponses(false);
    }
    callReferenceResponseApi().catch((e) => e);
  };

  const productClean = () => {
    setSelectedProduct({});
    originalProductSelected.current = {};
  };

  const partnerProductData = ({ products }) => {
    const mappedProducts = products?.map((product) => ({ label: product?.name, value: product?.id }));
    mappedProducts.unshift({ label: "Go to product setup", value: "newProduct" });
    return mappedProducts;
  };

  const save = async ({ data, question, setDisableItemList }) => {
    const responsesForThisQuestion = question?.responses?.find(
      (response) => response?.additionalComments || response?.comments || (response?.value && data?.selectedObject?.type === "date-picker")
    );
    if (responsesForThisQuestion?.comments === data?.comment && data?.method === "update") return;
    showSavingCB(data?.method !== "update" ? data?.selectedObject?.questionId : question?.id);
    const body = {
      questionId: data?.selectedObject?.questionId,
      productId: selectedProduct?.id,
      partnerId: partner?.id,
      value: data?.selectedObject?.value,
    };
    switch (data?.method) {
      case "create":
        body.comments = data?.selectedObject?.comments;
        const createResponse = await api.referenceResponses.create({ body });
        const createdResponseIdx = question.responses?.findIndex((response) => response?.id === createResponse?.id);
        if (createdResponseIdx === -1 || createdResponseIdx === undefined) {
          createResponse.additionalComments = data?.selectedObject?.additionalComments;
          createResponse.commentsRequired = data?.selectedObject?.commentsRequired;
          if (!question.responses) {
            question.responses = [];
          }
          question.responses?.push(createResponse);
        }
        break;
      case "update":
        body.id = responsesForThisQuestion?.id;
        body.value = responsesForThisQuestion?.value;
        body.questionId = question?.id;
        body.comments = data?.comment;
        const updateResponse = await api.referenceResponses.update({ id: body?.id, body });
        responsesForThisQuestion.comments = updateResponse?.comments;
        if (!!responsesForThisQuestion.comments) {
          requireCommentShow.current = false;
        }
        break;
      case "delete":
        const deleteResponse = question?.responses?.find((response) => response?.questionId === body.questionId && response?.value === body?.value);
        body.id = deleteResponse?.id;
        const deletedId = await api.referenceResponses.deleteData({ id: body?.id, productId: body.productId });
        const deletedResponseIdx = question?.responses?.findIndex((response) => response?.id === deletedId);
        if (deletedResponseIdx > -1) {
          question?.responses?.splice(deletedResponseIdx, 1);
        }
        break;
      default:
        break;
    }

    setTimeout(() => {
      if (setDisableItemList) {
        setDisableItemList([]);
      }
      showSavingDoneCB(true);
      setTimeout(() => {
        showSavingCB(false);
        showSavingDoneCB(false);
      }, 1000);
    }, 1000);
  };

  async function getUserPermissions() {
    const tempPartnerReferenceAdmin = await getUserCognitoGroups("partnerReferenceAdmin");
    setIsPartnerReferenceAdmin(tempPartnerReferenceAdmin);
    tempPartnerReferenceAdmin && (store?.partnerViewMemory?.partnerReferenceView === "questions" || !store?.partnerViewMemory?.partnerReferenceView)
      ? setView("questions")
      : setView("answers");
  }

  const setup = async () => {
    setLoading(true);
    await getUserPermissions();
    const tempSections = orderBy(await api.productSections.get({}), "sortOrder", "asc");

    const filteredData = tempSections.map((item) => ({
      ...item,
      subsections: item.subsections.filter((subsection) => subsection.name === "product-fact-sheet-subsection"),
    }));

    sections.content = [...filteredData];
    setLoading(false);
  };

  const updateProduct = useCallback((val) => {
    setSelectedProduct(val);
  }, []);

  const search = ({ query, setQuery, queryKeys, originalDataset, setNoMatch }) => {
    const dataset = structuredClone({ ...originalDataset });
    if (!query.trim()) {
      setQuery("");
      return dataset;
    }
    function updateUniqueMatches({ condition, sourceData, filteredDataset }) {
      if (condition) {
        const exists = filteredDataset?.find((d) => d?.id === sourceData?.id);
        if (!exists || isEmpty(exists)) {
          filteredDataset.push(sourceData);
        }
      }
    }

    function loopDataset({ dataset, sourceData, queryKeys, filteredDataset }) {
      // loop dataset
      if (sourceData[dataset] && !isEmpty(sourceData[dataset])) {
        for (let data of sourceData[dataset]) {
          // run data checks
          const dataMatches = [];
          for (let key of queryKeys) {
            if (typeof data[key] === "string" && data[key]?.toLowerCase()?.includes(query)) {
              dataMatches.push(data);
            }
          }
          const condition = dataMatches && !isEmpty(dataMatches);
          updateUniqueMatches({ condition, sourceData, filteredDataset });
        }
      }
    }

    function setParentDataset({ parent, child, filteredParent, filteredDataset }) {
      if (filteredDataset && !isEmpty(filteredDataset)) {
        parent[child] = filteredDataset;
        filteredParent.push(parent);
      }
    }

    // loop sections
    if (dataset?.sections) {
      const filteredSections = [];
      for (let section of dataset.sections) {
        const filteredSubsections = [];
        // loop subsections
        if (section?.subsections) {
          for (let subsection of section.subsections) {
            const filteredDataset = [];
            // loop questions
            if (subsection?.questions && !isEmpty(subsection?.questions)) {
              for (let question of subsection.questions) {
                // run question checks
                for (let key of queryKeys) {
                  const condition = typeof question[key] === "string" && question[key]?.toLowerCase()?.includes(query);
                  updateUniqueMatches({ condition, sourceData: question, filteredDataset });
                }
                loopDataset({ dataset: "answers", sourceData: question, queryKeys, filteredDataset });
                loopDataset({ dataset: "responses", sourceData: question, queryKeys, filteredDataset });
              }
            }
            setParentDataset({ parent: subsection, child: "questions", filteredParent: filteredSubsections, filteredDataset });
          }
          setParentDataset({ parent: section, child: "subsections", filteredParent: filteredSections, filteredDataset: filteredSubsections });
        }
      }
      if (filteredSections && !isEmpty(filteredSections)) {
        dataset.sections = filteredSections;
        setNoMatch(false);
      } else {
        setNoMatch(`couldn't find a match.`);
      }
      return dataset;
    } else {
      return "bar nothing to loop";
    }
  };

  const clearOverride = () => {
    setNoMatch(false);
    handleProductSelect(selectedProduct?.id);
  };

  const handleSetView = (e) => {
    if (!store?.productViewMemory) {
      store.productViewMemory = { partnerReferenceView: e };
    } else {
      store.productViewMemory.partnerReferenceView = e;
    }
    setView(e);
    if (selectedProduct?.id) {
      handleProductSelect(selectedProduct?.id);
    }
  };

  useEffect(() => {
    setup().catch((e) => e);
    // eslint-disable-next-line
  }, []);

  // Child components

  const getCommentStyle = ({ question }) => {
    const requiredComment = question?.responses?.find((response) => response?.commentsRequired);
    return !!requiredComment && !requiredComment?.comments;
  };

  const requireCommentShow = useRef(false);

  // Create a new MutationObserver instance
  const observer = new MutationObserver((mutationsList) => {
    const subElements = document.querySelectorAll("sub.has-text-danger");
    const articleElement = document.querySelector("#comments-required-banner");

    if (subElements.length > 0 && articleElement) {
      let shouldShow = false;
      subElements.forEach((subElement) => {
        if (subElement.textContent === "Comments are required") {
          shouldShow = true;
        }
      });

      if (shouldShow) {
        articleElement.classList.remove("is-hidden");
      } else {
        articleElement.classList.add("is-hidden");
      }
    } else if (articleElement) {
      articleElement.classList.add("is-hidden");
    }
  });

  // Specify the target node and options for the observer
  const targetNode = document.body;
  const config = { childList: true, subtree: true };

  // Start observing the target node
  observer.observe(targetNode, config);

  return (
    <>
      {loading ? (
        <div className="is-full">
          <Loader center size="sm" content="Loading Reference Info..." />
        </div>
      ) : (
        !!partner?.products?.length && (
          <div className="parent-flex">
            <div className="field is-capitalized is-flex is-align-items-center is-justify-content-space-between">
              {!isEmpty(partner) && (
                <SelectPicker
                  label="Product"
                  style={{ width: 224 }}
                  data={partnerProductData({ products: partner?.products })}
                  onChange={handleProductSelect}
                  renderMenuItem={(label, item) =>
                    item?.value === "newProduct" ? <div style={{ color: "#3e8ed0", textDecoration: "underline" }}>{label}</div> : <div>{label}</div>
                  }
                />
              )}
              {isPartnerReferenceAdmin && !loadingProductResponses && !isEmpty(selectedProduct) && (
                <span className="is-flex is-align-items-center">
                  <div className="is-flex is-flex-direction-column is-justify-content-flex-end mb-2" style={{ height: "45px" }}>
                    <div className="is-flex is-align-items-flex-end">
                      {views?.map((area) => (
                        <div
                          key={uuidV4()}
                          className={`tab ${view === area ? "has-background-link has-text-white " : ""}`}
                          onClick={() => handleSetView(area)}
                        >
                          {area}
                        </div>
                      ))}
                    </div>
                  </div>
                </span>
              )}
              {!loadingProductResponses && !isEmpty(selectedProduct) && (
                <button className="button is-info" onClick={() => setShowModal(true)}>
                  <i className="fa-solid fa-copy mr-2" />
                  <span>Duplicate Responses</span>
                </button>
              )}
            </div>
            {selectedProduct?.id && !loadingProductResponses && (
              <div className="mb-2">
                <Search
                  search={search}
                  dataset={selectedProduct}
                  originalDataset={originalProductSelected.current}
                  setNoMatch={setNoMatch}
                  setDataset={updateProduct}
                  placeholder="Search questions, answers, and comments"
                  queryKeys={["comments", "name", "text", "topic", "value"]}
                  clearOverride={clearOverride}
                  setLoading={setSearching}
                  setFiltered={setFiltered}
                />
                {!!noMatch && <div className="help is-danger">{noMatch}</div>}
              </div>
            )}
            <Modal
              title="Duplicate Responses"
              body={
                <DuplicateResponseModal
                  products={partner?.products}
                  currentProduct={selectedProduct}
                  productCopyData={pFSCopyData}
                  setProductCopyData={setPFSCopyData}
                  dataChange={dataChange}
                  disabled={copyingResponses}
                />
              }
              showCloseX={true}
              closingXDisabled={copyingResponses}
              actions={[
                {
                  buttonClass: "is-warning",
                  text: "Cancel",
                  iconClass: "fa-solid fa-ban",
                  action: cancelCopyResponses,
                  disabled: copyingResponses,
                },
                {
                  id: uuidV4(),
                  customButton: true,
                  body: (
                    <ApiSubmitButton
                      defaultLabel={`Copy Responses`}
                      operationLabel="Copy in Progress"
                      icon={<i className="fa-solid fa-clone" />}
                      action={copyResponses}
                      disabled={!pFSCopyData?.destination?.length || copyingResponses}
                      // used to force correct rendering when parent re-renders
                      actionTriggered={copyingResponses}
                      actionDone={doneCopying}
                    />
                  ),
                },
              ]}
              showModal={showModal}
              handleClose={() => setShowModal(false)}
            />
            <article className="message is-danger is-hidden" id="comments-required-banner">
              <div className="message-header custom-center">
                <p>Please Provide Required Comments</p>
              </div>
            </article>
            <div className="contain-and-scroll py-3 is-relative">
              {loadingProductResponses || searching ? (
                <div className="is-full">
                  <Loader center size="sm" content="Loading Response Info..." />
                </div>
              ) : (
                !isEmpty(partner) && (
                  <div id={`${selectedProduct?.id}-product-row`}>
                    {selectedProduct?.sections?.map(
                      (section, idx) =>
                        // Check if there are subsections in the section
                        section.subsections.length > 0 &&
                        // view can be 'answers' or 'questions'
                        (view === "questions" ? (
                          <div key={section?.id} className="mb-4 card">
                            <h4 className="card-header pl-3" style={{ color: "#3d84a5" }}>
                              {section.name}
                            </h4>
                            {section?.subsections?.map((subsection) => (
                              <div key={subsection?.id} className="card-content">
                                {!subsection?.name.includes("fact-sheet") && (
                                  <h5 className="mb-2 pb-1" style={{ color: "#7e7e8d", borderBottom: "1px solid #c9c9c9" }}>
                                    {subsection?.name}
                                  </h5>
                                )}
                                <div className="contents">
                                  <ol className="ml-4">
                                    {subsection?.questions?.map((question) => {
                                      return (
                                        <li key={question?.id}>
                                          <div className="is-flex">
                                            {question.topic && <div className="mr-2">{question.topic}:</div>}
                                            <span className="has-text-weight-bold">
                                              {question.text}
                                            </span>
                                          </div>
                                          <span className="is-italic help has-text-weight-normal mb-3">{question.description}</span>
                                          <div className="is-flex is-align-items-flex-start">
                                            <QuestionType
                                              type={question?.answerType}
                                              answers={orderBy(question?.answers, "sortOrder", "asc")}
                                              save={save}
                                              question={question}
                                              className="mb-5"
                                              style={{ flex: "1" }}
                                            />
                                            <InlineSaveIndicator showId={question?.id} showSaving={showSaving} showSavingDone={showSavingDone} />
                                          </div>
                                          {!!question?.responses?.find((response) => response?.additionalComments) && (
                                            <div
                                              className={
                                                "is-relative p-3 inline-save-required " + (getCommentStyle({ question }) ? "is-flagged-required" : "")
                                              }
                                            >
                                              <CommentBox
                                                question={question}
                                                requireCommentShow={requireCommentShow}
                                                setRequireComment={setRequireComment}
                                                requireComment={requireComment}
                                                save={save}
                                                showSaving={showSaving}
                                                maxlength={1024}
                                              />
                                            </div>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ol>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div id={`${selectedProduct?.id}-product-row`} key={section?.id}>
                            <ProductFactSheetAnswers
                              section={section}
                              subsections={orderBy(section.subsections, "sortOrder", "asc")}
                              key={section.id}
                              filtered={filtered}
                              selectedProduct={selectedProduct}
                            />
                          </div>
                        ))
                    )}
                    <div style={{ height: "400px" }}></div>
                  </div>
                )
              )}
            </div>
          </div>
        )
      )}
      {!loading && !partner?.products?.length && (
        <div className="container">
          <p>
            {images.noProductsImage && (
              <img src={images.noProductsImage?.fileObject} alt="web bank logo" aria-label="web bank logo" style={{ height: "300px" }} />
            )}
          </p>
          <h2 className="has-text-centered">Content Creation in Progress</h2>
        </div>
      )}
    </>
  );
}
