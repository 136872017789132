import AreYouSureDelete from "../buttons/AreYouSureDelete";
import { Cell } from "rsuite-table";
import { has } from 'lodash';

const DeleteCell = ({ rowData, cb, deleteAction, areYouSure, ...props }) => {
  // handle child reassign
  if(has(rowData, 'subsections')){
    areYouSure = !!!rowData?.subsections?.length;
  }
  if(has(rowData, 'questions')){
    areYouSure = !!!rowData?.questions?.length;
  }
  return (
    <Cell {...props}>
      <div className="is-flex is-justify-content-flex-end">
        {
          areYouSure ?
            <AreYouSureDelete
              icon={<i className="fa-solid fa-trash actions"></i>}
              action={deleteAction}
              data={{ id: rowData.id }}
              cb={cb}
            />
            :
            <button className="button is-danger animate-shake" onClick={() => deleteAction({ id: rowData?.id })}>
              <i className="fa-solid fa-trash actions" />
            </button>
        }
      </div>
    </Cell>
  )
};

export default DeleteCell;