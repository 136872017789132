import { useState, useEffect } from "react";
import InputField from "./TextInput";

const CommentBox = ({ question, requireCommentShow, setRequireComment, save, showSaving, maxlength }) => {
  const [comment, setComment] = useState(question?.responses?.find(response => response?.comments)?.comments || "");
  const handleComment = (e) => setComment(e?.target?.value);
  const requiredMessage = !!question?.responses?.find(response => response?.commentsRequired) &&
    !question?.responses?.find(response => response?.comments)?.comments ? 'Comments are required' : '';

  useEffect(() => {
    if (requireCommentShow.current) return;
    requireCommentShow.current = !!requiredMessage;
    setRequireComment(!!requiredMessage);
  }, [requiredMessage, requireCommentShow, setRequireComment]);

  return (
    <>
      <InputField
        type="textarea"
        label="Additional Comments"
        value={comment}
        controlClass="mb-5"
        onChange={handleComment}
        maxlength={maxlength}
        errorMessage={requiredMessage}
        onBlur={(data) => save({ data: { comment: data?.target?.textContent, method: 'update' }, question })}
        disabled={showSaving === question?.id}
      />
      {requiredMessage && <button className="button is-info" disabled={showSaving === question?.id}>Save</button>}
    </>
  )
};
export default CommentBox;