export default function Footer(props){
  return (
    <>
      <footer 
        className="skynet-footer p-2 mt-3"
        style={{
          height:props.height
        }}
      >{props.body}</footer>
    </>
  )
}