import { useEffect, useState } from "react";

const AnswerImage = () => {
  const [image, setImage] = useState();
  const [styling, setStyling] = useState({});

  const getStyle = () => {
    const imageEl = document.querySelectorAll("#prr-image")[0];
    setTimeout(() => {
      const s = {
        objectFit: "contain",
        overflow: "hidden",
        height: "auto",
        width: "auto",
        maxHeight: "90vh",
        maxWidth: "90vw",
      };
      imageEl.clientHeight >= imageEl.clientWidth ? (s.height = "100%") : (s.width = "100%");
      setStyling({ ...s });
    }, 30);
  };

  useEffect(() => {
    const tempImage = JSON.parse(localStorage.getItem("partnerReferenceResponseImage"));
    setImage(tempImage);
    localStorage.removeItem("partnerReferenceResponseImage");
    getStyle();
  }, []);

  return (
    <div className="is-flex is-justify-content-center is-align-items-center p-5" style={{ height: "100vh", width: "100vw" }}>
      <img id="prr-image" src={image?.content} alt={image?.image} style={styling} />
    </div>
  );
};
export default AnswerImage;
