import { Table } from "rsuite";
import { useEffect, useState } from "react";
import microservices from "../../services/microservices";

const ContractAnswers = ({ subsections }) => {
	// state
	const [tableHeight, setTableHeight] = useState(200);
	const [loaded, setLoaded] = useState(false);
	const [filteredSubsections, setFilteredSubsections] = useState();

	// refs and variables
	const { Column, HeaderCell, Cell } = Table;

	// functions and callbacks
	const getData = ({ data }) => {
		// restructure data so each answer is it's own contained dataset (needed to remove arrays for table display purposes)
		const newDataSet = [];
		for (let subsection of data) {
			if (subsection?.questions?.length) {
				subsection?.questions?.forEach((question) => {
					if (question?.responses?.length) {
						question?.responses?.forEach((response) => {
							const tempData = { ...response };
							tempData.topic = question?.topic;
              if(question?.description){
                tempData.questionText = `<p>${question?.text}<br /><span class="answer-text">${question?.description}</span></p>`;
              } else {
                tempData.questionText = question?.text;
              }
							tempData.subsectionName = subsection?.name;
							newDataSet.push(tempData);
						});
					}
				});
			}
		}

		// need to find number of questions per section and number of responses per section
		for (let subsection of data) {
			const ndidx = newDataSet?.indexOf(newDataSet?.find((entry) => entry?.subsectionName === subsection?.name));
			if (newDataSet[ndidx]) {
				newDataSet[ndidx].subsectionRowSpan = newDataSet?.filter((entry) => entry?.subsectionName === subsection?.name)?.length;
			}
			if (subsection?.questions?.length) {
				for (let question of subsection.questions) {
					const ndqidx = newDataSet?.filter((entry) => entry?.questionText === question?.text)[0];
					const idxof = newDataSet?.indexOf(ndqidx);
					if (newDataSet[idxof]) {
						newDataSet[idxof].questionRowSpan = question?.responses?.length;
					}
				}
			}
		}

		setFilteredSubsections(newDataSet);
	};

	useEffect(() => {
		getData({ data: microservices.filterTextAnswer(subsections) });
		if (document.querySelectorAll(".parent-flex")[0]) {
			const parentHeight = document.querySelectorAll(".parent-flex")[0]?.clientHeight - 200;
			setTableHeight(parentHeight);
			setLoaded(true);
		}
	}, [subsections]);

	const CustomWrapCell = ({ rowData, field, ...props }) => {
		return (
			<Cell {...props} key={rowData.id}>
				{typeof rowData[field] !== 'object'
					?
						<div className={"cell-word-wrap p-2 " + props.customClass} dangerouslySetInnerHTML={{ __html: rowData[field] }}></div>
					:
						<div className={"cell-word-wrap p-2 " + props.customClass}>{rowData[field]}</div>
        }
			</Cell>
		);
	};

	return (
		loaded && (
			<div id="contract-abstract-answer-table">
				<Table wordWrap="break-word" bordered cellBordered showHeader={false} height={tableHeight} className="caat" data={filteredSubsections}>
					<Column
						flexGrow={1}
						rowSpan={(rowData) => {
							return rowData.subsectionRowSpan;
						}}
					>
						<HeaderCell>Subsections</HeaderCell>
						<CustomWrapCell field="subsectionName" className="subsection" />
					</Column>

					<Column flexGrow={1}>
						<HeaderCell>Questions</HeaderCell>
						<CustomWrapCell field="questionText" className="questions-cell"/>
					</Column>

					<Column flexGrow={1}>
						<HeaderCell>Responses</HeaderCell>
						<CustomWrapCell field="value" />
					</Column>
				</Table>
			</div>
		)
	);
};
export default ContractAnswers;
