import { useState } from 'react';
import { signIn, logout } from '../store';

const SignBackInModal = ({globalStore, handleStoreUpdates}) => {
  document.addEventListener('click', () => {
    globalStore.actions.checkSession(handleStoreUpdates);
  });
  const [active] = useState(globalStore.signBackIn);
  
  const login = () => {
    async function loginAsync(){
      await signIn();
    }
    loginAsync().catch(e => e);
  };
  
  const cancelLogin = () => {
    async function cancelLoginAsync(){
      globalStore.signBackIn = false;
      await handleStoreUpdates(globalStore);
      await logout();
    }
    cancelLoginAsync().catch(e => e);
  };
  
  if(!active) return
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head" style={{backgroundColor:"#fff", borderBottom:0}}>
          <p className="is-size-5">Your session has expired</p>
        </header>
        <div className="modal-card-body">
          <p className="is-loading">Attempting automatic login. You can log in manually by clicking the "Login" button.</p>
          <br />
          <progress className="progress is-small is-primary" max="100">15%</progress>
        </div>
        <footer className="modal-card-foot" style={{backgroundColor:"#fff", borderBottom:0}}>
          <button className="button" onClick={cancelLogin}>Cancel</button>
          <button className="button is-info" onClick={login}>Login with Okta</button>
        </footer>
      </div>
    </div>
  )
};
export default SignBackInModal;