import { Table } from "rsuite";
import React, { useEffect, useState } from "react";

const ReferenceLibraryAnswerHeader = () => {

  const [loaded, setLoaded] = useState(false);
  const { Column, HeaderCell, Cell } = Table;

  useEffect(() => {
    if (document.querySelectorAll(".parent-flex")[0]) {
      setLoaded(true);
    }
  }, []);

  return (
    loaded && (
      <div id="contract-abstract-answer-table-header">
        <Table bordered cellBordered data={[]} autoHeight={true} wordWrap="break-word" className="caat">
          <Column
            flexGrow={1}
          >
            <HeaderCell className="has-text-centered has-text-weight-bold">Subsection</HeaderCell>
            <Cell></Cell>          
          </Column>

          <Column
            flexGrow={3}
          >
            <HeaderCell className="has-text-centered has-text-weight-bold">Question</HeaderCell>
            <Cell></Cell>          
          </Column>

          <Column flexGrow={3}>
            <HeaderCell className="has-text-centered has-text-weight-bold">Response</HeaderCell>
            <Cell></Cell>          
          </Column>

          <Column flexGrow={3}>
            <HeaderCell className="has-text-centered has-text-weight-bold">Image</HeaderCell>
            <Cell></Cell>          
          </Column>

          <Column
            flexGrow={3}
            rowSpan={(rowData) => {
              return rowData.questionRowSpan;
            }}
          >
            <HeaderCell className="has-text-centered has-text-weight-bold">Additional Comments</HeaderCell>
            <Cell></Cell>          
          </Column>
        </Table>
      </div>
    )
  );
};

export default ReferenceLibraryAnswerHeader;
