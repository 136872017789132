import { useState } from 'react';

const AreYouSureDelete = ({ action, title, cb, className, defaultText, icon, data, hideOtherElementsCb, disabled }) => {
  const [areYouSure, setAreYouSure] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [deleteComplete, setDeleteComplete] = useState(false);

  const handleAreYouSure = () => {
    if (hideOtherElementsCb) {
      hideOtherElementsCb(true)
    }
    setAreYouSure(true);
  };

  const handleAction = (e, data) => {
    async function asyncActions() {
      if (e.target.tagName === 'I' || e.target.className.includes('delete-accident')) return e.stopPropagation();
      if (buttonLoader) return;
      setButtonLoader(true);
      const response = await action(data);
      setDeleteComplete(true);
      setTimeout(() => {
        setAreYouSure(false);
        setButtonLoader(false);
        setDeleteComplete(false);
        if (cb) {
          cb({ response });
        }
      }, 1000);
    }
    asyncActions()
      .catch(error => {
        console.error(error);
      });
  };

  const handleCancel = () => {
    if (hideOtherElementsCb) {
      hideOtherElementsCb(false);
    }
    setAreYouSure(false);
  };

  return (
    <>
      {
        !areYouSure ?
          <button className={`button animate-shake ${className ? className : 'is-danger'}`} onClick={handleAreYouSure} title={title} disabled={disabled}>
            <span className={defaultText ? " mr-2 " : ""}>
              {icon || (<i className="fa-solid fa-exclamation"></i>)}
            </span>
            <span>{defaultText}</span>
          </button>
          :
          <span className='is-flex is-flex-wrap-nowrap'>
            {
              !buttonLoader &&
              <button className={`button is-warning mr-2 ${className}`} onClick={handleCancel}>
                <span className={defaultText ? "mr-2" : ""}>
                  <i className="fa-solid fa-ban"></i>
                </span>
                <span className='ays-cancel'>
                  {defaultText ? "Cancel" : ""}
                </span>
              </button>
            }
            <button className={`button is-danger animate-shake ${className}`} onClick={(e) => { handleAction(e, data) }}>
              {
                !buttonLoader ?
                  <span className='is-flex'>
                    <div className="is-flex is-align-items-center">
                      <p className='mb-0'>
                        <span className="mr-2">
                          <i className="fa-solid fa-triangle-exclamation animate-siren"></i>
                        </span>
                        <span className='ays-ays'>Are you sure?</span>
                      </p>
                    </div>
                  </span>
                  :
                  !deleteComplete ?
                    <p className="is-flex align-items-center" style={{ lineHeight: "1rem" }}>
                      <span className="loader mr-2"></span>
                      <span className='pr-5 ays-deleting'>Deleting</span>
                    </p>
                    :
                    <p>
                      <span className="mr-2">
                        <i className="fa-solid fa-check"></i>
                      </span>
                      <span className='pr-5 ays-done'>Done</span>
                    </p>
              }
            </button>
          </span>
      }
    </>
  )
};

export default AreYouSureDelete;