import { apiFetch } from ".";
import store from '../store';

export const getTopics = async (iStore = store) => {
  const response = await apiFetch({ endpoint: 'legal-topics' });
  if(!response) return;
  iStore.topics = [...response];
  return response;
};

export const createNewTopic = async ({params},iStore = store) => {
  console.log('calling create new Topic', params);
  const response = await apiFetch({ endpoint: 'legal-topics', method: 'POST', body:params });
  if(!response) return;
  await getTopics();
  console.log('created: ', response);
  return response;
};

export const updateTopic = async (id, {body}) => {
  if(!id) return Promise.reject('ID is required: Field: "id" is missing from this request.');
  const response = await apiFetch({ endpoint: `legal-topics/${id}`, method: 'PUT', body });
  return response;
};

export const deleteTopic = async (id, iStore = store) => {
  if(!id) {
    const message = "Unable to delete this Topic. Topic ID can not be found.";
    store.actions.handleError({message}, iStore);
    return Promise.reject(message)
  }
  const response = await apiFetch({ endpoint: `legal-topics/${id}`, method: 'DELETE', body: {} });
  if(!response) return;
  await getTopics();
  console.log('deleted: ', response);
  return response;  
}

export const getLegalResponsibilities = async (iStore = store) => {
  const response =  await apiFetch({ endpoint: 'legal-responsibilities' });
  if(!response) return;
  iStore.legalResponsibilities = [...response];
  return response;
};

export const getTopicRoles = async (iStore = store) => {
  const response =  await apiFetch({ endpoint: 'legal-roles' });
  if(!response) return;
  iStore.topicRoles = [...response];
  return response;
};

export const updateLegalResponsibilities = async ({id,body}) => {
  if(!id) return Promise.reject('ID is required: Field: "id" is missing from this request.');
  const response = await apiFetch({ endpoint: `legal-responsibilities/${id}`, method: 'PUT', body });
  return response;
};

const endpoints = {
  getTopics,
  createNewTopic,
  updateTopic,
  deleteTopic,
  getLegalResponsibilities,
  getTopicRoles,
  updateLegalResponsibilities
};

export default endpoints;