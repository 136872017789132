import InputField from "../../../components/TextInput";
import { useEffect } from "react";
import { SelectPicker } from "rsuite";
import microservices from "../../../services/microservices";
import { capitalize } from "lodash";

export default function ProductForm({
  productdetail,
  setproductdetail,
  disabled,
  setchangepending,
  cancelled,
  setcancelled,
  partners,
  products,
  partnerStatuses,
}) {
  const validation = () => setchangepending(!!productdetail?.name);

  const onChange = (e, key) => {
    productdetail[key] = e.target?.value;
    setproductdetail({ ...productdetail });
    validation();
  };

  useEffect(() => {
    // increment sort order based on highest current sortOrder value;
    productdetail.sortOrder = microservices.getNextValue({ list: products, key: "sortOrder" });
    if (cancelled) {
      setcancelled(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelled, products]);

  return (
    <div>
      <InputField label="Product Name" value={productdetail?.name} onChange={(e) => onChange(e, "name")} disabled={disabled} />
      <InputField
        label="Sort Order"
        type="number"
        value={productdetail?.sortOrder}
        onChange={(e) => onChange(e, "sortOrder")}
        disabled={disabled}
        style={{ maxWidth: "75px" }}
      />
      <div className="is-flex is-flex-direction-column mt-3">
        <label className="label mb-0" style={{ fontWeight: "normal", color: "#575757" }}>
          Partner
        </label>
        <div style={{ maxWidth: "500px" }}>
          <SelectPicker
            labelKey="name"
            valueKey="id"
            value={productdetail.partnerId}
            data={partners || []}
            disabled={disabled}
            onClean={() => {
              productdetail.partnerId = "";
              setproductdetail({ ...productdetail });
              validation();
            }}
            onChange={(id) => {
              productdetail.partnerId = id;
              setproductdetail({ ...productdetail });
              validation();
            }}
          />
        </div>
      </div>
      {!!partnerStatuses?.length && (
        <div className="is-flex is-flex-direction-column mt-3 mb-5">
          <label className="label mb-0" style={{ fontWeight: "normal", color: "#575757" }}>
            Product Status
          </label>
          <div style={{ maxWidth: "500px" }}>
            <SelectPicker
              labelKey="label"
              valueKey="value"
              value={productdetail?.status}
              data={partnerStatuses.map(ps => ({label:capitalize(ps), value:capitalize(ps)})) || []}
              className="is-capitalized"
              disabled={disabled}
              searchable={false}
              onClean={() => {
                productdetail.status = "";
                setproductdetail({ ...productdetail });
                validation();
              }}
              onChange={(id) => {
                productdetail.status = id;
                setproductdetail({ ...productdetail });
                validation();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
