import { signOut, getCurrentUser, signInWithRedirect, fetchAuthSession } from 'aws-amplify/auth';
import { capitalize, get, isEmpty, set } from 'lodash';
import { env as amplifyEnv } from '../redirect-mutator';
import api from "../api";
import { v4 as uuidv4 } from 'uuid'; // need to import this anywhere we define the incoming data object
import { setupSessionTimeout } from 'wb-session-timeout';
export const env = process.env.NODE_ENV;

console.log('node env is: ', env);
console.log('amplify env is: ', amplifyEnv);

const getRoleByKey = async ({ key, value }) => {
  return store.partnerRoles.find(pr => pr[key] === value);
};

const checkSession = async (cb = store.handleStoreUpdates) => {
  let sessionExpireTime = get(store, 'user.sessionExp');
  const nowDate = Math.floor(Date.now() / 1000);
  /* To see readable times, use "new Date([unix timestamp] * 1000).toISOString()" */
  if (nowDate >= sessionExpireTime) {
    store.signBackIn = true;
    await cb(store);
    setTimeout(async () => {
      try {
        await signIn();
      } catch (error) {
        console.error(error);
      }
    }, 4000);
  }
};

const setupComplete = () => {
  return store.finishedDataSetup === true;
}

export const store = {
  apiCalls: {
    partner: [],
    product: [],
    productSections: [],
    productSubsections: []
  },
  contracts: false,
  factsheet: false,
  contractSection: {},
  factsheetSection: {},
  havePartnerImagesFromDB: false,
  user: null,
  session: { accessToken: { jwtToken: null } },
  partnerResponsibilities: [],
  partnerRoles: [],
  partners: [],
  legalResponsibilities: [],
  topicRoles: [],
  topics: [],
  persons: [],
  errorCount: 0,
  actions: {
    handleError(e, iStore = store) {
      store.errorCount++
      if (store.errorCount > 3 && e === "Failed to fetch" && !store.vpnError) {
        store.vpnError = true;
        store.notifications = [
          {
            id: uuidv4(),
            type: 'error',
            titleText: 'Error',
            bodyText: (
              <>
                <span>{`${e}. Please verify that you are connected to the WebBank VPN and try again.`}</span>
                <p>If the issue persists, contact: <a href={`mailto:devteam@webbank.com?&subject=skynet%20${e.message}&body=a%20user%20received%20a%20${e.message}%20error%20on%20skynet.`}>devteam@webbank.com</a></p>
              </>
            ),
            canCloseMessage: true,
            autoCloseTimeout: 9000
          }
        ];
        iStore.handleStoreUpdates(iStore);
      }
      if (store.vpnError) return;
      if(typeof e === 'object'){
        try {
          e.message = JSON.parse(e.message);
        } catch (error) {
          e = e?.message ? e : {message: e};
        }
      } else {
        e = {message: e};
      }
      console.log('e.message', e.message);
      store.notifications.push(
        {
          id: uuidv4(),
          type: 'error',
          titleText: 'Error',
          bodyText: (
            <>
              <p>{e.message}</p>
              <p>If the issue persists, contact: <a href={`mailto:devteam@webbank.com?&subject=skynet%20${e.message}&body=a%20user%20received%20a%20${e.message}%20error%20on%20skynet.`}>devteam@webbank.com</a></p>
            </>
          ),
          canCloseMessage: true,
          autoCloseTimeout: 9000
        }
      )
      iStore.handleStoreUpdates(iStore);
    },
    checkSession
  },
  getters: {
    getRoleByKey,
    setupComplete
  },
  signBackIn: false,
  handleStoreUpdates() { },
  reports: [],
  s3ImageObjects: [],
  partnerImages: [],
  notifications: [],
  embeddedReports: [],
  partnerReferenceResponses: [],
  reportsDisplay: 'card',
  partnerUpdated: false
};
export default store;

export const signIn = async () => {
  sessionStorage.removeItem('signedOut');
  try {
    const provider = {
      custom: 'Okta'
    }
    await signInWithRedirect({ provider });
  } catch(err) {
    console.log(err);
  }
}

export const logout = async () => {
  try {
    store.user = null;
    sessionStorage.setItem('signedOut', true);
    document.removeEventListener('click', checkSession);
    await signOut();
  } catch(err) {
    console.log('error', err);
    store.actions.handleError(err, store);
  }
}

function _getNameFromUserName(username) {
  return username.replace('okta_', '').replace('@webbank.com', '').split('.').map((name) => (capitalize(name))).join(' ');
}

function _getEmailFromUserName(username) {
  return username.replace('okta_', '');
}

export const getUserCognitoGroups = async (targetGroup) => {
  const authSession = await fetchAuthSession();
  if(authSession && !isEmpty(authSession)){
    const tempGroups = authSession?.tokens?.idToken?.payload["cognito:groups"];
    return tempGroups?.includes(targetGroup) ?? [];
  } else {
    return [];
  }
};

const setupData = async () => {
  if(store?.user && !isEmpty(store?.user)){
    const apiCallArray = [];
    if (!store.topics.length) {
      apiCallArray.push(api.legal.getTopics());
    }
    if (!store.embeddedReports.length) {
      apiCallArray.push(api.reports.getEmbeddedReports());
    }
    await Promise.all(apiCallArray);
  }
  store.finishedDataSetup = true;
};

export const checkUser = async () => {
  try {
    const authSession = await fetchAuthSession();
    store.user = await getCurrentUser() ?? null;
    set(store, 'user.name', _getNameFromUserName(get(store, 'user.username')));
    set(store, 'user.email', _getEmailFromUserName(get(store, 'user.username')));
    const userGroups = authSession.tokens.idToken.payload["cognito:groups"];
    const exp = authSession.tokens.accessToken.payload.exp
    set(store, 'user.groups', userGroups);
    set(store, 'user.isAdmin', userGroups.includes('Admins'));
    set(store, 'user.sessionExp', exp);
    return store.user;
  } catch (error) {
    if(store.user === null){
      return error;
    }
  } finally {
    setupData().catch(e => e);
    if (store.user) {
      setupSessionTimeout(logout, 720);
    }
  }
}