import { Table } from "rsuite";
import React, { useEffect, useState } from "react";

const ReferenceLibraryAnswers = ({ subsections, section }) => {
  // state
  const [loaded, setLoaded] = useState(false);

  // refs and variables
  const { Column, HeaderCell, Cell } = Table;

  // functions and callbacks
  const getData = ({ data }) => {
    // restructure data so each answer is it's own contained dataset (needed to remove arrays for table display purposes)
    const newDataSet = [];
    for (let subsection of data) {
      if (subsection?.questions?.length) {
        subsection?.questions?.forEach((question) => {
          if (question?.responses?.length) {
            question?.responses?.forEach((response) => {
              const tempData = { ...response };
              tempData.topic = question?.topic;
              if(question?.description){
                tempData.questionText = `<p>${question?.text}<br /><span class="answer-text">${question?.description}</span></p>`;
              } else {
                tempData.questionText = question?.text;
              }
              tempData.subsectionName = subsection?.name;
              newDataSet.push(tempData);
            });
          }
        });
      }
    }

    // need to find number of questions per section and number of responses per section
    for (let subsection of data) {
      const ndidx = newDataSet?.indexOf(newDataSet?.find((entry) => entry?.subsectionName === subsection?.name));
      if (newDataSet[ndidx]) {
        newDataSet[ndidx].subsectionRowSpan = newDataSet?.filter((entry) => entry?.subsectionName === subsection?.name)?.length;
      }
      if (subsection?.questions?.length) {
        for (let question of subsection.questions) {
          const ndqidx = newDataSet?.filter((entry) => entry?.questionText === question?.text)[0];
          const idxof = newDataSet?.indexOf(ndqidx);
          if (newDataSet[idxof]) {
            newDataSet[idxof].questionRowSpan = question?.responses?.length;
          }
        }
      }
    }

    // Manipulate the JSON data to remove Text Answer from responses and put the child comment in the responses column
    const updatedJsonData = newDataSet.map((item) => {
      if (item.value === "Text Answer") {
        item.value = item.comments;
        item.comments = null;
      }
      return item;
    });

    return updatedJsonData;
  };

  const goToImage = ({ image }) => {
    localStorage.setItem("partnerReferenceResponseImage", JSON.stringify(image));
    const newTab = window.open();
    newTab.location.href = "/image";
  };

  useEffect(() => {
    if (document.querySelectorAll(".parent-flex")[0]) {
      setLoaded(true);
    }
  }, []);

  const CustomWrapCell = ({ rowData, field, ...props }) => {
    let displayValue = rowData[field];

    // Check if the string contains HTML tags
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(displayValue);
  
    if (typeof displayValue === 'string' && !isHTML && (displayValue.includes('\r') || displayValue.includes('\n'))) {
      const stringWithBreaks = displayValue.replace(/\\r|\\n|\r|\n/g, '<br />');
      const testArr = stringWithBreaks.split('<br />');
      displayValue = (<div className="is-flex is-flex-direction-column">{testArr.map((w, i) => (<div key={i}>{w}</div>))}</div>);
    }
  
    // Render the cell
    return (
      <div className={`${field === "image" ? "answer-image-cell" : ""}`}>  
        {field === "image" ? (
          <Cell {...props} key={rowData.id}>
            <div className={"cell-word-wrap " + props.customClass} style={{ overflow: "hidden" }}>
              <div
                className="p-1 is-flex is-justify-content-center is-align-items-center"
                style={{ width: "100%", cursor: "pointer" }}
                title="Click to open in new tab."
                onClick={() => goToImage({ image: rowData[field] })}
              >
                <img src={rowData[field]?.content} alt={rowData[field]?.name} style={{ maxHeight: "70px" }} />
              </div>
            </div>
          </Cell>
      ) : (
        <Cell {...props} key={rowData.id}>
          {typeof displayValue !== 'object'
            ?
              <div className={"cell-word-wrap " + props.customClass} dangerouslySetInnerHTML={{ __html: displayValue }}></div>
            :
              <div className={"cell-word-wrap " + props.customClass}>{displayValue}</div>
          }
        </Cell>
      )}
    </div>
  );
};

  

  return (
    loaded && (
      <div id="reference-library-answer-table">
        <div className="referenceTitle">{section.name}</div>
        <Table bordered cellBordered data={getData({ data: subsections })} autoHeight={true} wordWrap="break-word" className="caat" showHeader={false}>
          <Column
            flexGrow={1}
            rowSpan={(rowData) => {
              return rowData.subsectionRowSpan;
            }}
          >
            <HeaderCell className="has-text-centered has-text-weight-bold"></HeaderCell>
            <CustomWrapCell field="subsectionName" className="subsection" />
          </Column>

          <Column
            flexGrow={3}
            rowSpan={(rowData) => {
              return rowData.questionRowSpan;
            }}
          >
            <HeaderCell className="has-text-centered has-text-weight-bold">Question</HeaderCell>
            <CustomWrapCell field="questionText" className="questions-cell"/>
          </Column>

          <Column flexGrow={3}>
            <HeaderCell className="has-text-centered has-text-weight-bold">Response</HeaderCell>
            <CustomWrapCell field="value" />
          </Column>

          <Column flexGrow={3}>
            <HeaderCell className="has-text-centered has-text-weight-bold">Image</HeaderCell>
            <CustomWrapCell field="image" />
          </Column>

          <Column
            flexGrow={3}
            rowSpan={(rowData) => {
              return rowData.questionRowSpan;
            }}
          >
            <HeaderCell className="has-text-centered has-text-weight-bold">Additional Comments</HeaderCell>
            <CustomWrapCell field="comments" />
          </Column>
        </Table>
      </div>
    )
  );
};

export default ReferenceLibraryAnswers;
