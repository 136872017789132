import Header from "../components/header";
import SideMenu from "../components/sidemenu";
import { Loader } from "rsuite";
import { useState, useEffect } from "react";

export default function PageTemplate({ pageTitle, content, setup, getPermission, defaultOpenMenus, crudControls, className, ...props }) {
  const [dataLoading, setDataLoading] = useState(true);

  async function updateAllData() {
    setDataLoading(true);
    // Do page setup here.
    if (setup) {
      await setup();
    }
    setDataLoading(false);
  }

  async function runAsyncFunctions() {
    if (getPermission) {
      await getPermission();
    }
    await updateAllData();
  }

  useEffect(() => {
    runAsyncFunctions();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header></Header>
      <div className="is-flex" style={{ height: "100%" }}>
        <SideMenu defaultOpenMenus={defaultOpenMenus}></SideMenu>
        <div className="is-flex-grow-1 is-flex is-flex-direction-column">
          <h4 className="px-3 pt-2 title-color is-flex is-justify-content-space-between is-align-items-center">
            <span>{pageTitle}</span>
            {crudControls}
          </h4>
          <div className="is-flex is-flex-direction-column is-width-full" style={{ height: "100%" }}>
            {dataLoading ? (
              <div className="is-full">
                <Loader center size="lg" />
              </div>
            ) : (
              <div className={className} style={{ height: "100%" }}>
                {content}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
