import React, { useEffect } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const PdfButton = ({ reportName = "", section = "", id = "", tableContainerClass = "", sourceHeightId = "", paginate, setPdfLoading }) => {
  const printJsPDF = async ({ reportName, section, id }) => {
    setPdfLoading(true);
    try {
      if (paginate) {
        // hide all the elements we don't want in the export
        document.querySelectorAll(".hide-on-export").forEach((el) => el.classList.add("is-hidden"));
        const sourceHeight = document.getElementById(sourceHeightId).offsetHeight;

        const elementToCapture = document.getElementById(id);

        // remove classes on container so we capture all the data
        const elementToRemoveClasses = document.getElementById("remove-classes");

        // remove height and flex from parent-flex then get dimensions
        elementToCapture.className = "parent-flex-print";
        const elementWidth = elementToCapture.offsetWidth;

        elementToCapture.scrollTo(0, 0);

        const PDF_Height = 841.89; // Width of an 'a4' size page in landscape orientation
        const totalPDFPages = Math.ceil(sourceHeight / PDF_Height) + 1;
        const pdf = new jsPDF({ orientation: "l", unit: "pt", format: [elementWidth, PDF_Height], compress: true});
        for (let i = 0; i < totalPDFPages; i++) {
          const canvas = await html2canvas(elementToCapture, {
            y: i * PDF_Height,
            height: PDF_Height,
            width: elementWidth,
          });
          const imgData = canvas.toDataURL("image/png");
          if (i > 0) {
            pdf.addPage();
          }
          pdf.addImage(imgData, "PNG", 0, 0, elementWidth, PDF_Height, "", "FAST");
        }

        elementToRemoveClasses.className = "contain-and-scroll py-3 is-relative";
        elementToCapture.className = "parent-flex";
        const date = new Date().toISOString().split("T")[0];
        pdf.save(`${reportName} - ${section} report - ${date}`);

        let pdfIconToRemove = document.querySelector(".pdf-button-container");
        if (pdfIconToRemove) {
          pdfIconToRemove.style.visibility = "unset";
        }
      } else {
        // hide all the elements we don't want in the export
        document.querySelectorAll(".hide-on-export").forEach((el) => el.classList.add("is-hidden"));
        const sourceHeight = document.getElementById(sourceHeightId).offsetHeight;

        const elementToCapture = document.getElementById(id);

        // remove classes on container so we capture all the data
        const elementToRemoveClasses = document.getElementById("remove-classes");

        // remove height and flex from parent-flex then get dimensions
        elementToCapture.className = "parent-flex-print";
        const elementWidth = elementToCapture.offsetWidth;
        const elementHeight = elementToCapture.offsetHeight;

        elementToCapture.scrollTo(0, 0);

        const canvas = await html2canvas(elementToCapture, {
          width: elementWidth,
          height: elementHeight,
        });
        const imgData = canvas.toDataURL("image/png");
        elementToRemoveClasses.className = "contain-and-scroll py-3 is-relative";
        elementToCapture.className = "parent-flex";
        const format = [elementWidth, elementHeight];
        const pdf = new jsPDF({ orientation: "p", unit: "pt", format, compress: true});
        const top_left_margin = 10;
        pdf.addImage(imgData, "PNG", top_left_margin, top_left_margin, elementWidth, sourceHeight, "", "FAST");

        const date = new Date().toISOString().split("T")[0];
        pdf.save(`${reportName} - ${section} report - ${date}`);

        let pdfIconToRemove = document.querySelector(".pdf-button-container");
        if (pdfIconToRemove) {
          pdfIconToRemove.style.visibility = "unset";
        }
        let pdfDisclaimer = document.getElementById("pdf-disclaimer");
        if (pdfDisclaimer) {
          pdfDisclaimer.style.display = "none";
        }
      }
    } catch (error) {
      // Handle any errors
      console.error(error);
    } finally {
      // restore hidden elements
      document.querySelectorAll(".hide-on-export").forEach((el) => el.classList.remove("is-hidden"));
      setPdfLoading(false);
    }
  };

  useEffect(() => {
    // Call printJsPDF here if necessary
  }, [reportName, section, id, tableContainerClass]);

  return (
    <>
      <span className="pdf-button-container">
        <div id="save-as-pdf" className="one hide-on-download" onClick={() => printJsPDF({ reportName, section, id })}>
          <div className="pdf-flap-layer"></div>
          <div className="pdf-flap-infill-layer"></div>
          <div className="pdf-flap-negative-corner-layer"></div>
          <div className="pdf-base-negative-layer"></div>
          <div className="pdf-text-layer">PDF</div>
        </div>
      </span>
    </>
  );
};

export default PdfButton;
