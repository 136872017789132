import api from '../../../api';
import store from '../../../store';
import SharedComponent from './SharedProductComponent';
import { useState } from "react";

export default function ProductsView({ handleEdit, productUpdate, products, setProducts, ...props }) {
  const [loadingData, setLoadingData] = useState(true);

  const deleteAction = async ({ id }) => {
    if (!id) throw new Error("no id from deleteAction call");
    return api.products.deleteData({ id });
  };

  const assignPartnerName = ({ incomingData }) => {
    if (store?.partners) {
      for (let item of incomingData) {
        if (item?.partnerId) {
          item.partnerName = store?.partners?.find(partner => partner.id === item.partnerId)?.name;
        }
      }
    }
    return incomingData;
  };

  const sharedComponentProps = {
    ...props,
    handleEdit,
    data: assignPartnerName({ incomingData: products }),
    deleteAction,
    async cb(){
      await props.getAllPartnerData();
      props.setProductsLoading(false);
    },
    loadingData,
    setLoadingData,
    tab: 'products',
    areYouSure: true,
    rowExpandedHeight: 800,
  };

  return (
    <SharedComponent
      {...sharedComponentProps}
    />
  )
}