import { apiFetch } from "..";
import store from '../../store';

const getResponse = async ({ id, endpoint = `reference-responses/${id}`, method = 'GET', iStore = store }) => {
  const response = await apiFetch({ endpoint, method, iStore });
  if (!response) {
    const message = 'no response from getResponses endpoint.';
    console.error(message);
    return Promise.resolve(message);
  }
  return response;
};

const getResponses = async ({ productId, endpoint = `reference-responses/?productId=${productId}`, method = 'GET', iStore = store }) => {
  const response = await apiFetch({ endpoint, method, iStore });
  if (!response) {
    const message = 'no response from getResponses endpoint.';
    console.error(message);
    return Promise.resolve(message);
  }
  iStore.partnerReferenceResponses = [...response];
  return iStore.partnerReferenceResponses;
};

const createResponse = async ({ body, endpoint = `reference-responses`, method = 'POST', iStore = store }) => {
  const response = await apiFetch({ body, endpoint, method, iStore });
  if (!response) {
    const message = 'no response from getResponses endpoint.';
    console.error(message);
    return Promise.resolve(message);
  }
  body?.partnerId ? getResponses({ partnerId: response?.partnerId, endpoint: `reference-responses/?partnerId=${response?.partnerId}` }).catch(e => e) :
  getResponses({ productId: response?.productId }).catch(e => e);
  return response;
};

const updateResponse = async ({id, body, endpoint = `reference-responses/${id}`, method = 'PUT', iStore = store }) => {
  if(!id) {
    const message = `Unable to update this Response. Response ID: (${id}) can not be found.`;
    store.actions.handleError({message}, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ body, endpoint, method, iStore });
  if (!response) {
    const message = 'no response from getResponses endpoint.';
    console.error(message);
    return Promise.resolve(message);
  }
  body?.partnerId ? getResponses({ partnerId: response?.partnerId, endpoint: `reference-responses/?partnerId=${response?.partnerId}` }).catch(e => e) :
  getResponses({ productId: response?.productId }).catch(e => e);
  return response;
};

const deleteResponse = async ({ id, productId, partnerId, body = {}, endpoint = `reference-responses/${id}`, method = 'DELETE', iStore = store }) => {
  // Disallow api call if id is undefined to prevent 404 error
  if (typeof id === 'undefined') {
    console.log('id is undefined. Skipping the API call.');
    return;
  }
  const response = await apiFetch({ body, endpoint, method, iStore });
  if (!response) {
    const message = 'no response from getResponses endpoint.';
    console.error(message);
    return Promise.resolve(message);
  }
  partnerId ? getResponses({ partnerId, endpoint: `reference-responses/?partnerId=${partnerId}` }).catch(e => e) :
  getResponses({ productId }).catch(e => e);
  return response;
};

const endpoints = {
  get: ({ id, productId, endpoint }) => id ? getResponse({ id }) : getResponses({ productId, endpoint }),
  create: createResponse,
  update: updateResponse,
  deleteData: deleteResponse
};

export default endpoints;