import Header from "../../components/header";
import SideMenu from "../../components/sidemenu";
import { useNavigate, useLocation } from "react-router-dom";

export default function ReportView() {
  const props = useLocation().state;
  const navigate = useNavigate();

  // hash tag breaks the url so we need to do some voodoo that you do
  const reportUrl = decodeURIComponent(props.reportUrl);
  const title = decodeURIComponent(props.title);

  return (
    <>
      <Header></Header>
      <div className="is-flex" style={{ height: "100%" }}>
        <SideMenu defaultOpenMenus={["3"]}></SideMenu>
        <div className="is-flex-grow-1 is-flex is-flex-direction-column">
          <h4 className="px-3 pt-2 title-color is-flex is-justify-content-space-between is-align-items-center">
            <span>{title}</span>
            <button className="title-button" onClick={() => navigate(`/reports`)}>
              Back to Reports Dashboard
            </button>
          </h4>
          <div style={{ height: "100%" }}>
            {reportUrl.includes("pulse") ? (
              <tableau-pulse id="tableauPulse" src={reportUrl} height="800" width="100%" token={props.tableauJwt}></tableau-pulse>
            ) : (
              <tableau-viz id="tableau-viz" src={reportUrl} toolbar="bottom" token={props.tableauJwt}></tableau-viz>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
