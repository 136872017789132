import { useState } from "react";

const ApiDeleteButton = ({ ...props }) => {
  const [triggered, setTriggered] = useState(false);
  const [done, setDone] = useState(false);

  const handleDelete = async () => {
    setTriggered(true);
    await props.action();
    setDone(true);
    setTimeout(() => {
      setTriggered(false);
      setDone(false);
      if (props.cb) {
        props.cb();
      }
    }, 1000);
  };

  return (
    <button className={`is-danger button ${props.controlClass}`} onClick={handleDelete} style={props.controlStyle}>
      {!triggered ? <span>
        <i className={`fa-solid mr-2 ${props.defaultIcon || "fa-trash"}`} />
        <span>{props.defaultText || "Delete"}</span>
      </span>
        :
        !done ? <span className="is-flex is-align-items-center">
          <span className="loader mr-2"></span>
          <span>{props.processingText || "Deleting..."}</span>
        </span>
          :
          <span>
            <i className={`fa-solid mr-2 fa-check`} />
            <span>{props.completeText || "Done"}</span>
          </span>
      }
    </button>
  )
};

export default ApiDeleteButton;