import { useState } from 'react';

export default function Toggle({...props}){
  const [togglePos, setTogglePos] = useState(true);
  const toggleSwitch = () => {
    setTogglePos(!togglePos)
    props.cb(togglePos);
  };

  return (
    <div className="field is-flex is-align-items-center">
      <span className="mb-0 mr-2 label">{props.label}</span>
      <div className="is-clickable" onClick={toggleSwitch}>
        <div className={`override-toggle ${togglePos ? 'is-justify-content-flex-start' : 'is-justify-content-flex-end override-toggle-on'}`}>
          <div className={`override-latch ${togglePos ? '' : 'override-latch-on'}`}></div>
        </div>
      </div>
    </div>
  )
}