import { apiFetch } from "..";
import store from '../../store';

export const create = async ({ body, endpoint = `products` }, method = 'POST') => {
  console.log('create body', body);
  const response = await apiFetch({ body, endpoint, method });
  if (!response) return;
  return response;
};

export const getProduct = async (id, endpoint = `products/${id}/?includeSections=true`, iStore = store) => {
  if (!id) {
    const message = `Unable to get this Product. Product ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ id, endpoint });
  if (!response) return;
  return response;
};

export const getProducts = async ({ endpoint = `products/?includeSections=true`, iStore = store }) => {
  const response = await apiFetch({ endpoint });
  if (!response) return;
  iStore.products = [...response];
  return iStore.products;
};

export const update = async ({ id, body, endpoint = `products/${id}`, method = 'PUT', iStore = store }) => {
  console.log('updating', { id, body });
  if (!id) {
    const message = `Unable to update this Product. Product ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ id, body, endpoint, method });
  if (!response) return Promise.reject('error');
  return response;
};

export const deleteProduct = async ({ id, endpoint = `products/${id}`, method = 'DELETE', iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this Product. Product ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ id, endpoint, body: {}, method });
  if (!response) return Promise.reject('error');
  return response;
};

export const clone = async ({ body, endpoint = `copy-product-responses`, method = 'POST' }) => {
  const response = await apiFetch({ body, endpoint, method });
  if (!response) return Promise.reject('error');
  return response;
};

export const getProductStatusList = async () => {
  return ["in development", "active", "Terminated"];
};

const endpoints = {
  create,
  get: ({ id, endpoint }) => id ? getProduct(id) : getProducts({ endpoint }),
  update,
  deleteData: deleteProduct,
  clone,
  getProductStatusList,
};

export default endpoints;