import { useState } from "react";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import InputField from "../TextInput";
import ApiSubmitButton from "../buttons/ApiSubmitButton";
import AreYouSureDelete from "../buttons/AreYouSureDelete";
import { words, startCase } from "lodash";

const RoleColumnEdit = ({role, columnToggleAction}) => {
  const navigate = useNavigate();
  const [triggeredAreYouSure, setTriggeredAreYouSure] = useState(false);
  const [columnEditValue, setColumnEditValue] = useState("");
  const [savingColumn, setSavingColumn] = useState(false);
  const [doneSavingColumn, setDoneSavingColumn] = useState();

  const deletePartnerRole = async (id) => {
    await api.partner.deletePartnerRole({id});
  };

  const deletePartnerRoleCb = async () => {
    columnToggleAction();
    setTriggeredAreYouSure(false);
    navigate(0);
  };

  const handleRoleNameInput = (e) => {
    setColumnEditValue(e.target.value);
  };

  const handleColumnUpdate = async () => {
    setSavingColumn(true);
    const body = structuredClone(role);
    body.name = startCase(columnEditValue.toLowerCase());
    const response = await api.partner.updatePartnerRole({id:body.id, body});
    if(!response) throw new Error('no response from update api');
    return response;
  };

  const afterUpdateCb = async (val) => {  
    setSavingColumn(false);
    setDoneSavingColumn(true);
    navigate(0);
  };

  const cancelEdit = () => {
    setColumnEditValue("");
    columnToggleAction();
  };

  return (
    <div className="is-flex is-flex-direction-column" >
      <div>
        <InputField
          id={role.id}
          value={columnEditValue}
          placeholder={ words(role.name).join(' ') }
          onChange={handleRoleNameInput}
          disabled={triggeredAreYouSure || savingColumn}
          autoComplete="off"
          controlClass="mb-3"
        />
      </div>
      <span className="is-flex">
        {
          !triggeredAreYouSure &&
          <span className="is-flex">
            <button className="button is-warning mr-2" onClick={cancelEdit} title="Cancel" disabled={savingColumn}><i className="fa-solid fa-ban"></i></button>
            <ApiSubmitButton 
              title="Update"
              className="mr-2"
              defaultLabel=""
              operationLabel=""
              action={handleColumnUpdate}
              disabled={!!!columnEditValue}
              cb={afterUpdateCb}
              // used to force correct rendering when parent re-renders
              actionTriggered={savingColumn}
              actionDone={doneSavingColumn}
            />
          </span>
        }
        <AreYouSureDelete 
          title="Delete"
          icon={<i className="fa-solid fa-trash actions"></i>}
          action={() => deletePartnerRole(role.id)}
          hideOtherElementsCb={setTriggeredAreYouSure}
          cb={deletePartnerRoleCb}
          disabled={savingColumn}
        />
      </span>
    </div>
  )
};

export default RoleColumnEdit;