import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import Root from "../pages/root";
import PartnerResponsibilities from "../pages/partner/PartnerResponsibilities";
import PartnerResponsibilitiesMin from "../pages/partner/PartnerResponsibilitiesCompact";
import LegalResponsibilities from "../pages/legal/LegalResponsibilities";
import LegalDashboard from "../pages/legal/LegalDashboard";
import StrategicPartnersDashboard from "../pages/partner/StrategicPartnersDashboard";
import PartnerView from "../pages/partner/PartnerView";
import ProductsDashboard from "../pages/partner/products/ProductsDashboard";
import ReportsLandingPage from "../pages/reports/Reports";
import Faq from "../pages/faq/Faq";
import FaqAdminQuestion from "../pages/faq/FaqAdminQuestion";
import FaqAdminSection from "../pages/faq/FaqAdminSection";
import Login from "../pages/Login";
import DevAdmin from "../pages/DevAdmin";
import Compliance from "../pages/Compliance";
import store, { checkUser, signIn, getUserCognitoGroups } from "../store";
import AnswerImage from "../pages/partner/products/AnswerImage";
import Treasury from "../pages/treasury/TreasuryFunction";
// Reports
import ReportView from "../pages/reports/reportView";

const setCookieIfNotLoggedIn = () => {
  const queryString = window.location.search;

  // Create a new URLSearchParams object with the query string
  const params = new URLSearchParams(queryString);

  // Get individual query parameters
  const title = params.get("title");
  const url = params.get("url");

  let cookieData;

  if (title && url) {
    cookieData = {
      title: title,
      url: url,
    };
    const cookieValue = JSON.stringify(cookieData);
    // Set the cookie with the JSON string and optional parameters (e.g., expiration date)
    document.cookie = `tableauCookie=${cookieValue}; expires=${tabCookieExpirationDate()}; path=/`;
  }
};

const tabCookieExpirationDate = () => {
  // set the cookie to expire 10 minutes from now
  const currentDate = new Date();
  const tenMinutesLater = new Date(currentDate.getTime() + 10 * 60 * 1000);
  // Convert the Date objects to GMTString format
  const tenMinutesLaterGMTString = tenMinutesLater.toUTCString();
  return tenMinutesLaterGMTString;
};

const appStore = store;
const loaderCheck = async (a) => {
  if (a.params["*"]) {
    return redirect("/");
  }
  await checkUser();
  if (!appStore.user && sessionStorage.signedOut) {
    setCookieIfNotLoggedIn();
    return redirect("/login");
  } else if (!appStore.user) {
    setCookieIfNotLoggedIn();
    await signIn();
  }
  //TODO: Tie this to feature flag instead. Once released, this code should be cleaned up.
  const isDev = await getUserCognitoGroups("dev");
  if (a.request.url.includes("devAdmin") && !isDev) {
    return redirect("/");
  }
  // protect faq routes
  const isFaqAdmin = await getUserCognitoGroups("faqAdmin");
  if (a.request.url.includes("faq-admin") && !isFaqAdmin) {
    return redirect("/");
  }
  // protect legal routes
  const isLegalAdmin = await getUserCognitoGroups("legalResponsibilitiesAdmin");
  if (a.request.url.includes("legaldashboard") && !isLegalAdmin) {
    return redirect("/");
  }
  // protect product routes
  const isProductAdmin = await getUserCognitoGroups("productAdmin");
  if (a.request.url.includes("adminModules/products") && !isProductAdmin) {
    return redirect("/");
  }
  // protect contract-abstract routes
  const isContractAbstractAdmin = await getUserCognitoGroups("contractAbstractAdmin");
  if (a.request.url.includes("adminModules/contract-abstract") && !isContractAbstractAdmin) {
    return redirect("/");
  }
  // protect contract-abstract routes
  const partnerReferenceAdmin = await getUserCognitoGroups("partnerReferenceAdmin");
  if (a.request.url.includes("adminModules/product-details") && !partnerReferenceAdmin) {
    return redirect("/");
  }
  // protect contract-abstract routes
  if (a.request.url.includes("adminModules/partner-details") && !partnerReferenceAdmin) {
    return redirect("/");
  }
  // protect treasury routes
  const treasuryAdmin = await getUserCognitoGroups("treasuryAdmin");
  if (a.request.url.includes("treasury") && !treasuryAdmin) {
    return redirect("/");
  }

  return !store.user ? redirect("/login") : null;
};

export const routes = createBrowserRouter([
  {
    path: "*",
    element: <Root />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/",
    element: <Root />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/partnerresponsibilities",
    element: <PartnerResponsibilities />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/partnerresponsibilities/min",
    element: <PartnerResponsibilitiesMin />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/legalresponsibilities",
    element: <LegalResponsibilities />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/legaldashboard",
    element: <LegalDashboard />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/spd",
    element: <StrategicPartnersDashboard />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/partner/:id",
    element: <PartnerView />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/adminModules/:urlView",
    element: <ProductsDashboard />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/faq/:section",
    element: <Faq />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/faq",
    element: <Faq />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/compliance",
    element: <Compliance />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/faq-admin-question",
    element: <FaqAdminQuestion />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/faq-admin-section",
    element: <FaqAdminSection />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/reports",
    element: <ReportsLandingPage />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/reports/report-view",
    element: <ReportView />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/image",
    element: <AnswerImage />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/treasury",
    element: <Treasury />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
  {
    path: "/devAdmin",
    element: <DevAdmin />,
    async loader(a) {
      await checkUser();
      return loaderCheck(a);
    },
  },
]);

const router = {
  ReactDOM,
  RouterProvider,
};
export default router;
