import { apiFetch } from "..";
import store from '../../store';

export const create = async ({body}) => {
  console.log('create answer', body);
  const response = await apiFetch({body, method: 'POST', endpoint: 'reference-answers'});
  if(!response) return;
  return response;
};

export const getAnswer = async (id, iStore = store) => {
  if(!id) {
    const message = `Unable to get this answer. answer ID (${id}) can not be found.`;
    store.actions.handleError({message}, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({id, endpoint: `reference-answers/${id}`});
  if(!response) return;
  iStore.productAnswers = {...response};
  return iStore.productAnswers;
};

export const getAnswers = async ({endpoint, iStore = store}) => {
  const response = await apiFetch({endpoint: 'reference-answers'});
  if(!response) return;
  iStore.productAnswers = [...response];
  return iStore.productAnswers;
};

export const update = async ({id, body, iStore = store}) => {
  console.log('updating', {id, body});
  if(!id) {
    const message = `Unable to update this Answer. Answer ID (${id}) can not be found.`;
    store.actions.handleError({message}, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({id, body, method: 'PUT', endpoint: `reference-answers/${id}`});
  if(!response) return Promise.reject('error');
  return response;
};

export const deleteAnswer = async ({id, iStore = store}) => {
  console.log('deleting answer with id', id);
  if(!id) {
    const message = `Unable to delete this answer. answer ID (${id}) can not be found.`;
    store.actions.handleError({message}, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({id, endpoint: `reference-answers/${id}`, method: 'DELETE', body:{}});
  if(!response) return Promise.reject('error');
  return response;
};

const endpoints = {
  create,
  get: ({id, endpoint}) => id ? getAnswer(id): getAnswers({endpoint}),
  update,
  deleteData: deleteAnswer
};

export default endpoints;