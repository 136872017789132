import { Checkbox } from "rsuite";

export default function AnswerCell({ answer, tab }) {
  let allowImagePretty;
  switch (answer.allowImage) {
    case (1):
    case ("1"):
    case (true):
    case ("true"):
      allowImagePretty = "true";
      break;
    default:
      allowImagePretty = "false";
      break;
  }
  return (
    answer?.id &&
    <div className="ml-6 is-flex is-align-items-center no-pointer is-size-6" style={{ listStyleType: "circle" }}>
      <li>{answer.value}</li>
      {
        tab === 'questions' &&
        <div className="is-flex is-align-items-center">
          <span className="mx-3">|</span>
          <Checkbox checked={!!answer?.additionalComments} readOnly={true}>Additional Comments</Checkbox>
          <Checkbox checked={!!answer?.commentsRequired} readOnly={true}>Comments Required</Checkbox>
          <span className="mx-3">|</span>
          <span className="is-capitalized">Allow Image: {allowImagePretty}</span>
        </div>
      }
    </div>
  )
}