import { useState, useRef, useCallback, useEffect } from "react";
import { Loader } from "rsuite";
import api from "../../api";
import { orderBy } from "lodash";
import QuestionType from "../../components/products/QuestionType";
import { v4 as uuidV4 } from "uuid";
import store, { getUserCognitoGroups } from "../../store";
import InlineSaveIndicator from "../../components/InlineSaveIndicator";
import CommentBox from "../../components/CommentBox";
import PartnerFactSheetAnswers from "./PartnerFactSheetAnswerView";

const PartnerFactSheet = ({ partner, setView }) => {
  // state
  const [loading, setLoading] = useState(true);
  const [isFactSheetAdmin, setisFactSheetAdmin] = useState(false);
  const [areaView, setAreaView] = useState();
  const [subsections, setSubsections] = useState([]);
  const [showSaving, setShowSaving] = useState(false);
  const [showSavingDone, setShowSavingDone] = useState(false);
  const [requireComment, setRequireComment] = useState(false);
  const [parnterDetails, setPartnerDetails] = useState({});

  // variables and refs
  const views = ["answers", "questions"];
  const requireCommentShow = useRef(false);
  const responses = useRef([]);

  // Callbacks and functions
  const showSavingCB = useCallback((val) => setShowSaving(val), []);
  const showSavingDoneCB = useCallback((val) => setShowSavingDone(val), []);

  const save = async ({ data, question, setDisableItemList }) => {
    const responsesForThisQuestion = question?.responses?.find(
      (response) =>
        response?.additionalComments || response?.comments || response?.value || (response?.value && data?.selectedObject?.type === "date-picker")
    );
    if (responsesForThisQuestion?.comments === data?.comment && data?.method === "update") return;

    showSavingCB(data?.method !== "update" ? data?.selectedObject?.questionId : question?.id);
    const body = {
      questionId: data?.selectedObject?.questionId,
      partnerId: partner?.id,
      value: data?.selectedObject?.value,
    };
    switch (data?.method) {
      case "create":
        body.comments = data?.selectedObject?.comments;
        const createResponse = await api.referenceResponses.create({ body });
        const createdResponseIdx = question.responses?.findIndex((response) => response?.id === createResponse?.id);
        if (createdResponseIdx === -1 || createdResponseIdx === undefined) {
          createResponse.additionalComments = data?.selectedObject?.additionalComments;
          createResponse.commentsRequired = data?.selectedObject?.commentsRequired;
          if (!question.responses) {
            question.responses = [];
          }
          question.responses?.push(createResponse);
        }
        break;
      case "update":
        body.id = responsesForThisQuestion?.id;
        body.value = responsesForThisQuestion?.value;
        body.questionId = question?.id;
        body.comments = data?.comment;
        const updateResponse = await api.referenceResponses.update({ id: body?.id, body });
        responsesForThisQuestion.comments = updateResponse?.comments;
        if (responsesForThisQuestion.comments) {
          requireCommentShow.current = false;
        }
        break;
      case "delete":
        const deleteResponse = question?.responses?.find((response) => response?.questionId === body.questionId && response?.value === body?.value);
        body.id = deleteResponse?.id;
        const deletedId = await api.referenceResponses.deleteData({ id: body?.id, partnerId: body.partnerId });
        const deletedResponseIdx = question?.responses?.findIndex((response) => response?.id === deletedId);
        if (deletedResponseIdx > -1) {
          question?.responses?.splice(deletedResponseIdx, 1);
        }
        break;
      default:
        break;
    }

    setTimeout(() => {
      if (setDisableItemList) {
        setDisableItemList([]);
      }
      showSavingDoneCB(true);
      setTimeout(() => {
        showSavingCB(false);
        showSavingDoneCB(false);
      }, 1000);
    }, 1000);
  };

  const getCommentStyle = ({ question }) => {
    const requiredComment = question?.responses?.find((response) => response?.commentsRequired);
    return !!requiredComment && !requiredComment?.comments;
  };

  const handleSetAreaView = (e) => {
    if (!store?.partnerViewMemory) {
      store.partnerViewMemory = { partnerFactSheetView: e };
    } else {
      store.partnerViewMemory.partnerFactSheetView = e;
    }
    setAreaView(e);
  };

  async function getUserPermissions() {
    const tempFactSheetAdmin = await getUserCognitoGroups("partnerReferenceAdmin");
    const tempFactSheetView = await getUserCognitoGroups("partnerReferenceAdmin");
    setisFactSheetAdmin(tempFactSheetAdmin);
    return { partnerFactSheetAdmin: tempFactSheetAdmin, partnerFactSheetView: tempFactSheetView };
  }

  const fetchFactSheetResponses = async () => {
    setLoading(true);
    const tempResponse = await api.referenceResponses.get({ partnerId: partner?.id, endpoint: `reference-responses/?partnerId=${partner?.id}` });
    setLoading(false);
    return tempResponse;
  };

  const fetchSections = async () => {
    setLoading(true);
    const factSheets = await api.productSections.getFactSheetSections({
      endpoint: `reference-sections/?isFactSheet=true&includeSubSections=true&includeQuestions=true&includeAnswers=true`,
    });
    const tempSubs = [...orderBy(factSheets[0]?.subsections, "sortOrder", "asc")];

    // filter tempSubs to remove all items except where name === 'partner-fact-sheet-subsection'
    const filteredSubs = tempSubs.filter((subsection) => subsection.name === "partner-fact-sheet-subsection");

    const tempResponseList = await fetchFactSheetResponses().catch((e) => e);
    const tempResponsesWithSort = [];
    // separate responses by questionId
    const tempResponsesByQuestionId = {};
    for (let response of tempResponseList) {
      if (!tempResponsesByQuestionId[response?.questionId]) {
        tempResponsesByQuestionId[response?.questionId] = tempResponseList?.filter((r) => r?.questionId === response?.questionId);
      }
    }
    // assign the responses to the question definitions
    for (let subsection of filteredSubs) {
      if (subsection?.questions) {
        for (let question of subsection.questions) {
          if (tempResponsesByQuestionId[question?.id]) {
            question.responses = tempResponsesByQuestionId[question?.id];
            if (question?.responses) {
              if ((question.answerType !== "multiple choice" || question.answerType !== "tag") && question.responses?.length > 1) {
                // Type changed from Multi to single answer
                const ordered = orderBy(question.responses, "startDate", "desc");
                question.responses = [ordered.shift()];
                const deleteCallList = [];
                for (let resp of ordered) {
                  deleteCallList.push(api.referenceResponses.deleteData({ id: resp.id, productId: resp.productId, partnerId: resp.partnerId }));
                }
                await Promise.all(deleteCallList);
              }
              for (let response of question.responses) {
                response.additionalComments = question?.answers?.find((answer) => answer?.value === response?.value)?.additionalComments;
                response.commentsRequired = question?.answers?.find((answer) => answer?.value === response?.value)?.commentsRequired;
                response.sortOrder = question?.answers?.find((answer) => answer?.value === response?.value)?.sortOrder;
                tempResponsesWithSort.push(response);
              }
              tempResponsesByQuestionId[question?.id] = question.responses;
            }
          }
        }
      }
    }
    responses.current = [...orderBy(tempResponsesWithSort, "sortOrder", "asc")];
    setSubsections([...filteredSubs]);
  };

  const setup = async () => {
    const permissions = await getUserPermissions();
    await fetchSections().catch((e) => e);
    const memorizedView = store?.partnerViewMemory?.partnerFactSheetView || permissions?.partnerReferenceAdmin ? "questions" : "answers";
    setAreaView(memorizedView);
  };

  useEffect(() => {
    setup().catch((e) => e);
    // eslint-disable-next-line
  }, []);

  // get partner details - manager, products
  useEffect(() => {
    const fetchPartner = async (id) => {
      setLoading(true);
      try {
        const response = await api.partner.getPartnerDetails({ id: id });
        if (!response) throw new Error("no response from getPartnerDetails call");
        // Find the matching partner in store.partners
        const matchingPartner = store.partners.find(p => p.id === id);
        // If a matching partner is found and response[0] exists, add the managerName and products to the response
        if (matchingPartner && response[0]) {
          response[0].managerName = matchingPartner.managerName;
          response[0].products = matchingPartner.products;
        }
        setPartnerDetails(response);
      } catch (error) {
        console.error("Error fetching partner:", error);
      }
    };
    if (partner.id) {
      fetchPartner(partner.id);
    }
  }, [partner]);

  return (
    <>
      {loading ? (
        <div className="is-full">
          <Loader center size="sm" content="Loading Partner Fact Sheet..." />
        </div>
      ) : (
        <div className="is-flex is-flex-direction-column pt-3" style={{ flex: "1 1", borderTop: "1px solid #ddd" }}>
          <div className="field is-capitalized is-flex is-align-items-center is-justify-content-space-between">
            {isFactSheetAdmin && (
              <span className="is-flex is-align-items-center mb-3">
                <div className="is-flex is-flex-direction-column is-justify-content-flex-end mb-2" style={{ height: "45px" }}>
                  <div className="is-flex is-align-items-flex-end">
                    {views?.map((area) => (
                      <div
                        key={uuidV4()}
                        className={`tab ${areaView === area ? "has-background-link has-text-white " : ""}`}
                        onClick={() => handleSetAreaView(area)}
                      >
                        {area}
                      </div>
                    ))}
                  </div>
                </div>
              </span>
            )}
            {requireComment && (
              <div className="is-absolute" style={{ zIndex: "98", height: "1000%", width: "100%", backgroundColor: "rgba(0, 0, 0, .25)" }} />
            )}
          </div>
          <div className="parent-flex">
            <div className="contain-and-scroll is-relative">
              {areaView === "questions" ? (
                <div>
                  {!!subsections?.length &&
                    subsections?.map((subsection) => (
                      <div key={subsection?.id} className="card mb-3">
                        <div key={subsection?.id} className="card-content">
                          <div className="contents" key={subsection?.id}>
                            <ol className="ml-4">
                              {subsection?.questions
                                ?.filter((question) => question.answerType !== undefined)
                                .map((question) => {
                                  return (
                                    <li key={question?.id}>
                                      <div className="is-flex">
                                        {question.topic && <div className="mr-2">{question.topic}:</div>}
                                        <span className="has-text-weight-bold">
                                          {question.text}
                                        </span>
                                      </div>
                                      <span className="is-italic help has-text-weight-normal mb-3">{question.description}</span>
                                      <div className="is-flex is-align-items-flex-start">
                                        <QuestionType
                                          type={question?.answerType}
                                          answers={orderBy(question?.answers, "sortOrder", "asc")}
                                          save={save}
                                          question={question}
                                          className="mb-5"
                                          style={{ flex: "1" }}
                                        />
                                        <InlineSaveIndicator showId={question?.id} showSaving={showSaving} showSavingDone={showSavingDone} />
                                      </div>
                                      {!!question?.responses?.find((response) => response?.additionalComments) && (
                                        <div
                                          className="is-relative p-3 inline-save-required"
                                          style={{ zIndex: getCommentStyle({ question }) ? "99" : "auto" }}
                                        >
                                          <CommentBox
                                            question={question}
                                            requireCommentShow={requireCommentShow}
                                            setRequireComment={setRequireComment}
                                            save={save}
                                            showSaving={showSaving}
                                            maxlength={1024}
                                          />
                                        </div>
                                      )}
                                    </li>
                                  );
                                })}
                            </ol>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div style={{ height: "300px" }} />
                </div>
              ) : (
                !!subsections?.length && (
                  <PartnerFactSheetAnswers subsections={subsections} partnerDetails={parnterDetails} partnerStatus={partner.status} />
                )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default PartnerFactSheet;
