import InputField from "../../../components/TextInput";
import { useEffect, useState } from "react";
import { isEmpty, orderBy } from "lodash";
import microservices from "../../../services/microservices";
import { getUserCognitoGroups } from '../../../store';

export default function SubsectionForm({ subsectionDetail, setSubsectionDetail, disabled, setchangepending, editmode, cancelled, setcancelled, sections, isContracts }) {
  const [selectedSection, setSelectedSection] = useState({});
  const [originalDetail, setOriginalDetail] = useState({});
  const [editDetail, setEditDetail] = useState({});
  const [originalChildren, setOriginalChildren] = useState();
  const [isDev, setIsDev] = useState(false);

  const getPermissions = async () => {
    const tempDev = await getUserCognitoGroups('dev');
    setIsDev(!!tempDev);
  };
  
  const checkPendingChanges = async (a, b) => {
    let misMatch;
    for (let item in a) {
      //eslint-disable-next-line
      if (a[item] != b[item] && item !== 'questions') {
        misMatch = true;
        break;
      };
    }
    const isValid = await validation();
    setchangepending(!!misMatch && isValid);
  };

  const validation = () => !!(subsectionDetail?.name);

  const handleSubsectionInput = async (e) => {
    subsectionDetail.name = e.target?.value;
    setSubsectionDetail({ ...subsectionDetail });
    await checkPendingChanges(subsectionDetail?.originalData || originalDetail, subsectionDetail);
  };

  const handleSection = (e) => {
    async function handleSectionAsync() {
      const tempSection = sections.find(section => section?.id === e.target.value);
      subsectionDetail.sectionId = isEmpty(tempSection) ? null : tempSection?.id;

      // increment sort order based on highest current sortOrder value;
      subsectionDetail.sortOrder = microservices.getNextValue({ list: tempSection?.subsections, key: 'sortOrder' });

      setSelectedSection({ ...tempSection });
      setSubsectionDetail({ ...subsectionDetail });
      await checkPendingChanges(subsectionDetail?.originalData || originalDetail, subsectionDetail);
    }
    handleSectionAsync().catch(e => e);
  };

  const handleSortOrder = (e) => {
    subsectionDetail.sortOrder = e.target?.value;
    setSubsectionDetail({ ...subsectionDetail });
    validation();
  };

  const resetSubsection = () => {
    subsectionDetail.name = "";
    subsectionDetail.sortOrder = "";
    subsectionDetail.sectionId = "";
    setSelectedSection({});
    setSubsectionDetail({ ...subsectionDetail });
    validation();
  };

  useEffect(() => {
    if (editmode) {
      let tempOriginalDetail;
      if (isEmpty(editDetail)) {
        tempOriginalDetail = structuredClone(subsectionDetail);
        setEditDetail({ ...tempOriginalDetail });
      }
      let tempOriginalChildren;
      if (isEmpty(originalChildren) && subsectionDetail.questions?.length) {
        subsectionDetail.questions = orderBy(subsectionDetail?.questions, 'sortOrder', 'asc');
        tempOriginalChildren = structuredClone(subsectionDetail?.questions);
        setOriginalChildren([...tempOriginalChildren]);
      }

      subsectionDetail.originalData = structuredClone(tempOriginalDetail || editDetail);
      subsectionDetail.originalChildData = structuredClone(tempOriginalChildren || originalChildren);

      const section = sections.find(section => section?.id === subsectionDetail?.sectionId);
      if (section) {
        setSelectedSection(section);
      }

    } else {
      if(isContracts){
        const contractAbstractSection = sections?.find(section => section?.isContract);
        if(!isEmpty(contractAbstractSection)){
          setSelectedSection(contractAbstractSection);
          subsectionDetail.sectionId = contractAbstractSection?.id;
        }
      }

      if (isEmpty(originalDetail)) {
        const tempDetail = structuredClone({ ...subsectionDetail });
        setOriginalDetail(tempDetail);
        subsectionDetail.originalData = structuredClone({ ...tempDetail });
      }
      if (isEmpty(originalChildren) && subsectionDetail.questions?.length) {
        subsectionDetail.originalChildData = structuredClone(orderBy(subsectionDetail?.questions, 'sortOrder', 'asc'));
      }

    }

    if (cancelled) {
      resetSubsection();
      setcancelled(false);
    }
    getPermissions();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelled, editmode, subsectionDetail]);

  return (
    <div>
      <InputField
        label="subsection"
        value={subsectionDetail?.name || ''}
        onChange={handleSubsectionInput}
        disabled={disabled}
        controlClass="mb-3"
        maxlength={64}
      />
      {!isContracts && <div className="field is-capitalized">
        <label className="label mb-0" style={{ fontWeight: "normal" }}>Sections</label>
        <div className="select mr-2">
          <select id="subsection-sectionId" onChange={e => handleSection(e)} value={selectedSection?.id || ""} disabled={disabled}>
            <option defaultValue={true}>Choose From List</option>
            {sections?.filter(section => isDev || section?.isFactSheet === 0).map(section => <option key={section?.id} value={section?.id}>{section?.name}</option>)}
          </select>
        </div>
      </div>}
      <InputField
        label="Sort Order"
        type="number"
        value={subsectionDetail?.sortOrder}
        onChange={handleSortOrder}
        disabled={disabled || !selectedSection?.id}
        style={{ maxWidth: "75px" }}
        controlClass="mb-3"
      />
    </div>
  )
}