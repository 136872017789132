import { apiFetch } from "..";
import store from '../../store';

export const create = async ({ body }) => {
  console.log('create question', body);
  const response = await apiFetch({ body, method: 'POST', endpoint: `reference-questions` });
  if (!response) return;
  return response;
};

export const getQuestion = async (id, iStore = store) => {
  if (!id) {
    const message = `Unable to get this questions. questions ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ id, endpoint: `reference-questions/${id}` });
  if (!response) return;
  iStore.productQuestions = { ...response };
  return iStore.productQuestions;
};

export const getQuestions = async ({ endpoint = `reference-questions/?includeAnswers=true`, iStore = store }) => {
  let response = await apiFetch({ endpoint });
  if (!response) return;
  // if (iStore.contracts) {
  //   const contractSubsections = iStore.productSubsections?.filter(subsection => subsection?.sectionId === iStore.contractSection?.id);
  //   const tempResponse = response?.filter(question => question?.subSectionId === contractSubsections?.find(subsection => subsection?.id === question?.subSectionId)?.id);
  //   response = tempResponse.length ? tempResponse : [];
  // } else {
  //   const tempResponse = response?.filter(question => question?.subSectionId === iStore.productSubsections?.find(subsection => subsection?.id === question?.subSectionId)?.id);
  //   response = tempResponse.length ? tempResponse : [];
  // }
  // iStore.productQuestions = [...response];
  return response;
};

export const update = async ({ id, body, fallback, iStore = store }) => {
  console.log('updating', { id, body });
  if (!id) {
    const message = `Unable to update this Answer. Answer ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ id, body, method: 'PUT', endpoint: `reference-questions/${id}` });

  if (!response) return Promise.reject('error');
  return response;
};

export const deleteQuestion = async ({ id, iStore = store }) => {
  if (!id) {
    const message = `Unable to delete this questions. questions ID (${id}) can not be found.`;
    store.actions.handleError({ message }, iStore);
    return Promise.reject(message);
  }
  const response = await apiFetch({ id, endpoint: `reference-questions/${id}`, method: 'DELETE', body: {} });
  if (!response) return Promise.reject('error');
  return response;
};

const endpoints = {
  create,
  get: ({ id, endpoint }) => id ? getQuestion(id) : getQuestions({ endpoint }),
  update,
  deleteQuestion
};

export default endpoints;